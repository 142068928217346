import PropTypes from 'prop-types'

import LotDetails from 'components/Molecules/LotDetails'

import WhiteMode from '../WhiteMode'

const Details = props => {
  const { title } = props

  return (
    <WhiteMode title={title}>
      <LotDetails {...props} />
    </WhiteMode>
  )
}

Details.propTypes = {
  title: PropTypes.string,
  preventClose: PropTypes.bool,
}

export default Details
