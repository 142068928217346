import * as Cookies from 'js-cookie'
import { setCookie, parseCookies } from 'nookies'

import { removeFirstGuestPurchase } from 'models/guests'
import { isSDK } from 'helpers/sdk'

export const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN'
export const SET_SESSION = 'SET_SESSION'
export const CLEAR_SESSION = 'CLEAR_SESSION'

export const setAccessToken =
  ({ accessToken, isGuest }) =>
  dispatch => {
    dispatch({
      type: SET_ACCESS_TOKEN,
      payload: {
        accessToken: accessToken,
      },
    })

    removeAccessToken()

    if (!isGuest) {
      if (isSDK()) {
        localStorage.setItem('citifyd_access_token', accessToken)
      } else {
        Cookies.set('citifyd_access_token', accessToken)
      }
    } else {
      if (isSDK()) {
        localStorage.setItem('citifyd_guest_access_token', accessToken)
      } else {
        Cookies.set('citifyd_guest_access_token', accessToken)
      }
    }
  }

export const setSession = user => dispatch => {
  dispatch({
    type: SET_SESSION,
    payload: {
      userId: user.id,
    },
  })

  const currentToken = getAccessToken()

  if (!currentToken) {
    dispatch(
      setAccessToken({ accessToken: user.accessToken, isGuest: user.isGuest })
    )
  }

  if (!user.isGuest) dispatch(updateSessionIfNedded())
}

export const updateSessionIfNedded = () => async (dispatch, getStore, api) => {
  await api.updateSession().then(resp => {
    if (resp.renewed) {
      dispatch(setAccessToken(resp.accessToken))
    }
  })
}

export const clearSession = () => dispatch => {
  removeAccessToken()

  dispatch({
    type: CLEAR_SESSION,
  })
}

export const removeAccessToken = () => {
  removeFirstGuestPurchase()

  if (isSDK()) {
    localStorage.removeItem('citifyd_access_token')
    localStorage.removeItem('citifyd_guest_access_token')
    localStorage.removeItem('citifyd_ticket_session')
    localStorage.removeItem('citifyd_permit_session')
  } else {
    Cookies.remove('citifyd_access_token')
    Cookies.remove('citifyd_guest_access_token')
    Cookies.remove('citifyd_ticket_session')
    Cookies.remove('citifyd_permit_session')
  }
}

export const getGuestAccessToken = ctx => {
  if (isSDK()) {
    return localStorage.getItem('citifyd_guest_access_token')
  }
  if (!ctx) {
    return Cookies.get('citifyd_guest_access_token')
  }

  return parseCookies(ctx).citifyd_guest_access_token
}
export const getImpersonateUserId = ctx => {
  if (!ctx) {
    return Cookies.get('citifyd_impersonate_user_id')
  }

  return parseCookies(ctx).citifyd_impersonate_user_id
}

export const setImpersonateUserId = userId => {
  Cookies.set('citifyd_impersonate_user_id', userId)
}

export const removeImpersonateUserId = () => {
  Cookies.remove('citifyd_impersonate_user_id')
}

export const getAccessToken = ctx => {
  if (isSDK()) {
    return (
      localStorage?.getItem('citifyd_access_token') ||
      localStorage?.getItem('citifyd_guest_access_token')
    )
  }
  if (!ctx) {
    return (
      Cookies.get('citifyd_access_token') ||
      Cookies.get('citifyd_guest_access_token')
    )
  }

  return (
    parseCookies(ctx).citifyd_access_token ||
    parseCookies(ctx).citifyd_guest_access_token ||
    parseCookies(ctx).citifyd_reservation_access_token
  )
}

export const setPermitSession = (ctx, accessToken) => {
  if (isSDK()) {
    return localStorage.setItem('citifyd_permit_session', accessToken)
  }
  setCookie(ctx, 'citifyd_permit_session', accessToken, {
    path: '/',
  })
}

export const getPermitSession = ctx => {
  if (isSDK()) {
    return localStorage?.getItem('citifyd_permit_session')
  }
  if (!ctx) {
    return Cookies.get('citifyd_permit_session')
  }

  return parseCookies(ctx).citifyd_permit_session
}

export const setTicketSession = (ctx, accessToken) => {
  if (isSDK()) {
    return localStorage.setItem('citifyd_ticket_session', accessToken)
  }
  setCookie(ctx, 'citifyd_ticket_session', accessToken, {
    path: '/',
  })
}

export const getTicketSession = ctx => {
  if (isSDK()) {
    return localStorage?.getItem('citifyd_ticket_session')
  }
  if (!ctx) {
    return Cookies.get('citifyd_ticket_session')
  }

  return parseCookies(ctx).citifyd_ticket_session
}

export const getRegularUserAccessToken = () => {
  if (isSDK()) {
    return localStorage?.getItem('citifyd_ticket_session')
  }
  Cookies.get('citifyd_access_token')
}

export const setConversionId = conversionId => {
  if (isSDK()) {
    return localStorage.setItem('citifyd_conversion_id', conversionId)
  }
  Cookies.set('citifyd_conversion_id', conversionId)
}

export const getConversionId = () => {
  if (isSDK()) {
    return localStorage?.getItem('citifyd_conversion_id')
  }
  Cookies.get('citifyd_conversion_id')
}

export const removeConversionId = () => {
  if (isSDK()) {
    return localStorage.removeItem('citifyd_conversion_id')
  }
  Cookies.remove('citifyd_conversion_id')
}
