import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { withRouter } from 'next/router'

import API from 'api'
import { getAppRoute } from 'helpers/application'
import { getGuestSession, setGuestSession } from 'helpers/guest'
import { inputPhoneNumberValidation } from 'i18n-web/validators'
import {
  internationalPhoneNumber,
  nationalPhoneNumber,
} from 'helpers/phoneFormat'
import { setCountry } from 'redux-web/utils/countries/actions'
import { setReceiptAccount } from 'redux-web/utils/receipt/actions'
import { showModal, hideModal } from 'components/Atoms/Modal/actions'
import { useTranslation } from 'i18n-web/i18next'

import Screen from './Screen'

const SignIn = props => {
  const {
    hasGuestLogin,
    hasGuestLoginButton,
    isAppleLogin,
    isFacebookLogin,
    prefilledCountryCode,
    prefilledPhoneNumber,
    preventClose,
    redirectTo,
    router,
  } = props

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const country = useSelector(state => state.countries.country)
  const parentDomain = useSelector(state => state.settings.parentDomain)
  const receiptFlow = useSelector(state => state.receipt.account)
  const isSDK = useSelector(state => state.settings.isSDK)
  const user = useSelector(state => state.user.me)

  const [phoneNumber, setPhoneNumber] = useState(
    prefilledPhoneNumber || receiptFlow?.phoneNumber
  )

  const {
    query: { application },
  } = router

  useEffect(() => {
    if (user.id && !user?.isGuest) dispatch(hideModal())
  }, [user])

  useEffect(() => {
    if (prefilledCountryCode) {
      dispatch(setCountry(prefilledCountryCode))
    }
  }, [prefilledCountryCode])

  useEffect(() => {
    if (
      prefilledCountryCode &&
      prefilledPhoneNumber &&
      !isFacebookLogin &&
      !isAppleLogin
    ) {
      const getPhoneNumber = async () => {
        const resp = await API().getPhoneNumber(
          prefilledCountryCode,
          prefilledPhoneNumber,
          { sdk: isSDK }
        )

        dispatch(
          setReceiptAccount({
            phoneNumber: prefilledPhoneNumber,
            countryCode: prefilledCountryCode,
            type: resp?.phoneNumber,
          })
        )
      }

      getPhoneNumber()
    }
  }, [
    prefilledPhoneNumber,
    prefilledCountryCode,
    isFacebookLogin,
    isAppleLogin,
  ])

  const onForgotPasswordLink = () => {
    const currentPhoneNumber =
      phoneNumber || prefilledPhoneNumber || receiptFlow?.phoneNumber
    const currentCountryCode =
      country || prefilledCountryCode || receiptFlow?.countryCode

    const validPhoneNumber =
      currentPhoneNumber &&
      inputPhoneNumberValidation(currentPhoneNumber, currentCountryCode)
        ? internationalPhoneNumber(currentPhoneNumber, currentCountryCode)
        : null

    dispatch(
      showModal({
        childProps: {
          countryCode: currentCountryCode,
          redirectTo: redirectTo,
          phoneNumber: validPhoneNumber,
        },
        modalProps: {
          preventClose: preventClose,
          ...(receiptFlow && {
            onCloseModal: () => {
              dispatch(
                showModal({
                  childProps: {
                    prefilledPhoneNumber: nationalPhoneNumber(
                      currentPhoneNumber,
                      currentCountryCode
                    ),
                    prefilledCountryCode: currentCountryCode,
                    preventRedirection: true,
                    redirectTo:
                      redirectTo ||
                      `${getAppRoute(application, 'as')}/my-passes`,
                  },
                  modalProps: {
                    preventClose: true,
                    className: 'noBorders',
                  },
                  modalType: 'signIn',
                })
              )
            },
          }),
        },
        modalType: 'forgotPassword',
      })
    )
  }

  const onSignUpLink = () => {
    setTimeout(() => dispatch(hideModal()), 100)

    router.push(
      {
        pathname: `${getAppRoute(application, 'as')}/signup`,
        query: {
          countryCode: country,
          phoneNumber: phoneNumber,
          redirectTo,
        },
      },
      `${getAppRoute(application, 'url')}/signup`
    )
  }

  const onSetGuestSession = () => {
    setGuestSession()
    dispatch(hideModal())
    setTimeout(() => router.push(redirectTo), 100)
  }

  return (
    <Screen
      application={application}
      country={prefilledCountryCode || country}
      getGuestSession={getGuestSession}
      hasGuestLogin={hasGuestLogin ?? true}
      hasGuestLoginButton={hasGuestLoginButton ?? false}
      isSDK={isSDK}
      onForgotPasswordLink={onForgotPasswordLink}
      onSetGuestSession={onSetGuestSession}
      onSignUpLink={onSignUpLink}
      parentDomain={parentDomain}
      phoneNumber={phoneNumber}
      receiptFlow={receiptFlow}
      setPhoneNumber={setPhoneNumber}
      t={t}
      {...props}
    />
  )
}

SignIn.propTypes = {
  hasGuestLogin: PropTypes.bool,
  hasGuestLoginButton: PropTypes.bool,
  isAppleLogin: PropTypes.bool,
  isFacebookLogin: PropTypes.bool,
  prefilledCountryCode: PropTypes.string,
  prefilledPhoneNumber: PropTypes.string,
  preventClose: PropTypes.bool,
  redirectTo: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  router: PropTypes.object,
}

export default withRouter(SignIn)
