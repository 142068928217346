import {
  SET_IS_SUBMITTING,
  SET_SELECTED_CARD,
  SET_NEW_CARD_OPTION,
  SET_NATIVE_PAYMENT,
  SET_NATIVE_PAYMENT_TYPE,
  SET_IS_VALID_FORM,
  RESET_PURCHASE,
  SET_TERMS_AND_CONDITIONS_AGREEMENT,
} from './actions'

const defaultState = {
  isSubmitting: false,
  selectedCard: null,
  isNewCard: false,
  nativePaymentType: null,
  selectedNativePayment: null,
  isValidForm: false,
  termsAndConditionsAgreement: false,
}

export const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_IS_SUBMITTING:
      return {
        ...state,
        isSubmitting: action.payload,
      }
    case SET_TERMS_AND_CONDITIONS_AGREEMENT:
      return {
        ...state,
        termsAndConditionsAgreement: action.payload,
      }
    case SET_SELECTED_CARD:
      return {
        ...state,
        selectedCard: action.payload,
        isNewCard: false,
        selectedNativePayment: null,
      }
    case SET_NEW_CARD_OPTION:
      return {
        ...state,
        selectedCard: null,
        selectedNativePayment: null,
        isNewCard: action.payload,
      }
    case SET_NATIVE_PAYMENT:
      return {
        ...state,
        selectedCard: null,
        isNewCard: false,
        selectedNativePayment: action.payload,
      }
    case SET_NATIVE_PAYMENT_TYPE:
      return {
        ...state,
        nativePaymentType: action.payload,
      }
    case SET_IS_VALID_FORM:
      return {
        ...state,
        isValidForm: action.payload,
      }
    case RESET_PURCHASE:
      return {
        ...defaultState,
      }
    default:
      return state
  }
}
