import router from 'next/router'

import Intercom from 'helpers/intercom'
import { showBasicModal } from 'helpers/modal'
import { i18n } from 'i18n-web/i18next'

import {
  setSession,
  clearSession,
  removeImpersonateUserId,
} from '../session/actions'

export const SET_USER = 'SET_USER'
export const SET_ORIGINAL_USER = 'SET_ORIGINAL_USER'
export const CLEAR_USER = 'CLEAR_USER'
export const CLEAR_ORIGINAL_USER = 'CLEAR_ORIGINAL_USER'
export const FETCH_ME_BEGIN = 'FETCH_ME_BEGIN'
export const FETCH_ME_ERROR = 'FETCH_ME_ERROR'
export const UPDATE_USER_BEGIN = 'UPDATE_USER_BEGIN'
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR'

export const setUser = user => dispatch => {
  dispatch({
    type: SET_USER,
    payload: user,
  })

  if (!user.isGuest) Intercom().saveUser(user)
}

export const setOriginalUser = user => dispatch => {
  dispatch({
    type: SET_ORIGINAL_USER,
    payload: user,
  })
}

export const updateUser = (data, sdk) => async (dispatch, getState, api) => {
  dispatch({
    type: UPDATE_USER_BEGIN,
  })

  return await api
    .putMe({
      ...(sdk && { sdk: true }),
      body: data,
    })
    .then(resp => {
      if (!resp.error) {
        dispatch(setUser(resp.user))
      } else {
        dispatch({
          type: UPDATE_USER_ERROR,
          payload: resp.error.message,
        })
      }

      return resp
    })
    .catch(() => {})
}

export const updateUserDefaultVehicle = vehicleId => async dispatch => {
  const response = await dispatch(
    updateUser(JSON.stringify({ defaultVehicleId: vehicleId }))
  )

  if (response.error) {
    dispatch(
      showBasicModal({
        title: i18n.t('common.title.error'),
        description: response.error.message,
      })
    )
  }

  return response
}

export const onLogout = () => async (dispatch, getState) => {
  dispatch({
    type: CLEAR_USER,
  })
  dispatch(clearSession())
  removeImpersonateUserId()

  const { isSDK } = getState().settings

  Intercom().shutdown()
  !isSDK && Intercom().init()
}

export const fetchMe = sdk => async (dispatch, getState, api) => {
  dispatch({
    type: FETCH_ME_BEGIN,
  })

  await api
    .getMe({ ...(sdk && { sdk: true }) })
    .then(resp => {
      if (resp.error) {
        if (resp.error?.code === 401) {
          dispatch(clearSession())
        } else if (resp.error?.code === 408) {
          dispatch(showBasicModal({ title: resp.error.message }))
        }
      } else {
        dispatch(setSession(resp.user))
        dispatch(setUser(resp.user))
      }
    })
    .catch(() =>
      dispatch({
        type: FETCH_ME_ERROR,
      })
    )
}

export const fetchOriginalUser = () => async (dispatch, getState, api) => {
  dispatch({
    type: FETCH_ME_BEGIN,
  })

  await api
    .getMe({ headers: { 'Impersonate-user-id': null } })
    .then(resp => {
      if (resp.error) {
        if (resp.error?.code === 401) {
          dispatch(clearSession())
        } else if (resp.error?.code === 408) {
          dispatch(showBasicModal({ title: resp.error.message }))
        }
      } else {
        if (!resp.user?.isAdmin) {
          removeImpersonateUserId()
          dispatch(onLogout())
          router.reload()
        } else {
          dispatch(setSession(resp.user))
          dispatch(setOriginalUser(resp.user))
        }
      }
    })
    .catch(() =>
      dispatch({
        type: FETCH_ME_ERROR,
      })
    )
}
