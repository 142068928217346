import React from 'react'
import { omit } from 'lodash'
import styled from 'styled-components'

export const InputComponent = styled.input.attrs(props => ({
  autoComplete: props.autoComplete || 'off',
  type: props.type || 'text',
  value: props.value || '',
}))`
  appearance: none;
  padding: 10px;
  height: 44px;
  font-size: ${props => props.theme.font.size.mobile.medium};
  color: ${props => props.theme.palette.grayDark};
  border: 1px solid ${props => props.theme.palette.grayDark}30;
  border-radius: 4px;
  margin-top: 4px;
  outline: none;
  appearance: none;
  transition: 150ms;

  &:focus {
    border: 1px solid ${props => props.theme.palette.grayDark}80;
  }

  &.error {
    border-color: ${props => props.theme.palette.error};
  }

  &.small {
    font-size: ${props => props.theme.font.size.mobile.small};
  }

  &.regular {
    font-size: ${props => props.theme.font.size.mobile.regular};
  }

  &.bold {
    font-weight: 700;
  }

  &.h2 {
    font-size: ${props => props.theme.font.size.mobile.h2};
    font-weight: normal;
  }

  &.center {
    text-align: center;
  }

  &.full {
    width: 100%;
  }

  &.noBorders {
    border: none;
    border-radius: 0;
    :focus {
      border: 0px;
    }
  }

  &.noMargins {
    margin: 0;
  }
`

const Input = React.forwardRef((props, ref) => (
  <InputComponent ref={ref} {...omit(props, 'onFieldChange')} />
))

Input.displayName = ''

export default Input
