import 'react-dates/initialize'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { isTablet, isDesktop } from 'react-device-detect'
import { useDispatch } from 'react-redux'

import { showModal } from 'components/Atoms/Modal/actions'

import DesktopDatePicker from './Desktop'

const Input = styled.input`
  border-bottom: none;
  font-weight: 400;
  outline: none;
  border: none;
  width: 100%;
  margin-top: 0;
  display: flex;
  padding: 0 0 0 18px;
  background: transparent;
  color: ${props => props.theme.palette.grayDark};
  font-size: ${props => props.theme.font.size.mobile.tiny};
  -webkit-text-fill-color: ${props => props.theme.palette.grayDark};
  opacity: 1; /* required on iOS */
  &::placeholder,
  &:disabled {
    color: ${props => props.theme.palette.grayDark};
  }
  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.md}rem) {
    margin-top: 4px;
  }
`

const DatePicker = ({ label, onChange, value }) => {
  const dispatch = useDispatch()

  const dateString = value?.format('MM/DD/YYYY')

  const openDatePickerModal = () => {
    dispatch(
      showModal({
        modalProps: {
          open: true,
          className: 'noBorders',
          mobileCloseIcon: true,
          noPadding: true,
        },
        childProps: {
          date: value,
          modalCallback: date => onChange({ value: date, label: label }),
        },
        fullScreen: true,
        modalType: 'datePicker',
      })
    )
  }

  if (isTablet || isDesktop) {
    return <DesktopDatePicker onChange={onChange} value={value} label={label} />
  }

  return (
    <div onClick={() => openDatePickerModal()}>
      <Input
        type="text"
        placeholder={label}
        name="date"
        defaultValue={dateString}
        disabled={true}
      />
    </div>
  )
}

DatePicker.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.object,
}

export default DatePicker
