import { useDispatch } from 'react-redux'

import { useTranslation } from 'i18n-web/i18next'
import { showBasicModal } from 'helpers/modal'
import API from 'api'
import WithStripeHOC from 'hocs/withStripe'
import { fetchMe } from 'redux-web/utils/user/actions'
import { hideModal } from 'components/Atoms/Modal/actions'

import AddCardModal from './Screen'

const AddCardModalWrapper = props => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const handleSubmit = async (formValues, cardToken) => {
    try {
      const response = await API().addCard({
        body: JSON.stringify({
          cardToken,
        }),
      })

      if (response.error) {
        dispatch(
          showBasicModal({
            title: t('common.title.error'),
            description: response.error.message,
            type: 'error',
          })
        )

        return response
      }

      await dispatch(fetchMe())
      dispatch(hideModal())
      dispatch(
        showBasicModal({
          title: t('myAccount.cards.modal.addCard.success.title'),
          description: t('myAccount.cards.modal.addCard.success.description'),
        })
      )

      return response
    } catch (error) {
      return error
    }
  }

  return <AddCardModal {...props} onSubmit={handleSubmit} />
}

export default WithStripeHOC(AddCardModalWrapper)
