import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Formik, Form, Field } from 'formik'

import Button from 'components/Atoms/Button'
import Text from 'components/Atoms/Text'
import { Radio } from 'components/Molecules/Form'
import { TextField } from 'components/Molecules/Form'
import { useTranslation } from 'i18n-web/i18next'

const Component = styled.div`
  text-align: center;
  min-height: 130px;
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.palette.white};
  border-radius: 6px;
`
const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

const TitleBox = styled.div`
  flex: 1;
  display: flex;
  padding: 12px 20px;
  align-items: center;
  justify-content: center;
  border: solid 3px ${props => props.theme.palette.white};
  border-bottom: none;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  background-color: ${props => props.theme.palette.primary};

  &.error {
    background-color: ${props => props.theme.palette.error};
  }

  &.facebook {
    background-color: ${props => props.theme.palette.facebookBlue};
  }
`

const Title = styled(Text)`
  font-size: ${props => props.theme.font.size.mobile.h4};
  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    font-size: ${props => props.theme.font.size.mobile.h3};
  }
`

const FormWrapper = styled.div`
  padding: 15px 30px 0;
`

const NewVehicleForm = styled(Form)`
  margin-top: 10px;
`

const ActionContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${props =>
    props.multipleButtons ? 'space-between' : 'center'};
  height: 50px;
  line-height: 40px;
  margin-top: 0;

  &.divisor {
    margin-top: 15px;
    border-top: 1px solid ${props => props.theme.palette.grayLight};
  }
`

const ActionContentButton = styled(Button)`
  flex: 1;
  height: 100%;

  &:last-of-type {
    border-left: solid 1px ${props => props.theme.palette.grayDark}30 !important;
  }
`

const ChangeVehicle = ({
  onCancel,
  onChangeVehicle,
  selectedVehicle,
  setLicensePlate,
  setSelectedVehicle,
  vehicles,
}) => {
  const { t } = useTranslation()

  const isNewVehicle = !selectedVehicle

  return (
    <Component>
      <Content>
        <TitleBox>
          <Title className="center contrastText bold">
            {t('monthly.pass.changeVehicleModal.title')}
          </Title>
        </TitleBox>

        <FormWrapper>
          {vehicles?.map(({ license, id }) => (
            <Radio
              checked={selectedVehicle === license}
              id={license}
              key={id}
              label={license}
              name="vehicle"
              onClick={() => {
                setLicensePlate(false)
                setSelectedVehicle(license)
              }}
            />
          ))}
          <Radio
            checked={isNewVehicle}
            id="newVehicle"
            label={t('monthly.pass.changeVehicleModal.newVehicleOptionLabel')}
            name="vehicle"
            onClick={() => {
              setLicensePlate('')
              setSelectedVehicle(false)
            }}
          />
          <Formik
            initialValues={{ licensePlate: '' }}
            onSubmit={() => {}}
            render={props => {
              const { values } = props

              return (
                <>
                  {isNewVehicle && (
                    <NewVehicleForm>
                      <Field
                        component={TextField}
                        disabled={!isNewVehicle}
                        label={t(
                          'monthly.pass.changeVehicleModal.newVehicleFieldLabel'
                        )}
                        name="licensePlate"
                        onKeyUp={e => setLicensePlate(e.target.value)}
                        value={values.licensePlate}
                      />
                    </NewVehicleForm>
                  )}
                </>
              )
            }}
          />
        </FormWrapper>
      </Content>
      <ActionContent className="divisor" multipleButtons>
        <ActionContentButton
          onClick={onCancel}
          className="pointer center transparent noBorders semiBold">
          {t('monthly.pass.changeVehicleModal.cancelButton')}
        </ActionContentButton>
        <ActionContentButton
          className="pointer center transparent noBorders semiBold"
          onClick={onChangeVehicle}>
          {t('monthly.pass.changeVehicleModal.submitButton')}
        </ActionContentButton>
      </ActionContent>
    </Component>
  )
}

ChangeVehicle.propTypes = {
  onCancel: PropTypes.func,
  onChangeVehicle: PropTypes.func,
  selectedVehicle: PropTypes.string,
  setLicensePlate: PropTypes.func,
  setSelectedVehicle: PropTypes.func,
  values: PropTypes.object,
  vehicles: PropTypes.arrayOf(PropTypes.object),
}

export default ChangeVehicle
