import React, { useState, useMemo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { orderBy } from 'lodash'
import PropTypes from 'prop-types'

import { getAvailableCountryCodes } from 'helpers/countries'
import { setCountry } from 'redux-web/utils/countries/actions'
import { useTranslation } from 'i18n-web/i18next'

import PhoneNumber from './Screen'

const PhoneNumberHOC = WrapperComponent => {
  const Wrapper = props => {
    const {
      country,
      name,
      className,
      disabled,
      autoFocus,
      onFieldChange,
      placeholder,
      onBlur,
      onFocus,
      inputRef,
    } = props

    const dispatch = useDispatch()
    const state = useSelector(state => state)
    const { t } = useTranslation()

    const [currentCountry, setCurrentCountry] = useState(
      country || state.countries.country
    )

    const onCountrySelect = event => {
      dispatch(setCountry(event.target.value.toLowerCase()))
      setCurrentCountry(event.target.value.toLowerCase())
      onFieldChange('phoneNumber', '')
    }

    const availableCountryCodes = useMemo(
      () => getAvailableCountryCodes(state),
      [state]
    )

    useEffect(() => {
      if (country) {
        setCurrentCountry(country)
      }
    }, [country])

    useEffect(() => {
      if (state && currentCountry !== state.countries.country && !country) {
        setCurrentCountry(state.countries.country)
      }
    }, [state])

    const inputProps = useMemo(
      () => ({
        ref: inputRef,
        name: name,
        className: className,
        country: currentCountry?.toUpperCase(),
        disabled: disabled,
        autoFocus: autoFocus,
        placeholder: placeholder,
        onFocus: onFocus,
        onBlur: onBlur,
        autoComplete: 'off',
      }),
      [
        name,
        className,
        currentCountry,
        onFieldChange,
        disabled,
        autoFocus,
        onBlur,
        onFocus,
      ]
    )

    return (
      <WrapperComponent
        convertToNationalWhileTyping
        countries={orderBy(availableCountryCodes, 'name')}
        countryFlag={currentCountry}
        inputProps={inputProps}
        onCountrySelect={onCountrySelect}
        t={t}
        {...props}
      />
    )
  }

  Wrapper.propTypes = {
    country: PropTypes.string,
    name: PropTypes.string,
    className: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    autoFocus: PropTypes.bool,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onFieldChange: PropTypes.func,
    inputRef: PropTypes.object,
  }

  return Wrapper
}

export default PhoneNumberHOC(PhoneNumber)
