import styled from 'styled-components'
import PropTypes from 'prop-types'

import Text from 'components/Atoms/Text'
import Button from 'components/Atoms/Button'
import { SendPassToEmailForm } from 'components/Molecules'

const Component = styled.div`
  text-align: center;
  min-height: 130px;
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.palette.white};
  border-radius: 6px;
`
const Description = styled(Text)`
  margin: 20px 20px 0;
  font-size: ${props => props.theme.font.size.mobile.medium};

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    font-size: ${props => props.theme.font.size.desktop.regular};
  }
`

const FormBox = styled.div`
  margin: 0 20px;
`

const TitleBox = styled.div`
  flex: 1;
  display: flex;
  padding: 12px 20px;
  align-items: center;
  justify-content: center;
  border: solid 3px ${props => props.theme.palette.white};
  border-bottom: none;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  background-color: ${props => props.theme.palette.primary};

  &.error {
    background-color: ${props => props.theme.palette.error};
  }

  &.facebook {
    background-color: ${props => props.theme.palette.facebookBlue};
  }
`

const Title = styled(Text)`
  font-size: ${props => props.theme.font.size.mobile.h4};
  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    font-size: ${props => props.theme.font.size.mobile.h3};
  }
`

const ActionContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${props =>
    props.multipleButtons ? 'space-between' : 'center'};
  height: 50px;
  line-height: 40px;
  margin-top: 25px;
  border-top: 1px solid ${props => props.theme.palette.grayDark}30;
`

const ActionContentButton = styled(Button)`
  flex: 1;
  height: 100%;

  &:not(:first-child) {
    border-left: solid 1px ${props => props.theme.palette.grayDark}30;
  }
`

const SendPassToEmail = ({
  application,
  onCancelLink,
  t,
  title,
  description,
  cancelText,
}) => (
  <Component>
    <TitleBox>
      <Title className="center contrastText bold">
        {title || t('events.receipt.sendToEmail.title')}
      </Title>
    </TitleBox>
    <Description>
      {description || t('events.receipt.sendToEmail.description')}
    </Description>
    <FormBox>
      <SendPassToEmailForm application={application} />
    </FormBox>
    <ActionContent>
      <ActionContentButton
        className="transparent noBorders semiBold"
        onClick={() => onCancelLink()}>
        {cancelText || t('events.receipt.sendToEmail.button')}
      </ActionContentButton>
    </ActionContent>
  </Component>
)

SendPassToEmail.propTypes = {
  t: PropTypes.func,
  onCancelLink: PropTypes.func,
  application: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  cancelText: PropTypes.string,
}

export default SendPassToEmail
