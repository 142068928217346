import React, { useState, useRef, useEffect } from 'react'
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import { useDispatch } from 'react-redux'
import { useRouter } from 'next/router'

import { useTranslation } from 'i18n-web/i18next'
import { setLocation } from 'redux-web/utils/monthly/actions'
import { hideModal } from 'components/Atoms/Modal/actions'
import { getMonthlyUrl } from 'models/monthly'

import Screen from './Screen'

const MonthlyParkingSearchHOC = props => {
  const inputRef = useRef()
  const [address, setAddress] = useState()
  const [locationError, setLocationError] = useState()
  const [loadingMyLocation, setLoadingMyLocation] = useState(false)
  const [isLocationInputFocused, setIsLocationInputFocused] = useState(false)

  const { t } = useTranslation()
  const router = useRouter()

  const dispatch = useDispatch()

  useEffect(() => {
    if (isLocationInputFocused) {
      setLocationError(null)
    }
  }, [isLocationInputFocused])

  const handleLocationChange = locationAddress => setAddress(locationAddress)

  const handleLocationSelect = locationAddress => {
    setIsLocationInputFocused(false)
    setAddress(locationAddress)

    geocodeByAddress(locationAddress)
      .then(results => getLatLng(results[0]))
      .then(latLng => {
        dispatch(
          setLocation({
            address: locationAddress,
            ...latLng,
          })
        )
      })

    inputRef?.current?.blur()
  }

  const getUserCurrentLocation = () => {
    setAddress(null)
    setLoadingMyLocation(true)

    const options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0,
    }

    navigator?.geolocation.getCurrentPosition(
      position => {
        const {
          coords: { latitude: lat, longitude: lng },
        } = position

        setLoadingMyLocation(false)
        setAddress(t('reservedParking.myLocation'))

        dispatch(
          setLocation({
            address: t('reservedParking.myLocation'),
            lat,
            lng,
          })
        )
      },
      error => {
        setLoadingMyLocation(false)

        if (error.code === 1) {
          setLocationError(t('reservedParking.locationDisabled.description'))
        }

        if (error.code === 3) {
          setLocationError(
            t('reservedParking.locationTimeoutExpired.description')
          )
        }
      },
      options
    )
  }

  const onSubmitClick = () => {
    if (!router.pathname?.includes('/monthly-parking')) {
      const monthlyParkingUrl = getMonthlyUrl(address)

      router.push(monthlyParkingUrl)
    }

    dispatch(hideModal())
  }

  return (
    <Screen
      address={address}
      getUserCurrentLocation={getUserCurrentLocation}
      inputRef={inputRef}
      isLocationInputFocused={isLocationInputFocused}
      loadingMyLocation={loadingMyLocation}
      locationError={locationError}
      onLocationChange={handleLocationChange}
      onLocationSelect={handleLocationSelect}
      onSubmitClick={onSubmitClick}
      setIsLocationInputFocused={setIsLocationInputFocused}
      {...props}
    />
  )
}

export default MonthlyParkingSearchHOC
