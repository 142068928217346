import { useEffect, useRef, useState } from 'react'
import PropTypes, { number } from 'prop-types'
import { useSelector } from 'react-redux'
import { isEmpty } from 'lodash'

import {
  selectVehicleAllowedCountries,
  selectUserAvailableCountry,
} from 'redux-web/selectors/countries'
import {
  selectVehicleById,
  selectDefaultVehicleId,
} from 'redux-web/selectors/vehicles'
import API from 'api'
import { useShowError } from 'helpers/hooks'

import Screen from './Screen'

const FormikWrapper = props => {
  const { setIsLoading, onSubmit, vehicleId } = props

  const [showError] = useShowError()
  const user = useSelector(state => state.user.me)
  const settings = useSelector(state => state.settings)
  const vehicle = useSelector(state => selectVehicleById(state, vehicleId))
  const defaultVehicleId = useSelector(state => selectDefaultVehicleId(state))

  const formikRef = useRef()
  const availableCountries = useSelector(selectVehicleAllowedCountries)
  const [countryList, setCountryList] = useState(null)
  const [makes, setMakes] = useState(null)
  const [colors, setColors] = useState(null)
  const [selectedCountry, setSelectedCountry] = useState(null)
  const [dynamicFields, setDynamicFields] = useState([])
  const [hasDefaultButton, setHasDefaultButton] = useState(false)

  const initialCountry = selectUserAvailableCountry(
    availableCountries,
    user?.phoneCountryCode
  )

  const initialValues = {
    color: null,
    countryCode: initialCountry,
    makeCode: null,
    label: null,
    isDefault: isEmpty(vehicle) ? false : vehicle.id === defaultVehicleId,
    ...vehicle,
  }

  useEffect(() => {
    setHasDefaultButton(true)
  }, [user])

  useEffect(() => {
    setCountryList(
      availableCountries
        .map(country => ({
          label: country.name,
          value: country.isoCode,
        }))
        .sort((a, b) => a?.label?.localeCompare(b?.label))
    )
  }, [availableCountries])

  useEffect(() => {
    setColors(
      settings?.availableVehicleColors?.map(availableColor => ({
        label: availableColor.label,
        value: availableColor.code,
      }))
    )
  }, [settings])

  useEffect(() => {
    if (!isEmpty(selectedCountry)) {
      loadCountry(selectedCountry)
    }
  }, [selectedCountry])

  useEffect(() => {
    resetCountryFields()
  }, [dynamicFields])

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true)
    setIsLoading(true)
    try {
      await onSubmit(values)
    } finally {
      setSubmitting(false)
      setIsLoading(false)
    }
  }

  const onChange = newValues => {
    setSelectedCountry(newValues.countryCode)
  }

  const loadCountry = async isoCode => {
    setIsLoading(true)

    try {
      const response = await API().getCountry(isoCode)

      if (response?.error?.message) {
        if (!isEmpty(vehicle)) {
          showError(response.error.message, 'editVehicle', {
            vehicleId: vehicle.id,
          })
        } else {
          showError(response.error.message, 'addVehicle')
        }

        return
      }

      if (response?.country?.schemas) {
        const { schemas } = response.country
        const { vehicleLicenseInfo } = schemas
        const propertyKeys = Object.keys(vehicleLicenseInfo.properties)
        let fields = []

        propertyKeys.forEach(property => {
          fields.push({
            ...vehicleLicenseInfo.properties[property],
            propertyName: `licenseInfo.${property}`,
          })
        })
        fields = fields.sort((a, b) => a.layout.order - b.layout.order)
        setDynamicFields(fields)

        setMakes(
          response.country.vehicleMakes?.map(make => ({
            label: make.name,
            value: make.code,
          }))
        )
      }
    } finally {
      setIsLoading(false)
    }
  }

  const resetCountryFields = () => {
    // skip validation on mount
    if (formikRef?.current?.fields?.countryCode?.props?.formik?.dirty) {
      formikRef.current.setFieldValue('licenseInfo', {})
      formikRef.current.setFieldValue('makeCode', '')
    }
  }

  return (
    <Screen
      {...props}
      formikRef={formikRef}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      options={{
        countries: countryList,
        colors,
        makes,
      }}
      hasDefaultButton={hasDefaultButton}
      dynamicFields={dynamicFields}
      onChange={onChange}
    />
  )
}

FormikWrapper.propTypes = {
  vehicleId: number,
  onSubmit: PropTypes.func,
  setIsLoading: PropTypes.func,
}

export default FormikWrapper
