const Search = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="35"
    viewBox="0 0 26 35">
    <g fill="none" fillRule="evenodd">
      <g stroke="#FFF" strokeWidth="2.5">
        <g>
          <g transform="translate(-43 -479) translate(19 461) rotate(-30 60.454 -27.115)">
            <circle cx="10.5" cy="10.5" r="10.5" />
            <path strokeLinecap="square" d="M10.646 21L10.646 34.315" />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default Search
