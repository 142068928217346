import App from 'next/app'
import Head from 'next/head'
import React from 'react'
import styled from 'styled-components'
import { withRouter } from 'next/router'
import { Normalize } from 'styled-normalize'
import { Provider } from 'react-redux'
import { DefaultSeo } from 'next-seo'
import { ModalProvider, BaseModalBackground } from 'styled-react-modal'
import { SkeletonTheme } from 'react-loading-skeleton'

import 'react-dates/lib/css/_datepicker.css'
import '@splidejs/splide/dist/css/themes/splide-default.min.css'
// import 'bootstrap/dist/css/bootstrap.min.css'
import { sendPageSize } from 'helpers/sdk'
import withReduxStore from 'redux-web/with-redux-store'
import withIntercom from 'hocs/withIntercom'
import withMultiLanguage from 'hocs/withMultiLanguage'
import withApplication from 'hocs/withApplication'
import GlobalStyles from 'styles/GlobalStyles'
import { appWithTranslation } from 'i18n-web/i18next'
import { routerEventsListening } from 'helpers/router'
import { fetchSettings } from 'redux-web/utils/settings/actions'
import { fetchCountries } from 'redux-web/utils/countries/actions'
import { fetchMe, fetchOriginalUser } from 'redux-web/utils/user/actions'
import {
  setParentDomain,
  setDisableHeaderAndFooter,
} from 'redux-web/utils/settings/actions'
import {
  getAccessToken,
  removeConversionId,
} from 'redux-web/utils/session/actions'
import Modal from 'components/Atoms/Modal'
import AppComponent from 'components/Molecules/AppComponent'

import SEO from '../../next-seo.config'
import { getImpersonateUserId } from '../redux-web/utils/session/actions'
const ModalBackground = styled(BaseModalBackground)`
  height: -webkit-fill-available;
  background-color: rgba(55, 56, 64, 0.9);
  z-index: 110;
`

class MyApp extends App {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    removeConversionId()
    const router = this.props?.router
    const { dispatch } = this.props?.reduxStore

    const {
      application,
      parentDomain,
      disableHeaderAndFooter,
      sdk,
      emailSupport,
      phoneNumberSupport,
    } = router?.query

    dispatch(
      fetchSettings({
        application,
        sdk,
        support: { emailSupport, phoneNumberSupport },
      })
    )
    dispatch(fetchCountries(sdk))

    if (getAccessToken()) {
      dispatch(fetchMe(sdk))
    }

    if (getImpersonateUserId()) {
      dispatch(fetchOriginalUser(sdk))
    }

    if (parentDomain) {
      dispatch(setParentDomain(parentDomain))
    }

    if (disableHeaderAndFooter) {
      dispatch(setDisableHeaderAndFooter(disableHeaderAndFooter))
    }

    if (sdk) {
      sendPageSize({
        parentDomain: parentDomain,
      })
    }

    // listening router status.
    routerEventsListening(this, parentDomain)
  }

  render() {
    const { Component, pageProps, reduxStore, err } = this.props

    return (
      <>
        <Head>
          <meta
            name="viewport"
            content="width=device-width, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"
          />
          <link
            rel="apple-touch-icon"
            sizes="57x57"
            href="/static/favicons/apple-touch-icon-57x57.png"
          />
          <link
            rel="apple-touch-icon"
            sizes="60x60"
            href="/static/favicons/apple-touch-icon-60x60.png"
          />
          <link
            rel="apple-touch-icon"
            sizes="72x72"
            href="/static/favicons/apple-touch-icon-72x72.png"
          />
          <link
            rel="apple-touch-icon"
            sizes="76x76"
            href="/static/favicons/apple-touch-icon-76x76.png"
          />
          <link
            rel="apple-touch-icon"
            sizes="114x114"
            href="/static/favicons/apple-touch-icon-114x114.png"
          />
          <link
            rel="apple-touch-icon"
            sizes="120x120"
            href="/static/favicons/apple-touch-icon-120x120.png"
          />
          <link
            rel="apple-touch-icon"
            sizes="144x144"
            href="/static/favicons/apple-touch-icon-144x144.png"
          />
          <link
            rel="apple-touch-icon"
            sizes="152x152"
            href="/static/favicons/apple-touch-icon-152x152.png"
          />
          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href="/static/favicons/apple-touch-icon-180x180.png"
          />
          <link
            rel="icon"
            type="image/png"
            href="/static/favicons/favicon-32x32.png"
            sizes="32x32"
          />
          <link
            rel="icon"
            type="image/png"
            href="/static/favicons/android-chrome-192x192.png"
            sizes="192x192"
          />
          <link
            rel="icon"
            type="image/png"
            href="/static/favicons/favicon-96x96.png"
            sizes="96x96"
          />
          <link
            rel="icon"
            type="image/png"
            href="/static/favicons/favicon-16x16.png"
            sizes="16x16"
          />
          <link
            rel="mask-icon"
            href="/static/favicons/safari-pinned-tab.svg"
            color="#f5b928"
          />
          <link rel="shortcut icon" href="/static/favicons/favicon.ico" />
        </Head>
        <DefaultSeo {...SEO} canonical={pageProps.canonicalURL} />
        <Normalize />
        <GlobalStyles />
        <Provider store={reduxStore}>
          <ModalProvider backgroundComponent={ModalBackground}>
            <SkeletonTheme color="#e8e8e8" highlightColor="#f0f0f0">
              <AppComponent
                Component={Component}
                pageProps={pageProps}
                err={err}
                loadingPage={this.state?.isLoading}
                router={this.props.router}
                intercom={this.props.intercom}
                application={this.props.application}
              />
            </SkeletonTheme>
            <Modal />
          </ModalProvider>
        </Provider>
      </>
    )
  }
}

export default withReduxStore(
  withRouter(
    withIntercom(withMultiLanguage(appWithTranslation(withApplication(MyApp))))
  )
)
