export const __NEXT_REDUX_STORE__ = '__NEXT_REDUX_STORE__'
const isServer = typeof window === 'undefined'

export function getStore() {
  if (isServer) {
    return null
  }

  return window[__NEXT_REDUX_STORE__]
}
