import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Component = styled.div`
  display: flex;
  height: 45px;
  align-items: center;
  position: relative;
`

const RadioElement = styled.div`
  margin: 5px 13px 5px 0px;
  flex: none;
  cursor: pointer;
  width: 20px;
  height: 20px;
  position: relative;

  &::before {
    content: '';
    border-radius: 100%;
    border: 1px solid ${props => props.theme.palette.grayDark}20;
    background: ${props => props.theme.palette.white};
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    box-sizing: border-box;
    pointer-events: none;
    z-index: 0;
  }
`

const Fill = styled.div`
  background: ${props => props.theme.palette.grayDark};
  width: 0;
  height: 0;
  border-radius: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
  z-index: 1;
`

const Input = styled.input`
  opacity: 0;
  z-index: 2;
  top: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &:checked {
    & ~ ${Fill} {
      width: 12px;
      height: 12px;
      transition: width 0.2s ease-out, height 0.2s ease-out;
      background: ${props => props.theme.palette.primary};
    }
  }
`

const RadioTemplate = ({
  className,
  id,
  name,
  value,
  onClick,
  checked,
  children,
}) => (
  <Component className={className}>
    <RadioElement>
      <Input
        checked={checked}
        name={name}
        id={id}
        onChange={onClick}
        type="radio"
        value={value}
      />
      <Fill />
    </RadioElement>
    {children}
  </Component>
)

RadioTemplate.propTypes = {
  name: PropTypes.string,
  value: PropTypes.any,
  className: PropTypes.string,
  onClick: PropTypes.func,
  checked: PropTypes.bool,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.node,
}

export default RadioTemplate
