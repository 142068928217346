import styled from 'styled-components'
import PropTypes from 'prop-types'

import { ForgotPasswordForm } from 'components/Molecules'
import Text from 'components/Atoms/Text'
import PageTitle from 'components/Atoms/PageTitle'

const Content = styled.div`
  margin: 20px 0;

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    margin-left: 35px;
    margin-right: 35px;
  }
`

const Divisor = styled.div`
  margin-top: 20px;
  border-top: 1px solid ${props => props.theme.palette.gray}80;
  display: none;

  &.title {
    margin-top: 0;
  }

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    display: block;
  }
`

const SigninBox = styled(Text)`
  margin-top: 15px;
  text-align: center;
`

const ForgotPassword = ({
  phoneNumber,
  redirectTo,
  countryCode,
  preventClose,
  showSignInModal,
  t,
}) => (
  <>
    <PageTitle
      showBackButton={!preventClose}
      noBorder
      modal
      shadow
      title={t('forgotPassword.title')}
    />
    <Divisor />
    <Content>
      <ForgotPasswordForm
        preventClose={preventClose}
        phoneNumber={phoneNumber}
        redirectTo={redirectTo}
        countryCode={countryCode}
      />
      <SigninBox>
        <Text
          className="semiBold inline pointer small"
          onClick={() => showSignInModal()}>
          {t('forgotPassword.signInLink')}
        </Text>
      </SigninBox>
    </Content>
  </>
)

ForgotPassword.propTypes = {
  phoneNumber: PropTypes.string,
  countryCode: PropTypes.string,
  redirectTo: PropTypes.string,
  showSignInModal: PropTypes.func,
  preventClose: PropTypes.bool,
  t: PropTypes.func,
}

export default ForgotPassword
