import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import classNames from 'classnames'
import { withRouter } from 'next/router'
import { IoIosArrowBack } from 'react-icons/io'

const Component = styled.button`
  display: inline-flex;
  align-items: center;
  padding: 0;
  line-height: 1;
  background: none;
  border: none;

  &.noBorder {
    &:after {
      display: none;
    }
  }

  &:after {
    content: '';
    height: 24px;
    border-left: 1px solid ${props => props.theme.palette.grayLight};
    margin-left: 4px;
  }

  .icon,
  .text {
    transition: 150ms;
  }

  .icon {
    margin-right: 8px;
    font-size: 22px;
    margin-left: -5px;
    color: ${props => props.theme.palette.grayDark};
  }

  .text {
    display: none;
  }

  &:hover {
    .icon,
    .text {
      color: ${props => props.theme.palette.grayDark};
    }
  }

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    &:after {
      display: none;
    }

    .icon {
      font-size: 32px;
      margin-left: -8px;
    }

    .text {
      display: inline-block;
      text-transform: uppercase;
    }
  }
`

const BackButton = ({ onBackButton, router, noBorder }) => (
  <Component
    className={classNames({ noBorder: noBorder })}
    onClick={() => (onBackButton ? onBackButton() : router.back())}>
    <IoIosArrowBack className="icon" />
  </Component>
)

BackButton.propTypes = {
  router: PropTypes.object,
  onBackButton: PropTypes.func,
  noBorder: PropTypes.bool,
}

BackButton.defaultProps = {
  noBorder: false,
}

export default withRouter(BackButton)
