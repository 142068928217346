import styled from 'styled-components'

const ShadowDivisor = styled.div`
  position: absolute;
  height: 45px;
  bottom: 0px;
  z-index: -1;
  width: 100vw;
  margin-left: ${props =>
    props.noMargin ? '-1rem' : props.extraMargin ? '-64px' : '-1rem'};
  box-shadow: 0px 3px 20px 0 rgba(0, 0, 0, 0.15);
  &:before {
    content: '';
    width: 100%;
    height: 25px;
    display: block;
    top: -25px;
    position: absolute;
    background: ${props => props.theme.palette.background};
  }

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    display: none;
  }
`

const Component = props => <ShadowDivisor {...props} />

export default Component
