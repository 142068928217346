import styled from 'styled-components'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Text from 'components/Atoms/Text'
import { getCardBrandIcon } from 'models/purchase'
import { useClientResponsive } from 'helpers/hooks'
import { useTranslation } from 'i18n-web/i18next'

const Component = styled.div`
  &.desktop {
    display: none;
  }
  &.mobile {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
  }
  @media print {
    &.desktop {
      display: none;
    }
    &.mobile {
      display: block;
    }
  }
  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    &.desktop {
      display: block;
    }
    &.mobile {
      display: none;
    }
  }
`

const Subtitle = styled(Text)`
  margin-top: 5px;

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    margin-top: 0px;
    margin-bottom: 10px;
  }
`

const CardBox = styled.div`
  margin-top: 2px;
  align-items: center;
  display: flex;
  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    margin-top: 10px;
  }
`

const CardNumber = styled(Text)`
  margin-left: 10px;
  align-items: center;
  display: inline-flex;
  font-size: ${props => props.theme.font.size.mobile.small};

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    font-size: ${props => props.theme.font.size.desktop.h4};
  }

  &:before {
    content: '\\2022 \\2022 \\2022 \\2022';
    display: inline-flex;
    margin-right: 5px;
  }
`

const PaymentCard = ({ className, card, nativePaymentType, hideSubtitle }) => {
  const { isMobile } = useClientResponsive()
  const { t } = useTranslation()

  return (
    <Component className={className}>
      {!hideSubtitle && (
        <Subtitle
          className={classNames('background small', {
            light: !isMobile,
            uppercase: !isMobile,
          })}>
          {t('receipt.details.chargedTo')}
        </Subtitle>
      )}
      <CardBox>
        {!nativePaymentType && card?.brand && (
          <>
            <img
              src={getCardBrandIcon(card?.brand)}
              width={isMobile ? '40px' : '65px'}
              height="auto"
            />
            {card?.last4 && (
              <CardNumber
                className={classNames('contrastText', {
                  bold: isMobile,
                })}>
                {card.last4}
              </CardNumber>
            )}
          </>
        )}
        {nativePaymentType && (
          <img
            src={`/static/icons/${nativePaymentType}-mark.svg`}
            width={isMobile ? '40px' : '65px'}
            height="auto"
          />
        )}
      </CardBox>
    </Component>
  )
}

PaymentCard.propTypes = {
  card: PropTypes.object,
  className: PropTypes.string,
  hideSubtitle: PropTypes.bool,
  nativePaymentType: PropTypes.string,
}

PaymentCard.defaultProps = {
  hideSubtitle: false,
}

export default PaymentCard
