import { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { isEmpty, get, zipObject } from 'lodash'

import useCreateStripeToken from 'hooks/useCreateStripeToken'
import { selectUserAvailableCountry } from 'redux-web/selectors/countries'
import { selectCardById } from 'redux-web/selectors/user'
import API from 'api'
import { selectAllCountries } from 'redux-web/selectors/countries'
import { useShowError } from 'helpers/hooks'

import Screen from './Screen'

const FormikWrapper = props => {
  const { setIsLoading, onSubmit, cardId } = props
  const { submit: submitStripeToken } = useCreateStripeToken()

  const [showError] = useShowError()
  const user = useSelector(state => state.user.me)
  const countries = useSelector(selectAllCountries)
  const card = useSelector(state => selectCardById(state, cardId))

  const formikRef = useRef()
  const [countryList, setCountryList] = useState(null)
  const [selectedCountry, setSelectedCountry] = useState(null)
  const [dynamicFields, setDynamicFields] = useState([])

  const initialCountry = selectUserAvailableCountry(
    countries,
    user?.phoneCountryCode
  )

  let initialValues = {}

  if (isEmpty(card)) {
    initialValues = {
      cardNumber: '',
      cardExpiration: '',
      cardCvc: '',
      name: '',
      countryCode: initialCountry,
    }
  } else {
    initialValues = {
      ...card,
      name: card.name,
      cardNumber: `**** **** **** ${card.last4}`,
      cardExpiration: `${card.expMonth}/${card.expYear}`,
      cardCvc: card.brand !== 'American Express' ? '***' : '****',
      countryCode: (
        get(card, 'address.country') || initialCountry
      ).toLowerCase(),
    }
  }

  useEffect(() => {
    setCountryList(
      countries
        .map(country => ({
          label: country.name,
          value: country.isoCode,
        }))
        .sort((a, b) => a.label?.localeCompare(b.label))
    )
  }, [countries])

  useEffect(() => {
    if (!isEmpty(selectedCountry)) {
      loadCountry(selectedCountry)
    }
  }, [selectedCountry])

  useEffect(() => {
    resetCountryFields()
  }, [dynamicFields])

  const onChange = newValues => {
    setSelectedCountry(newValues.countryCode)
  }

  const loadCountry = async isoCode => {
    setIsLoading(true)
    try {
      const response = await API().getCountry(isoCode)

      if (response.error?.message) {
        if (!isEmpty(card)) {
          showError(response.error.message, 'editCard', {
            cardId: card.id,
          })
        } else {
          showError(response.error.message, 'AddCard')
        }

        return
      }

      const postalCode =
        response.country?.schemas?.address?.properties?.postalCode
      const fields = []

      if (!isEmpty(postalCode)) {
        fields.push({ ...postalCode, propertyName: 'address.postalCode' })
      }
      setDynamicFields(fields)
    } finally {
      setIsLoading(false)
    }
  }

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true)
    setIsLoading(true)
    try {
      // Create new card
      if (isEmpty(card)) {
        const response = await submitStripeToken(
          values.address.postalCode,
          values.countryCode,
          values.name
        )

        return await onSubmit(values, response.token?.id)
      }

      // Update existing card
      const expirationDate = zipObject(
        ['expMonth', 'expYear'],
        values.cardExpiration.split('/').map(Number)
      )

      return await onSubmit({ ...values, ...expirationDate })
    } finally {
      setSubmitting(false)
      setIsLoading(false)
    }
  }

  const resetCountryFields = () => {
    // skip validation on mount
    if (formikRef?.current?.fields?.countryCode?.props?.formik?.dirty) {
      formikRef.current.setFieldValue('address', {})
    }
  }

  return (
    <Screen
      {...props}
      formikRef={formikRef}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      options={{
        countries: countryList,
      }}
      dynamicFields={dynamicFields}
      onChange={onChange}
      isEditing={!isEmpty(card)}
    />
  )
}

FormikWrapper.propTypes = {
  cardId: PropTypes.number,
  onSubmit: PropTypes.func,
  setIsLoading: PropTypes.func,
}

export default FormikWrapper
