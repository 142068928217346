import styled from 'styled-components'
import PropTypes from 'prop-types'

import Text from 'components/Atoms/Text'

const Component = styled.div`
  padding: 8px 12px 10px;
  background-color: ${props => props.theme.palette.quaternary};
  border-radius: 4px;

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    padding: 16px;
  }
`

const Title = styled(Text)`
  margin-bottom: 8px;
  padding-bottom: 8px;
  font-size: ${props => props.theme.font.size.mobile.medium};
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    font-size: ${props => props.theme.font.size.desktop.h4};
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
`

const Description = styled(Text)`
  font-size: ${props => props.theme.font.size.mobile.small};

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    font-size: ${props => props.theme.font.size.desktop.medium};
  }
`

const Disclaimer = ({ className, title, description }) => (
  <Component className={className}>
    <Title className="bold contrastText uppercase">{title}</Title>
    <Description className="contrastText">{description}</Description>
  </Component>
)

Disclaimer.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
}

export default Disclaimer
