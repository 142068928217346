import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Row } from 'react-styled-flexboxgrid'

const Component = styled(Row)`
  flex: 1;
  margin: 0;
  &.spacingLarge {
    margin-bottom: 40px;
  }
  &.spacingSmall {
    margin-bottom: 10px;
  }
  &.border {
    border-top: 1px solid ${props => props.theme.palette.grayDark}20;
    padding-top: 10px;
  }
`

const Group = ({ className, children }) => (
  <Component className={className}>{children}</Component>
)

Group.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
}

export default Group
