import { withRouter } from 'next/router'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { getAppRoute } from 'helpers/application'
import { showModal } from 'components/Atoms/Modal/actions'
import { useTranslation } from 'i18n-web/i18next'

import Screen from './Screen'

const ForgotPassword = props => {
  const { countryCode, phoneNumber, redirectTo, router } = props

  const {
    query: { application },
  } = router

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const modalProps = useSelector(state => state.modal.modalProps)

  const showSignInModal = () => {
    dispatch(
      showModal({
        childProps: {
          countryCode: countryCode,
          phoneNumber: phoneNumber,
          redirectTo:
            redirectTo || `${getAppRoute(application, 'as')}/my-passes`,
        },
        modalProps: {
          preventClose: modalProps?.preventClose,
          className: 'noBorders',
        },
        modalType: 'signIn',
      })
    )
  }

  return <Screen showSignInModal={showSignInModal} t={t} {...props} />
}

ForgotPassword.propTypes = {
  countryCode: PropTypes.string,
  phoneNumber: PropTypes.string,
  redirectTo: PropTypes.string,
  router: PropTypes.object,
}

export default withRouter(ForgotPassword)
