import styled from 'styled-components'
import Modal from 'styled-react-modal'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { IoIosArrowBack } from 'react-icons/io'
import { MdClose } from 'react-icons/md'
import scrollbarSize from 'scrollbar-size'
import { withRouter } from 'next/router'

import isLightMode from 'helpers/isLightMode'
import { isClientRender } from 'helpers'

const Component = Modal.styled`
  display: flex;
  justify-content: center;
  padding: 0;
  position: relative;
  z-index: 9999;
  top: ${props => (props.fullScreen ? '0' : '56px')};
  width: 100%;
  height: ${props => (props.fullScreen ? '100%' : 'auto')};
  align-self: ${props => (props.fullScreen ? 'center' : 'flex-start')};
  background-color: ${props =>
    props.fullScreen ? props.theme.palette.background : 'transparent'};
  border-radius: 0;
  opacity: 0;
  transform: scale(1.1);
  transition: 100ms ease-in-out;
  ${props => props.customStyle}

  > * {
    flex: 1
  }

  &.open {
    opacity: 1;
    transform: scale(1);
  }

  &.center {
    text-align: center;
  }

  &.noBorders {
    padding: 0;
  }

  &.sdkPosition {
    margin-top: 0;
    align-self: flex-start;
  }

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    width: 490px;
    height: auto;
    border-radius: 6px;
    align-self: initial;
    top: 0;
    max-height: 90vh;
    background-color: ${props => props.theme.palette.background};
    
    &.sdkPosition {
      margin-top: 160px;
      align-self: flex-start;
    }

    ${props => props.customStyle}
  }
`

const InnerContent = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 100vh;

  @media (max-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    padding: 30px 1rem;
  }
`

const CloseButton = styled.div`
  display: flex;
  position: absolute;
  left: 10px;
  top: 7px;
  padding: 8px;
  cursor: pointer;
  z-index: 999;

  &:after {
    content: '';
    height: 26px;
    border-left: 1px solid ${props => props.theme.palette.grayLight};
    margin-left: 10px;
  }

  &.hideBackButtonDivisor {
    &:after {
      display: none;
    }
  }

  &.mobileCloseIcon {
    right: 10px;
    padding: 0;
    left: initial;
    .closeButton {
      display: inline-block;
    }

    .backButton {
      display: none;
    }

    &:after {
      display: none;
    }
  }

  .closeButton {
    width: 30px;
    height: 30px;
    display: none;
    color: ${props => props.theme.palette.primary};

    &.white {
      color: ${props => props.theme.palette.white};
    }
  }

  .backButton {
    height: 28px;
    width: 28px;
    margin-top: -2px;
    margin-left: -10px;
    padding: 3px 4px 3px 2px;
    background: ${props => props.theme.palette.background};
    border-radius: 4px;
    color: ${props => props.theme.palette.grayDark};
  }

  @media (max-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    &.hideCloseMobile {
      display: none;
    }
  }

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    &.hideCloseDesktop {
      .closeButton {
        display: none;
      }
    }

    display: inline;
    top: -35px;
    right: -35px;
    left: auto;

    .backButton {
      display: none;
    }

    .closeButton {
      display: inline-block;
    }

    &:after {
      display: none;
    }
  }
`

const ModalComponent = ({
  afterClose,
  afterOpen,
  children,
  className,
  closeModal,
  customStyle,
  fullScreen,
  hideBackButtonDivisor,
  hideCloseDesktop,
  hideCloseMobile,
  isOpen,
  isSDK,
  mobileCloseIcon,
  noPadding,
  openAnimation,
  preventClose,
  preventCloseOnBackgroundClick,
  router,
}) => {
  const {
    query: { application },
  } = router

  // Prevents issue with jumping content when scrollbar is hidden after a modal is open
  const handleToggleScrollbar = () => {
    const sizeOfScrollbar = scrollbarSize()
    const pageIsScrollable =
      isClientRender() &&
      document.body.clientHeight > document.documentElement.clientHeight

    if (!pageIsScrollable || !sizeOfScrollbar) return null

    const element = document.querySelector('body')
    const hasScrollbarFix = !!element.style.getPropertyValue('margin-right')

    if (hasScrollbarFix) {
      element.style.marginRight = ''
    } else {
      element.style.marginRight = `${sizeOfScrollbar}px`
    }
  }

  return (
    <Component
      afterOpen={e => afterOpen(e)}
      afterClose={afterClose}
      beforeClose={() => handleToggleScrollbar()}
      beforeOpen={() => handleToggleScrollbar()}
      className={classNames(className, {
        open: openAnimation,
        sdkPosition: isSDK,
      })}
      customStyle={customStyle}
      fullScreen={fullScreen}
      isOpen={isOpen}
      onBackgroundClick={e =>
        !preventClose && !preventCloseOnBackgroundClick && closeModal(e)
      }
      onEscapeKeydown={e => !preventClose && closeModal(e)}>
      {fullScreen && !preventClose && (
        <CloseButton
          className={classNames({
            hideCloseDesktop,
            hideCloseMobile,
            mobileCloseIcon,
            hideBackButtonDivisor,
          })}
          onClick={e => closeModal(e)}>
          <MdClose
            className={classNames('closeButton', {
              white: !isLightMode(application),
            })}
          />
          <IoIosArrowBack className="backButton" />
        </CloseButton>
      )}
      {noPadding ? children : <InnerContent>{children}</InnerContent>}
    </Component>
  )
}

ModalComponent.propTypes = {
  afterClose: PropTypes.func,
  afterOpen: PropTypes.func,
  children: PropTypes.node,
  className: PropTypes.string,
  closeModal: PropTypes.func,
  customStyle: PropTypes.any,
  fullScreen: PropTypes.bool,
  hideBackButtonDivisor: PropTypes.bool,
  hideCloseDesktop: PropTypes.bool,
  hideCloseMobile: PropTypes.bool,
  isOpen: PropTypes.bool,
  isSDK: PropTypes.bool,
  mobileCloseIcon: PropTypes.bool,
  noPadding: PropTypes.bool,
  openAnimation: PropTypes.bool,
  preventClose: PropTypes.bool,
  preventCloseOnBackgroundClick: PropTypes.bool,
  router: PropTypes.object,
}

ModalComponent.defaultProps = {
  preventClose: false,
}

export default withRouter(ModalComponent)
