import styled from 'styled-components'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Button from 'components/Atoms/Button'
import PageTitle from 'components/Atoms/PageTitle'
import Text from 'components/Atoms/Text'
import isLightMode from 'helpers/isLightMode'
import { formatCurrency } from 'i18n-web/formatters'

const SectionRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid ${props => props.theme.palette.grayLight};
  padding: 10px 0;
  &:first-of-type {
    margin-top: 20px;
  }
  &.last {
    border-bottom: 1px solid ${props => props.theme.palette.grayLight};
    margin-bottom: 40px;
  }
`

const TextValue = styled(Text)`
  font-size: ${props => props.theme.font.size.mobile.h2};
  font-weight: 700;
  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    font-size: ${props => props.theme.font.size.mobile.h2};
  }
`

const TextDescription = styled(Text)`
  font-size: ${props => props.theme.font.size.mobile.regular};
  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    font-size: ${props => props.theme.font.size.desktop.large};
  }
`

const Subtitle = styled(Text)`
  font-weight: 300;
  line-height: 24px;
  font-size: ${props => props.theme.font.size.mobile.small};
  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    font-size: ${props => props.theme.font.size.desktop.h4};
    font-weight: 400;
    line-height: 34px;
    margin-top: 15px;
    margin-bottom: 30px;
  }
`

const SectionColumn = styled.div`
  padding: 0;
`

const CancelLink = styled.div`
  padding: 15px 0;
  text-align: center;
`

const Header = styled.div`
  padding: 30px 1rem 0;
  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    padding: 0;
  }
`

const Component = styled.div`
  display: flex;
  flex-direction: column;
`

const Content = styled.div`
  padding: 0 1rem 90px;
  overflow-y: scroll;
  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    margin-left: 35px;
    margin-right: 35px;
    padding: 0;
    overflow: auto;
  }
`

const CancelText = styled(Text)`
  font-size: ${props => props.theme.font.size.mobile.medium};
  font-weight: 700;
  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    font-size: ${props => props.theme.font.size.desktop.h4};
  }
`

const Divisor = styled.div`
  margin-top: 20px;
  border-top: 2px solid ${props => props.theme.palette.gray};
  display: none;
  &.title {
    margin-top: 0;
  }
  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    display: block;
    margin-top: 12px;
    margin-bottom: 10px;
  }
`

const Screen = ({
  application,
  disabledLinks,
  onCancel,
  onCancelPassButton,
  refund,
  t,
}) => (
  <Component>
    <Header>
      <PageTitle
        modal
        noBorder
        showBackButton={true}
        shadow
        title={t('cancelYourPass.title')}
      />
    </Header>
    <Divisor />
    <Content>
      <Subtitle>{t('cancelYourPass.subTitle')}</Subtitle>

      {refund.breakdown.map((item, index) => (
        <SectionRow
          key={index}
          className={classNames({
            last: index === refund.breakdown.length - 1,
          })}>
          <SectionColumn xs={6}>
            <TextDescription>{item.label}</TextDescription>
          </SectionColumn>
          <SectionColumn xs={6}>
            <TextValue className="right">
              {formatCurrency(item.value, refund.currency)}
            </TextValue>
          </SectionColumn>
        </SectionRow>
      ))}

      <Button
        className={classNames('full', {
          primary: isLightMode(application),
          contrastText: !isLightMode(application),
        })}
        disabled={disabledLinks}
        onClick={() => !disabledLinks && onCancelPassButton()}>
        {t('cancelYourPass.button').toUpperCase()}
      </Button>

      <CancelLink>
        <CancelText
          onClick={() => !disabledLinks && onCancel()}
          className={classNames('inline pointer center', {
            disabled: disabledLinks,
          })}>
          {t('common.button.notNow')}
        </CancelText>
      </CancelLink>
    </Content>
  </Component>
)

Screen.propTypes = {
  application: PropTypes.string,
  disabledLinks: PropTypes.bool,
  onCancel: PropTypes.func,
  onCancelPassButton: PropTypes.func,
  refund: PropTypes.object,
  t: PropTypes.func,
}

export default Screen
