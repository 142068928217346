import PropTypes from 'prop-types'
import styled from 'styled-components'
import classNames from 'classnames'

import Button from 'components/Atoms/Button'

import IconExchange from './Icons/Exchange'
import IconTransfer from './Icons/Transfer'
import IconSupport from './Icons/Support'
import IconDirections from './Icons/Directions'
import IconCancel from './Icons/Cancel'
import IconCash from './Icons/Cash'
import IconSendToEmail from './Icons/Email'
import IconViewPass from './Icons/Search'
import IconDownloadApp from './Icons/DownloadApp'
import IconReclaim from './Icons/Reclaim'

const Component = styled(Button)`
  height: 72px;
  display: flex;
  align-items: center;
  padding: 6px 10px 6px 6px;
  border: none;
  margin-bottom: 10px;
  text-align: left;
  font-size: ${props => props.theme.font.size.mobile.regular};
  box-shadow: 2px 4px 10px 0 rgba(55, 56, 64, 0.3);
  &.defaultColor {
    color: ${props => props.theme.palette.grayDark};
    background: ${props => props.theme.palette.grayLight};
  }

  &.blueDark,
  &.blue,
  &.quaternary,
  &.primary {
    color: ${props => props.theme.palette.white};
  }

  &.blueDark {
    background: #0099b8;
  }

  &.blue {
    background: #12b6d7;
  }

  &.primary {
    background: ${props => props.theme.palette.primary};
  }

  &.quaternary {
    background: ${props => props.theme.palette.quaternary};
  }
`

const IconComponent = styled.div`
  padding: 0 19px 0 13px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 17px;
  border-right: 1px solid ${props => props.theme.palette.white}60;
  svg {
    width: 34px;
    height: 34px;
  }

  &.defaultColor {
    svg g,
    svg path {
      fill: ${props => props.theme.palette.grayDark};
    }
  }
`

const ManagePassButton = ({ onClick, title, type, defaultColor, color }) => {
  const renderIcon = () => {
    let icon

    switch (type) {
      case 'transfer':
        icon = <IconTransfer />
        break
      case 'exchange':
        icon = <IconExchange />
        break
      case 'cancel':
        icon = <IconCancel />
        break
      case 'purchase':
        icon = <IconCash />
        break
      case 'support':
        icon = <IconSupport />
        break
      case 'directions':
        icon = <IconDirections />
        break
      case 'sendToEmail':
        icon = <IconSendToEmail />
        break
      case 'viewPass':
        icon = <IconViewPass />
        break
      case 'downloadApp':
        icon = <IconDownloadApp />
        break
      case 'reclaim':
        icon = <IconReclaim />
        break
      case 'retransfer':
        icon = <IconExchange />
        break
      case 'updateVehicle':
        icon = <IconExchange />
        break
      default:
        icon = <IconExchange />
    }

    return icon
  }

  return (
    <Component
      onClick={onClick}
      className={classNames('full', {
        defaultColor: defaultColor && !color,
        [color]: color,
      })}>
      <IconComponent
        className={classNames({
          defaultColor: defaultColor && !color,
          [color]: color,
        })}>
        {renderIcon()}
      </IconComponent>
      {title.toUpperCase()}
    </Component>
  )
}

ManagePassButton.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string,
  color: PropTypes.string,
  onClick: PropTypes.func,
  defaultColor: PropTypes.bool,
}

ManagePassButton.defaultProps = {
  defaultColor: true,
}

export default ManagePassButton
