import { find } from 'lodash'

import { convertToQueryString } from 'helpers'

const MAPS_URL = 'https://maps.googleapis.com/maps/api/geocode/json'
const TIMEZONE_URL = 'https://maps.googleapis.com/maps/api/timezone/json'

const fetchAPI = url =>
  fetch(url, {
    headers: {
      Accept: 'application/json',
    },
    method: 'GET',
    timeout: 10000, // 10s timeout
  }).then(resp => resp.json())

export const place = async addr => {
  const payload = {
    address: encodeURIComponent(addr),
    key: encodeURIComponent(process.env.NEXT_PUBLIC_GOOGLE_API_KEY),
  }

  const params = convertToQueryString(payload)
  const url = `${MAPS_URL}?${params}`

  const res = await fetchAPI(url)

  const {
    results: [address],
  } = res

  if (!address) return {}

  const state = find(address.address_components, {
    types: ['administrative_area_level_1'],
  })

  const city = find(address.address_components, {
    types: ['locality'],
    // eslint-disable-next-line
    types: ['administrative_area_level_2'],
  })

  return { city: city && city.long_name, state: state && state.short_name }
}

export const getLocationTimezone = async (latitude, longitude) => {
  const payload = {
    location: `${latitude},${longitude}`,
    timestamp: encodeURIComponent(Math.round(Date.now() / 1000)),
    language: 'en',
    key: encodeURIComponent(process.env.NEXT_PUBLIC_GOOGLE_API_KEY),
  }

  const params = convertToQueryString(payload)
  const url = `${TIMEZONE_URL}?${params}`

  const res = await fetchAPI(url)

  return res
}
