import * as Yup from 'yup'

import { i18n } from 'i18n-web/i18next'
import { passwordSchema } from 'i18n-web/validators'

const validationSchema = passwordRules =>
  Yup.object().shape({
    currentPassword: Yup.string().required(
      i18n.t(
        'myAccount.settings.changePassword.modal.form.currentPassword.error'
      )
    ),
    password: passwordSchema({ passwordRules }),
    confirmPassword: Yup.string()
      .oneOf(
        [Yup.ref('password'), null],
        i18n.t(
          'myAccount.settings.changePassword.modal.form.confirmPassword.error.mismatch'
        )
      )
      .required(
        i18n.t(
          'myAccount.settings.changePassword.modal.form.confirmPassword.error.required'
        )
      ),
  })

export default validationSchema
