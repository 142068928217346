export const SET_FLOW = 'SET_FLOW'
export const SET_RATE = 'SET_RATE'
export const SET_CARD = 'SET_CARD'
export const SET_LICENSE_PLATE = 'SET_LICENSE_PLATE'
export const SET_PHONE_NUMBER = 'SET_PHONE_NUMBER'
export const SET_PARKING_CODE = 'SET_PARKING_CODE'
export const RESET_PAY_BY_SIGNAGE = 'RESET_PAY_BY_SIGNAGE'

export const setFlow = flow => dispatch => {
  dispatch({
    type: SET_FLOW,
    payload: flow,
  })
}

export const setRate = rate => dispatch => {
  dispatch({
    type: SET_RATE,
    payload: rate,
  })
}

export const setCard = card => dispatch => {
  dispatch({
    type: SET_CARD,
    payload: card,
  })
}

export const setLicensePlate = licensePlate => dispatch => {
  dispatch({
    type: SET_LICENSE_PLATE,
    payload: licensePlate,
  })
}

export const setPhoneNumber = phoneNumber => dispatch => {
  dispatch({
    type: SET_PHONE_NUMBER,
    payload: phoneNumber,
  })
}

export const setParkingCode = parkingCode => dispatch => {
  dispatch({
    type: SET_PARKING_CODE,
    payload: parkingCode,
  })
}

export const resetPayBySignage = () => dispatch => {
  dispatch({
    type: RESET_PAY_BY_SIGNAGE,
  })
}
