import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Link } from 'i18n-web/i18next'

const Item = styled.a`
  font-size: ${props => props.theme.font.size.mobile.tiny};

  background: transparent;
  padding: 2px 15px;
  display: block;
  width: 100%;
  clear: both;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  line-height: 30px;
  cursor: pointer;

  &:hover {
    color: ${props => props.theme.palette.quaternary};
  }

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.md}rem) {
    font-size: ${props => props.theme.font.size.desktop.small};
  }
`

const MenuDropdownItem = ({ href, children, onClick }) => (
  <Link href={href}>
    <Item onClick={onClick}>{children}</Item>
  </Link>
)

MenuDropdownItem.propTypes = {
  children: PropTypes.string,
  href: PropTypes.string,
  onClick: PropTypes.func,
}

export default MenuDropdownItem
