import styled from 'styled-components'
import PropTypes from 'prop-types'

import Button from 'components/Atoms/Button'
import Text from 'components/Atoms/Text'
import { TwoFactorAuthenticationForm } from 'components/Molecules/SignIn'

const Component = styled.div`
  text-align: center;
  min-height: 130px;
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.palette.white};
  border-radius: 6px;
`

const TitleBox = styled.div`
  flex: 1;
  display: flex;
  padding: 12px 20px;
  align-items: center;
  justify-content: center;
  border: solid 3px ${props => props.theme.palette.white};
  border-bottom: none;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  background-color: ${props => props.theme.palette.primary};

  &.error {
    background-color: ${props => props.theme.palette.error};
  }

  &.facebook {
    background-color: ${props => props.theme.palette.facebookBlue};
  }
`

const Title = styled(Text)`
  font-size: ${props => props.theme.font.size.mobile.h4};
  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    font-size: ${props => props.theme.font.size.mobile.h3};
  }
`

const ActionContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${props =>
    props.multipleButtons ? 'space-between' : 'center'};
  height: 50px;
  line-height: 40px;
  margin-top: 25px;
  border-top: 1px solid ${props => props.theme.palette.grayDark}30;
`

const ActionContentButton = styled(Button)`
  flex: 1;
  height: 100%;

  &:not(:first-child) {
    border-left: solid 1px ${props => props.theme.palette.grayDark}30;
  }
`

const TwoFactorAuthentication = ({
  formikRef,
  onCancel,
  onResendCode,
  credentials,
  t,
}) => (
  <Component>
    <TitleBox>
      <Title className="center contrastText bold">
        {t('twoFactorAuthentication.title')}
      </Title>
    </TitleBox>
    <TwoFactorAuthenticationForm
      formikRef={formikRef}
      credentials={credentials}
    />
    <ActionContent>
      <ActionContentButton
        className="transparent noBorders semiBold"
        onClick={() => onCancel()}>
        {t('twoFactorAuthentication.cancel')}
      </ActionContentButton>

      <ActionContentButton
        className="transparent noBorders semiBold"
        onClick={() => onResendCode('sms')}>
        {t('twoFactorAuthentication.resendCode')}
      </ActionContentButton>

      <ActionContentButton
        className="transparent noBorders semiBold"
        onClick={() => onResendCode('call')}>
        {t('twoFactorAuthentication.callMe')}
      </ActionContentButton>
    </ActionContent>
  </Component>
)

TwoFactorAuthentication.propTypes = {
  formikRef: PropTypes.func,
  onCancel: PropTypes.func,
  onResendCode: PropTypes.func,
  t: PropTypes.func,
  credentials: PropTypes.object,
}

export default TwoFactorAuthentication
