import styled from 'styled-components'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Button from 'components/Atoms/Button'
import Text from 'components/Atoms/Text'

const Component = styled.div`
  text-align: center;
  min-height: 130px;
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.palette.white};
  border-radius: 6px;
`
const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

const TitleBox = styled.div`
  flex: 1;
  display: flex;
  padding: 12px 20px;
  align-items: center;
  justify-content: center;
  border: solid 3px ${props => props.theme.palette.white};
  border-bottom: none;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  background-color: ${props => props.theme.palette.primary};

  &.error {
    background-color: ${props => props.theme.palette.error};
  }

  &.facebook {
    background-color: ${props => props.theme.palette.facebookBlue};
  }
`

const Title = styled(Text)`
  font-size: ${props => props.theme.font.size.mobile.h4};
  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    font-size: ${props => props.theme.font.size.mobile.h3};
  }
`

const DescriptionBox = styled.div`
  flex: 1;
  margin-top: 15px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: ${props => (props.extraPadding ? '15px' : '0')};
`

const Description = styled(Text)`
  font-size: ${props => props.theme.font.size.mobile.medium};

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    font-size: ${props => props.theme.font.size.desktop.regular};
  }
`

const ActionContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${props =>
    props.multipleButtons ? 'space-between' : 'center'};
  height: 50px;
  line-height: 40px;
  margin-top: 0;

  &.divisor {
    margin-top: 15px;
    border-top: 1px solid ${props => props.theme.palette.grayLight};
  }
`

const ActionContentButton = styled(Button)`
  flex: 1;
  height: 100%;

  &:last-of-type {
    border-left: solid 1px ${props => props.theme.palette.grayDark}30 !important;
  }
`

const Screen = ({
  actionButtons: {
    rightButtonOnClick,
    rightButtonText,
    singleButtonOnClick,
    singleButtonText,
  },
  component,
  description,
  hideButton,
  onButtonClicked,
  t,
  textButton,
  title,
  type,
}) => {
  const handleSingleButtonClick = () => {
    onButtonClicked()
    singleButtonOnClick()
  }

  const handleRightButtonClick = () => {
    onButtonClicked()
    rightButtonOnClick()
  }

  return (
    <Component>
      <Content>
        {title && (
          <TitleBox className={type}>
            <Title className="center contrastText bold">{title}</Title>
          </TitleBox>
        )}
        {description && (
          <DescriptionBox extraPadding={hideButton}>
            <Description className="center">{description}</Description>
          </DescriptionBox>
        )}
        {component}
      </Content>
      {!hideButton && (
        <ActionContent
          className={classNames({ divisor: !!description || !!component })}
          multipleButtons={rightButtonText && rightButtonOnClick}>
          {singleButtonOnClick && singleButtonText ? (
            <ActionContentButton
              className="pointer center transparent noBorders semiBold"
              onClick={handleSingleButtonClick}>
              {singleButtonText}
            </ActionContentButton>
          ) : (
            <ActionContentButton
              onClick={() => onButtonClicked()}
              className="pointer center transparent noBorders semiBold">
              {textButton ? textButton : t('common.button.gotIt')}
            </ActionContentButton>
          )}
          {rightButtonText && rightButtonOnClick && (
            <ActionContentButton
              className="pointer center transparent noBorders semiBold"
              onClick={handleRightButtonClick}>
              {rightButtonText}
            </ActionContentButton>
          )}
        </ActionContent>
      )}
    </Component>
  )
}

Screen.propTypes = {
  actionButtons: PropTypes.shape({
    rightButtonOnClick: PropTypes.func,
    rightButtonText: PropTypes.string,
    singleButtonOnClick: PropTypes.func,
    singleButtonText: PropTypes.string,
  }),
  component: PropTypes.node,
  description: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  hideButton: PropTypes.bool,
  onButtonClicked: PropTypes.func,
  t: PropTypes.func,
  textButton: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
}

Screen.defaultProps = {
  actionButtons: {},
  description: undefined,
  hideButton: false,
  type: undefined,
}

export default Screen
