import { useState, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import { useSelector, useDispatch } from 'react-redux'

import { useTranslation } from 'i18n-web/i18next'
import { setLicensePlate } from 'redux-web/utils/payBySignage/actions'
import Tooltip from 'components/Atoms/Tooltip'
import Text from 'components/Atoms/Text'
import SelectSearch from 'components/Atoms/SelectSearch'

const Component = styled.div`
  margin-bottom: 40px;

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    ${({ isMonthly }) =>
      isMonthly &&
      `
      display: flex;
      flex-direction: row;
      gap: 30px; 
    `}
  }
`

const Container = styled.div`
  width: 100%;

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    ${({ isMonthly }) =>
      isMonthly &&
      `
      width: 850px;
    `}
  }
`

const VehicleAlertMobile = styled(Text)`
  margin-top: 20px;
  padding: 8px 12px;
  border-radius: 4px;
  color: ${props => props.theme.palette.white};
  background-color: ${props => props.theme.palette.quaternary};

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    margin-top: 0;
  }
`

const VehicleAlertContainer = styled.div`
  margin: ${({ isMonthly }) => (isMonthly ? '0' : '0 0 15px')};
`

const TooltipText = styled(Text)`
  margin-bottom: 5px;
`

const VehicleSearch = ({
  data,
  hideAlert,
  hideLabel,
  isMonthly,
  licensePlateRef,
  onChange,
  submitCount,
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { licensePlate } = useSelector(state => state.payBySignage)

  const [isValid, setIsValid] = useState(true)
  const [showAlert, setShowAlert] = useState(false)
  const [value, setValue] = useState(licensePlate)

  useEffect(() => {
    if (!submitCount || submitCount <= 0) return

    if (isEmpty(value)) {
      setIsValid(false)
    } else {
      setIsValid(true)
    }
  }, [submitCount])

  const dataFormatted = useMemo(
    () =>
      data && data.map(item => ({ label: item.license, value: item.license })),
    [data]
  )

  const onFocus = () => {
    if (isEmpty(value)) {
      setIsValid(false)
    } else {
      setIsValid(true)
    }

    if (!showAlert) {
      setShowAlert(true)
    }
  }

  const onKeyUp = () => {
    if (isEmpty(value)) {
      setIsValid(false)
    } else {
      setIsValid(true)
    }
  }

  const onBlur = () => {
    if (isEmpty(value)) {
      setIsValid(false)
    }
  }

  useEffect(() => {
    dispatch(setLicensePlate(value))
    if (onChange) {
      onChange(value)
    }
  }, [value])

  return (
    <Component isMonthly={isMonthly}>
      <Container isMonthly={isMonthly}>
        {!isMonthly && !hideAlert && (
          <VehicleAlertContainer isMonthly={isMonthly}>
            <VehicleAlertMobile className="small semiBold">
              {t('payBySignage.licensePlate.disclaimer')}
            </VehicleAlertMobile>
          </VehicleAlertContainer>
        )}
        <SelectSearch
          data={dataFormatted}
          error={t('common.form.error.required')}
          fieldRef={licensePlateRef}
          isValid={isValid}
          label={hideLabel ? null : t('payBySignage.licensePlate.label')}
          uppercase
          onBlur={onBlur}
          onChangeCallback={data => setValue(data)}
          onFocus={onFocus}
          onKeyUp={onKeyUp}
          hideArrow={true}
        />
        <Tooltip title={t('payBySignage.licensePlate.tooltip.title')}>
          <TooltipText className="tiny semiBold">
            {t('payBySignage.licensePlate.tooltip.description')}
          </TooltipText>
        </Tooltip>
      </Container>
      {isMonthly && !hideAlert && (
        <VehicleAlertContainer isMonthly={isMonthly}>
          <VehicleAlertMobile className="small semiBold">
            {t('payBySignage.licensePlate.disclaimer')}
          </VehicleAlertMobile>
        </VehicleAlertContainer>
      )}
    </Component>
  )
}

VehicleSearch.propTypes = {
  data: PropTypes.array,
  hideLabel: PropTypes.bool,
  isMonthly: PropTypes.bool,
  licensePlateRef: PropTypes.object,
  onChange: PropTypes.func,
  hideAlert: PropTypes.bool,
  submitCount: PropTypes.number,
}

export default VehicleSearch
