import { useDispatch } from 'react-redux'

import { useTranslation } from 'i18n-web/i18next'
import { fetchMe, updateUserDefaultVehicle } from 'redux-web/utils/user/actions'
import { showBasicModal } from 'helpers/modal'
import { hideModal } from 'components/Atoms/Modal/actions'
import API from 'api'

import EditVehicleModal from './Screen'

const EditVehicleModalWrapper = props => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const handleSubmit = async formValues => {
    try {
      const {
        id,
        color,
        countryCode,
        makeCode,
        label,
        isDefault,
        licenseInfo,
      } = formValues
      const response = await API().editVehicle(formValues.id, {
        body: JSON.stringify({
          id,
          color,
          countryCode,
          makeCode,
          label,
          licenseInfo,
        }),
      })

      if (response.error) {
        dispatch(
          showBasicModal({
            title: t('common.title.error'),
            description: response.error.message,
            type: 'error',
          })
        )

        return response
      }

      if (isDefault) {
        await dispatch(updateUserDefaultVehicle(response.vehicle.id))
      } else {
        await dispatch(fetchMe())
      }

      dispatch(hideModal())
      dispatch(
        showBasicModal({
          title: t('myAccount.vehicles.modal.editVehicle.success.title'),
          description: (
            <span style={{ whiteSpace: 'pre-wrap', fontWeight: 'bold' }}>
              {t('myAccount.vehicles.modal.editVehicle.success.description')}
            </span>
          ),
        })
      )

      return response
    } catch (error) {
      return error
    }
  }

  const deleteVehicle = async vehicleId => {
    try {
      const response = await API().deleteVehicle(vehicleId)

      if (response.error) {
        dispatch(
          showBasicModal({
            title: t('common.title.error'),
            description: response.error.message,
            type: 'error',
          })
        )

        return response
      }

      await dispatch(fetchMe())
      dispatch(hideModal())
      dispatch(
        showBasicModal({
          title: t('myAccount.vehicles.modal.deleteVehicle.success.title'),
          description: t(
            'myAccount.vehicles.modal.deleteVehicle.success.description'
          ),
        })
      )

      return response
    } catch (error) {
      return error
    }
  }

  const handleDeleteVehicle = vehicleId => {
    dispatch(
      showBasicModal({
        title: t('myAccount.vehicles.modal.deleteVehicle.confirmation.title'),
        description: t(
          'myAccount.vehicles.modal.deleteVehicle.confirmation.description'
        ),
        actionButtons: {
          rightButtonOnClick: () => deleteVehicle(vehicleId),
          rightButtonText: t(
            'myAccount.vehicles.modal.deleteVehicle.confirmation.rightButton'
          ),
          singleButtonOnClick: hideModal,
          singleButtonText: t(
            'myAccount.vehicles.modal.deleteVehicle.confirmation.leftButton'
          ),
        },
      })
    )
  }

  return (
    <EditVehicleModal
      {...props}
      onSubmit={handleSubmit}
      onDeleteVehicle={handleDeleteVehicle}
    />
  )
}

export default EditVehicleModalWrapper
