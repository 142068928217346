import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import PhoneInput from 'react-phone-number-input/input'

import CountryBox from 'components/Atoms/CountryBox'
import { InputComponent } from 'components/Atoms/Input'

const CountryComponent = styled.div`
  display: flex;
  align-items: flex-start;

  .phoneInputGroup {
    width: 100%;
  }
`

const Input = styled(InputComponent)`
  width: 100%;
`

const Component = ({
  countries,
  countryFlag,
  disabled,
  inputProps,
  name,
  onChange,
  onCountrySelect,
  onFieldChange,
  onKeyUp,
  value,
}) => (
  <CountryComponent>
    <CountryBox
      disabled={disabled}
      countries={countries}
      countryFlag={countryFlag}
      onChange={onCountrySelect}
    />
    <PhoneInput
      {...inputProps}
      autoFocus={inputProps.autoFocus}
      inputComponent={Input}
      onKeyUp={onKeyUp}
      onChange={value => {
        const inputValue = value || ''

        if (onChange) {
          onChange(inputValue)
        }
        onFieldChange(name, inputValue)
      }}
      type="tel"
      value={value}
    />
  </CountryComponent>
)

Component.defaultProps = {
  countries: [],
}

Component.propTypes = {
  countries: PropTypes.array,
  countryFlag: PropTypes.string,
  disabled: PropTypes.bool,
  inputProps: PropTypes.object,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onCountrySelect: PropTypes.func,
  onFieldChange: PropTypes.func,
  onKeyUp: PropTypes.func,
  value: PropTypes.string,
}

export default Component
