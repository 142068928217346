import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const ItemLinkComponent = styled.a`
  padding: 6px 8px;
  font-size: ${props => props.theme.font.size.mobile.tiny};
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0.08px;
  text-transform: uppercase;
  color: ${props => props.theme.palette.white};

  .highlight {
    color: ${props => props.theme.palette.highlight};

    &:hover {
      color: ${props => props.theme.palette.grayDark};
      background-color: ${props => props.theme.palette.highlight};
    }
  }

  &:visited {
    color: ${props => props.theme.palette.white};
  }

  &.active,
  &:hover {
    color: ${props => props.theme.palette.grayDark};
    background-color: ${props => props.theme.palette.highlight};
    cursor: pointer;
  }

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.md}rem) {
    display: inline;
    padding: 5px 10px;
    font-size: ${props => props.theme.font.size.desktop.tiny};
  }
`

const NavItem = forwardRef(
  ({ application, children, className, href, onClick }, ref) => (
    <ItemLinkComponent
      application={application}
      className={className}
      href={href}
      onClick={onClick}
      ref={ref}>
      {children}
    </ItemLinkComponent>
  )
)

NavItem.displayName = 'NavItem'

NavItem.propTypes = {
  application: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  className: PropTypes.string,
  href: PropTypes.string,
  onClick: PropTypes.func,
}

export default NavItem
