import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { hideModal, showModal } from 'components/Atoms/Modal/actions'
import { setMyPasses } from 'redux-web/utils/my-passes/actions'
import { useTranslation } from 'i18n-web/i18next'
import API from 'api'
import { showBasicModal } from 'helpers/modal'

import Screen from './Screen'

const RetransferYourPass = props => {
  const { ticket, event, lot } = props

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const isSDK = useSelector(state => state.settings.isSDK)
  const [error, setError] = useState(null)
  const [disabledLinks, setDisabledLinks] = useState(false)

  const onCloseModal = () => {
    dispatch(hideModal())
  }

  const onRetransferYourPass = async () => {
    setDisabledLinks(true)
    await API()
      .confirmTransferTicket(ticket.id, {
        ...(isSDK && { sdk: true }),
        body: JSON.stringify({ confirm: false }),
      })
      .then(resp => {
        setDisabledLinks(false)
        if (!resp.error) {
          API()
            .getMyEvents(
              { ...(isSDK && { sdk: true }) },
              'showPurchasedOnly=true&includeTransferred=true'
            )
            .then(resp => dispatch(setMyPasses(resp.events)))
            .catch(() => {})

          dispatch(
            showModal({
              modalProps: {},
              modalType: 'transferYourPass',
              childProps: {
                ticket: ticket,
                event: event,
                lot: lot,
              },
            })
          )
        } else {
          setError(resp.error)
          setDisabledLinks(false)
          if (resp.error.code === 408) {
            dispatch(
              showBasicModal({
                title: resp.error.message,
              })
            )
          }
        }
      })
      .catch(() => {
        setDisabledLinks(false)
      })
  }

  return (
    <Screen
      onRetransferYourPass={onRetransferYourPass}
      onCloseModal={onCloseModal}
      disabledLinks={disabledLinks}
      error={error}
      t={t}
      {...props}
    />
  )
}

RetransferYourPass.propTypes = {
  ticket: PropTypes.object,
  event: PropTypes.object,
  lot: PropTypes.object,
}

export default RetransferYourPass
