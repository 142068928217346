import styled from 'styled-components'
import PropTypes from 'prop-types'

import { useTranslation } from 'i18n-web/i18next'
import Text from 'components/Atoms/Text'
import Button from 'components/Atoms/Button'

const Overlay = styled.div`
  position: absolute;
  z-index: 999;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-self: center;
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.palette.grayDark}E6;
`

const Modal = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 64px;
  margin-right: 8px;
  margin-left: 8px;
  z-index: 1000;
  flex-basis: 684px;

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    margin-top: 0px;
    justify-content: center;
  }
`

const Tab = styled.div`
  display: flex;
  flex-direction: row;
  width: fit-content;
  height: 36px;
  padding: 4px 13px;
  background-color: #42424a;
  position: relative;
  align-items: center;

  &:after {
    content: '';
    position: absolute;
    right: -20px;
    top: 0;
    border-top: 18px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 18px solid #2e2f37;
    border-left: 10px solid #2e2f37;
    height: 0px;
    width: 0px;
  }
`
const TabTitle = styled(Text)`
  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    &.tiny {
      font-size: ${props => props.theme.font.size.desktop.regular};
    }
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;
  padding-right: 12px;
  padding-bottom: 12px;
  padding-left: 12px;
  background-color: #42424a;

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    padding-top: 54px;
    padding-bottom: 43px;
  }
`

const ModalText = styled(Text)`
  margin-top: 28px;
  white-space: pre-wrap;
  text-align: center;
`

const RefreshButton = styled(Button)`
  margin-top: 34px;
  max-width: 450px;
`

RefreshButton.displayName = 'MaintenanceModal__RefreshButton'

const MaintenanceModal = ({ router }) => {
  const { t } = useTranslation()

  const onRefreshClick = () => {
    router.reload()
  }

  return (
    <Overlay>
      <Modal>
        <Tab>
          <TabTitle className={'tiny grayLight semiBold'}>
            {t('maintenance.title')}
          </TabTitle>
        </Tab>
        <Content>
          <img src={'/static/icons/maintenance-icon.svg'} />
          <ModalText className="contrastText medium">
            {t('maintenance.text')}
          </ModalText>
          <RefreshButton className={'full uppercase'} onClick={onRefreshClick}>
            {t('maintenance.button')}
          </RefreshButton>
        </Content>
      </Modal>
    </Overlay>
  )
}

MaintenanceModal.propTypes = {
  router: PropTypes.object,
}

export default MaintenanceModal
