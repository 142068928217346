import { getStore } from 'redux-web/getStore'

export const SET_SETTINGS = 'SET_SETTINGS'
export const SET_LOCALE = 'SET_LOCALE'
export const SET_APPLICATION = 'SET_APPLICATION'
export const SET_SDK = 'SET_SDK'
export const SET_CONTACT_SUPPORT = 'SET_CONTACT_SUPPORT'
export const FETCH_SETTINGS_BEGIN = 'FETCH_SETTINGS_BEGIN'
export const FETCH_SETTINGS_ERROR = 'FETCH_SETTINGS_ERROR'
export const SET_PARENT_DOMAIN = 'SET_PARENT_DOMAIN'
export const SET_DISABLE_HEADER_AND_FOOTER = 'SET_DISABLE_HEADER_AND_FOOTER'
export const SET_SHARED_COORDINATES = 'SET_SHARED_COORDINATES'
export const SET_HAS_BROWSER_PERMISSION = 'SET_HAS_BROWSER_PERMISSION'

export const fetchSettings =
  ({ application, sdk = false, support }) =>
  async (dispatch, getState, api) => {
    dispatch({
      type: FETCH_SETTINGS_BEGIN,
    })

    dispatch({
      payload: Boolean(sdk),
      type: SET_SDK,
    })

    dispatch({
      payload: support,
      type: SET_CONTACT_SUPPORT,
    })

    dispatch({
      payload: application,
      type: SET_APPLICATION,
    })

    await api
      .getSettings({ ...(sdk && { sdk: true }) })
      .then(resp => {
        dispatch({
          payload: resp,
          type: SET_SETTINGS,
        })
      })
      .catch(error => {
        dispatch(setFetchSettingsErrorAction(error?.status))
      })
  }
export const setParentDomain = domain => dispatch => {
  dispatch({
    payload: domain,
    type: SET_PARENT_DOMAIN,
  })
}

export const setDisableHeaderAndFooter = bool => dispatch => {
  dispatch({
    payload: bool,
    type: SET_DISABLE_HEADER_AND_FOOTER,
  })
}

const setFetchSettingsErrorAction = errorStatus => ({
  type: FETCH_SETTINGS_ERROR,
  payload: errorStatus,
})

export const setFetchSettingsError = errorStatus => {
  const store = getStore()

  if (store) {
    store.dispatch(setFetchSettingsErrorAction(errorStatus))
  }
}

export const getFetchSettingsError = () => {
  const store = getStore()

  if (store) {
    return store.getState()?.settings?.errorStatus
  }

  return null
}

export const setSharedCoordinates =
  ({ latitude, longitude }) =>
  dispatch => {
    dispatch({
      payload: {
        latitude,
        longitude,
      },
      type: SET_SHARED_COORDINATES,
    })
  }

export const setHasGeolocationBrowserPermission = bool => dispatch =>
  dispatch({
    payload: bool,
    type: SET_HAS_BROWSER_PERMISSION,
  })
