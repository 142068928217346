export const SET_TICKET = 'SET_TICKET'
export const RESET_TICKET = 'RESET_TICKET'

export const setTicket = data => async dispatch => {
  dispatch({
    type: SET_TICKET,
    payload: data,
  })
}

export const resetTicket = () => async dispatch => {
  dispatch({
    type: RESET_TICKET,
  })
}
