import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { UnlinkForm } from 'components/Molecules/UnlinkFacebook'
import PageTitle from 'components/Atoms/PageTitle'

const ModalSection = styled.div`
  margin: 20px 0;

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    margin-left: 35px;
    margin-right: 35px;
  }
`

const Divisor = styled.div`
  margin-top: 20px;
  border-top: 1px solid ${props => props.theme.palette.gray}80;
  display: none;

  &.title {
    margin-top: 0;
  }

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    display: block;
  }
`

const Screen = ({
  application,
  phoneVerificationToken,
  countryCode,
  name,
  email,
  phoneNumber,
  t,
}) => (
  <>
    <PageTitle
      modal
      noBorder
      shadow
      showBackButton
      title={t('unlinkFacebook.title')}
    />
    <Divisor />
    <ModalSection>
      <UnlinkForm
        application={application}
        countryCode={countryCode}
        phoneNumber={phoneNumber}
        phoneVerificationToken={phoneVerificationToken}
        name={name}
        email={email}
      />
    </ModalSection>
  </>
)

Screen.propTypes = {
  application: PropTypes.string,
  countryCode: PropTypes.string,
  phoneNumber: PropTypes.string,
  phoneVerificationToken: PropTypes.string,
  name: PropTypes.string,
  email: PropTypes.string,
  t: PropTypes.func,
}

export default Screen
