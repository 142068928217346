import styled from 'styled-components'
import PropTypes from 'prop-types'
import moment from 'moment'
import classNames from 'classnames'

import Button from 'components/Atoms/Button'
import PageTitle from 'components/Atoms/PageTitle'
import Text from 'components/Atoms/Text'
import isLightMode from 'helpers/isLightMode'

const Section = styled.div`
  border-top: 1px solid ${props => props.theme.palette.grayLight};
  padding: 12px 0;
  &:first-of-type {
    margin-top: 20px;
  }
  &.last {
    border-bottom: 1px solid ${props => props.theme.palette.grayLight};
    margin-bottom: 30px;
  }
`

const Content = styled.div`
  margin: 20px 0;

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    margin-left: 35px;
    margin-right: 35px;
  }
`

const Subtitle = styled(Text)`
  font-weight: 300;
  line-height: 24px;
  font-size: ${props => props.theme.font.size.mobile.small};
  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    font-size: ${props => props.theme.font.size.desktop.h4};
    font-weight: 400;
    line-height: 34px;
    margin-top: 15px;
    margin-bottom: 30px;
  }
`

const SectionTitle = styled(Text)`
  font-size: ${props => props.theme.font.size.mobile.medium};
  font-weight: 600;
  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    font-size: ${props => props.theme.font.size.desktop.h4};
    font-weight: 700;
  }
`

const SectionDescription = styled(Text)`
  font-size: ${props => props.theme.font.size.mobile.small};
  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    font-size: ${props => props.theme.font.size.desktop.regular};
    line-height: 26px;
  }
`

const Divisor = styled.div`
  margin-top: 20px;
  border-top: 2px solid ${props => props.theme.palette.gray};
  display: none;

  &.title {
    margin-top: 0;
  }

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    display: block;
    margin-top: 12px;
    margin-bottom: 10px;
  }
`

const TransferYouPassSuccess = ({
  application,
  data: { code, event, lot, recipient },
  onCloseModal,
  t,
}) => (
  <>
    <PageTitle
      modal
      noBorder
      shadow
      title={t('transferYourPassSuccess.title')}
    />
    <Divisor />
    <Content>
      <Subtitle>{t('transferYourPassSuccess.subTitle')}</Subtitle>
      <Section>
        <SectionTitle>{t('transferYourPassSuccess.parkingPass')}</SectionTitle>
        <SectionDescription>{event.name}</SectionDescription>
        <SectionDescription>{`${moment(event.start).format(
          'LT, MMM D, YYYY'
        )}`}</SectionDescription>
        <SectionDescription>{lot.name}</SectionDescription>
      </Section>

      <Section>
        <SectionTitle>{t('transferYourPassSuccess.recipient')}</SectionTitle>
        <SectionDescription>{recipient}</SectionDescription>
      </Section>

      <Section className="last">
        <SectionTitle>{t('transferYourPassSuccess.transferCode')}</SectionTitle>
        <SectionDescription>{code}</SectionDescription>
      </Section>

      <Button
        className={classNames('full', 'uppercase', {
          primary: isLightMode(application),
          contrastText: !isLightMode(application),
        })}
        onClick={() => onCloseModal()}>
        {t('common.button.gotIt')}
      </Button>
    </Content>
  </>
)

TransferYouPassSuccess.propTypes = {
  application: PropTypes.string,
  data: PropTypes.object,
  onCloseModal: PropTypes.func,
  t: PropTypes.func,
}

export default TransferYouPassSuccess
