import { Blazers, Citifyd } from 'themes'

export const getTheme = application => {
  const themes = {
    'portland-trailblazers': Blazers,
    blazers: Blazers,
    citifyd: Citifyd,
  }

  return themes[application] || false
}

export const getAppKey = application => {
  const AppKey = {
    'portland-trailblazers': process.env.NEXT_PUBLIC_APP_KEY_BLAZERS,
    blazers: process.env.NEXT_PUBLIC_APP_KEY_BLAZERS,
  }

  return AppKey[application] || false
}

export const getAppleLoginKey = query => {
  const AppKey = {
    'portland-trailblazers': process.env.NEXT_PUBLIC_APPLE_KEY_BLAZERS,
    blazers: process.env.NEXT_PUBLIC_APPLE_KEY_BLAZERS,
    citifyd: process.env.NEXT_PUBLIC_APPLE_KEY_CITIFYD,
  }

  return AppKey[query] || AppKey['citifyd']
}

export const getAppName = application => {
  const applicationName = {
    'portland-trailblazers': 'Portland Trail Blazers',
    blazers: 'Portland Trail Blazers',
    citifyd: 'Citifyd',
  }

  return applicationName[application] || false
}

export const getThemePath = application => {
  const applicationName = {
    'portland-trailblazers': 'blazers',
    blazers: 'blazers',
  }

  return applicationName[application] || false
}

export const getThemeName = application => {
  const applicationName = {
    'portland-trailblazers': 'Blazers',
    blazers: 'Blazers',
    citifyd: 'Citifyd',
  }

  return applicationName[application] || false
}

export const getCanonicalUrl = route => `https://citifyd.com${route}`
