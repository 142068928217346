import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { isValidPhoneNumber } from 'react-phone-number-input'

import {
  internationalPhoneNumber,
  nationalPhoneNumber,
} from 'helpers/phoneFormat'
import { showModal } from 'components/Atoms/Modal/actions'
import { showBasicModal } from 'helpers/modal'
import API from 'api'

import Screen from './Screen'

const PhoneNumberHOC = props => {
  const { phoneNumberCallback, phoneNumberError } = props

  const dispatch = useDispatch()
  const [phoneNumber, setPhoneNumber] = useState({
    number: null,
    isValid: false,
  })
  const [isValid, setIsValid] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [focused, setFocused] = useState(true)
  const [hasTouched, setHasTouched] = useState(false)
  const [shouldValidateOnkeyUp, setShouldValidateOnkeyUp] = useState(false)
  const user = useSelector(state => state.user.me)
  const isSDK = useSelector(state => state.settings.isSDK)
  const countries = useSelector(state => state.countries)

  const onFieldChange = (name, value) => {
    setPhoneNumber({ number: value, isValid: false })
  }

  const submitOnKeyUp = async () => {
    if (isValidPhoneNumber(phoneNumber?.number) && shouldValidateOnkeyUp) {
      await getPhoneNumber()
    }
  }

  const getPhoneNumber = async () => {
    setIsLoading(true)
    await API()
      .getPhoneNumber(
        countries.country,
        nationalPhoneNumber(phoneNumber?.number, countries.country),
        { ...(isSDK && { sdk: true }) }
      )
      .then(resp => {
        if (resp.phoneNumber) {
          if (!resp.phoneNumber.userExists) {
            setIsValid(true)
            setIsLoading(false)
            setFocused(false)
            setPhoneNumber({ number: phoneNumber?.number, isValid: true })
          } else {
            setPhoneNumber({ number: phoneNumber?.number, isValid: false })

            dispatch(
              showModal({
                childProps: {
                  isAppleLogin: resp.isAppleConnected,
                  isFacebookLogin: resp.isFacebookConnected,
                  prefilledPhoneNumber: phoneNumber?.number,
                },
                modalProps: {
                  className: 'noBorders',
                },
                modalType: 'signIn',
              })
            )

            setIsLoading(false)
            setFocused(true)
          }
        } else {
          setIsLoading(false)
        }
      })
      .catch(error => {
        setIsLoading(false)
        dispatch(showBasicModal({ title: error.message }))
      })
  }

  const onBlur = async () => {
    setFocused(false)
    if (!shouldValidateOnkeyUp) {
      if (isValidPhoneNumber(phoneNumber?.number)) {
        await getPhoneNumber()
      } else {
        setPhoneNumber({ number: phoneNumber?.number, isValid: false })
        setIsValid(false)
      }
    }
  }

  const onFocus = () => {
    setHasTouched(true)
    setFocused(true)
    setIsValid(true)
  }

  useEffect(() => {
    if (countries?.country) {
      setShouldValidateOnkeyUp(countries.country.toLowerCase() === 'us')
    }
  }, [countries])

  useEffect(() => {
    if (phoneNumberError) {
      setIsValid(false)
      setIsLoading(false)
      setFocused(false)
      setPhoneNumber({
        isValid: false,
      })
    }
  }, [phoneNumberError])

  useEffect(() => {
    if (hasTouched) {
      phoneNumberCallback({
        number: phoneNumber?.number,
        isValid: phoneNumber.isValid,
      })
    }
  }, [phoneNumber, hasTouched])

  useEffect(() => {
    setIsValid(false)
    setFocused(true)
    setPhoneNumber({
      isValid: false,
    })
  }, [countries])

  useEffect(() => {
    if (!phoneNumber?.number && user.phoneNumber) {
      const phoneNumberObj = {
        number: internationalPhoneNumber(
          user.phoneNumber,
          user.phoneCountryCode
        ),
        isValid: true,
      }

      setPhoneNumber(phoneNumberObj)

      phoneNumberCallback(phoneNumberObj)
    }
  }, [user])

  return (
    <Screen
      {...props}
      isLoading={isLoading}
      onBlur={onBlur}
      focused={focused}
      onFocus={onFocus}
      isValid={isValid}
      submitOnKeyUp={submitOnKeyUp}
      onFieldChange={onFieldChange}
    />
  )
}

PhoneNumberHOC.propTypes = {
  phoneNumberCallback: PropTypes.func,
  phoneNumberError: PropTypes.bool,
  value: PropTypes.string,
}

export default PhoneNumberHOC
