const Cash = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="35"
    height="35"
    viewBox="0 0 35 35"
    {...props}>
    <g fill="none" fillRule="evenodd">
      <g fill="#fff">
        <g>
          <g>
            <g>
              <path
                d="M18 2c8.832 0 16 7.168 16 16s-7.168 16-16 16S2 26.832 2 18 9.168 2 18 2zm1.05 4.95h-2V8.5c-2.7.35-4.55 2.15-4.55 4.675v.025c0 2.55 1.4 3.9 4.6 5.125v5.15c-1.375-.25-2.55-.95-3.65-1.975l-1.6 2.125c1.475 1.4 3.325 2.175 5.2 2.375v2.5h2V26c2.75-.35 4.625-2.15 4.625-4.75v-.05c0-2.45-1.325-3.95-4.675-5.225V11.05c1 .225 1.95.775 2.85 1.5l1.5-2.15c-1.25-1.075-2.675-1.7-4.3-1.875V6.95zM19 19.025c1.475.675 1.9 1.35 1.9 2.3v.05c0 1.075-.675 1.875-1.9 2.125v-4.475zm-1.9-8.05v4.275c-1.4-.675-1.8-1.275-1.8-2.225V13c0-.975.6-1.775 1.8-2.025z"
                transform="translate(-38 -480) translate(18 133) translate(0 328) translate(20 19)"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default Cash
