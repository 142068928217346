import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import PlacesAutocomplete from 'react-places-autocomplete'
import classNames from 'classnames'
import isEmpty from 'lodash/isEmpty'
import moment from 'moment'
import styled from 'styled-components'
import { TiLocationArrow } from 'react-icons/ti'

import Button from 'components/Atoms/Button'
import DateTimePicker from 'components/Atoms/DateTimePicker'
import Input from 'components/Atoms/Input'
import Text from 'components/Atoms/Text'
import TimePicker from 'components/Atoms/TimePicker'
import useGoogleMaps from 'hooks/useGoogleMaps'
import { useClientResponsive } from 'helpers/hooks'
import { useTranslation } from 'i18n-web/i18next'

const Wrapper = styled.div`
  @media (max-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    padding: 20px 20px 25px;
    background-color: ${props => props.theme.palette.white};
    border-radius: 4px;
  }
`

const TextInput = styled(Input)`
  margin: 0;
  padding: 4px 12px;
  width: 100%;
  height: 42px;
  font-size: ${props => props.theme.font.size.mobile.medium};
  font-weight: 800;
  text-transform: uppercase;
  background-color: ${props => props.theme.palette.background};
  border: solid 0.1rem ${props => props.theme.palette.gray} !important;
  border-radius: 4px;

  :focus {
    background-color: ${props => props.theme.palette.background};
    border: solid 0.1rem ${props => props.theme.palette.gray} !important;
    border-bottom: solid 2px ${props => props.theme.palette.grayDark};
  }

  ::placeholder {
    color: ${props => props.theme.palette.grayLight};
  }

  ${({ isDate }) =>
    isDate &&
    `
    padding: 5px 0;
    padding-bottom: 5px;
    height: 30px;
    cursor: pointer;
  `}

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    padding: 28px 20px;
    height: 88px;
    font-size: ${props => props.theme.font.size.desktop.h3};
    font-weight: 800;
    border: none !important;

    :focus {
      background-color: ${props => props.theme.palette.background};
      border: none;
      border-bottom: solid 2px ${props => props.theme.palette.grayDark};
    }
  }
`

const Suggestions = styled.div`
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  padding-top: 10px;

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    position: absolute;
    top: 100px;
    padding-top: 0;
  }
`

const SuggestionItemSecondary = styled.span`
  color: ${props => props.theme.palette.gray};

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    color: ${props => props.theme.palette.grayLight};
  }
`

const SuggestionItem = styled.span`
  display: block;
  padding: 10px;
  font-size: ${props => props.theme.font.size.mobile.medium};
  font-weight: 600;
  color: ${props => props.theme.palette.grayDark};

  :hover {
    color: ${props => props.theme.palette.grayDark};

    ${SuggestionItemSecondary} {
      color: ${props => props.theme.palette.gray};
    }
  }

  ${({ isLoading }) =>
    !isLoading &&
    `
    cursor: pointer;
  `}

  ${({ myLocation }) =>
    myLocation &&
    `
    cursor: pointer;
    display: flex;
    align-items: center;
  `}

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    padding: 10px 16px;
    font-size: ${props => props.theme.font.size.desktop.h3};
    color: ${props => props.theme.palette.white};

    :hover {
      color: ${props => props.theme.palette.highlight};

      ${SuggestionItemSecondary} {
        color: ${props => props.theme.palette.highlight};
      }
    }
  }
`

const LocationHint = styled(Text)`
  padding-bottom: 20px;

  &.hidden {
    visibility: hidden;
  }

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    padding: 0px 25px 0;
    font-size: ${props => props.theme.font.size.desktop.h4};
  }
`

const LocationError = styled(Text)`
  padding: 10px 0 0;

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    padding: 15px 25px 0;
    font-size: ${props => props.theme.font.size.desktop.h4};
  }
`

const DatesGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px 0;

  @media (max-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    flex-direction: column;
  }
`

const DateInputGroup = styled.div`
  flex: 50%;
  margin-bottom: 20px;

  .DateInput_input {
    font-size: ${props => props.theme.font.size.mobile.medium} !important;
    font-weight: 800 !important;
    border-bottom: none !important;
    height: 100%;
  }

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    padding: 14px 20px;
    margin-bottom: 0;
    background-color: ${props => props.theme.palette.white};
    border-radius: 4px;

    &:first-of-type {
      margin-right: 5px;
    }

    &:last-of-type {
      margin-left: 5px;
    }

    .DateInput_input {
      font-size: ${props => props.theme.font.size.desktop.h3} !important;
      font-weight: 800 !important;
      border-bottom: none !important;
      height: 100%;
    }
  }
`

const DatePickers = styled.div`
  @media (max-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    display: flex;
    gap: 10px;

    > div {
      flex: 50%;
      padding: 0 0 5px;
      border-top: none;
      border-bottom: solid 1px ${props => props.theme.palette.gray}60;
    }
  }
`

const LocationIcon = styled(TiLocationArrow)`
  font-size: ${props => props.theme.font.size.mobile.h2};
  margin-left: 5px;

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    margin-left: 20px;
    font-size: ${props => props.theme.font.size.desktop.h2};
  }
`

const DateInputLabel = styled.span`
  display: inline-block;
  font-size: ${props => props.theme.font.size.mobile.tiny};
  color: ${props => props.theme.palette.gray};

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    margin-bottom: 5px;
    font-size: ${props => props.theme.font.size.desktop.regular};
  }
`

const SubmitButton = styled(Button)`
  font-size: ${props => props.theme.font.size.mobile.h3};
  font-weight: 400;
  text-transform: uppercase;

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    height: 70px;
    font-size: ${props => props.theme.font.size.desktop.h3};
  }
`

const ReservedParkingSearch = ({
  address,
  endDate,
  formattedEndTime,
  formattedStartTime,
  getDateFormattedLabel,
  getUserCurrentLocation,
  inputRef,
  isLocationInputFocused,
  loadingMyLocation,
  locationError,
  onEndDateChange,
  onEndTimeChange,
  onLocationChange,
  onLocationSelect,
  onStartDateChange,
  onStartTimeChange,
  onSubmitClick,
  setIsLocationInputFocused,
  startDate,
  startTime,
}) => {
  const [myLocationEnabled, setMyLocationEnabled] = useState(false)
  const [showMyLocationOption, setShowMyLocationOption] = useState(false)

  const { gmapsLoaded } = useGoogleMaps()
  const { isMobile } = useClientResponsive()
  const { t } = useTranslation()

  const showLocationHint = (loading, suggestions) =>
    locationError ||
    loading ||
    loadingMyLocation ||
    !isEmpty(suggestions) ||
    address ||
    (showMyLocationOption && myLocationEnabled)

  useEffect(() => {
    setMyLocationEnabled(navigator?.geolocation)
  }, [])

  useEffect(() => {
    if (loadingMyLocation) {
      setShowMyLocationOption(false)
    }
  }, [loadingMyLocation])

  const isSameDay = useMemo(
    () => moment(startDate).isSame(endDate, 'day'),
    [startDate, endDate]
  )

  const getFormContent = ({ loading, suggestions }) => {
    if (
      isLocationInputFocused ||
      loading ||
      loadingMyLocation ||
      !isEmpty(suggestions) ||
      !address
    ) {
      return null
    }

    return (
      <>
        <DatesGroup>
          <DateInputGroup>
            <DateInputLabel>
              {t('reservedParking.search.form.label.startDate')}
            </DateInputLabel>
            <DatePickers>
              <DateTimePicker
                id="startDate"
                isModalMobileVersion={isMobile}
                isModalVersion={!isMobile}
                label={getDateFormattedLabel(startDate)}
                onChange={onStartDateChange}
                value={formattedStartTime}
              />
              <TimePicker
                className={classNames({ mobile: isMobile })}
                date={startDate}
                disablePastHours
                onChange={onStartTimeChange}
                value={formattedStartTime}
              />
            </DatePickers>
          </DateInputGroup>
          <DateInputGroup>
            <DateInputLabel>
              {t('reservedParking.search.form.label.endDate')}
            </DateInputLabel>
            <DatePickers>
              <DateTimePicker
                enableSelectFrom={startDate}
                id="endDate"
                isModalMobileVersion={isMobile}
                isModalVersion={!isMobile}
                label={getDateFormattedLabel(endDate)}
                onChange={onEndDateChange}
                value={formattedEndTime}
              />
              <TimePicker
                className={classNames({ mobile: isMobile })}
                date={endDate}
                disablePastHours
                onChange={onEndTimeChange}
                value={formattedEndTime}
                enableFromHour={isSameDay ? startTime : undefined}
              />
            </DatePickers>
          </DateInputGroup>
        </DatesGroup>
        <SubmitButton
          className={classNames('full', {
            grayLight: isMobile,
            normal: isMobile,
          })}
          onClick={onSubmitClick}>
          {t('reservedParking.search.form.submit')}
        </SubmitButton>
      </>
    )
  }

  return (
    <form onSubmit={() => {}}>
      <Wrapper>
        {gmapsLoaded && (
          <PlacesAutocomplete
            value={address}
            onChange={onLocationChange}
            onSelect={locationAddress => {
              onLocationSelect(locationAddress)
              setShowMyLocationOption(false)
            }}>
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading,
            }) => (
              <>
                <LocationHint className={classNames('bold medium grayDark')}>
                  {t('reservedParking.search.form.locationHint')}
                </LocationHint>

                <TextInput
                  {...getInputProps({
                    id: 'location',
                    onBlur: () => {
                      setTimeout(() => setShowMyLocationOption(false), 150)
                      setIsLocationInputFocused(false)
                    },
                    onFocus: () => {
                      setShowMyLocationOption(true)
                      setIsLocationInputFocused(true)
                    },
                    placeholder: t(
                      'reservedParking.search.form.whereAreYouGoing'
                    ),
                    ref: inputRef,
                  })}
                />
                <Suggestions className="autocomplete-dropdown-container">
                  {(loading || loadingMyLocation) && (
                    <SuggestionItem isLoading>
                      {t('reservedParking.search.form.loading')}
                    </SuggestionItem>
                  )}
                  {myLocationEnabled && showMyLocationOption && (
                    <SuggestionItem
                      onClick={() => {
                        setShowMyLocationOption(false)
                        getUserCurrentLocation()
                      }}
                      myLocation>
                      {t('reservedParking.myLocation')}
                      <LocationIcon />
                    </SuggestionItem>
                  )}
                  {suggestions.slice(0, 3).map(suggestion => {
                    const { formattedSuggestion, placeId } = suggestion

                    return (
                      <SuggestionItem
                        key={placeId}
                        {...getSuggestionItemProps(suggestion)}>
                        {formattedSuggestion?.mainText}
                        {formattedSuggestion?.secondaryText && (
                          <SuggestionItemSecondary>
                            {' '}
                            {formattedSuggestion?.secondaryText}
                          </SuggestionItemSecondary>
                        )}
                      </SuggestionItem>
                    )
                  })}
                </Suggestions>
                {getFormContent({ loading, suggestions })}
                {locationError && !showMyLocationOption && (
                  <LocationError
                    className={classNames('bold contrastText error', {
                      medium: isMobile,
                    })}>
                    {locationError}
                  </LocationError>
                )}
                {!isMobile && (
                  <LocationHint
                    className={classNames('bold contrastText', {
                      hidden: showLocationHint(loading, suggestions),
                    })}>
                    {t('reservedParking.search.form.locationHint')}
                  </LocationHint>
                )}
              </>
            )}
          </PlacesAutocomplete>
        )}
      </Wrapper>
    </form>
  )
}

ReservedParkingSearch.propTypes = {
  address: PropTypes.string,
  endDate: PropTypes.string,
  formattedEndTime: PropTypes.string,
  formattedStartTime: PropTypes.string,
  getDateFormattedLabel: PropTypes.func,
  getUserCurrentLocation: PropTypes.func,
  inputRef: PropTypes.object,
  isLocationInputFocused: PropTypes.bool,
  loadingMyLocation: PropTypes.bool,
  locationError: PropTypes.string,
  onEndDateChange: PropTypes.func,
  onEndTimeChange: PropTypes.func,
  onLocationChange: PropTypes.func,
  onLocationSelect: PropTypes.func,
  onStartDateChange: PropTypes.func,
  onStartTimeChange: PropTypes.func,
  onSubmitClick: PropTypes.func,
  setIsLocationInputFocused: PropTypes.func,
  startDate: PropTypes.string,
  startTime: PropTypes.string,
}

export default ReservedParkingSearch
