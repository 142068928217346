import { parsePhoneNumberFromString } from 'libphonenumber-js'
import { includes } from 'lodash'

export const formatPhoneNumber = (phoneNumber, countryCode) => {
  if (!phoneNumber) {
    return ''
  }

  let formattedPhoneNumber

  if (!countryCode || includes(['us', 'ca'], countryCode)) {
    countryCode = countryCode || 'us'
    const number = parsePhoneNumberFromString(
      phoneNumber,
      countryCode.toUpperCase()
    )

    formattedPhoneNumber = number.formatNational()
  } else {
    const number = parsePhoneNumberFromString(
      phoneNumber,
      countryCode.toUpperCase()
    )

    formattedPhoneNumber = number ? number.formatInternational() : ''
  }

  return formattedPhoneNumber
}

export const nationalPhoneNumber = (phoneNumber, countryCode) => {
  if (!phoneNumber || !countryCode) {
    return ''
  }

  const number = parsePhoneNumberFromString(
    phoneNumber,
    countryCode.toUpperCase()
  )

  return number ? number.nationalNumber : ''
}

export const internationalPhoneNumber = (phoneNumber, countryCode) => {
  if (!phoneNumber || !countryCode) {
    return ''
  }

  const number = parsePhoneNumberFromString(
    phoneNumber,
    countryCode.toUpperCase()
  )

  return number ? number.number : ''
}
