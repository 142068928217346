import * as Yup from 'yup'

import { i18n } from 'i18n-web/i18next'

const validationSchema = isEditing =>
  Yup.object().shape({
    cardNumber:
      !isEditing &&
      Yup.string().required(
        i18n.t('myAccount.cards.modal.form.fields.cardNumber.error')
      ),
    cardExpiration: Yup.string().required(
      i18n.t('common.form.error.invalidExpiryDate')
    ),
    cardCvc:
      !isEditing &&
      Yup.string().required(
        i18n.t('myAccount.cards.modal.form.fields.cardCode.error')
      ),

    name: Yup.string().required(i18n.t('common.form.error.required')),
  })

export default validationSchema
