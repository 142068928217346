import React, { useState, useRef, useEffect } from 'react'
import moment from 'moment'
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import { useDispatch, useSelector } from 'react-redux'
import { useRouter } from 'next/router'

import { useTranslation } from 'i18n-web/i18next'
import {
  setEndDate,
  setEndTime,
  setLocation,
  setStartDate,
  setStartTime,
} from 'redux-web/utils/reservedParking/actions'
import { hideModal } from 'components/Atoms/Modal/actions'
import {
  getFormattedDate,
  getFormattedDateAndTime,
  getReservedParkingUrl,
  getFormattedTime,
} from 'models/reservedParking'

import Screen from './Screen'

const ReservedParkingSearchHOC = props => {
  const inputRef = useRef()
  const [address, setAddress] = useState('')
  const [locationError, setLocationError] = useState()
  const [loadingMyLocation, setLoadingMyLocation] = useState(false)
  const [isLocationInputFocused, setIsLocationInputFocused] = useState(false)

  const { t } = useTranslation()
  const router = useRouter()
  const { endDate, endTime, startDate, startTime } = useSelector(
    state => state.reservedParking
  )
  const dispatch = useDispatch()

  useEffect(() => {
    if (isLocationInputFocused) {
      setLocationError(null)
    }
  }, [isLocationInputFocused])

  const handleStartDateChange = ({ value }) => {
    const hour = moment(startTime).hour()
    const minute = moment(startTime).minute()

    const date = moment(value).set({ hours: hour, minutes: minute }).format()

    dispatch(setStartDate(getFormattedDate(date)))
    dispatch(setStartTime(getFormattedTime(date)))
  }

  const handleEndDateChange = ({ value }) => {
    const hour = moment(endTime).hour()
    const minute = moment(endTime).minute()

    const date = moment(value)
      .set('hours', hour)
      .set('minutes', minute)
      .format()

    dispatch(setEndDate(getFormattedDate(date)))
    dispatch(setEndTime(getFormattedTime(date)))
  }

  const onStartTimeChange = time => {
    const hour = moment(time).hour()
    const minute = moment(time).minute()

    const date = moment(startDate)
      .set({ hours: hour, minutes: minute })
      .format()

    dispatch(setStartTime(getFormattedTime(date)))
    dispatch(setStartDate(getFormattedDate(date)))
  }

  const onEndTimeChange = time => {
    const hour = moment(time).hour()
    const minute = moment(time).minute()

    const date = moment(endDate).set({ hours: hour, minutes: minute }).format()

    dispatch(setEndTime(getFormattedTime(date)))
    dispatch(setEndDate(getFormattedDate(date)))
  }

  const handleLocationChange = locationAddress => setAddress(locationAddress)

  const handleLocationSelect = locationAddress => {
    setIsLocationInputFocused(false)
    setAddress(locationAddress)

    geocodeByAddress(locationAddress)
      .then(results => getLatLng(results[0]))
      .then(latLng => {
        dispatch(
          setLocation({
            address: locationAddress,
            ...latLng,
          })
        )
      })

    inputRef?.current?.blur()
  }

  const getDateFormattedLabel = date => moment(date).format('L')

  const getUserCurrentLocation = () => {
    setAddress(null)
    setLoadingMyLocation(true)

    const options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0,
    }

    navigator?.geolocation.getCurrentPosition(
      position => {
        const {
          coords: { latitude: lat, longitude: lng },
        } = position

        setLoadingMyLocation(false)
        setAddress(t('reservedParking.myLocation'))

        dispatch(
          setLocation({
            address: t('reservedParking.myLocation'),
            lat,
            lng,
          })
        )
      },
      error => {
        setLoadingMyLocation(false)

        if (error.code === 1) {
          setLocationError(t('reservedParking.locationDisabled.description'))
        }

        if (error.code === 3) {
          setLocationError(
            t('reservedParking.locationTimeoutExpired.description')
          )
        }
      },
      options
    )
  }

  const onSubmitClick = () => {
    if (!router.pathname?.includes('/reserved-parking')) {
      const reservedParkingUrl = getReservedParkingUrl({
        address,
        startTime,
        startDate,
        endDate,
        endTime,
      })

      router.push(reservedParkingUrl)
    }

    dispatch(hideModal())
  }

  return (
    <Screen
      address={address}
      endDate={endDate}
      formattedStartTime={getFormattedDateAndTime(startDate, startTime)}
      formattedEndTime={getFormattedDateAndTime(endDate, endTime)}
      getDateFormattedLabel={getDateFormattedLabel}
      getUserCurrentLocation={getUserCurrentLocation}
      inputRef={inputRef}
      isLocationInputFocused={isLocationInputFocused}
      loadingMyLocation={loadingMyLocation}
      locationError={locationError}
      onEndDateChange={handleEndDateChange}
      onEndTimeChange={onEndTimeChange}
      onLocationChange={handleLocationChange}
      onLocationSelect={handleLocationSelect}
      onStartDateChange={handleStartDateChange}
      onStartTimeChange={onStartTimeChange}
      onSubmitClick={onSubmitClick}
      setIsLocationInputFocused={setIsLocationInputFocused}
      startDate={startDate}
      startTime={startTime}
      {...props}
    />
  )
}

export default ReservedParkingSearchHOC
