import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import * as Cookies from 'js-cookie'
import styled from 'styled-components'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { FaFacebookSquare } from 'react-icons/fa'

import Button from 'components/Atoms/Button'

const FacebookButton = styled(Button)`
  align-items: center;
  justify-content: center;
  display: flex;
`

const FacebookIcon = styled(FaFacebookSquare)`
  margin-right: 10px;
  font-size: ${props => props.theme.font.size.mobile.h4};
`

const Component = ({
  children,
  className,
  language,
  onCallback,
  setTryAgain,
  redirectTo,
}) => {
  const handleOnClick = f => {
    if (redirectTo) {
      Cookies.set('citifyd_facebook_login_redirect', redirectTo)
    }

    // Save function to be used on Try Again in case of error
    setTryAgain(() => f)

    f()
  }

  return (
    <FacebookLogin
      appId={process.env.NEXT_PUBLIC_FACEBOOK_ID}
      callback={resp => onCallback(resp)}
      disableMobileRedirect={true}
      language={language}
      render={renderProps => (
        <FacebookButton
          className={classNames(className, 'facebook')}
          disabled={renderProps.isDisabled || renderProps.isProcessing}
          onClick={() => handleOnClick(renderProps.onClick)}>
          <FacebookIcon />
          {children}
        </FacebookButton>
      )}
    />
  )
}

Component.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  language: PropTypes.string,
  redirectTo: PropTypes.string,
  onCallback: PropTypes.func,
  setTryAgain: PropTypes.func,
}

export default Component
