import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { useTranslation } from 'i18n-web/i18next'
import { showModal } from 'components/Atoms/Modal/actions'
import { setTermsAndConditionsAgreement } from 'redux-web/utils/purchase/actions'

import Screen from './Screen'

const TermsAndConditions = props => {
  const { onChecked } = props
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const { termsAndConditionsAgreement } = useSelector(state => state.purchase)
  const [checked, setChecked] = useState(termsAndConditionsAgreement)

  useEffect(() => {
    dispatch(setTermsAndConditionsAgreement(checked))
  }, [checked])

  const onTermsClick = bool => {
    onChecked(bool)
    setChecked(bool)
  }

  const onModalTermsClick = () => {
    dispatch(
      showModal({
        modalProps: {
          open: true,
          customStyle: { width: '580px' },
          className: 'noBorders',
          hideCloseDesktop: true,
        },
        modalType: 'termsAndConditions',
      })
    )
  }

  return (
    <Screen
      {...props}
      t={t}
      onTermsClick={onTermsClick}
      onModalTermsClick={onModalTermsClick}
      setChecked={setChecked}
      checked={checked}
    />
  )
}

TermsAndConditions.propTypes = {
  onChecked: PropTypes.func,
}

export default TermsAndConditions
