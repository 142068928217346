import PropTypes from 'prop-types'
import { isEmpty, get } from 'lodash'
import styled from 'styled-components'

import SelectSearch from 'components/Atoms/SelectSearch'
import { getLabelByValue } from 'helpers/tupleList'

const Container = styled.div`
  margin-bottom: 15px;
`

const SelectSearchField = ({ Component, items, field, form, ...props }) => {
  const { name, value } = field
  const { setFieldValue, errors } = form
  const fieldError = get(errors, name)
  const SelectComponent = Component || SelectSearch

  const currentItemLabel = getLabelByValue(items, value)

  const handleChange = (event, { suggestion }) => {
    setFieldValue(name, suggestion.value)
  }

  return (
    <Container>
      <SelectComponent
        {...props}
        data={items}
        error={fieldError}
        isValid={isEmpty(fieldError)}
        resetValueOnBlur={true}
        selectedItem={{ label: currentItemLabel, value: value }}
        onSuggestionSelected={handleChange}
        focusInputOnSuggestionClick={false}
      />
    </Container>
  )
}

SelectSearchField.propTypes = {
  Component: PropTypes.func,
  items: PropTypes.array,
  field: PropTypes.object,
  form: PropTypes.object,
}

SelectSearchField.defaultProps = {
  openOnFocus: true,
}

export default SelectSearchField
