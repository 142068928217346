import { useDispatch } from 'react-redux'

import { useTranslation } from 'i18n-web/i18next'
import { showBasicModal } from 'helpers/modal'
import API from 'api'
import WithStripeHOC from 'hocs/withStripe'
import { fetchMe } from 'redux-web/utils/user/actions'
import { hideModal } from 'components/Atoms/Modal/actions'

import EditCardModal from './Screen'

const EditCardModalWrapper = props => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const handleSubmit = async formValues => {
    try {
      const response = await API().editCard(formValues.id, {
        body: JSON.stringify({
          ...formValues,
        }),
      })

      if (response.error) {
        dispatch(
          showBasicModal({
            title: t('common.title.error'),
            description: response.error.message,
            type: 'error',
          })
        )

        return response
      }

      await dispatch(fetchMe())
      dispatch(hideModal())
      dispatch(
        showBasicModal({
          title: t('myAccount.cards.modal.editCard.success.title'),
          description: t('myAccount.cards.modal.editCard.success.description'),
        })
      )

      return response
    } catch (error) {
      return error
    }
  }

  const deleteCard = async cardId => {
    try {
      const response = await API().deleteCard(cardId)

      if (response.error) {
        dispatch(
          showBasicModal({
            title: t('common.title.error'),
            description: response.error.message,
            type: 'error',
          })
        )

        return response
      }

      await dispatch(fetchMe())
      dispatch(hideModal())
      dispatch(
        showBasicModal({
          title: t('myAccount.cards.modal.deleteCard.success.title'),
          description: t(
            'myAccount.cards.modal.deleteCard.success.description'
          ),
        })
      )

      return response
    } catch (error) {
      return error
    }
  }

  const handleDeleteCard = cardId => {
    dispatch(
      showBasicModal({
        title: t('myAccount.cards.modal.deleteCard.confirmation.title'),
        description: t(
          'myAccount.cards.modal.deleteCard.confirmation.description'
        ),
        actionButtons: {
          rightButtonOnClick: () => deleteCard(cardId),
          rightButtonText: t(
            'myAccount.cards.modal.deleteCard.confirmation.rightButton'
          ),
          singleButtonOnClick: hideModal,
          singleButtonText: t(
            'myAccount.cards.modal.deleteCard.confirmation.leftButton'
          ),
        },
      })
    )
  }

  return (
    <EditCardModal
      {...props}
      onSubmit={handleSubmit}
      onDeleteCard={handleDeleteCard}
    />
  )
}

export default WithStripeHOC(EditCardModalWrapper)
