import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Formik, Form, Field } from 'formik'
import classNames from 'classnames'

import BulletPoint from 'components/Atoms/BulletPoint'
import Button from 'components/Atoms/Button'
import PhoneInput from 'components/Atoms/PhoneInput'
import TextDivisor from 'components/Atoms/TextDivisor'
import isLightMode from 'helpers/isLightMode'
import { TextField } from 'components/Molecules/Form'

import validationSchema from './validationSchema'

const Component = styled(Form)`
  margin-top: 20px;
  text-align: center;

  .textDivisor {
    display: block;
    margin: 20px 0 12px;
    height: 1px;
  }

  .confirmPassword {
    padding-top: 5px;
  }
`

const ChangePasswordForm = ({
  application,
  formikRef,
  handlePasswordOnKeyUp,
  handleSubmit,
  initialValues,
  passwordRules,
  t,
}) => (
  <Formik
    initialValues={initialValues}
    onSubmit={handleSubmit}
    ref={ref => formikRef(ref)}
    render={props => {
      const { isSubmitting, isValid, values } = props

      const hasPhoneNumber = values.phoneNumber && !values.email
      const hasEmail = !hasPhoneNumber

      return (
        <Component>
          {hasPhoneNumber && (
            <PhoneInput
              disabled
              name="phoneNumber"
              value={values.phoneNumber}
            />
          )}
          {hasEmail && (
            <Field
              component={TextField}
              disabled
              label={t('changePassword.form.email.label')}
              name="email"
              value={values.email}
            />
          )}
          <TextDivisor className="textDivisor" />
          <Field
            component={TextField}
            disabled={isSubmitting}
            label={t('changePassword.form.newPassword.label')}
            name="password"
            onKeyUp={handlePasswordOnKeyUp}
            type="password"
          />
          <BulletPoint
            isChecked={
              passwordRules?.missingFeatures &&
              !passwordRules?.missingFeatures.includes('minimum')
            }>
            {t('signUp.form.password.requirements.minimum')}
          </BulletPoint>
          <Field
            className="confirmPassword"
            component={TextField}
            disabled={isSubmitting}
            label={t('changePassword.form.confirmNewPassword.label')}
            name="confirmPassword"
            type="password"
          />
          <Button
            className={classNames('extraPadding', 'full', {
              highlight: isLightMode(application),
              contrastText: !isLightMode(application),
            })}
            disabled={isSubmitting || !isValid}
            type="submit">
            {t('changePassword.form.button').toUpperCase()}
          </Button>
        </Component>
      )
    }}
    validationSchema={() => validationSchema(passwordRules)}
  />
)

ChangePasswordForm.propTypes = {
  application: PropTypes.string,
  formikRef: PropTypes.func,
  handlePasswordOnKeyUp: PropTypes.func,
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  isSubmitting: PropTypes.bool,
  isValid: PropTypes.bool,
  passwordRules: PropTypes.object,
  t: PropTypes.func,
  values: PropTypes.object,
}

export default ChangePasswordForm
