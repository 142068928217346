import styled from 'styled-components'
import { MdClose, MdKeyboardArrowDown } from 'react-icons/md'
import PropTypes from 'prop-types'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { TiLocationArrow } from 'react-icons/ti'
import { isBrowser } from 'react-device-detect'
import { kebabCase } from 'lodash'
import { useState, useEffect, useMemo } from 'react'
import { withRouter } from 'next/router'

import DatePicker from 'components/Atoms/DatePicker'
import Dropdown from 'components/Atoms/Dropdown'
import Text from 'components/Atoms/Text'
import { showBasicModal } from 'helpers/modal'
import { useTranslation } from 'i18n-web/i18next'

import DropdownList from './DropdownList'
import SelectList from './SelectList'

const FilterDropdown = styled(Dropdown)`
  width: 100%;
`

const Component = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  cursor: pointer;
  padding-bottom: 4px;
  tap-highlight-color: transparent;
  border-right: 1px solid ${props => props.theme.palette.grayLight};
  &.noBorder {
    border-right: none;
  }
  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    padding-bottom: 0;
    border-right: none;
    border-bottom: 1px solid ${props => props.theme.palette.grayLight};
  }
`

const LocationIcon = styled(TiLocationArrow)`
  font-size: ${props => props.theme.font.size.desktop.h3};
  position: absolute;
  right: 9px;
  top: 9px;
`

const Label = styled(Text)`
  z-index: 1;
  position: relative;
  padding-left: 18px;
  font-size: ${props => props.theme.font.size.mobile.tiny};
  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    font-size: ${props => props.theme.font.size.desktop.small};
  }
  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.md}rem) {
    font-size: ${props => props.theme.font.size.desktop.regular};
  }
`

const Icon = styled.img`
  margin-right: 5px;
  margin-bottom: 2px;
  height: 15px;
  position: absolute;
  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    height: 15px;
  }
`

const ArrowDownIcon = styled(MdKeyboardArrowDown)`
  font-size: ${props => props.theme.font.size.desktop.h3};
  display: none;
  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    display: block;
    position: absolute;
    right: -6px;
    top: -1px;
  }
`

const CloseIcon = styled(MdClose)`
  z-index: 9;
  font-size: ${props => props.theme.font.size.desktop.h4};
  display: none;
  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    display: block;
    position: absolute;
    right: -2px;
    top: 4px;
  }
`

const FilterBy = ({
  router,
  type,
  label,
  className,
  onClear,
  resetFilters,
  onChange,
  data = [],
  setIsLoadingEvents,
}) => {
  const [value, setValue] = useState()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { query } = router

  useEffect(() => {
    if (query.search) {
      let search = new URLSearchParams(query.search)

      if (search.get('eventType') && type === 'eventType') {
        const item = data.filter(
          item => item.value === search.get('eventType')
        )[0]

        if (isBrowser) {
          setValue(item?.label)
        } else {
          item?.value ? setValue(item?.value) : setValue(item?.label)
        }
      } else if (search.get('startDate') && type === 'date') {
        setValue(moment(search.get('startDate')))
      } else if (
        search.get('latitude') &&
        search.get('longitude') &&
        type === 'location'
      ) {
        const item = data.filter(item => {
          if (
            item?.coordinates?.latitude.toString() === search.get('latitude') &&
            item?.coordinates?.longitude.toString() === search.get('longitude')
          ) {
            return item
          }
        })[0]

        if (isBrowser) {
          setValue(item?.label || t('events.myLocation'))
        } else if (!isBrowser) {
          item?.value ? setValue(item?.value) : setValue(item?.label)
        }
      }
    }
  }, [query])

  useEffect(() => {
    if (resetFilters) {
      setValue(type === 'date' ? null : label)
    }
  }, [resetFilters])

  const items = useMemo(
    () =>
      type === 'location' && navigator.geolocation
        ? [
            {
              label: t('events.myLocation'),
              value: 'my-location',
              icon: <LocationIcon />,
            },
            ...data,
          ]
        : data,
    [type, data]
  )

  const formatCoordinates = ({ item, position }) =>
    onChange({
      ...item,
      coordinates: {
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      },
      type: type,
    })

  const onFilterChange = item => {
    if (item) {
      if (type === 'date' || !isBrowser) {
        setValue(item?.value)
      } else {
        setValue(item?.label)
      }

      if (item?.value === 'my-location') {
        setIsLoadingEvents(true)
        navigator.geolocation.getCurrentPosition(
          position => {
            formatCoordinates({ item, position })
          },
          error => {
            setValue('')
            setIsLoadingEvents(false)
            if (error.code === 1) {
              dispatch(
                showBasicModal({
                  title: t('events.locationDisabled.title'),
                  description: t('events.locationDisabled.description'),
                })
              )
            }
          }
        )
      } else {
        onChange({ ...item, type })
      }
    } else {
      onClear(type)
      setValue('')
    }
  }

  const renderSelectList = () => (
    <SelectList
      value={value}
      onChange={item => onFilterChange(item)}
      label={label}
      items={items}
    />
  )

  const renderDropdownList = () => (
    <FilterDropdown
      fullWidth
      closeOnClick
      content={
        <DropdownList
          value={value}
          items={items}
          onChange={item => onFilterChange(item)}
        />
      }>
      <Label>{value || label}</Label>
    </FilterDropdown>
  )

  const renderComponent = () => {
    switch (type) {
      case 'date':
        return (
          <DatePicker
            value={value}
            onChange={item => onFilterChange(item)}
            label={label}
          />
        )
      default:
        if (isBrowser) {
          return renderDropdownList()
        }

        return renderSelectList()
    }
  }

  return (
    <Component className={className} onClick={() => {}}>
      <Icon src={`/static/icons/icon-${kebabCase(type)}.svg`} />
      {isBrowser && (
        <>
          {value === label || !value ? (
            <ArrowDownIcon />
          ) : (
            <CloseIcon
              onClick={() => {
                onClear(type)
                setValue(type !== 'date' ? label : null)
              }}
            />
          )}
        </>
      )}
      {renderComponent()}
    </Component>
  )
}

FilterBy.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  data: PropTypes.array,
  onChange: PropTypes.func,
  setIsLoadingEvents: PropTypes.func,
  onClear: PropTypes.func,
  router: PropTypes.object,
  resetFilters: PropTypes.bool,
}

export default withRouter(FilterBy)
