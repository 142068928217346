import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { withRouter } from 'next/router'
import PropTypes from 'prop-types'

import { attemptLogin } from 'redux-web/utils/authentication/actions'
import { useTranslation } from 'i18n-web/i18next'
import { hideModal } from 'components/Atoms/Modal/actions'

import Screen from './Screen'

const TwoFactorAuthentication = props => {
  const { credentials } = props

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [formikRef, setFormikRef] = useState(null)
  const [codeSentBy, setCodeSentBy] = useState()

  const onCancel = () => {
    dispatch(hideModal())
  }
  const onResendCode = type => {
    setCodeSentBy(type)
    resendCode(type)
  }

  const formikInstance = ref => {
    setFormikRef(ref)
  }

  const resendCode = async method => {
    formikRef.setSubmitting(false)

    dispatch(
      attemptLogin({
        showErrorMessage: err => formikRef.setStatus({ message: err }),
        data: {
          ...credentials,
          twoFactorAuthenticationMethod: method,
        },
      })
    )
  }

  return (
    <Screen
      {...props}
      credentials={credentials}
      onCancel={onCancel}
      onResendCode={onResendCode}
      formikRef={formikInstance}
      codeSentBy={codeSentBy}
      t={t}
    />
  )
}

TwoFactorAuthentication.propTypes = {
  credentials: PropTypes.object,
}

export default withRouter(TwoFactorAuthentication)
