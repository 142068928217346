import React, { useEffect, useRef, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { isEmpty } from 'lodash'
import {
  useStripe,
  useElements,
  CardNumberElement,
} from '@stripe/react-stripe-js'
import PropTypes from 'prop-types'

import { place } from 'api/google'
import API from 'api'
import { updateUser } from 'redux-web/utils/user/actions'
import {
  setIsSubmitting,
  setIsValidForm,
} from 'redux-web/utils/purchase/actions'
import { getGuestSession } from 'helpers/guest'
import { useTranslation } from 'i18n-web/i18next'
import { showModal } from 'components/Atoms/Modal/actions'
import { showBasicModal } from 'helpers/modal'
import { createGuestUser } from 'helpers/guest'
import {
  setReservationSession,
  getReservationSession,
} from 'helpers/reservation'

import CardForm from './Form'

const CardFormHOC = props => {
  const stripe = useStripe()
  const elements = useElements()

  const {
    formObject,
    guestUser,
    isPayBySignage,
    phoneNumber,
    phoneVerificationToken,
    saveCard,
    setStripeElements,
    submitCallback,
  } = props

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const state = useSelector(state => state)
  const user = useSelector(state => state.user.me)
  const isSDK = useSelector(state => state.settings.isSDK)
  const selectedNativePayment = useSelector(
    state => state.purchase.selectedNativePayment
  )
  const { isSubmitting, selectedCard } = useSelector(state => state.purchase)

  const formikRef = useRef()
  const country = useMemo(() => state.countries.country, [state])
  const initialValues = {
    cardExpiration: '',
    cardNumber: '',
    cardCvc: '',
    saveCard: saveCard || false,
  }

  useEffect(() => {
    setStripeElements(elements)
  }, [elements])

  useEffect(() => {
    if (!isEmpty(user) && !user.isGuest && formikRef) {
      formikRef.current.setFieldValue('acceptedTerms', true)
    }
    if (user && user.error) {
      formikRef.current.setStatus({ message: user.error })
    }
  }, [user, selectedNativePayment])

  useEffect(() => {
    if (selectedNativePayment) {
      if (formikRef.current.state.values.acceptedTerms) {
        dispatch(setIsValidForm(true))
      } else {
        dispatch(setIsValidForm(false))
      }
    }
  }, [selectedNativePayment, formikRef])

  useEffect(() => {
    formObject(formikRef)
  }, [formikRef])

  const onTermsClick = () => {
    dispatch(
      showModal({
        modalProps: {
          open: true,
          customStyle: { width: '580px' },
          className: 'noBorders',
          hideCloseDesktop: true,
        },
        modalType: 'termsAndConditions',
      })
    )
  }

  const onFieldBlur = isValid => {
    dispatch(setIsValidForm(isValid))
  }

  const createCard = async ({ guest, cardToken }) => {
    const response = await API().addCard({
      accessToken: guest && guest.accessToken,
      ...(isSDK && { sdk: true }),
      body: JSON.stringify({
        cardToken: cardToken,
      }),
    })

    if (response.card) {
      if (response.card.id) {
        submitCallback({
          card: response.card,
        })
        if (!guest) {
          dispatch(
            updateUser(
              JSON.stringify({ defaultCardId: response.card.id }),
              isSDK
            )
          )
        }
      }
    } else if (response.error) {
      dispatch(setIsSubmitting(false))
      submitCallback({ error: true })

      // Custom timeout message
      if (response.error.code === 408 && isPayBySignage) {
        dispatch(
          showBasicModal({
            title: t('reservation.error.timeout'),
          })
        )
      } else if (response.error.message) {
        dispatch(showBasicModal({ title: response.error.message }))
      } else {
        dispatch(showBasicModal({ title: t('common.alert.api.description') }))
      }
    }
  }

  const guestSubmit = async ({
    cardToken,
    resetForm,
    disablePhoneVerificationToken,
  }) => {
    let response = {}
    const guestSession = getGuestSession()

    if (!guestUser && guestSession) {
      response = await createGuestUser({
        country,
        phoneNumber,
        ...(!disablePhoneVerificationToken && {
          phoneVerificationToken: phoneVerificationToken,
        }),
        dispatch,
        hideDefaultErrorModal: true,
        isSDK,
      })
    }

    if (!response.error) {
      await createCard({
        guest: response.guest || user,
        cardToken,
      })
    } else {
      dispatch(setIsSubmitting(false))
      submitCallback({ error: true })

      if (response.error.code === 2532) {
        guestSubmit({
          cardToken,
          resetForm,
          disablePhoneVerificationToken: true,
        })
      } else if (response.error.code !== 408) {
        dispatch(showBasicModal({ title: t('common.alert.api.description') }))
      }
    }
  }

  const reservationSubmit = async ({ cardToken }) => {
    let response = {}
    const accessToken = getReservationSession()

    if (!accessToken) response = await setReservationSession()

    if (!response.error || accessToken) {
      submitCallback({
        accessToken: response.accessToken || accessToken,
        paymentToken: cardToken,
      })
    } else {
      dispatch(setIsSubmitting(false))
      submitCallback({ error: true })
      if (response?.error?.code === 408) {
        dispatch(showBasicModal({ title: response.error.message }))
      } else {
        dispatch(showBasicModal({ title: t('common.alert.api.description') }))
      }
    }
  }

  const customerSubmit = async ({ cardToken, skipSaveCard }) => {
    if (!skipSaveCard) {
      await createCard({
        cardToken,
      })
    } else {
      submitCallback({
        paymentToken: cardToken,
      })
    }
  }

  const handleSubmit = async (values, { resetForm }) => {
    if (stripe) {
      dispatch(setIsSubmitting(true))

      const userPlace = await place(`${values.zipCode}, ${country}`)
      const cardNumber = elements.getElement(CardNumberElement)
      const guestSession = getGuestSession()

      const card = {
        address_country: country.toUpperCase(),
        address_zip: values.zipCode,
        address_city: userPlace && userPlace.city,
        address_state: userPlace && userPlace.state,
      }

      stripe
        .createToken(cardNumber, card)
        .then(resp => {
          if (resp.token) {
            if (guestUser || guestSession) {
              guestSubmit({
                cardToken: resp.token.id,
                resetForm,
              })
            } else if (!isEmpty(user)) {
              customerSubmit({
                cardToken: resp.token.id,
                resetForm,
                skipSaveCard: !values.saveCard,
              })
            } else {
              reservationSubmit({
                cardToken: resp.token.id,
                resetForm,
              })
            }
          } else if (resp.error) {
            if (resp.error.type !== 'validation_error') {
              dispatch(showBasicModal({ title: resp.error.message }))
              submitCallback({ error: true })
            }
            dispatch(setIsSubmitting(false))
          }
        })
        .catch(error => {
          dispatch(setIsSubmitting(false))
          submitCallback({ error: true })
          if (error.message) {
            dispatch(showBasicModal({ title: error.message }))
          } else {
            dispatch(
              showBasicModal({ title: t('common.alert.api.description') })
            )
          }
        })
    }
  }

  return (
    <CardForm
      {...props}
      t={t}
      onFieldBlur={onFieldBlur}
      showSaveCard={
        saveCard
          ? false
          : !isSubmitting &&
            Boolean(!guestUser && !isEmpty(user)) &&
            !selectedNativePayment &&
            Boolean(!guestUser && !selectedCard)
      }
      selectedNativePayment={selectedNativePayment}
      initialValues={initialValues}
      formikRef={formikRef}
      handleSubmit={handleSubmit}
      onTermsClick={onTermsClick}
    />
  )
}

CardFormHOC.propTypes = {
  stripe: PropTypes.object,
  formObject: PropTypes.any,
  guestUser: PropTypes.bool,
  saveCard: PropTypes.bool,
  phoneNumber: PropTypes.any,
  phoneVerificationToken: PropTypes.string,
  submitCallback: PropTypes.func,
  setStripeElements: PropTypes.func,
  requiredFieldIsEmpty: PropTypes.bool,
  isPayBySignage: PropTypes.bool,
}

export default CardFormHOC
