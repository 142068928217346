import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { withRouter } from 'next/router'

import { hideModal } from 'components/Atoms/Modal/actions'
import { useTranslation } from 'i18n-web/i18next'

import Component from './Screen'

const Basic = props => {
  const { children, preventClose } = props

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const onButtonClicked = () => {
    if (!preventClose) {
      dispatch(hideModal())
    }
  }

  return (
    <Component onButtonClicked={onButtonClicked} t={t} {...props}>
      {children}
    </Component>
  )
}

Basic.propTypes = {
  children: PropTypes.node,
  preventClose: PropTypes.bool,
}

export default withRouter(Basic)
