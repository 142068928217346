import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import classNames from 'classnames'
import { Grid, Col, Row } from 'react-styled-flexboxgrid'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'next/router'

import Button from 'components/Atoms/Button'
import Image from 'components/Atoms/Image'
import Text from 'components/Atoms/Text'
import isLightMode from 'helpers/isLightMode'
import { getAppRoute, isCitifydApp } from 'helpers/application'
import { getThemePath } from 'models/application'
import { showModal } from 'components/Atoms/Modal/actions'
import { useTranslation, Link } from 'i18n-web/i18next'

const Component = styled.footer`
  padding: 25px 0;
  flex-shrink: 0;
  background-color: ${props =>
    isLightMode(props.application)
      ? props.theme.palette.grayDark
      : props.theme.palette.black};

  h4 {
    margin-bottom: 3px;
  }

  .columnMobile {
    margin-top: 30px;
  }

  .border {
    margin: 25px 0 0 0;
    padding-top: 25px;
    border: none;
    border-top: solid 1px;
    border-top-color: ${props => props.theme.palette.gray};
  }

  .security {
    color: ${props => props.theme.palette.primary};
  }

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    .columnMobile {
      margin-top: 0;
    }
  }

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.md}rem) {
    display: flex;
    align-items: center;
    padding: ${props => (props.hasCompactFooter ? '6px 0' : '25px 0')};
    height: auto;
  }

  @media print {
    display: none;
  }
`

const CitifydLogo = styled(Image)`
  display: block;
  width: 81px;
  height: auto;
`

const SupportButton = styled(Button)`
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
  color: ${props => props.theme.palette.white};

  &.inline {
    display: inline-block;
  }
`

const Separator = styled(Text)`
  display: inline-block;
  margin: 0 5px;
`

const SecurityWrapper = styled.div`
  display: flex;
`

const SecurityIcon = styled(Image)`
  margin-right: 12px;
  width: 30px;
  height: 41px;
`

const NPAComponent = styled.div`
  display: flex;

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.md}rem) {
    justify-content: center;
  }
`
const NPALogo = styled(Image)`
  width: 108px;
  height: 50px;
`

const Footer = ({ router, hasCompactFooter }) => {
  const {
    query: { application },
  } = router

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const isSDK = useSelector(state => state.settings.isSDK)
  const phoneNumberSupportSDK = useSelector(
    state => state.settings.phoneNumberSupport
  )
  const emailSupportSDK = useSelector(state => state.settings.emailSupport)

  const emailPartnership = process.env.NEXT_PUBLIC_CITIFYD_EMAIL_PARTNERSHIP

  const emailSupport = isSDK
    ? emailSupportSDK
    : process.env.NEXT_PUBLIC_CITIFYD_EMAIL

  const phoneNumberSupport = isSDK
    ? phoneNumberSupportSDK
    : process.env.NEXT_PUBLIC_CITIFYD_PHONE_NUMBER

  const getFooterData = () => {
    if (isCitifydApp(application)) {
      return {
        href: `${getAppRoute(application, 'url')}/`,
        logo: '/static/images/logo_with_text.png',
      }
    }

    return {
      href: `${getAppRoute(application, 'url')}/events`,
      logo: `/static/images/${getThemePath(application)}/logo_footer.svg`,
    }
  }

  const onTermsClick = () => {
    dispatch(
      showModal({
        modalProps: {
          open: true,
          customStyle: { width: '580px' },
          className: 'noBorders',
        },
        modalType: 'termsAndConditions',
      })
    )
  }

  return (
    <Component application={application} hasCompactFooter={hasCompactFooter}>
      <Grid>
        <Row>
          {!hasCompactFooter && (
            <Col sm={2} xs={false}>
              <Link href={getFooterData().href}>
                <a className="logoLink">
                  <CitifydLogo src={getFooterData().logo} />
                </a>
              </Link>
            </Col>
          )}
          {/* eslint-disable-next-line react/jsx-sort-props */}
          <Col xs={12} sm={hasCompactFooter ? 12 : 8}>
            <Row>
              <Col xs={6} sm={hasCompactFooter ? 5 : 4}>
                <Text
                  as="h4"
                  className={classNames('contrastText', 'uppercase', 'bold', {
                    small: !hasCompactFooter,
                    tiny: hasCompactFooter,
                  })}>
                  {t('footer.help.title')}
                </Text>
                {phoneNumberSupport && (
                  <SupportButton
                    className={classNames({
                      inline: hasCompactFooter,
                    })}
                    as="a"
                    href={`tel:${phoneNumberSupport}`}>
                    <Text
                      className={classNames('contrastText', {
                        small: !hasCompactFooter,
                        tiny: hasCompactFooter,
                      })}>
                      {t('footer.help.message')}
                      {hasCompactFooter ? ' ' : <br />}
                      {phoneNumberSupport}
                    </Text>
                  </SupportButton>
                )}
                {hasCompactFooter && (
                  <Separator
                    className={classNames('contrastText', {
                      tiny: hasCompactFooter,
                    })}>
                    /
                  </Separator>
                )}
                {emailSupport && (
                  <SupportButton
                    className={classNames({
                      inline: hasCompactFooter,
                    })}
                    as="a"
                    href={`mailto:${emailSupport}?subject=Questions for ${application} (Web)`}>
                    <Text
                      className={classNames('contrastText', 'link', {
                        small: !hasCompactFooter,
                        tiny: hasCompactFooter,
                      })}>
                      {emailSupport}
                    </Text>
                  </SupportButton>
                )}
              </Col>
              <Col xs={6} sm={hasCompactFooter ? 3 : 4}>
                <Text
                  as="h4"
                  className={classNames('contrastText', 'uppercase', 'bold', {
                    small: !hasCompactFooter,
                    tiny: hasCompactFooter,
                  })}>
                  {t('footer.about.title')}
                </Text>
                <Text
                  onClick={() => onTermsClick()}
                  className={classNames('contrastText', 'link', {
                    small: !hasCompactFooter,
                    tiny: hasCompactFooter,
                  })}>
                  {t('footer.about.buttons.termsAndConditions')}
                </Text>
              </Col>
              {!isSDK && (
                <Col
                  xs={6}
                  sm={hasCompactFooter ? 3 : 4}
                  className="columnMobile">
                  <Text
                    as="h4"
                    className={classNames('contrastText', 'uppercase', 'bold', {
                      small: !hasCompactFooter,
                      tiny: hasCompactFooter,
                    })}>
                    {t('footer.businessInquiries.title')}
                  </Text>

                  <SupportButton
                    className={classNames({
                      inline: hasCompactFooter,
                    })}
                    as="a"
                    href={`mailto:${emailPartnership}`}>
                    <Text
                      className={classNames('contrastText', 'link', {
                        small: !hasCompactFooter,
                        tiny: hasCompactFooter,
                      })}>
                      {emailPartnership}
                    </Text>
                  </SupportButton>
                </Col>
              )}
              {!hasCompactFooter && (
                <Col xs={4} sm={false} className="columnMobile">
                  <NPAComponent>
                    <NPALogo src="/static/images/footer/npa-logo@3x.png" />
                  </NPAComponent>
                </Col>
              )}
            </Row>
          </Col>
          {!hasCompactFooter && (
            <Col xs={false} sm={2}>
              <NPAComponent>
                <NPALogo src="/static/images/footer/npa-logo@3x.png" />
              </NPAComponent>
            </Col>
          )}
        </Row>
        {!hasCompactFooter && (
          <Row className="border">
            <Col md={2} xs={0}></Col>
            <Col md={10} xs={12}>
              <SecurityWrapper>
                <SecurityIcon src="/static/images/footer/security.svg"></SecurityIcon>
                <div>
                  <Text as="h4" className="security uppercase tiny bold">
                    {t('footer.security.title')}
                  </Text>
                  <Text className="contrastText tiny">
                    {t('footer.security.message')}
                  </Text>
                </div>
              </SecurityWrapper>
            </Col>
          </Row>
        )}
      </Grid>
    </Component>
  )
}

Footer.propTypes = {
  hasCompactFooter: PropTypes.bool,
  router: PropTypes.object,
}

export default withRouter(Footer)
