import { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { useTranslation } from 'i18n-web/i18next'
import PageTitle from 'components/Atoms/PageTitle'
import VehicleForm from 'components/Molecules/MyAccount/Vehicles/VehicleForm'
import Backdrop from 'components/Atoms/Backdrop'
import Text from 'components/Atoms/Text'

import DeleteVehicleButton from './DeleteVehicleButton'

const Loading = styled(Backdrop)`
  top: 0px;
  left: 0px;
  background: rgba(255, 255, 255, 0.5);
`

const Divisor = styled.div`
  margin-top: 18px;
  border-top: 2px solid ${props => props.theme.palette.grayDard};
  display: none;

  &.title {
    margin-top: 0;
  }

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    display: block;
  }
`

const Container = styled.div`
  padding: 20px 18px;

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    padding: 32px 37px 37px 37px;
  }
`

export const AlertBox = styled.div`
  padding: 8px;
  margin-bottom: 10px;
  border-radius: 4px;
  background-color: ${props => props.theme.palette.primary};
`

const EditVehicleModal = ({ onSubmit, vehicleId, onDeleteVehicle }) => {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)

  return (
    <>
      <PageTitle
        modal
        noBorder
        shadow
        showBackButton={true}
        title={t('myAccount.vehicles.modal.editVehicle.title')}
      />

      <Divisor />
      <Container>
        <AlertBox>
          <Text className="medium bold">
            {t('myAccount.vehicles.modal.addVehicle.ifThisNewVehicle')}
          </Text>
        </AlertBox>

        <DeleteVehicleButton
          vehicleId={vehicleId}
          onClick={() => onDeleteVehicle(vehicleId)}
        />
        <VehicleForm
          onSubmit={onSubmit}
          setIsLoading={setIsLoading}
          vehicleId={vehicleId}
        />
        {isLoading && <Loading />}
      </Container>
    </>
  )
}

EditVehicleModal.propTypes = {
  vehicleId: PropTypes.number,
  onDeleteVehicle: PropTypes.func,
  onSubmit: PropTypes.func,
}

export default EditVehicleModal
