import { kebabCase, isEmpty } from 'lodash'
import moment from 'moment-timezone'

import getImageUrl from 'helpers/image'
import {
  formatAddress,
  formatCurrency,
  formatRoundedPrice,
} from 'i18n-web/formatters'
import { i18n } from 'i18n-web/i18next'
import { selectCurrencyForCountry } from 'redux-web/selectors/countries'
import { getDistanceByGeoLib } from 'models/lots'

export const getFormattedDateAndTimeWithTimezone = (date, time, timezone) =>
  moment.tz(`${date}T${time}`, timezone).format()

export const getFormattedDateAndTime = (date, time) => `${date}T${time}`

export const getFormattedDate = date => moment(date).format('YYYY-MM-DD')

export const getFormattedTime = time => moment(time).format('HH:mm:ss')

export const sortByDistance = lots =>
  lots?.slice().sort((a, b) => a?.node?.lot?.distance - b?.node?.lot?.distance)

export const sortByPrice = lots =>
  lots
    ?.slice()
    .sort(
      (a, b) =>
        a?.node?.calculatedPrice?.total - b?.node?.calculatedPrice?.total
    )

export const getReservedParkingPurchaseUrl = ({
  lot,
  startTime,
  startDate,
  endTime,
  endDate,
  timezone,
}) => {
  const formattedStartTime = encodeURIComponent(
    getFormattedDateAndTimeWithTimezone(startDate, startTime, timezone)
  )

  const formattedEndTime = encodeURIComponent(
    getFormattedDateAndTimeWithTimezone(endDate, endTime, timezone)
  )

  const formattedTimezone = encodeURIComponent(timezone)

  const citySegment = kebabCase(`${lot.address.city}-${lot.address.state}`)
  const lotAddressSegment = kebabCase(`${lot.id}-${lot.address.line1}`)

  return `/reserved-parking/${citySegment}/${lotAddressSegment}/purchase?startTime=${formattedStartTime}&endTime=${formattedEndTime}&timezone=${formattedTimezone}`
}

export const getReservedParkingUrl = ({
  address,
  startTime,
  endTime,
  startDate,
  endDate,
}) => {
  const locationSegment = kebabCase(address)
  const formattedStartTime = getFormattedDateAndTime(
    startDate,
    encodeURIComponent(startTime)
  )
  const formattedEndTime = getFormattedDateAndTime(
    endDate,
    encodeURIComponent(endTime)
  )

  return `/reserved-parking/${locationSegment}?startTime=${formattedStartTime}&endTime=${formattedEndTime}`
}

export const getFormattedLot = ({
  node,
  countries,
  lat,
  lng,
  startTime,
  endTime,
  addressFormat = 'full_inline',
}) => {
  if (isEmpty(node) || isEmpty(countries)) {
    return {}
  }

  const { lot, calculatedPrice } = node ?? {}
  const currency = selectCurrencyForCountry(countries, lot?.countryCode)

  const formattedRoundedTotalPrice =
    calculatedPrice?.total &&
    formatRoundedPrice({
      price: calculatedPrice?.total,
      currency: lot?.country.currency,
      language: lot?.country.language,
    })

  const formattedRoundedValuePrice =
    calculatedPrice?.value &&
    formatRoundedPrice({
      price: calculatedPrice?.value,
      currency: lot?.country.currency,
      language: lot?.country.language,
    })

  const formattedRoundedFeePrice =
    calculatedPrice?.fee &&
    formatRoundedPrice({
      price: calculatedPrice?.fee,
      currency: lot?.country.currency,
      language: lot?.country.language,
    })

  const formattedTotalPrice = formatCurrency(
    calculatedPrice?.total,
    lot?.country?.currency
  )

  const formattedValuePrice = formatCurrency(
    calculatedPrice?.value,
    lot?.country?.currency
  )

  const formattedFeePrice = formatCurrency(
    calculatedPrice?.fee,
    lot?.country?.currency
  )

  return {
    about: lot?.about,
    additionalPhotos: lot?.additionalPhotos,
    address: lot?.rawAddress,
    addressFormatted: formatAddress(
      lot?.rawAddress,
      lot?.countryCode,
      addressFormat
    ),
    amenities: lot?.amenities,
    countryCode: lot?.countryCode,
    currency: currency,
    directionsNotes: lot?.directionsNotes,
    endTime,
    id: lot?.id,
    image: getImageUrl(
      lot?.mainPhoto?.image?.info,
      'c_thumb,w_460,h_270,g_center'
    ),
    latitude: lot?.latitude,
    longitude: lot?.longitude,
    mainPhoto: lot?.mainPhoto,
    name: lot?.name,
    notes: lot?.notes,
    reservedParkingValidationMethod: lot?.reservedParkingValidationMethod,
    startTime,
    thumbnail: getImageUrl(
      lot?.mainPhoto?.image?.info,
      'c_thumb,w_120,h_120,g_center'
    ),
    ...(calculatedPrice
      ? {
          order: {
            raw: {
              value: calculatedPrice?.value,
              fee: calculatedPrice?.fee,
              total: calculatedPrice?.total,
            },
            rounded: {
              value: formattedRoundedValuePrice,
              fee: formattedRoundedFeePrice,
              total: formattedRoundedTotalPrice,
            },
            value: formattedValuePrice,
            fee: formattedFeePrice,
            total: formattedTotalPrice,
          },
          price:
            calculatedPrice?.total === 0
              ? i18n.t('common.free')
              : formattedTotalPrice,
        }
      : {}),
    ...(lat && lng
      ? {
          distance: getDistanceByGeoLib(
            lot?.latitude,
            lot?.longitude,
            lat,
            lng
          ),
        }
      : {}),
  }
}
