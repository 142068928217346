import { first, get } from 'lodash'

import { i18n } from 'i18n-web/i18next'

export const getPricingInformation = ({
  totalPriceBeforeDeductions,
  creditsLeft,
}) => ({
  appliedCreditsAmount: Math.min(totalPriceBeforeDeductions, creditsLeft),
  purchaseTotal: Math.max(0, totalPriceBeforeDeductions - creditsLeft),
})

export const getFeeAmount = (rate, value) => {
  const { percentFee, fee } = rate

  if (percentFee) {
    const resultFee = (percentFee / 100) * value

    return resultFee
  }

  return fee
}

export const applyFee = (rate, value) => {
  const fee = getFeeAmount(rate, value)

  return value + fee
}

export const getReadableText = (rate, currency) => {
  const { value, type } = rate

  switch (type) {
    case 'hourly':
      return i18n.t('onDemand.plan.hourly', {
        value: applyFee(rate, value),
        currency,
      })

    case 'fixed':
      return i18n.t('onDemand.plan.fixed', {
        value: applyFee(rate, value),
        currency,
      })

    case 'variable': {
      const { rules } = rate
      const firstRule = first(rules)
      const firstRuleValue = applyFee(rate, get(firstRule, 'value'))

      return i18n.t('onDemand.plan.variable', {
        value: firstRuleValue,
        currency,
      })
    }
  }
}
