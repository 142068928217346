import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Formik, Form, Field } from 'formik'
import classNames from 'classnames'
import { isFunction } from 'lodash'

import { useTranslation } from 'i18n-web/i18next'
import Button from 'components/Atoms/Button'
import { Checkbox, TextField } from 'components/Molecules/Form'
import SelectSearchField from 'components/Molecules/Form/SelectSearchField'
import FormikObserver from 'components/Molecules/Form/FormikObserver'
import DynamicField from 'components/Molecules/Form/DynamicField'
import CountrySelectSearchField from 'components/Molecules/Form/CountrySelectSearchField'
import Text from 'components/Atoms/Text'

import validationSchema from './validationSchema'

const ProTipTitle = styled(Text)`
  margin-top: 26px;

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    &.small {
      font-size: ${props => props.theme.font.size.desktop.medium};
    }
  }
`

const ProTipText = styled(Text)`
  margin-top: 8px;
  margin-bottom: 22px;

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    &.small {
      font-size: ${props => props.theme.font.size.desktop.regular};
    }
  }
`

const DefaultVehicleContainer = styled.div`
  margin-top: 32px;
`

const VehicleFormContainer = ({
  formikRef,
  initialValues,
  onSubmit,
  onChange,
  options,
  dynamicFields,
  hasDefaultButton,
}) => {
  const { t } = useTranslation()

  const handleOnChange = values => {
    if (isFunction(onChange)) {
      onChange(values)
    }
  }

  return (
    <Formik
      ref={formikRef}
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      render={props => {
        const { isSubmitting, values, handleChange } = props

        return (
          <Form>
            <Field
              name="countryCode"
              component={CountrySelectSearchField}
              items={options.countries}
            />

            <Field
              name="makeCode"
              component={SelectSearchField}
              label={t('myAccount.vehicles.modal.form.fields.make.label')}
              items={options.makes}
            />
            <Field
              name="color"
              component={SelectSearchField}
              label={t('myAccount.vehicles.modal.form.fields.color.label')}
              items={options.colors}
            />

            {dynamicFields?.map((field, index) => (
              <DynamicField key={index} {...field} />
            ))}

            <Field
              name="label"
              label={t('myAccount.vehicles.modal.form.fields.nickname.label')}
              component={TextField}
              validateOnFocus
            />

            <DefaultVehicleContainer>
              {hasDefaultButton && (
                <Field
                  name="isDefault"
                  label={t(
                    'myAccount.vehicles.modal.form.fields.isDefault.label'
                  )}
                  component={Checkbox}
                  onClick={handleChange}
                />
              )}

              <ProTipTitle className={'small bold'}>
                {t('myAccount.vehicles.modal.form.proTip.title')}
              </ProTipTitle>
              <ProTipText className={'small'}>
                {t('myAccount.vehicles.modal.form.proTip.description')}
              </ProTipText>
            </DefaultVehicleContainer>

            <Button
              className={classNames('full uppercase')}
              disabled={isSubmitting}
              type="submit">
              {t('myAccount.vehicles.modal.form.buttons.submit')}
            </Button>

            <FormikObserver values={values} onChange={handleOnChange} />
          </Form>
        )
      }}
    />
  )
}

VehicleFormContainer.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  formikRef: PropTypes.object,
  setStatus: PropTypes.func,
  status: PropTypes.string,
  isSubmitting: PropTypes.bool,
  isValid: PropTypes.bool,
  error: PropTypes.object,
  onChange: PropTypes.func,
  isLoading: PropTypes.bool,
  values: PropTypes.object,
  options: PropTypes.object,
  dynamicFields: PropTypes.array,
  hasDefaultButton: PropTypes.bool,
  handleChange: PropTypes.func,
}

export default VehicleFormContainer
