import { SET_RESERVATION, RESET_RESERVATION } from './actions'

const defaultState = {}

export const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_RESERVATION:
      return {
        ...action.payload,
      }
    case RESET_RESERVATION:
      return {
        ...defaultState,
      }
    default:
      return state
  }
}
