import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import Maintenance from 'components/Organisms/Maintenance'

const AppComponent = ({
  application,
  Component,
  err,
  intercom,
  loadingPage,
  pageProps,
  router,
}) => {
  const settingsStatus = useSelector(state => state.settings.errorStatus)
  const isMaintenanceMode = settingsStatus >= 500 && settingsStatus <= 599

  return (
    <>
      {isMaintenanceMode ? (
        <Maintenance />
      ) : (
        <Component
          {...pageProps}
          err={err}
          sdk={Boolean(router?.query?.sdk)}
          loadingPage={loadingPage}
          router={router}
          intercom={intercom}
          application={application}
        />
      )}
    </>
  )
}

AppComponent.propTypes = {
  application: PropTypes.string,
  Component: PropTypes.func,
  err: PropTypes.object,
  intercom: PropTypes.object,
  loadingPage: PropTypes.bool,
  pageProps: PropTypes.object,
  router: PropTypes.object,
}

export default AppComponent
