import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
import { withRouter } from 'next/router'

import { useTranslation } from 'i18n-web/i18next'
import { Grid as TemplateGrid } from 'components/Templates'
import SEO from 'seo/page'
import MaintenanceModal from 'components/Molecules/MaintenanceModal'

const Maintenance = ({ router }) => {
  const user = useSelector(state => state.user.me)
  const { t } = useTranslation()

  return (
    <>
      <MaintenanceModal router={router} />
      <TemplateGrid
        hideFooter
        hideMenu={isEmpty(user) || user?.isGuest}
        spacingTop>
        <SEO
          title={t('common.seo.title')}
          description={t('common.seo.description')}
        />
      </TemplateGrid>
    </>
  )
}

Maintenance.propTypes = {
  router: PropTypes.object,
}

export default withRouter(Maintenance)
