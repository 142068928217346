import React from 'react'
import PropTypes from 'prop-types'
import styled, { keyframes } from 'styled-components'

import { getThemePath } from 'models/application'
import { isCitifydApp } from 'helpers/application'

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const Component = styled.div.attrs(props => ({
  size: props.size || '3',
}))`
  width: ${props => props.size}rem;
  height: ${props => props.size}rem;
  position: relative;
`

const ImageOuter = styled.div.attrs(props => ({
  size: props.size || '3',
}))`
  background: ${props =>
    isCitifydApp(props.application)
      ? "url('/static/icons/loading/progress_bkg@3x.png') no-repeat"
      : `url(/static/icons/${getThemePath(
          props.application
        )}/loading/progress_bkg@3x.png) no-repeat`};
  background-size: cover;
  width: ${props => props.size}rem;
  height: ${props => props.size}rem;
  animation: ${rotate} 1s linear infinite;
`

const ImageInner = styled.div.attrs(props => ({
  size: props.size || '3',
}))`
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  position: absolute;
  animation: ${rotate} 1s linear infinite;
  animation-direction: reverse;
  background: ${props =>
    isCitifydApp(props.application)
      ? "url('/static/icons/loading/progress@3x.png') no-repeat"
      : `url(/static/icons/${getThemePath(
          props.application
        )}/loading/progress@3x.png) no-repeat`};
  background-size: cover;
  width: ${props => props.size * 0.6}rem;
  height: ${props => props.size * 0.6}rem;
`

const Loading = props => {
  const { size, application } = props

  return (
    <Component {...props}>
      <ImageOuter size={size} application={application} />
      <ImageInner size={size} application={application} />
    </Component>
  )
}

Loading.propTypes = {
  size: PropTypes.number,
  application: PropTypes.string,
}

export default Loading
