import isEmpty from 'lodash/isEmpty'
import moment from 'moment'

import { getLocationTimezone } from 'api/google'

import {
  getFormattedDateAndTime,
  getFormattedDate,
  getFormattedTime,
} from '../../../models/reservedParking'

export const CLEAR_FILTERS = 'CLEAR_FILTERS'
export const RESET_RESERVED_PARKING = 'RESET_RESERVED_PARKING'
export const SET_ACTIVE_LOT = 'SET_ACTIVE_LOT'
export const SET_ACTIVE_PIN = 'SET_ACTIVE_PIN'
export const SET_AVAILABLE_FILTERS = 'SET_AVAILABLE_FILTERS'
export const SET_END_DATE = 'SET_END_DATE'
export const SET_END_TIME = 'SET_END_TIME'
export const SET_FILTERS = 'SET_FILTERS'
export const SET_LOCATION = 'SET_LOCATION'
export const SET_LOTS = 'SET_LOTS'
export const SET_PASS = 'SET_PASS'
export const SET_PASSES = 'SET_PASSES'
export const SET_RADIUS = 'SET_RADIUS'
export const SET_RESULTS_COUNT = 'SET_RESULTS_COUNT'
export const SET_SHOW_SEARCH = 'SET_SHOW_SEARCH'
export const SET_START_DATE = 'SET_START_DATE'
export const SET_START_TIME = 'SET_START_TIME'
export const SET_TIMEZONE = 'SET_TIMEZONE'

export const setLocation = location => async dispatch => {
  dispatch({
    type: SET_LOCATION,
    payload: location,
  })

  const { lat, lng } = location

  const { timeZoneId } = await getLocationTimezone(lat, lng)

  dispatch(setTimezone(timeZoneId))
}

export const setTimezone = timezone => dispatch =>
  dispatch({
    type: SET_TIMEZONE,
    payload: timezone,
  })

export const setStartDate = date => (dispatch, getState) => {
  const { endDate, endTime, startTime } = getState().reservedParking
  const endDateTime = getFormattedDateAndTime(endDate, endTime)
  const startDateTime = moment(getFormattedDateAndTime(date, startTime))

  if (startDateTime.isAfter(endDateTime)) {
    const newEndDate = moment(startDateTime).add(1, 'day').format()

    dispatch(setEndDate(getFormattedDate(newEndDate)))
  }

  return dispatch({
    type: SET_START_DATE,
    payload: date,
  })
}

export const setEndDate = date => dispatch =>
  dispatch({
    type: SET_END_DATE,
    payload: date,
  })

export const setStartTime = time => (dispatch, getState) => {
  const { endDate, endTime, startDate } = getState().reservedParking
  const endDateTime = getFormattedDateAndTime(endDate, endTime)
  const startDateTime = moment(getFormattedDateAndTime(startDate, time))

  if (startDateTime.isSameOrAfter(endDateTime)) {
    const newEndDateTime = moment(startDateTime).add(1, 'hour').format()

    dispatch(setEndTime(getFormattedTime(newEndDateTime)))
    dispatch(setEndDate(getFormattedDate(newEndDateTime)))
  }

  return dispatch({
    type: SET_START_TIME,
    payload: time,
  })
}

export const setEndTime = time => dispatch =>
  dispatch({
    type: SET_END_TIME,
    payload: time,
  })

export const setRadius = radius => dispatch =>
  dispatch({
    type: SET_RADIUS,
    payload: radius,
  })

export const setAvailableFilters = filters => dispatch =>
  dispatch({
    type: SET_AVAILABLE_FILTERS,
    payload: filters,
  })

export const setFilters = filter => dispatch => {
  if (isEmpty(filter)) {
    return dispatch({
      type: CLEAR_FILTERS,
    })
  }

  return dispatch({
    type: SET_FILTERS,
    payload: filter,
  })
}

export const setLots = lots => dispatch =>
  dispatch({
    type: SET_LOTS,
    payload: lots,
  })

export const setResultsCount = count => dispatch =>
  dispatch({
    type: SET_RESULTS_COUNT,
    payload: count,
  })

export const setActiveLot = id => dispatch =>
  dispatch({
    type: SET_ACTIVE_LOT,
    payload: id,
  })

export const setActivePin = id => dispatch =>
  dispatch({
    type: SET_ACTIVE_PIN,
    payload: id,
  })

export const resetReservedParking = () => dispatch =>
  dispatch({
    type: RESET_RESERVED_PARKING,
  })

export const setPass = pass => dispatch =>
  dispatch({
    type: SET_PASS,
    payload: pass,
  })

export const setShowSearch = pass => dispatch =>
  dispatch({
    type: SET_SHOW_SEARCH,
    payload: pass,
  })
