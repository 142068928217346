import React from 'react'

import PhoneInput from 'components/Atoms/PhoneInput'

import { TextField } from '../index'

const Input = React.forwardRef((props, ref) => (
  <PhoneInput inputRef={ref} {...props} />
))

Input.displayName = 'phoneInput'

const PhoneField = props => <TextField {...props} InputComponent={Input} />

export default PhoneField
