import React, { useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import { attemptLogin } from 'redux-web/utils/authentication/actions'
import { showBasicModal } from 'helpers/modal'
import { useTranslation } from 'i18n-web/i18next'

import FacebookButton from './Screen'

const FacebookButtonHOC = WrapperComponent => {
  const Wrapper = props => {
    const { showErrorMessage, showSupportMessage } = props
    const { t, i18n } = useTranslation()
    const dispatch = useDispatch()
    const currentLanguage = i18n.language?.split('-')[0]
    const currentRegion = i18n.language?.split('-')[1] || 'us'

    const language = useMemo(
      () => `${currentLanguage}_${currentRegion.toUpperCase()}`,
      [currentLanguage, currentRegion]
    )

    const [tryAgain, setTryAgain] = useState(() => {})

    const onCallback = resp => {
      if (resp.accessToken) {
        dispatch(
          attemptLogin({
            data: { facebookToken: resp.accessToken },
            showErrorMessage: showErrorMessage,
            showSupportMessage: showSupportMessage,
          })
        )
      } else {
        dispatch(
          showBasicModal({
            actionButtons: {
              rightButtonOnClick: () => tryAgain(),
              rightButtonText: t('common.button.tryAgain'),
            },
            description: t('signIn.facebook.error.description'),
            title: t('signIn.facebook.error.title'),
            type: 'facebook',
          })
        )
      }
    }

    return (
      <WrapperComponent
        {...props}
        language={language}
        onCallback={onCallback}
        setTryAgain={setTryAgain}
      />
    )
  }

  Wrapper.propTypes = {
    showErrorMessage: PropTypes.bool,
    showSupportMessage: PropTypes.bool,
  }

  return Wrapper
}

export default FacebookButtonHOC(FacebookButton)
