import { isRegExp } from 'lodash'
import * as Sentry from '@sentry/nextjs'
import AbortController from 'abort-controller'

import { i18n } from 'i18n-web/i18next'
import { getReservationSession } from 'helpers/reservation'
import { setFetchSettingsError } from 'redux-web/utils/settings/actions'

export function getErrorMessage({ message }) {
  let errorMessage = message

  if (!errorMessage) {
    errorMessage = i18n.t('common.alert.serverCommunication.description')
  }

  return errorMessage
}

export const reservationTokenWhitelist = url => {
  if (getReservationSession()) {
    const whitelist = [
      'me/sessions/current',
      'me',
      'now',
      'me/magic-links',
      'me/reservations',
      'me/reservations/current/end',
      new RegExp(/^me\/reservations\/(?:([^/]+?))\/fee-breakdown?$/i),
      new RegExp(/^me\/reservations\/(?:([^/]+?))\/?$/i),
      new RegExp(/^reservations\/(?:([^/]+?))\/?$/i),
    ]
    const urlWhiteListed = whitelist.filter(
      item => item === url || (isRegExp(item) && item.test(url))
    )[0]

    return urlWhiteListed ? getReservationSession() : null
  }

  return null
}

export const fetchWithTimeout = (
  url,
  params,
  timeout = process.env.NEXT_PUBLIC_REQUESTS_TIMEOUT || 10000
) => {
  const controller = new AbortController()

  return new Promise((resolve, reject) => {
    const { signal } = controller

    signal.addEventListener('abort', () => {
      resolve(
        new Response(
          JSON.stringify({
            error: {
              code: 408,
              type: 'try again',
              message: params?.timeoutTryAgainMessage
                ? i18n.t('common.alert.timeout.tryAgain')
                : i18n.t('common.alert.timeout.support'),
            },
          }),
          {
            headers: {
              'Content-Type': 'application/json',
            },
            ok: false,
            status: 408,
            statusText: 'timeout',
          }
        )
      )
    })

    const timer = setTimeout(() => {
      controller?.abort()
    }, timeout)

    fetch(url, { ...params, signal: controller?.signal })
      .then(value => {
        clearTimeout(timer)
        resolve(value)
      })
      .catch(reason => {
        clearTimeout(timer)
        reject(reason)
        Sentry.captureMessage(`Fetch Error - url: ${url} - reason: ${reason}`)
      })
  })
}

export const heathCheckAPI = options =>
  fetchWithTimeout('/api/settings', {
    ...options,
  })
    .then(response => {
      if (!response.ok) {
        setFetchSettingsError(response.status)
      }
    })
    .catch(err => {
      Sentry.captureMessage(`Health check Error ${err}`)
    })
