import { showModal } from 'components/Atoms/Modal/actions'

export const showBasicModal = ({
  actionButtons = {},
  component,
  description,
  hideButton,
  preventClose,
  textButton,
  title,
  type,
}) =>
  showModal({
    modalProps: {
      open: true,
      preventClose: preventClose,
      className: 'noBorders',
    },
    childProps: {
      actionButtons: actionButtons,
      component: component,
      description: description,
      hideButton: hideButton,
      textButton: textButton,
      title: title,
      type,
    },
    fullScreen: false,
    modalType: 'basic',
  })

export const showWhiteModeModal = props =>
  showModal({
    modalProps: {
      open: true,
      preventClose: props?.preventClose,
      className: 'noBorders',
      customStyle: {
        ...(props?.alignTop && {
          top: '0',
        }),
      },
    },
    childProps: {
      ...props,
    },
    fullScreen: false,
    modalType: 'whiteMode',
  })
