import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRouter } from 'next/router'

import API from 'api'
import { fetchMe } from 'redux-web/utils/user/actions'
import { hideModal } from 'components/Atoms/Modal/actions'
import {
  internationalPhoneNumber,
  nationalPhoneNumber,
} from 'helpers/phoneFormat'
import { showBasicModal } from 'helpers/modal'
import { showModal } from 'components/Atoms/Modal/actions'
import { useTranslation } from 'i18n-web/i18next'

import EditAccountForm from './Form'

const EditAccountHOC = () => {
  const dispatch = useDispatch()
  const router = useRouter()
  const { t } = useTranslation()

  const countryCode = useSelector(state => state.countries.country)
  const isSDK = useSelector(state => state.settings.isSDK)
  const { email, name, phoneCountryCode, phoneNumber } = useSelector(
    state => state.user.me
  )

  // eslint-disable-next-line no-unused-vars
  const [formikRef, setFormikRef] = useState(null)
  const {
    query: { application },
  } = router

  const initialValues = {
    name,
    email,
    phoneNumber: internationalPhoneNumber(phoneNumber, phoneCountryCode),
  }

  const formikInstance = ref => setFormikRef(ref)

  const showError = error => {
    dispatch(hideModal())
    dispatch(
      showBasicModal({
        actionButtons: {
          rightButtonOnClick: dispatch(
            showModal({
              fullScreen: true,
              modalProps: {
                className: 'noBorders',
                open: true,
              },
              modalType: 'editAccount',
            })
          ),
          rightButtonText: t('common.button.tryAgain'),
          singleButtonOnClick: () => {},
          singleButtonText: t('common.button.cancel'),
        },
        description: error,
        title: t('common.title.error'),
        type: 'error',
      })
    )
  }

  const onSubmit = async (values, setSubmitting, token) => {
    const newPhoneNumber = nationalPhoneNumber(values.phoneNumber, countryCode)

    const data = {
      name: values.name,
      email: values.email,
      ...(token && {
        phoneCountryCode: countryCode,
        phoneNumber: newPhoneNumber,
        phoneVerificationToken: token,
      }),
    }

    try {
      const response = await API().putMe({
        timeoutTryAgainMessage: true,
        body: JSON.stringify(data),
      })

      setSubmitting(false)

      if (response?.error) {
        showError(response.error?.message)
      }

      await dispatch(fetchMe(isSDK))
      dispatch(hideModal())
      dispatch(
        showBasicModal({
          title: t('myAccount.settings.editAccount.modal.success.title'),
          description: t(
            'myAccount.settings.editAccount.modal.success.description'
          ),
        })
      )
    } catch (error) {
      setSubmitting(false)
      showError(error)
    }
  }

  const sendCodeVerification = (values, setSubmitting) => {
    const newPhoneNumber = nationalPhoneNumber(values.phoneNumber, countryCode)

    API()
      .sendPhoneVerification({
        ...(isSDK && { sdk: true }),
        timeoutTryAgainMessage: true,
        body: JSON.stringify({
          method: 'textMessage',
          phoneCountryCode: countryCode,
          phoneNumber: newPhoneNumber,
        }),
      })
      .then(res => {
        if (res.success) {
          dispatch(
            showModal({
              childProps: {
                countryCode: countryCode,
                phoneNumber: newPhoneNumber,
                modalCallback: token => onSubmit(values, setSubmitting, token),
              },
              fullScreen: false,
              modalProps: {
                open: true,
                preventClose: true,
              },
              modalType: 'verifyPhoneNumber',
            })
          )
        }

        if (res?.error?.message) {
          showError(res?.error?.message)
        }
      })
      .catch(err => {
        showError(err)
      })
  }

  const getPhoneNumberVerificationToken = (values, setSubmitting) => {
    const newInternationalPhoneNumber = internationalPhoneNumber(
      values.phoneNumber,
      countryCode
    )

    if (newInternationalPhoneNumber === initialValues.phoneNumber) {
      return onSubmit(values, setSubmitting)
    }

    const newNationalPhoneNumber = nationalPhoneNumber(
      values.phoneNumber,
      countryCode
    )

    API()
      .validateUserNewPhoneNumber(countryCode, newNationalPhoneNumber)
      .then(res => {
        if (res?.phoneNumber?.userExists || res?.phoneNumber?.guestExists) {
          return showError(
            t('myAccount.settings.editAccount.modal.error.accountExists')
          )
        }

        sendCodeVerification(values, setSubmitting)
      })
      .catch(err => {
        showError(err)
      })
  }

  const handleSubmit = (values, { setSubmitting }) => {
    setSubmitting(true)

    getPhoneNumberVerificationToken(values, setSubmitting)
  }

  return (
    <EditAccountForm
      application={application}
      country={phoneCountryCode}
      formikRef={formikInstance}
      handleSubmit={handleSubmit}
      initialValues={initialValues}
    />
  )
}

export default EditAccountHOC
