import { withRouter } from 'next/router'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'

import { getAppRoute } from 'helpers/application'
import { attemptLogin } from 'redux-web/utils/authentication/actions'
import { useTranslation } from 'i18n-web/i18next'
import { hideModal } from 'components/Atoms/Modal/actions'

import VerifyPhoneNumberForm from './Form'

const FormikHOC = props => {
  const { credentials, router } = props

  const {
    query: { application },
  } = router

  const { t } = useTranslation()
  const user = useSelector(state => state.user.me)
  const dispatch = useDispatch()

  const initialValues = {
    code: '',
    remember: false,
  }

  useEffect(() => {
    if (!isEmpty(user) && !user.isGuest) {
      dispatch(hideModal())
      router.push(`${getAppRoute(application, 'url')}/my-passes`)
    }
  }, [user])

  const handleSubmit = (values, { setSubmitting, setStatus }) => {
    setSubmitting(true)

    dispatch(
      attemptLogin({
        showErrorMessage: err => {
          setSubmitting(false)
          setStatus({ message: err })
        },
        data: {
          ...credentials,
          twoFactorAuthenticationCode: values.code,
          twoFactorAuthenticationTrustPermanently: values.remember,
        },
        sessionType: 'management',
      })
    )
  }

  return (
    <VerifyPhoneNumberForm
      {...props}
      application={application}
      handleSubmit={handleSubmit}
      initialValues={initialValues}
      t={t}
    />
  )
}

FormikHOC.propTypes = {
  credentials: PropTypes.object,
  router: PropTypes.object,
}

export default withRouter(FormikHOC)
