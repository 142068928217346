import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Grid, Col, Row } from 'react-styled-flexboxgrid'
import { withRouter } from 'next/router'
import dynamic from 'next/dynamic'

import isLightMode from 'helpers/isLightMode'
import { Link } from 'i18n-web/i18next'
import { Menu } from 'components/Molecules'
import { getAppRoute } from 'helpers/application'
import { getThemePath } from 'models/application'
import { isCitifydApp } from 'helpers/application'
const LanguageSelector = dynamic(
  () => import('components/Molecules/LanguageSelector'),
  {
    ssr: false,
  }
)

const Component = styled.header`
  position: relative;
  flex-shrink: 0;
  height: 56px;
  background-color: ${props =>
    isLightMode(props.application)
      ? props.theme.palette.grayDark
      : props.theme.palette.black};
  z-index: 100;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 56px;
  }

  .logo {
    width: 170px;
    height: auto;
  }

  .logoLink {
    line-height: 1;
  }
`

const RightColumn = styled.div`
  display: flex;
  align-items: center;
`

const Header = ({
  router,
  onLogoutRedirection,
  hideMenu,
  hideLanguageSelector,
}) => {
  const {
    query: { application },
  } = router

  const getHeaderData = () => {
    if (isCitifydApp(application)) {
      return {
        href: `${getAppRoute(application, 'url')}/`,
        logo: '/static/images/logo_with_text_long@3x.png',
      }
    }

    return {
      href: `${getAppRoute(application, 'url')}/events`,
      logo: `/static/images/${getThemePath(application)}/logo_header.svg`,
    }
  }

  return (
    <Component application={application}>
      <Grid>
        <Row center="xs">
          <Col className="header" xs={12}>
            {!process.env.NEXT_PUBLIC_ENABLE_PAY_BY_SIGNAGE_ONLY ? (
              <Link href={getHeaderData().href}>
                <a className="logoLink">
                  <img className="logo" src={getHeaderData().logo} />
                </a>
              </Link>
            ) : (
              <img className="logo" src={getHeaderData().logo} />
            )}
            <RightColumn>
              {!hideLanguageSelector && <LanguageSelector />}
              {!hideMenu && <Menu onLogoutRedirection={onLogoutRedirection} />}
            </RightColumn>
          </Col>
        </Row>
      </Grid>
    </Component>
  )
}

Header.propTypes = {
  router: PropTypes.object,
  onLogoutRedirection: PropTypes.string,
  hideMenu: PropTypes.bool,
  hideLanguageSelector: PropTypes.bool,
}

Header.defaultProps = { logoImage: undefined }

export default withRouter(Header)
