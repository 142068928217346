import styled from 'styled-components'
import { Col } from 'react-styled-flexboxgrid'
import PropTypes from 'prop-types'

const Component = styled(Col)`
  padding: 0;
  &.right {
    text-align: right;
  }
  a {
    color: ${props => props.theme.palette.primary};
    text-decoration: none;
  }
`

export const Left = ({ full, children }) => (
  <Component xs={!full ? 8 : 12}>{children}</Component>
)

Left.propTypes = {
  full: PropTypes.bool,
  children: PropTypes.node,
}

export const Right = ({ children }) => (
  <Component className="right" xs={4}>
    {children}
  </Component>
)

Right.propTypes = {
  children: PropTypes.node,
}
