import { kebabCase } from 'lodash'
import getDistance from 'geolib/es/getDistance'

import getImageUrl from 'helpers/image'
import { convertMetersToMiles, convertMetersToKilometers } from 'helpers'
import { formatAddress } from 'i18n-web/formatters'
import { formatCurrency } from 'i18n-web/formatters'
import { getMeasurementSystemFromLocale } from 'models/settings'
import { i18n } from 'i18n-web/i18next'
import { selectCurrencyForCountry } from 'redux-web/selectors/countries'

export const getLotUrl = lot => kebabCase(`${lot.id}-${lot.name}`)

export const getDistanceByGeoLib = (
  lotLatitude,
  lotLongitude,
  latitude,
  longitude
) => {
  if (!lotLatitude || !lotLongitude || !latitude || !longitude) {
    return false
  }

  const { language } = i18n
  const measurementSystem = getMeasurementSystemFromLocale(language)

  const distance = getDistance(
    { latitude: lotLatitude, longitude: lotLongitude },
    { latitude: latitude, longitude: longitude }
  )

  const formattedDistance = distance
    ? measurementSystem === 'imperial'
      ? i18n.t('lot.distance.miles', {
          distance: convertMetersToMiles(distance).toFixed(1),
        })
      : i18n.t('lot.distance.kilometers', {
          distance: convertMetersToKilometers(distance).toFixed(1),
        })
    : null

  return formattedDistance
}

export const getFormattedLot = ({
  isBundleEvent,
  event,
  lot,
  countries,
  t,
}) => {
  const { language } = i18n
  const lotRate = !isBundleEvent ? lot.calculatedPrice ?? lot.rate : event.price
  const currency = selectCurrencyForCountry(countries, lot?.countryCode)
  const price =
    formatCurrency(lotRate.value + lotRate.fee, currency) || undefined

  const measurementSystem = getMeasurementSystemFromLocale(language)

  const convertedDistance = lot?.lotDistance?.distance
    ? measurementSystem === 'imperial'
      ? t('event.distance.miles', {
          distance: convertMetersToMiles(lot.lotDistance.distance).toFixed(2),
        })
      : t('event.distance.kilometers', {
          distance: convertMetersToKilometers(lot.lotDistance.distance).toFixed(
            2
          ),
        })
    : null

  return {
    ...lot,
    addressFormatted: formatAddress(
      lot?.address,
      lot?.countryCode,
      'full_inline'
    ),
    addressLine1: lot?.address?.line1,
    convertedDistance: convertedDistance,
    currency: currency,
    image: getImageUrl(lot?.photo),
    lat: lot?.latitude,
    lng: lot?.longitude,
    notes: lot?.notes,
    price: price,
    rate: lotRate,
    ticketExportAllowed: lot?.ticketExportAllowed,
    vehicleRequired: lot?.vehicleRequired,
  }
}
