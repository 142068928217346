import {
  CLEAR_HOURLY_FILTERS,
  RESET_HOURLY_SEARCH,
  SET_HOURLY_ACTIVE_LOT,
  SET_HOURLY_ACTIVE_PIN,
  SET_HOURLY_AVAILABLE_FILTERS,
  SET_HOURLY_ERROR,
  SET_HOURLY_FILTERS,
  SET_HOURLY_LOADING,
  SET_HOURLY_LOCATION,
  SET_HOURLY_LOTS,
  SET_HOURLY_RADIUS,
  SET_HOURLY_RESULTS_COUNT,
  SET_HOURLY_SHOW_SEARCH,
  START_REDIRECTING,
  STOP_REDIRECTING,
} from './actions'

const defaultState = {
  activeLot: false,
  activePin: false,
  availableFilters: [],
  error: null,
  filters: {},
  loading: false,
  location: {},
  lots: null,
  radius: 20,
  resultsCount: 0,
  isRedirecting: false,
}

export const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_HOURLY_LOCATION:
      return {
        ...state,
        location: {
          ...action.payload,
        },
      }

    case SET_HOURLY_RADIUS:
      return {
        ...state,
        radius: action.payload,
      }

    case SET_HOURLY_AVAILABLE_FILTERS:
      return {
        ...state,
        availableFilters: action.payload,
      }

    case SET_HOURLY_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload,
        },
      }

    case SET_HOURLY_LOTS:
      return {
        ...state,
        lots: action.payload,
      }

    case CLEAR_HOURLY_FILTERS:
      return {
        ...state,
        filters: {},
      }

    case SET_HOURLY_RESULTS_COUNT:
      return {
        ...state,
        resultsCount: action.payload,
      }

    case SET_HOURLY_ACTIVE_LOT:
      return {
        ...state,
        activeLot: action.payload,
      }

    case SET_HOURLY_ACTIVE_PIN:
      return {
        ...state,
        activePin: action.payload,
      }

    case RESET_HOURLY_SEARCH:
      return {
        ...defaultState,
      }

    case SET_HOURLY_SHOW_SEARCH:
      return {
        ...state,
        showSearch: action.payload,
      }

    case SET_HOURLY_LOADING:
      return {
        ...state,
        loading: action.payload,
      }

    case SET_HOURLY_ERROR:
      return {
        ...state,
        error: action.payload,
      }

    case START_REDIRECTING:
      return {
        ...state,
        isRedirecting: true,
      }

    case STOP_REDIRECTING:
      return {
        ...state,
        isRedirecting: false,
      }

    default:
      return state
  }
}
