import * as Yup from 'yup'

import { phoneNumberSchema } from 'i18n-web/validators'
import { i18n } from 'i18n-web/i18next'

const validationSchema = (countryCode, showPassword, showEmail) =>
  Yup.object().shape({
    phoneNumber: phoneNumberSchema({ countryCode }),
    password: Yup.string()
      .required(showPassword)
      .max(20, i18n.t('signIn.form.password.error')),
    email: Yup.string()
      .required(showEmail)
      .email(i18n.t('signIn.form.email.error')),
  })

export default validationSchema
