import React, { useState, useRef, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import { useDispatch } from 'react-redux'
import { useRouter } from 'next/router'

import { useTranslation } from 'i18n-web/i18next'
import { setLocation } from 'redux-web/utils/hourlySearch/actions'
import { selectGeolocation } from 'redux-web/selectors/settings'
import { hideModal } from 'components/Atoms/Modal/actions'
import { getHourlySearchUrl } from 'models/hourlySearch'
import { setSharedCoordinates } from 'redux-web/utils/settings/actions'

import Screen from './Screen'

import { initializeApollo } from '@/graphql/apolloClient'

const HourlyParkingSearchHOC = props => {
  const apolloClient = initializeApollo()
  const inputRef = useRef()
  const [address, setAddress] = useState()
  const [locationError, setLocationError] = useState()
  const [loadingMyLocation, setLoadingMyLocation] = useState(false)
  const [isLocationInputFocused, setIsLocationInputFocused] = useState(false)
  const { hasBrowserPermission: geolocationBrowserPermission } = useSelector(
    state => selectGeolocation(state)
  )

  const { t } = useTranslation()
  const router = useRouter()

  const dispatch = useDispatch()

  useEffect(() => {
    if (isLocationInputFocused) {
      setLocationError(null)
    }
  }, [isLocationInputFocused])

  const handleLocationChange = locationAddress => setAddress(locationAddress)

  const handleLocationSelect = locationAddress => {
    setIsLocationInputFocused(false)
    setAddress(locationAddress)

    geocodeByAddress(locationAddress)
      .then(results => getLatLng(results[0]))
      .then(latLng => {
        dispatch(
          setLocation({
            address: locationAddress,
            ...latLng,
          })
        )
      })

    inputRef?.current?.blur()
  }

  const getUserCurrentLocation = () => {
    if (!geolocationBrowserPermission) return

    setLoadingMyLocation(true)

    setAddress(t('reservedParking.myLocation'))

    const options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0,
    }

    navigator?.geolocation.getCurrentPosition(
      position => {
        const {
          coords: { latitude, longitude },
        } = position

        setLoadingMyLocation(false)
        setAddress(t('reservedParking.myLocation'))

        dispatch(
          setLocation({
            address: t('reservedParking.myLocation'),
            lat: latitude,
            lng: longitude,
          })
        )

        dispatch(
          setSharedCoordinates({
            latitude,
            longitude,
          })
        )
      },
      error => {
        setLoadingMyLocation(false)

        if (error.code === 1) {
          setLocationError(t('reservedParking.locationDisabled.description'))
        }

        if (error.code === 3) {
          setLocationError(
            t('reservedParking.locationTimeoutExpired.description')
          )
        }
      },
      options
    )
  }

  useEffect(() => {
    getUserCurrentLocation()
  }, [geolocationBrowserPermission])

  const onSubmitClick = () => {
    if (!router.pathname?.includes('/hourly-parking')) {
      const url = getHourlySearchUrl(address)

      router.push(url)
    }

    dispatch(hideModal())
  }

  return (
    <Screen
      address={address}
      apolloClient={apolloClient}
      getUserCurrentLocation={getUserCurrentLocation}
      inputRef={inputRef}
      isLocationInputFocused={isLocationInputFocused}
      loadingMyLocation={loadingMyLocation}
      locationError={locationError}
      onLocationChange={handleLocationChange}
      onLocationSelect={handleLocationSelect}
      onSubmitClick={onSubmitClick}
      setIsLocationInputFocused={setIsLocationInputFocused}
      {...props}
    />
  )
}

export default HourlyParkingSearchHOC
