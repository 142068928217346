const Cancel = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="35"
    height="35"
    viewBox="0 0 35 35">
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g>
            <g>
              <g transform="translate(-38 -316) translate(18 133) translate(0 164) translate(20 19) translate(1.75 1.75)">
                <circle
                  cx="15.75"
                  cy="15.75"
                  r="14.75"
                  stroke="#fff"
                  strokeWidth="2"
                />
                <path
                  fill="#fff"
                  d="M20.763 9L22.62 10.857 10.857 22.62 9 20.763 20.763 9"
                />
                <path
                  fill="#fff"
                  d="M9 10.857L10.857 9 22.62 20.763 20.763 22.62 9 10.857"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default Cancel
