import styled from 'styled-components'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'

import { useTranslation } from 'i18n-web/i18next'
import Breakdown from 'components/Molecules/Receipt/Breakdown'
import Receipt, { Group } from 'components/Molecules/Receipt/Template'
import Text from 'components/Atoms/Text'
import PageTitle from 'components/Atoms/PageTitle'

const Content = styled.div`
  overflow: scroll;
  padding: 0 1rem 30px;

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    margin-left: 35px;
    margin-right: 35px;
    padding: 0;
  }
`

const Header = styled.div`
  padding: 30px 1rem 0;
  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    padding: 0;
  }
`

const Component = styled.div`
  display: flex;
  flex-direction: column;
`

const Divisor = styled.div`
  margin-top: 20px;
  border-top: 1px solid ${props => props.theme.palette.gray}80;
  display: none;

  &.title {
    margin-top: 0;
  }

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    display: block;
    margin-bottom: 10px;
  }
`

const PurchaseDetails = ({ ticket }) => {
  const { t } = useTranslation()
  const { event, lot, availability, financialFormatted, financial, card } =
    ticket

  return (
    <Component>
      <Header>
        <PageTitle
          modal
          noBorder
          showBackButton={true}
          shadow
          title={t('purchaseDetails.title')}
        />
      </Header>
      <Divisor />
      <Content>
        <Receipt
          parkingType={t('events.receipt.details.parkingType')}
          total={financial?.userChargeAmount > 0 && financialFormatted.total}
          card={card}
          headerText={t('events.receipt.details.disclaimer')}
          nativePaymentType={card?.nativePayment}>
          <Group title={event?.name}>
            <Text>{`${event?.venueName}, ${event?.address.city}, ${event?.address.state} ${event?.address.postalCode}`}</Text>
            <Text>
              {event &&
                `${moment(event.start)
                  .tz(event.timezoneName)
                  .format('ll')} - ${moment(event.start)
                  .tz(event.timezoneName)
                  .format('LT')}`}
            </Text>
          </Group>
          <Group title={lot?.name}>
            <Text>{lot?.address.line1}</Text>
            <Text>{`${lot?.address.city}, ${lot?.address.state} ${lot?.address.postalCode}`}</Text>
            <Text>{`${
              availability &&
              `${moment(availability.start).format(
                'MMM D YYYY, LT'
              )} - ${moment(availability.end).format('LT')}`
            }`}</Text>
          </Group>
        </Receipt>
        {financial?.userChargeAmount > 0 && (
          <Breakdown
            amount={{
              value: financialFormatted.value,
              fee: financialFormatted.fee,
              credits: financialFormatted.credits,
              total: financialFormatted.total,
            }}
          />
        )}
      </Content>
    </Component>
  )
}

PurchaseDetails.propTypes = {
  ticket: PropTypes.object,
}

export default PurchaseDetails
