import { withFormik } from 'formik'
import PropTypes from 'prop-types'
import { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { withRouter } from 'next/router'

import { showBasicModal } from 'helpers/modal'
import { getAppKey } from 'models/application'
import { isCitifydApp } from 'helpers/application'
import { getFormattedTicket } from 'models/tickets'
import { setTicket } from 'redux-web/utils/ticket/actions'
import { showModal } from 'components/Atoms/Modal/actions'
import { nationalPhoneNumber } from 'helpers/phoneFormat'
import { setMyPasses } from 'redux-web/utils/my-passes/actions'
import API from 'api'
import { useTranslation } from 'i18n-web/i18next'

import validationSchema from './validationSchema'
import TransferYourPass from './Form'

const TransferYourPassHOC = props => {
  const { phoneNumber, router, ticket } = props

  const {
    query: { application },
  } = router

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const isSDK = useSelector(state => state.settings.isSDK)
  const country = useSelector(state => state.countries.country)
  const [initialPhoneNumber, setInitialPhoneNumber] = useState(phoneNumber)

  const refCountry = useRef(country)

  useEffect(() => {
    refCountry.current !== country && setInitialPhoneNumber('')
  }, [country])

  const Formik = withFormik({
    mapPropsToValues: () => ({
      email: '',
      phoneNumber: initialPhoneNumber || '',
    }),
    isInitialValid: initialPhoneNumber ? true : false,
    validationSchema: () => validationSchema(country),
    handleSubmit: (values, { setStatus, setSubmitting }) => {
      setSubmitting(true)
      let data

      if (values.email && !values.phoneNumber) {
        data = { email: values.email }
      } else if (values.phoneNumber) {
        const phoneNumber = nationalPhoneNumber(values.phoneNumber, country)

        data = {
          phoneCountryCode: country,
          phoneNumber: phoneNumber,
        }
      }

      if (values.email || values.phoneNumber) {
        API()
          .transferTicket(ticket?.id, {
            timeoutTryAgainMessage: true,
            body: JSON.stringify(data),
            ...(isSDK && { sdk: true }),
            ...(!isCitifydApp(application) && {
              headers: { 'App-Key': getAppKey(application) },
            }),
          })
          .then(resp => {
            if (resp.error) {
              setStatus({ message: resp.error.message })
              if (resp.error.code === 408) {
                dispatch(
                  showBasicModal({
                    title: resp.error.message,
                  })
                )
              }
            } else {
              dispatch(
                showModal({
                  modalProps: {},
                  modalType: 'transferYourPassSuccess',
                  childProps: {
                    data: {
                      code: resp.code,
                      recipient: data.email || data.phoneNumber,
                      event: {
                        name: ticket?.event.name,
                        start: ticket?.event.start,
                      },
                      lot: {
                        name: ticket?.lot.name,
                      },
                    },
                  },
                })
              )

              API()
                .getMyEvents(
                  { ...(isSDK && { sdk: true }) },
                  'showPurchasedOnly=true&includeTransferred=true'
                )
                .then(resp => {
                  dispatch(setMyPasses(resp.events))
                })
                .catch(() => {})

              API()
                .confirmTransferTicket(ticket.id, {
                  ...(isSDK && { sdk: true }),
                  body: JSON.stringify({ confirm: true }),
                  ...(!isCitifydApp(application) && {
                    headers: { 'App-Key': getAppKey(application) },
                  }),
                })
                .then(
                  resp =>
                    resp?.ticket &&
                    dispatch(
                      setTicket(
                        getFormattedTicket({
                          ticket: { ...ticket, isPendingTransfer: true },
                        })
                      )
                    )
                )
            }
          })
          .catch(error => {
            setStatus({ message: error })
            setSubmitting(false)
          })
      }
    },
    displayName: 'TransferYourPass',
  })(TransferYourPass)

  return <Formik {...props} application={application} t={t} />
}

TransferYourPassHOC.propTypes = {
  phoneNumber: PropTypes.string,
  router: PropTypes.object,
  ticket: PropTypes.object,
}

export default withRouter(TransferYourPassHOC)
