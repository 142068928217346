export const SET_IS_SUBMITTING = 'SET_IS_SUBMITTING'
export const SET_SELECTED_CARD = 'SET_SELECTED_CARD'
export const SET_NEW_CARD_OPTION = 'SET_NEW_CARD_OPTION'
export const SET_NATIVE_PAYMENT = 'SET_NATIVE_PAYMENT'
export const SET_NATIVE_PAYMENT_TYPE = 'SET_NATIVE_PAYMENT_TYPE'
export const SET_IS_VALID_FORM = 'SET_IS_VALID_FORM'
export const RESET_PURCHASE = 'RESET_PURCHASE'
export const SET_TERMS_AND_CONDITIONS_AGREEMENT =
  'SET_TERMS_AND_CONDITIONS_AGREEMENT'

export const setIsSubmitting = bool => dispatch => {
  dispatch({
    type: SET_IS_SUBMITTING,
    payload: bool,
  })
}

export const setSelectedCard = card => dispatch => {
  dispatch({
    type: SET_SELECTED_CARD,
    payload: card,
  })
}

export const setTermsAndConditionsAgreement = bool => dispatch => {
  dispatch({
    type: SET_TERMS_AND_CONDITIONS_AGREEMENT,
    payload: bool,
  })
}

export const setNewCardOption = bool => dispatch => {
  dispatch({
    type: SET_NEW_CARD_OPTION,
    payload: bool,
  })
}

export const setNativePayment = data => dispatch => {
  dispatch({
    type: SET_NATIVE_PAYMENT,
    payload: data,
  })
}

export const setNativePaymentType = data => dispatch => {
  dispatch({
    type: SET_NATIVE_PAYMENT_TYPE,
    payload: data,
  })
}

export const setIsValidForm = data => dispatch => {
  dispatch({
    type: SET_IS_VALID_FORM,
    payload: data,
  })
}

export const resetPurchase = () => dispatch => {
  dispatch({
    type: RESET_PURCHASE,
  })
}
