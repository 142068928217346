import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Skeleton from 'react-loading-skeleton'

import { Component } from './index'

const Content = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  flex-direction: column;
  min-width: 0;
  margin: 7px 9px;

  & > span {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
  }

  .skeleton {
    height: 13px;
    margin-bottom: 5px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    margin: 8px 12px;

    .skeleton {
      height: 16px;
      margin-bottom: 8px;
    }
  }
`

const ImageBox = styled.div`
  position: relative;
  margin: 2px;

  & > span {
    display: flex;
  }

  .skeleton {
    position: relative;
    width: 58px;
    height: 58px;
  }

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    .skeleton {
      width: 78px;
      height: 78px;
    }
  }
`

const LoadingSkeleton = ({ className, hasImage }) => (
  <Component className={className}>
    {hasImage && (
      <ImageBox>
        <Skeleton className="skeleton" count={1} />
      </ImageBox>
    )}
    <Content>
      <Skeleton className="skeleton" width="100%" count={3} />
    </Content>
  </Component>
)

LoadingSkeleton.propTypes = {
  className: PropTypes.string,
  hasImage: PropTypes.bool,
}

LoadingSkeleton.defaultProps = {
  hasImage: true,
}

export default LoadingSkeleton
