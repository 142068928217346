import * as Yup from 'yup'

import { i18n } from 'i18n-web/i18next'

const validationSchema = ({ isNativePayment, showCardFields }) =>
  Yup.object().shape({
    cardNumber:
      !isNativePayment &&
      showCardFields &&
      Yup.string().required(i18n.t('purchase.form.cardNumber.error')),
    cardExpiration:
      !isNativePayment &&
      showCardFields &&
      Yup.string().required(i18n.t('purchase.form.expirationDate.error')),
    cardCvc:
      !isNativePayment &&
      showCardFields &&
      Yup.string().required(i18n.t('purchase.form.cardCode.error')),
  })

export default validationSchema
