import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useRouter } from 'next/router'
import PropTypes from 'prop-types'

import { getAppRoute } from 'helpers/application'
import { hideModal } from 'components/Atoms/Modal/actions'
import { onLogout } from 'redux-web/utils/user/actions'
import { showModal } from 'components/Atoms/Modal/actions'
import { useTranslation } from 'i18n-web/i18next'

import Screen from './Screen'

const Menu = props => {
  const { onLogoutRedirection, children } = props

  const { t } = useTranslation()
  const router = useRouter()
  const dispatch = useDispatch()
  const user = useSelector(state => state.user.me)
  const loggedIn = useSelector(state => state.session.loggedIn)
  const isLoading = useSelector(state => state.user.isLoading)

  const isAuthenticated = loggedIn
  const isGuest = user?.isGuest

  const { application } = router.query

  const onLogoutUser = () => {
    dispatch(onLogout())
    dispatch(hideModal())

    if (onLogoutRedirection) {
      router.push(onLogoutRedirection)
    } else {
      router.push(
        `${getAppRoute(application, 'as')}/events`,
        `${getAppRoute(application, 'url')}/events`
      )
    }
  }

  const onLoginButton = redirectTo => {
    dispatch(
      showModal({
        childProps: {
          redirectTo: redirectTo,
        },
        modalProps: {
          className: 'noBorders',
        },
        modalType: 'signIn',
      })
    )
  }

  return (
    <Screen
      isPageLoaded={!isLoading}
      application={application}
      isAuthenticated={isAuthenticated}
      isGuest={isGuest}
      onLoginButton={onLoginButton}
      onLogoutUser={onLogoutUser}
      t={t}
      {...props}>
      {children}
    </Screen>
  )
}

Menu.propTypes = {
  onLogoutRedirection: PropTypes.string,
  router: PropTypes.object,
  children: PropTypes.node,
}

export default Menu
