import styled from 'styled-components'
import PropTypes from 'prop-types'

const Component = styled.div`
  background-image: url(${props => props.image});
  background-size: cover;
  background-position: center center;
  height: 30vh;
  width: 100%;
`

const Image = ({ image }) => <Component image={image} />

Image.propTypes = {
  image: PropTypes.string,
}

export default Image
