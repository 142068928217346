import withAuth from 'hocs/withAuth'
import withManagePass from 'hocs/withManagePass'
import { useTranslation } from 'i18n-web/i18next'

import ManagePass from './Screen'

const ManagePassHOC = Component => {
  const Wrapper = props => {
    const { t } = useTranslation()

    return <Component {...props} t={t} />
  }

  return Wrapper
}

export default withAuth(withManagePass(ManagePassHOC(ManagePass)))
