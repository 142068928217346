import thunk from 'redux-thunk'
import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'

import API from 'api'

import Reducer from './combineReducers'

const api = API()

let store

export const getStore = () => store

export function initializeStore(initialState) {
  const middleware =
    process.env.NEXT_PUBLIC_RUNTIME_ENVIRONMENT === 'production'
      ? applyMiddleware(thunk.withExtraArgument(api))
      : composeWithDevTools(applyMiddleware(thunk.withExtraArgument(api)))

  store = createStore(Reducer, initialState, middleware)

  return store
}
