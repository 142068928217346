import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { withRouter } from 'next/router'
import { useSelector } from 'react-redux'

import { usePrevious } from 'helpers/hooks'

const WithTemplateHOC = WrapperComponent => {
  const Wrapper = props => {
    let loadingTimeout
    const { router, hideHeader, hideFooter } = props
    const [authLoading, setAuthLoading] = useState(false)
    const prevLoading = usePrevious(authLoading)

    const loading = useSelector(state => state.request.loading)

    useEffect(() => {
      if (loading) {
        startLoadingTimeout()
      } else {
        clearTimeout(loadingTimeout)
        setAuthLoading(false)
      }

      return () => clearTimeout(loadingTimeout)
    }, [loading])

    useEffect(() => {
      if (authLoading !== prevLoading && prevLoading !== undefined) {
        if (!authLoading) {
          clearTimeout(loadingTimeout)
          setAuthLoading(false)
        }
      }

      return () => clearTimeout(loadingTimeout)
    }, [authLoading, prevLoading])

    const startLoadingTimeout = () => {
      loadingTimeout = setTimeout(() => {
        setAuthLoading(true)
      }, 100)
    }

    return (
      <WrapperComponent
        {...props}
        hideHeader={router?.query?.disableHeaderAndFooter || hideHeader}
        hideFooter={router?.query?.disableHeaderAndFooter || hideFooter}
        authLoading={authLoading}
      />
    )
  }

  if (WrapperComponent.getInitialProps) {
    Wrapper.getInitialProps = WrapperComponent.getInitialProps
  }

  Wrapper.propTypes = {
    router: PropTypes.object,
    hideFooter: PropTypes.bool,
    hideHeader: PropTypes.bool,
  }

  return withRouter(Wrapper)
}

export default WithTemplateHOC
