import moment from 'moment'
import { slice } from 'lodash'

const getRuleEndMoment = ({ rule, startMoment }) => {
  const { end } = rule

  switch (end.type) {
    case 'duration':
      return startMoment.clone().add(end.minute, 'minutes')
    case 'time': {
      const { time, days = 0 } = end
      const [hour, minute] = time.split(':').map(Number)

      return startMoment
        .clone()
        .startOf('day')
        .add(days, 'days')
        .hour(hour)
        .minute(minute)
    }
    default:
      throw new Error(`Unknown rate rule end type ${end.type}`)
  }
}

const isRuleAvailable = ({
  rules,
  ruleIndex,
  reservationStartMoment,
  reservationExpirationMoment,
}) => {
  const ruleEndMoment = getRuleEndMoment({
    rule: rules[ruleIndex],
    startMoment: reservationStartMoment,
  })
  const isAvailable = ruleEndMoment.isAfter(
    reservationExpirationMoment.clone().add(1, 'minutes')
  )

  return isAvailable
}

export const isRuleBasedOnDuration = ({ rules, ruleIndex }) => {
  const rule = rules[ruleIndex]

  return rule.end.type === 'duration'
}

export const getRuleAggregatedPrice = ({ rules, ruleIndex }) => {
  const appliedRules = slice(rules, 0, ruleIndex + 1)

  return appliedRules.reduce((result, rule, i) => {
    const { aggregateMethod, value } = rule

    if (i === 0 || aggregateMethod === 'replace') {
      return value
    }
    if (aggregateMethod === 'sum') {
      return result + value
    }
    throw new Error(`Invalid aggregate method ${aggregateMethod}`)
  }, 0)
}

export const getVariableRuleExpirationMoment = ({
  rules,
  ruleIndex,
  reservationStartTime,
  timezoneName,
}) => {
  const startMoment = moment.tz(reservationStartTime, timezoneName)

  return getRuleEndMoment({
    rule: rules[ruleIndex],
    startMoment,
  }).add(-1, 'seconds')
}

export const getAvailableRulesForExtension = ({
  rules,
  expirationTime,
  reservationStartTime,
  timezoneName,
}) => {
  const reservationExpirationMoment = moment.tz(expirationTime, timezoneName)
  const reservationStartMoment = moment.tz(reservationStartTime, timezoneName)

  return rules.filter((rule, index) =>
    isRuleAvailable({
      rules,
      ruleIndex: index,
      reservationExpirationMoment,
      reservationStartMoment,
    })
  )
}
