import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Text from 'components/Atoms/Text'

const Component = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin: 20px 0;
  text-transform: uppercase;
  color: ${props => props.theme.palette.grayDark};

  span {
    padding-right: 10px;
    background-color: ${props => props.theme.palette.background};
    font-size: ${props => props.theme.font.size.mobile.small};
    z-index: 1;
  }

  &.quaternary {
    &:after {
      background-color: ${props => props.theme.palette.quaternary};
    }
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    background-color: ${props => props.theme.palette.gray}50;
    width: 100%;
    height: 1px;
  }

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    font-size: ${props => props.theme.font.size.mobile.medium};
  }
`
const TextDivisor = ({ className, textClassName, text }) => (
  <Component className={className}>
    <Text className={textClassName} as="span">
      {text}
    </Text>
  </Component>
)

TextDivisor.propTypes = {
  className: PropTypes.string,
  textClassName: PropTypes.string,
  text: PropTypes.string,
}

export default TextDivisor
