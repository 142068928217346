const Exchange = () => (
  <svg xmlns="http://www.w3.org/2000/svg">
    <g fill="#fff" fillRule="evenodd">
      <g>
        <g>
          <g>
            <g>
              <path
                d="M7.265 18.865c0 6.627 5.372 12 12 12 4.175 0 7.851-2.132 10.001-5.366l-.001 1.366 1.49.001c-2.53 3.626-6.733 5.999-11.49 5.999-7.732 0-14-6.268-14-14 0-.68.048-1.347.142-2H7.43c-.11.65-.166 1.318-.166 2zm12-14c7.732 0 14 6.268 14 14 0 .68-.049 1.347-.142 2h-2.024c.109-.65.166-1.318.166-2 0-6.628-5.373-12-12-12-4.174 0-7.85 2.13-10 5.364v-1.364h-1.49c2.53-3.627 6.732-6 11.49-6z"
                transform="translate(-38 -238) translate(18 133) translate(0 82) translate(18.25 18.125) rotate(-25 19.265 18.865)"
              />
              <path
                d="M5.775 9.075v5.849h5.85v1.95h-7.8v-7.8h1.95z"
                transform="translate(-38 -238) translate(18 133) translate(0 82) translate(18.25 18.125) rotate(-25 7.725 12.975)"
              />
              <path
                d="M26.205 20.155h1.95v5.85h5.85v1.95h-7.8v-7.8z"
                transform="translate(-38 -238) translate(18 133) translate(0 82) translate(18.25 18.125) rotate(155 30.105 24.055)"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default Exchange
