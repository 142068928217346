import { i18n } from 'i18n-web/i18next'

export const getPasswordDefinitions = {
  uppercase: {
    pattern: /[A-Z]/,
    title: i18n.t('signUp.form.password.requirements.uppercase'),
    message: i18n.t('signUp.form.password.errors.uppercase'),
  },
  lowercase: {
    pattern: /[a-z]/,
    title: i18n.t('signUp.form.password.requirements.lowercase'),
    message: i18n.t('signUp.form.password.errors.lowercase'),
  },
  special: {
    pattern: /[^a-zA-Z0-9 ]/,
    title: i18n.t('signUp.form.password.requirements.special'),
    message: i18n.t('signUp.form.password.errors.special'),
  },
  minimum: {
    pattern: /^.{6,}$/,
    title: i18n.t('signUp.form.password.requirements.minimum'),
    message: i18n.t('signUp.form.password.errors.minimum'),
  },
}
