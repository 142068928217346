import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Image from 'components/Atoms/Image'
import Box from 'components/Atoms/Box'

import LoadingSkeleton from './LoadingSkeleton'

const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  min-width: 0;
  margin: 7px 9px;
  transition: all 150ms ease-out;

  &.isExpanded {
    height: 164px;
  }

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    margin: 7px 12px;
  }
`

const ImageBox = styled.div`
  width: ${props => props.imageSize}px;
  height: ${props => props.imageSize}px;
  border-radius: 3px 0 0 3px;
  position: relative;
  overflow: hidden;
  transition: all 150ms ease-out;

  &.isExpanded {
    width: 147px;
    height: auto;
  }

  & > img {
    object-fit: cover;
  }

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    width: 80px;
    height: 80px;
  }
`

const Component = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
  cursor: pointer;
  border: solid 2px transparent;
  transition: 0.2s;

  p {
    transition: 0.2s;
  }

  &.disabled {
    cursor: default;
  }

  &.sliderCard {
    background-color: ${props => props.theme.palette.background};
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.5);

    ${ImageBox} {
      width: 70px;
      height: 70px;
      margin: 0;
    }

    &:hover:not(.disabled) {
      ${Content} {
        p {
          color: ${props => props.theme.palette.grayDark};
        }
      }
    }

    & .active {
      &:not(.disabled) {
        p {
          color: ${props => props.theme.palette.grayDark};
        }
      }
    }
  }

  &.active {
    &:not(.disabled) {
      cursor: pointer;

      ${ImageBox} {
        &:before {
          background-color: ${props => props.theme.palette.primary};
        }
      }

      ${Content} {
        p.title {
          color: ${props => props.theme.palette.primary};
        }
      }
    }
  }

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    margin-bottom: 14px;
  }

  &.activeBorder {
    border: solid 2px ${props => props.theme.palette.primary};
  }

  &:hover:not(.disabled) {
    ${Content} {
      p.title {
        color: ${props => props.theme.palette.primary};
      }
    }
  }
`

const ListItem = React.forwardRef(
  (
    {
      children,
      className,
      disabled,
      id,
      image,
      imageSize,
      isExpanded,
      isLoading,
      showLoadingImage,
      onClick,
      onMouseEnter,
      onMouseLeave,
      onMouseOver,
      text,
    },
    ref
  ) => {
    if (isLoading) {
      return (
        <LoadingSkeleton className={className} hasImage={showLoadingImage} />
      )
    }

    return (
      <Component
        className={classNames(className, {
          disabled: disabled,
          isExpanded: isExpanded,
        })}
        id={id}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onMouseOver={onMouseOver}
        ref={ref}>
        {image && (
          <ImageBox
            imageSize={imageSize}
            className={classNames({ isExpanded: isExpanded })}
            hasText={Boolean(text && text())}>
            {text && text()}
            <Image alt="preview" height="100%" src={image} width="100%" />
          </ImageBox>
        )}
        <Content>{children}</Content>
      </Component>
    )
  }
)

ListItem.displayName = 'ListItem'

ListItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  image: PropTypes.string,
  isExpanded: PropTypes.bool,
  isLoading: PropTypes.bool,
  showLoadingImage: PropTypes.bool,
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onMouseOver: PropTypes.func,
  text: PropTypes.func,
  imageSize: PropTypes.number,
}

ListItem.defaultProps = {
  onMouseEnter: () => {},
  onMouseLeave: () => {},
  onMouseOver: () => {},
  imageSize: 60,
  showLoadingImage: true,
}

export { Component, Content, ImageBox, ListItem }
