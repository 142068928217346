export const MODAL_SHOW = 'MODAL_SHOW'
export const MODAL_HIDE = 'MODAL_HIDE'
export const MODAL_RESET = 'MODAL_RESET'

export const showModal =
  ({ modalProps, modalType, fullScreen = true, childProps }) =>
  dispatch => {
    dispatch({
      type: MODAL_SHOW,
      modalProps,
      modalType,
      fullScreen,
      childProps,
    })
  }

export const hideModal = () => dispatch => {
  dispatch({
    type: MODAL_HIDE,
  })
}

export const resetModal = () => dispatch => {
  dispatch({
    type: MODAL_RESET,
  })
}
