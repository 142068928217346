import styled from 'styled-components'
import PropTypes from 'prop-types'

import { TransferYourPassForm } from 'components/Molecules'
import Text from 'components/Atoms/Text'
import PageTitle from 'components/Atoms/PageTitle'

const CancelLink = styled.div`
  padding: 15px 0;
  text-align: center;
`

const Header = styled.div`
  padding: 30px 1rem 0;
  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    padding: 0;
  }
`

const Component = styled.div`
  display: flex;
  flex-direction: column;
`

const DefaultComponent = styled.div`
  padding: 0 1rem 90px;
  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    margin-left: 35px;
    margin-right: 35px;
    padding: 0;
  }
`

const Subtitle = styled(Text)`
  font-weight: 300;
  line-height: 24px;
  font-size: ${props => props.theme.font.size.mobile.small};
  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    font-size: ${props => props.theme.font.size.desktop.h4};
    font-weight: 400;
    line-height: 34px;
    margin-top: 15px;
    margin-bottom: 30px;
  }
`

const Divisor = styled.div`
  margin-top: 20px;
  border-top: 2px solid ${props => props.theme.palette.gray};
  display: none;

  &.title {
    margin-top: 0;
  }

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    display: block;
    margin-top: 12px;
    margin-bottom: 10px;
  }
`

const TransferYourPass = ({ t, ticket, onCancelLink }) => (
  <Component>
    <Header>
      <PageTitle
        modal
        noBorder
        showBackButton={true}
        shadow
        title={t('transferYourPass.title')}
      />
    </Header>
    <Divisor />
    <DefaultComponent>
      <Subtitle>{t('transferYourPass.subTitle')}</Subtitle>
      <TransferYourPassForm ticket={ticket} />
      <CancelLink>
        <Text
          onClick={() => onCancelLink()}
          className="medium inline pointer center bold">
          {t('transferYourPass.cancel')}
        </Text>
      </CancelLink>
    </DefaultComponent>
  </Component>
)

TransferYourPass.propTypes = {
  t: PropTypes.func,
  ticket: PropTypes.object,
  onCancelLink: PropTypes.func,
}

export default TransferYourPass
