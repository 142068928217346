import {
  CLEAR_MONTHLY_FILTERS,
  RESET_MONTHLY_PARKING,
  SET_MONTHLY_ACTIVE_LOT,
  SET_MONTHLY_ACTIVE_PIN,
  SET_MONTHLY_AVAILABLE_FILTERS,
  SET_MONTHLY_ERROR,
  SET_MONTHLY_FILTERS,
  SET_MONTHLY_LOADING,
  SET_MONTHLY_LOCATION,
  SET_MONTHLY_LOT,
  SET_MONTHLY_LOTS,
  SET_MONTHLY_LICENSE_PLATE,
  SET_MONTHLY_PASS,
  SET_MONTHLY_PLAN_DATA,
  SET_MONTHLY_RADIUS,
  SET_MONTHLY_RESULTS_COUNT,
  SET_MONTHLY_SELECTED_VEHICLE,
  SET_MONTHLY_SELECTED_CARD,
  SET_MONTHLY_SHOW_SEARCH,
  SET_MONTHLY_SUBSCRIPTION_DATA,
} from './actions'

const defaultState = {
  activeLot: false,
  activePin: false,
  availableFilters: [],
  error: null,
  filters: {},
  loading: false,
  location: {},
  lot: {},
  lots: null,
  licensePlate: false,
  pass: {},
  plan: {},
  radius: 20,
  resultsCount: 0,
  selectedVehicle: null,
  selectedCard: null,
  showSearch: false,
  subscription: {},
}

export const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_MONTHLY_LOCATION:
      return {
        ...state,
        location: {
          ...action.payload,
        },
      }

    case SET_MONTHLY_RADIUS:
      return {
        ...state,
        radius: action.payload,
      }

    case SET_MONTHLY_AVAILABLE_FILTERS:
      return {
        ...state,
        availableFilters: action.payload,
      }

    case SET_MONTHLY_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload,
        },
      }

    case SET_MONTHLY_LOT:
      return {
        ...state,
        lot: action.payload,
      }

    case SET_MONTHLY_PLAN_DATA:
      return {
        ...state,
        plan: action.payload,
      }

    case SET_MONTHLY_SUBSCRIPTION_DATA:
      return {
        ...state,
        subscription: action.payload,
      }

    case SET_MONTHLY_LOTS:
      return {
        ...state,
        lots: action.payload,
      }

    case CLEAR_MONTHLY_FILTERS:
      return {
        ...state,
        filters: {},
      }

    case SET_MONTHLY_RESULTS_COUNT:
      return {
        ...state,
        resultsCount: action.payload,
      }

    case SET_MONTHLY_ACTIVE_LOT:
      return {
        ...state,
        activeLot: action.payload,
      }

    case SET_MONTHLY_ACTIVE_PIN:
      return {
        ...state,
        activePin: action.payload,
      }

    case RESET_MONTHLY_PARKING:
      return {
        ...defaultState,
      }

    case SET_MONTHLY_PASS:
      return {
        ...state,
        pass: action.payload,
      }

    case SET_MONTHLY_SELECTED_VEHICLE:
      return {
        ...state,
        selectedVehicle: action.payload,
      }

    case SET_MONTHLY_SELECTED_CARD:
      return {
        ...state,
        selectedCard: action.payload,
      }

    case SET_MONTHLY_LICENSE_PLATE:
      return {
        ...state,
        licensePlate: action.payload,
      }

    case SET_MONTHLY_SHOW_SEARCH:
      return {
        ...state,
        showSearch: action.payload,
      }

    case SET_MONTHLY_LOADING:
      return {
        ...state,
        loading: action.payload,
      }

    case SET_MONTHLY_ERROR:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}
