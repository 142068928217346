import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import { Field } from 'formik'

import { useTranslation } from 'i18n-web/i18next'
import SelectSearchField from 'components/Molecules/Form/SelectSearchField'
import { TextField } from 'components/Molecules/Form'

const DynamicField = ({
  propertyName,
  messages,
  name,
  pattern,
  required,
  type,
  layout,
  minLength,
  ...props
}) => {
  const { t } = useTranslation()

  const validate = value => {
    if (required && isEmpty(value)) {
      return t('common.form.error.required')
    }

    if (!isEmpty(pattern)) {
      // pattern is surrounded by '/'
      const regex = new RegExp(pattern.substring(1, pattern.length - 1))

      if (!regex.test(value)) {
        return messages.pattern
      }
    }

    if (!isEmpty(props.enum)) {
      if (!props.enum.includes(value)) {
        return messages.enum
      }
    }

    if (minLength && !isEmpty(value)) {
      const strValue = String(value)

      if (strValue.length < minLength) {
        return t('common.form.error.minLength', { length: minLength })
      }
    }
  }

  if (type === 'string') {
    if (!isEmpty(props.enum)) {
      const items = props.enum.map(item => ({ label: item, value: item }))

      return (
        <Field
          {...props}
          name={propertyName}
          label={name}
          component={SelectSearchField}
          validate={validate}
          items={items}
          hint={layout?.hint}
        />
      )
    }

    return (
      <Field
        {...props}
        name={propertyName}
        label={name}
        component={TextField}
        validate={validate}
        validateOnChange
        hint={layout?.hint}
      />
    )
  }

  return null
}

DynamicField.propTypes = {
  propertyName: PropTypes.string,
  enum: PropTypes.array,
  layout: PropTypes.object,
  maxLength: PropTypes.number,
  minLength: PropTypes.number,
  messages: PropTypes.object,
  name: PropTypes.string,
  pattern: PropTypes.string,
  required: PropTypes.bool,
  type: PropTypes.string,
}

export default DynamicField
