import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import API from 'api'
import { fetchMe } from 'redux-web/utils/user/actions'
import { getAppRoute } from 'helpers/application'
import { getPasswordDefinitions } from 'models/password'
import { hideModal } from 'components/Atoms/Modal/actions'
import { internationalPhoneNumber } from 'helpers/phoneFormat'
import { setAccessToken } from 'redux-web/utils/session/actions'
import { showBasicModal } from 'helpers/modal'
import { showModal } from 'components/Atoms/Modal/actions'
import { useTranslation } from 'i18n-web/i18next'

import ChangePasswordForm from './Form'

const ChangePasswordHOC = props => {
  const {
    code,
    countryCode,
    email,
    redirectTo,
    phoneNumber,
    router,
    preventClose,
  } = props

  const {
    query: { application },
  } = router

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const isSDK = useSelector(state => state.settings.isSDK)

  // eslint-disable-next-line
  const [formikRef, setFormikRef] = useState(null)
  const [passwordRules, setPasswordRules] = useState({})

  const initialValues = useMemo(
    () => ({
      confirmPassword: '',
      email,
      password: '',
      phoneNumber: internationalPhoneNumber(phoneNumber, countryCode),
    }),
    [phoneNumber]
  )

  const formikInstance = ref => setFormikRef(ref)

  useEffect(() => {
    API()
      .passwordRules({ ...(isSDK && { sdk: true }) })
      .then(res => setPasswordRules(res.passwordRules))
      .catch(err => console.error(err))
  }, [])

  const hasPhoneNumber = phoneNumber && !email
  const hasEmail = !hasPhoneNumber

  const showChangePasswordError = (error, rightButtonOnClick) => {
    let actionButtons

    if (preventClose) {
      actionButtons = {
        singleButtonOnClick: () => rightButtonOnClick(),
        singleButtonText: t('common.button.tryAgain'),
      }
    } else {
      actionButtons = {
        rightButtonOnClick,
        rightButtonText: t('common.button.tryAgain'),
        singleButtonOnClick: () => {},
        singleButtonText: t('common.button.cancel'),
      }
    }

    dispatch(
      showBasicModal({
        actionButtons: actionButtons,
        description: error,
        title: t('changePassword.title'),
        type: 'error',
      })
    )
  }

  const onChangePasswordSubmit = (values, setStatus, setSubmitting) => {
    let data

    if (hasEmail) {
      data = {
        code,
        email: email,
        method: 'code',
        password: values.password,
      }
    } else if (hasPhoneNumber) {
      data = {
        code,
        method: 'code',
        password: values.password,
        phoneCountryCode: countryCode,
        phoneNumber,
      }
    }

    setSubmitting(false)

    API()
      .changePassword({
        timeoutTryAgainMessage: true,
        body: JSON.stringify(data),
      })
      .then(res => {
        setSubmitting(false)

        if (res.success) {
          setStatus({ message: res.message, success: true })

          if (res.success.accessToken) {
            dispatch(hideModal())
            dispatch(setAccessToken({ accessToken: res.success.accessToken }))
            dispatch(fetchMe(isSDK))

            if (redirectTo) {
              router.push(redirectTo)
            } else {
              router.push(
                `${getAppRoute(application, 'as')}/my-passes`,
                `${getAppRoute(application, 'url')}/my-passes`
              )
            }
          }
        } else if (res.error) {
          setStatus({ message: res.error.message })

          if (res.error.code === 408) {
            dispatch(
              showBasicModal({
                title: res.error.message,
              })
            )
          } else if (res.error.code === 2520) {
            showChangePasswordError(res.error.message, () =>
              dispatch(
                showModal({
                  childProps: {
                    redirectTo:
                      redirectTo ||
                      `${getAppRoute(application, 'as')}/my-passes`,
                  },
                  modalProps: {
                    className: 'noBorders',
                    preventClose: preventClose,
                  },
                  modalType: 'signIn',
                })
              )
            )
          } else {
            showChangePasswordError(res.error.message, () =>
              dispatch(
                showModal({
                  childProps: {
                    ...(redirectTo && { redirectTo: redirectTo }),
                    ...(hasPhoneNumber && { countryCode }),
                    ...(hasPhoneNumber && { phoneNumber }),
                    ...(hasEmail && { email }),
                    code,
                  },
                  fullScreen: true,
                  modalProps: {
                    preventClose: preventClose,
                    className: 'noBorders',
                    open: true,
                  },
                  modalType: 'changePassword',
                })
              )
            )
          }
        }
      })
      .catch(err => {
        setStatus({ message: err })
        setSubmitting(false)

        showChangePasswordError(err)
      })
  }

  const handleSubmit = (values, { setStatus, setSubmitting }) => {
    setSubmitting(true)
    setStatus(null)

    onChangePasswordSubmit(values, setStatus, setSubmitting)
  }

  const validatePassword = password => {
    password = (password || '').toString()

    const validations = [...passwordRules.features, 'minimum']

    let result = { missingFeatures: [] }

    for (const feature of validations) {
      const { pattern } = getPasswordDefinitions[feature]

      if (!pattern.test(password)) {
        result.missingFeatures.push(feature)
      }
    }

    setPasswordRules({
      ...passwordRules,
      ...result,
    })
  }

  const handlePasswordOnKeyUp = e => validatePassword(e.target.value)

  return (
    <ChangePasswordForm
      application={application}
      formikRef={formikInstance}
      handlePasswordOnKeyUp={handlePasswordOnKeyUp}
      handleSubmit={handleSubmit}
      initialValues={initialValues}
      passwordRules={passwordRules}
      t={t}
      {...props}
    />
  )
}

ChangePasswordHOC.propTypes = {
  code: PropTypes.number,
  countryCode: PropTypes.string,
  email: PropTypes.string,
  phoneNumber: PropTypes.string,
  router: PropTypes.object,
  preventClose: PropTypes.bool,
  redirectTo: PropTypes.string,
}

export default ChangePasswordHOC
