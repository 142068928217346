import * as Cookies from 'js-cookie'
import moment from 'moment'
import { last } from 'lodash'

import { removeGuestSession } from 'helpers/guest'
import { getAvailableVariableRules } from 'models/rate/variable'
import API from 'api'

export const getReservationSession = () =>
  Cookies.get('citifyd_reservation_access_token')

export const removeReservationSession = () =>
  Cookies.remove('citifyd_reservation_access_token')

export const setReservationSession = async () => {
  const reservation = await API().createReservationSession()

  if (reservation.accessToken) {
    removeGuestSession()
    Cookies.set('citifyd_reservation_access_token', reservation.accessToken)
  }

  return reservation
}
// On demand only
export const getCurrentAvailabilityAllocation = (
  reservation,
  returnIfEnded = false
) => {
  if (!reservation) {
    return null
  }

  const { availabilityAllocations } = reservation
  const lastAllocation = last(availabilityAllocations)

  if (!lastAllocation) {
    return null
  }

  const now = moment()
  const allocationStartTime = moment(lastAllocation.startTime)
  const allocationEndTime =
    lastAllocation.endTime && moment(lastAllocation.endTime)

  if (
    now.isSameOrAfter(allocationStartTime) &&
    (returnIfEnded || !allocationEndTime || now.isBefore(allocationEndTime))
  ) {
    return lastAllocation
  }

  return null
}

export const canExtendReservation = reservation => {
  if (!reservation) {
    return false
  }
  if (reservation.lot && !reservation.lot.onDemandExtensionsEnabled) {
    return false
  }
  const currentAllocation = getCurrentAvailabilityAllocation(reservation)

  if (!currentAllocation) {
    return false
  }
  const { rate, availabilityStartTime } = currentAllocation
  const { expirationTime, startTime: reservationStartTime, lot } = reservation
  const { timezoneName } = lot
  const availableExtensions = getAvailableVariableRules({
    rate,
    expirationTime,
    availabilityStartTime,
    reservationStartTime,
    timezoneName,
  })

  return availableExtensions.length > 0
}
