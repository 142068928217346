import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import classNames from 'classnames'

import Button from 'components/Atoms/Button'
import isLightMode from 'helpers/isLightMode'

const Screen = ({
  application,
  children,
  disabled,
  isNewCard,
  onPurchaseButton,
}) => {
  const selectedCard = useSelector(state => state.purchase.selectedCard)

  return (
    <Button
      className={classNames('full', {
        highlight: isLightMode(application),
        contrastText: !isLightMode(application),
      })}
      disabled={disabled}
      onClick={() =>
        onPurchaseButton({ newCard: isNewCard, currentCard: selectedCard })
      }>
      {children}
    </Button>
  )
}

Screen.propTypes = {
  application: PropTypes.string,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  isNewCard: PropTypes.bool,
  onPurchaseButton: PropTypes.func,
}

export default Screen
