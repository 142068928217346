import { kebabCase } from 'lodash'

export const removeSelectedRateOnStorage = lotId => {
  if (localStorage?.getItem(`selected-rate-lot-${lotId}`)) {
    localStorage.removeItem(`selected-rate-lot-${lotId}`)
  }
}

export const getSelectedRateOnStorage = lotId =>
  localStorage?.getItem(`selected-rate-lot-${lotId}`)

export const getSelectedRateName = (rates, rate) =>
  rates.filter(item => item.key === rate).map(item => item.name)[0]

export const getSelectedRateKey = (rates, rate) =>
  rates.filter(item => item.name === rate).map(item => item.key)[0]

export const getPayBySignageUrl = lot => {
  const citySegment = kebabCase(`${lot?.address?.city}-${lot?.address?.state}`)
  const lotSegment = kebabCase(`${lot?.id}-${lot?.name}`)

  return `${citySegment}/${lotSegment}`
}
