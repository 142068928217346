import styled from 'styled-components'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import PageTitle from 'components/Atoms/PageTitle'
import Text from 'components/Atoms/Text'
import Button from 'components/Atoms/Button'

const CancelLink = styled.div`
  padding: 15px 0;
  text-align: center;
`

const Content = styled.div`
  margin: 20px 0;

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    margin-left: 35px;
    margin-right: 35px;
  }
`

const ContinueButton = styled(Button)`
  margin-top: 20px;
`

const Divisor = styled.div`
  margin-top: 20px;
  border-top: 1px solid ${props => props.theme.palette.gray}80;
  display: none;

  &.title {
    margin-top: 0;
  }

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    display: block;
    margin-bottom: 10px;
  }
`

const RetransferYourPass = ({
  t,
  disabledLinks,
  onCloseModal,
  onRetransferYourPass,
}) => (
  <>
    <PageTitle modal noBorder shadow title={t('retransferYourPass.title')} />
    <Divisor />
    <Content>
      <Text className="small light">{t('retransferYourPass.description')}</Text>

      <ContinueButton
        onClick={() => !disabledLinks && onRetransferYourPass()}
        className={classNames('uppercase full primary', {
          disabled: disabledLinks,
        })}>
        {t('retransferYourPass.button.continue')}
      </ContinueButton>

      <CancelLink>
        <Text
          onClick={() => onCloseModal()}
          className="medium inline pointer center bold">
          {t('retransferYourPass.button.cancel')}
        </Text>
      </CancelLink>
    </Content>
  </>
)

RetransferYourPass.propTypes = {
  t: PropTypes.func,
  onCloseModal: PropTypes.func,
  disabledLinks: PropTypes.bool,
  onRetransferYourPass: PropTypes.func,
}

export default RetransferYourPass
