import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRouter } from 'next/router'

import API from 'api'
import { fetchMe } from 'redux-web/utils/user/actions'
import { getPasswordDefinitions } from 'models/password'
import { hideModal } from 'components/Atoms/Modal/actions'
import { showBasicModal } from 'helpers/modal'
import { showModal } from 'components/Atoms/Modal/actions'
import { useTranslation } from 'i18n-web/i18next'

import ChangePasswordForm from './Form'

const ChangePasswordHOC = () => {
  const dispatch = useDispatch()
  const isSDK = useSelector(state => state.settings.isSDK)
  const router = useRouter()
  const { t } = useTranslation()

  // eslint-disable-next-line no-unused-vars
  const [formikRef, setFormikRef] = useState(null)
  const [passwordRules, setPasswordRules] = useState({})

  const {
    query: { application },
  } = router

  const initialValues = {
    confirmPassword: '',
    currentPassword: '',
    password: '',
  }

  const formikInstance = ref => setFormikRef(ref)

  useEffect(() => {
    API()
      .passwordRules({ ...(isSDK && { sdk: true }) })
      .then(res => setPasswordRules(res.passwordRules))
      .catch(err => console.error(err))
  }, [])

  const showError = error => {
    dispatch(hideModal())
    dispatch(
      showBasicModal({
        actionButtons: {
          rightButtonOnClick: dispatch(
            showModal({
              fullScreen: true,
              modalProps: {
                className: 'noBorders',
                open: true,
              },
              modalType: 'myAccountChangePassword',
            })
          ),
          rightButtonText: t('common.button.tryAgain'),
          singleButtonOnClick: () => {},
          singleButtonText: t('common.button.cancel'),
        },
        description: error,
        title: t('common.title.error'),
        type: 'error',
      })
    )
  }

  const onSubmit = async (values, setSubmitting) => {
    const data = {
      currentPassword: values.currentPassword,
      password: values.password,
    }

    try {
      const response = await API().putMe({
        timeoutTryAgainMessage: true,
        body: JSON.stringify(data),
      })

      setSubmitting(false)

      if (response?.error) {
        showError(response.error?.message)
      }

      await dispatch(fetchMe(isSDK))
      dispatch(hideModal())
      dispatch(
        showBasicModal({
          title: t('myAccount.settings.changePassword.modal.success.title'),
          description: t(
            'myAccount.settings.changePassword.modal.success.description'
          ),
        })
      )
    } catch (error) {
      setSubmitting(false)
      showError(error)
    }
  }

  const handleSubmit = (values, { setSubmitting }) => {
    setSubmitting(true)

    onSubmit(values, setSubmitting)
  }

  const validatePassword = password => {
    password = (password || '').toString()

    const validations = [...passwordRules.features, 'minimum']

    let result = { missingFeatures: [] }

    for (const feature of validations) {
      const { pattern } = getPasswordDefinitions[feature]

      if (!pattern.test(password)) {
        result.missingFeatures.push(feature)
      }
    }

    setPasswordRules({
      ...passwordRules,
      ...result,
    })
  }

  const handlePasswordOnKeyUp = e => validatePassword(e.target.value)

  return (
    <ChangePasswordForm
      application={application}
      formikRef={formikInstance}
      handlePasswordOnKeyUp={handlePasswordOnKeyUp}
      handleSubmit={handleSubmit}
      initialValues={initialValues}
      passwordRules={passwordRules}
    />
  )
}

export default ChangePasswordHOC
