import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styled from 'styled-components'

import Text from 'components/Atoms/Text'

const Component = styled(Text)`
  position: relative;
  margin-bottom: 8px;
  padding-left: 18px;
  font-size: ${props => props.theme.font.size.mobile.small};
  color: ${props => props.theme.palette.grayDark};

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -5px;
    width: 10px;
    height: 10px;
    background-color: ${props => props.theme.palette.primary}30;
    border-radius: 50%;
  }

  &.checked:before {
    background-color: ${props => props.theme.palette.primary};
  }
`

const BulletPoint = ({ children, className, isChecked }) => (
  <Component
    className={classNames({
      checked: !!isChecked,
      [className]: !!className,
    })}>
    {children}
  </Component>
)

BulletPoint.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  isChecked: PropTypes.bool,
}

export default BulletPoint
