import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const Component = styled.p`
  font-size: ${props => props.theme.font.size.mobile.regular};
  color: ${props => props.theme.palette.grayDark};
  font-weight: 400;
  text-align: left;
  line-height: 150%;
  margin: 0;

  &.link {
    color: ${props => props.theme.palette.grayDark};
    text-decoration: underline;

    &:hover {
      cursor: pointer;
    }
  }

  &.inline {
    display: inline-flex;
  }

  &.gutterBottomSmall {
    margin-bottom: 5px;
  }

  &.gutterBottomMedium {
    margin-bottom: 10px;
  }

  &.disabled {
    opacity: 0.6;
    cursor: default !important;
  }

  &.left {
    text-align: left;
  }

  &.right {
    text-align: right;
  }

  &.center {
    text-align: center;
  }

  &.uppercase {
    text-transform: uppercase;
  }

  &.primary {
    color: ${props => props.theme.palette.primary};

    &.link {
      color: ${props => props.theme.palette.primary};
    }
  }

  &.secondary {
    color: ${props => props.theme.palette.grayDark};

    &.link {
      color: ${props => props.theme.palette.grayDark};
    }
  }

  &.quaternary {
    color: ${props => props.theme.palette.quaternary};
    &.link {
      color: ${props => props.theme.palette.quaternary};
    }
  }

  &.complementary {
    color: ${props => props.theme.palette.complementary};
    &.link {
      color: ${props => props.theme.palette.complementary};
    }
  }

  &.highlight {
    color: ${props => props.theme.palette.highlight};
    &.link {
      color: ${props => props.theme.palette.highlight};
    }
  }

  &.contrastText {
    color: ${props => props.theme.palette.white};

    &.link {
      color: ${props => props.theme.palette.white};
    }
  }

  &.grayDark {
    color: ${props => props.theme.palette.grayDark};
  }

  &.gray {
    color: ${props => props.theme.palette.gray};
  }

  &.grayLight {
    color: ${props => props.theme.palette.grayLight};
  }

  &.background {
    color: ${props => props.theme.palette.background};
  }

  &.white {
    color: ${props => props.theme.palette.white};
  }

  &.opacity {
    color: ${props => props.theme.palette.grayDark}80;
  }

  &.grayMedium {
    color: ${props => props.theme.palette.grayMedium};
  }

  &.error {
    color: ${props => props.theme.palette.error};
  }

  &.large {
    font-size: ${props => props.theme.font.size.mobile.regular};

    @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
      font-size: ${props => props.theme.font.size.desktop.large};
    }
  }

  &.medium {
    font-size: ${props => props.theme.font.size.mobile.medium};
    line-height: 150%;

    @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
      font-size: ${props => props.theme.font.size.desktop.medium};
    }
  }

  &.small {
    font-size: ${props => props.theme.font.size.mobile.small};

    @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
      font-size: ${props => props.theme.font.size.desktop.small};
    }
  }

  &.tiny {
    font-size: ${props => props.theme.font.size.mobile.tiny};

    @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
      font-size: ${props => props.theme.font.size.desktop.tiny};
    }
  }

  &.extraBold {
    font-weight: 800;
  }

  &.bold {
    font-weight: 700;
  }

  &.semiBold {
    font-weight: 600;
  }

  &.light {
    font-weight: 300;
  }

  &.pointer {
    cursor: pointer;
  }

  &.h1,
  &.h2,
  &.h3,
  &.h4 {
    font-weight: 800;
    line-height: 1.1;
    text-transform: uppercase;
  }

  &.h1 {
    font-size: ${props => props.theme.font.size.mobile.h1};
  }

  &.h2 {
    font-size: ${props => props.theme.font.size.mobile.h2};
  }

  &.h3 {
    font-size: ${props => props.theme.font.size.mobile.h3};
  }

  &.h4 {
    font-size: ${props => props.theme.font.size.mobile.medium};
  }

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    &.regular {
      font-size: ${props => props.theme.font.size.desktop.regular};
    }

    &.h1 {
      font-size: ${props => props.theme.font.size.desktop.h1};
    }

    &.h2 {
      font-size: ${props => props.theme.font.size.desktop.h2};
    }

    &.h3 {
      font-size: ${props => props.theme.font.size.desktop.h3};
    }

    &.h4 {
      font-size: ${props => props.theme.font.size.desktop.h4};
    }

    &.regular {
      font-size: ${props => props.theme.font.size.desktop.regular};
    }
  }
`

const Text = props => {
  const { className, children } = props

  return (
    <Component className={className} {...props}>
      {children}
    </Component>
  )
}

Text.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}

export default Text
