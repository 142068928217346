import styled from 'styled-components'
import PropTypes from 'prop-types'
import { get } from 'lodash'

import Button from 'components/Atoms/Button'
import { Radio } from 'components/Molecules/Form'

const Component = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  @media (max-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    .content {
      padding: 7px 15px;
    }
  }
`

const RadioContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const TrashButton = styled(Button)`
  width: 42px;
  border: none;
`

const CardsList = ({
  t,
  user,
  isNewCard,
  selectedCard,
  selectedNativePayment,
  onDeleteCardClick,
  nativePaymentType,
  onNativePaymentClick,
  onCardClick,
  onNewCardClick,
  isGuest,
}) => {
  const { cards } = user
  const hasCard = cards && cards.length > 0 && !isGuest

  if (!nativePaymentType && !hasCard) return null

  return (
    <Component>
      {nativePaymentType === 'GooglePay' && (
        <Radio
          name="paymentCard"
          id={'googlePay'}
          checked={selectedNativePayment === 'GooglePay' && !isNewCard}
          onClick={() => onNativePaymentClick('GooglePay')}
          icon="/static/icons/google-pay-mark.svg"
          label={t('purchase.cardsList.payWith')}
        />
      )}
      {nativePaymentType === 'ApplePay' && (
        <Radio
          name="paymentCard"
          id={'applePay'}
          checked={selectedNativePayment === 'ApplePay' && !isNewCard}
          onClick={() => onNativePaymentClick('ApplePay')}
          icon="/static/icons/apple-pay-mark.svg"
          label={t('purchase.cardsList.payWith')}
        />
      )}
      {hasCard &&
        !isGuest &&
        cards.map(item => (
          <RadioContainer key={item.id}>
            <Radio
              id={item.id}
              name="paymentCard"
              checked={item.id === get(selectedCard, 'id') && !isNewCard}
              onClick={() => onCardClick(item)}
              label={`${item.brand} *${item.last4}`}
              value={item.id}
            />
            {item.id === get(selectedCard, 'id') && !isNewCard && (
              <TrashButton
                className="icon transparent"
                onClick={() => onDeleteCardClick(item.id)}>
                <img src="/static/icons/trash.png" width="100%" />
              </TrashButton>
            )}
          </RadioContainer>
        ))}
      <Radio
        name="paymentCard"
        onClick={() => onNewCardClick()}
        checked={isNewCard}
        label={
          hasCard
            ? t('purchase.cardsList.payWithAnotherCard')
            : t('purchase.cardsList.payWithCard')
        }
        id="newCard"
        value="new"
      />
    </Component>
  )
}

CardsList.propTypes = {
  t: PropTypes.func,
  onDeleteCardClick: PropTypes.func,
  onNativePaymentClick: PropTypes.func,
  onCardClick: PropTypes.func,
  onNewCardClick: PropTypes.func,
  user: PropTypes.object,
  selectedCard: PropTypes.object,
  isNewCard: PropTypes.bool,
  isGuest: PropTypes.bool,
  selectedNativePayment: PropTypes.string,
  nativePaymentType: PropTypes.string,
}

export default CardsList
