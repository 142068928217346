import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import moment from 'moment'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'
import {
  SingleDatePicker,
  isInclusivelyAfterDay,
  isInclusivelyBeforeDay,
} from 'react-dates'
import 'react-dates/initialize'

const CustomDatePicker = styled.div`
  .CalendarDay__default,
  .CalendarMonth_table .CalendarDay__blocked_out_of_range {
    border-color: transparent;
  }

  .CalendarMonth_table {
    border-collapse: separate;

    .CalendarDay__blocked_out_of_range {
      color: ${props => props.theme.palette.gray} !important;

      :hover {
        background-color: transparent !important;
      }
    }

    .CalendarDay__selected,
    .CalendarDay__selected:active,
    .CalendarDay__selected:hover {
      color: ${props => props.theme.palette.background} !important;
      background-color: ${props => props.theme.palette.quaternary} !important;
    }

    .CalendarDay__default {
      font-weight: 600;
      color: ${props => props.theme.palette.grayDark};
      border-color: transparent;
      border-radius: 50%;
      outline: 0;

      :hover {
        border-radius: 50%;
        color: ${props => props.theme.palette.grayDark};
        background-color: ${props => props.theme.palette.grayLighter};
        border-color: transparent;
      }
    }
  }

  .DayPicker_weekHeader {
    text-transform: uppercase;
  }

  .CalendarMonth_caption {
    text-transform: uppercase;
    color: ${props => props.theme.palette.grayDark};
  }
`

const CustomDatePickerDesktop = styled(CustomDatePicker)`
  flex: 1;
  .SingleDatePickerInput,
  .DateInput,
  .DateInput_input {
    background: transparent;
    cursor: pointer;
  }

  .DateInput_input {
    padding: 0;
    height: 30px;
    font-size: ${props => props.theme.font.size.desktop.medium};
    font-weight: 600;
    color: ${props => props.theme.palette.grayDark};
    line-height: auto;
    border-bottom: solid 2px ${props => props.theme.palette.grayDark};
    outline: none;
    tap-highlight-color: transparent;

    &::placeholder {
      color: ${props => props.theme.palette.grayDark};
    }
  }

  &.show {
    .SingleDatePicker_picker {
      max-height: 320px;
      box-shadow: 0 0 20px 0 rgba(55, 56, 64, 0.3);
    }
  }

  .SingleDatePicker_picker {
    max-height: 0;
    z-index: 9;
    transition-property: max-height;
    transition-duration: 0.2s;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    overflow: hidden;
    margin-top: -14px;
  }

  .DayPicker_transitionContainer,
  .DayPicker__withBorder {
    border-radius: 0;
  }

  .DayPicker_weekHeader {
    top: 60px;
    color: ${props => props.theme.palette.grayDark};
    font-weight: 600;
  }

  .DayPicker_weekHeader_ul {
    margin-bottom: 10px;
  }

  .DayPicker_weekHeader_li small {
    font-size: ${props => props.theme.font.size.mobile.small};
  }

  .DayPicker_transitionContainer__horizontal,
  .DayPicker__horizontal,
  .CalendarMonthGrid,
  .CalendarMonth,
  .CalendarDay__default,
  .CalendarDay__blocked_out_of_range {
    background: ${props => props.theme.palette.background};
  }

  .SingleDatePickerInput_calendarIcon {
    padding: 0;
    margin: 0;
  }

  .CalendarMonth_caption {
    padding: 20px 0;
    margin-bottom: 25px;
    color: ${props => props.theme.palette.gray};
    font-size: ${props => props.theme.font.size.mobile.tiny};
    text-transform: uppercase;
  }

  .DayPickerNavigation_button {
    position: absolute;
    top: 19px;
    color: ${props => props.theme.palette.gray};
    font-size: ${props => props.theme.font.size.desktop.medium};

    &:first-of-type {
      left: 30px;
    }

    &:last-of-type {
      right: 30px;
    }
  }

  .CalendarMonth_table {
    .CalendarDay__blocked_out_of_range {
      color: ${props => props.theme.palette.gray};
    }
  }

  &.modal {
    .SingleDatePicker_picker,
    .DayPicker,
    .DayPicker_transitionContainer,
    .DayPicker_focusRegion {
      width: 290px !important;
    }

    .DayPicker > div > div {
      width: 290px !important;
    }

    .SingleDatePicker_picker {
      top: 56px !important;
      left: -20px;
    }

    .CalendarMonth,
    .DayPicker_weekHeader {
      padding: 0 !important;
    }

    .DayPickerNavigation_button {
      position: absolute;
      top: 19px;
      color: ${props => props.theme.palette.gray};
      font-size: ${props => props.theme.font.size.desktop.medium};

      &:first-of-type {
        left: 20px !important;
      }

      &:last-of-type {
        right: 20px !important;
      }
    }
  }

  &.modalMobile {
    .SingleDatePicker_picker,
    .DayPicker,
    .DayPicker_transitionContainer,
    .DayPicker_focusRegion {
      width: 290px !important;
    }

    .DayPicker > div > div {
      width: 290px !important;
    }

    .SingleDatePicker_picker {
      top: 45px !important;
      left: 0;
    }

    .CalendarMonth,
    .DayPicker_weekHeader {
      padding: 0 !important;
    }

    .DayPickerNavigation_button {
      position: absolute;
      top: 19px;
      color: ${props => props.theme.palette.gray};
      font-size: ${props => props.theme.font.size.desktop.medium};

      &:first-of-type {
        left: 20px !important;
      }

      &:last-of-type {
        right: 20px !important;
      }
    }
  }
`

const DatePicker = ({
  enableSelectFrom = moment(),
  isModalMobileVersion,
  isModalVersion,
  label,
  onChange,
  value,
}) => {
  const [focused, setFocused] = useState()
  const [animation, setAnimation] = useState()

  useEffect(() => {
    setTimeout(() => setAnimation(focused), 100)
  }, [focused])

  return (
    <CustomDatePickerDesktop
      className={classNames({
        show: animation,
        modal: isModalVersion,
        modalMobile: isModalMobileVersion,
      })}>
      <SingleDatePicker
        anchorDirection="right"
        block
        date={moment(value) || null}
        focused={focused}
        hideKeyboardShortcutsPanel
        navNext={<FaChevronRight />}
        navPrev={<FaChevronLeft />}
        noBorder
        numberOfMonths={1}
        onDateChange={date => onChange({ value: date, label: label })}
        onFocusChange={({ focused }) => setFocused(focused)}
        placeholder={label}
        readOnly
        showDefaultInputIcon={false}
        verticalSpacing={0}
        isOutsideRange={day =>
          isInclusivelyBeforeDay(
            day,
            moment(enableSelectFrom).subtract(1, 'day')
          ) || isInclusivelyAfterDay(day, moment().add(1, 'year'))
        }
      />
    </CustomDatePickerDesktop>
  )
}

DatePicker.propTypes = {
  enableSelectFrom: PropTypes.string,
  isModalMobileVersion: PropTypes.bool,
  isModalVersion: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
}

export default DatePicker
