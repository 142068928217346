import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import isString from 'lodash/isString'
import { useDispatch, useSelector } from 'react-redux'

import { hideModal } from 'components/Atoms/Modal/actions'
import { setLoading } from 'redux-web/utils/monthly/actions'
import {
  setSelectedVehicle,
  setLicensePlate,
} from 'redux-web/utils/monthly/actions'
import { showBasicModal } from 'helpers/modal'
import { useTranslation } from 'i18n-web/i18next'

import Screen from './Screen'

const ChangeVehicleHOC = ({ passId, onUpdateSubscription }) => {
  const dispatch = useDispatch()
  const { licensePlate, lot, selectedVehicle, subscription } = useSelector(
    state => state.monthly
  )
  const { t } = useTranslation()
  const { vehicles } = useSelector(state => state.user.me)

  const currentLicensePlate = subscription?.vehicle?.license
  const newLicensePlate = licensePlate || selectedVehicle

  const handleChangeSelectedVehicle = vehicle =>
    dispatch(setSelectedVehicle(vehicle))

  const handleChangeLicensePlate = licensePlate =>
    dispatch(setLicensePlate(licensePlate))

  // Set selected license plate as default option
  useEffect(() => {
    const defaultVehicle = vehicles?.find(
      ({ license }) => license === currentLicensePlate
    )?.license

    handleChangeSelectedVehicle(defaultVehicle)
  }, [])

  const handleOnChangeVehicle = () => {
    const currentLicensePlate = subscription?.vehicle?.license

    if (
      newLicensePlate === currentLicensePlate ||
      !isString(newLicensePlate) ||
      !!newLicensePlate.length === 0
    ) {
      dispatch(hideModal())

      return
    }

    dispatch(
      showBasicModal({
        description: t(
          'monthly.pass.changeVehicleConfirmationModal.description',
          { licensePlate: newLicensePlate }
        ),
        title: t('monthly.pass.changeVehicleConfirmationModal.title'),
        actionButtons: {
          rightButtonOnClick: () => {
            if (isString(newLicensePlate)) {
              dispatch(setLoading(true))
              onUpdateSubscription({
                variables: {
                  id: passId,
                  vehicle: {
                    vehicleData: {
                      countryCode: lot?.countryCode,
                      licenseInfo: {
                        [lot?.countryCode]: { plate: newLicensePlate },
                      },
                    },
                  },
                },
              })
            }
          },
          rightButtonText: t('monthly.pass.changeVehicleModal.submitButton'),
          singleButtonOnClick: () => dispatch(hideModal()),
          singleButtonText: t('monthly.pass.changeVehicleModal.cancelButton'),
        },
      })
    )
  }

  return (
    <Screen
      onCancel={() => dispatch(hideModal())}
      onChangeVehicle={handleOnChangeVehicle}
      selectedVehicle={selectedVehicle}
      setLicensePlate={handleChangeLicensePlate}
      setSelectedVehicle={handleChangeSelectedVehicle}
      vehicles={vehicles}
    />
  )
}

ChangeVehicleHOC.propTypes = {
  passId: PropTypes.number,
  onUpdateSubscription: PropTypes.func,
}

export default ChangeVehicleHOC
