import PropTypes from 'prop-types'
import { withRouter } from 'next/router'

import { useTranslation } from 'i18n-web/i18next'

import Screen from './Screen'

const UnlinkFacebook = props => {
  const { router } = props

  const { t } = useTranslation()

  const {
    query: { application },
  } = router

  return <Screen application={application} t={t} {...props} />
}

UnlinkFacebook.propTypes = {
  router: PropTypes.object,
}

export default withRouter(UnlinkFacebook)
