import PropTypes from 'prop-types'
import styled from 'styled-components'
import { isEmpty } from 'lodash'
import { useSelector } from 'react-redux'

import Button from 'components/Atoms/Button'
import Text from 'components/Atoms/Text'
import { selectVehicleById } from 'redux-web/selectors/vehicles'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    padding-bottom: 35px;
  }
`

const Title = styled(Text)`
  font-size: ${props => props.theme.font.size.desktop.h4};
`

const TrashButton = styled(Button)`
  width: 22px;
  border: none;
  padding: 0px;
`

const DeleteVehicleButton = ({ vehicleId, onClick }) => {
  const vehicle = useSelector(state => selectVehicleById(state, vehicleId))

  if (!vehicle) {
    return null
  }

  const { license, color, make } = vehicle

  const carProperties = []

  if (!isEmpty(color)) {
    carProperties.push(color)
  }
  if (!isEmpty(make)) {
    carProperties.push(make)
  }

  const titleComplement =
    carProperties.length > 0 ? ` (${carProperties.join(' ')})` : ''

  const title = `${license}${titleComplement}`

  return (
    <Container>
      <Title className={'bold'}>{title}</Title>
      <TrashButton className="transparent" onClick={onClick}>
        <img src="/static/icons/trash.png" width="100%" />
      </TrashButton>
    </Container>
  )
}

DeleteVehicleButton.propTypes = {
  vehicleId: PropTypes.number,
  onClick: PropTypes.func,
}

export default DeleteVehicleButton
