import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { withRouter } from 'next/router'
import { isEmpty } from 'lodash'

import API from 'api'
import Intercom from 'helpers/intercom'
import {
  attemptLogin,
  clearAuthentication,
  unlinkApple,
  unlinkFacebook,
} from 'redux-web/utils/authentication/actions'
import { createGuestUser } from 'helpers/guest'
import { getAppRoute } from 'helpers/application'
import { hideModal } from 'components/Atoms/Modal/actions'
import {
  internationalPhoneNumber,
  nationalPhoneNumber,
} from 'helpers/phoneFormat'
import { showBasicModal } from 'helpers/modal'
import { showModal } from 'components/Atoms/Modal/actions'
import { useTranslation } from 'i18n-web/i18next'

import SignInForm from './Form'

const FormikHOC = props => {
  const {
    country,
    phoneNumber,
    router,
    setSignUpLink,
    setSocialButtons,
    isGuestAvailable,
  } = props

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const error = useSelector(state => state.authentication.error)
  const user = useSelector(state => state.user.me)
  const childProps = useSelector(state => state.modal.childProps)
  const modalProps = useSelector(state => state.modal.modalProps)
  const receiptFlow = useSelector(state => state.receipt.account)
  const isSDK = useSelector(state => state.settings.isSDK)

  const [showAppleConnected, setShowAppleConnected] = useState(
    receiptFlow?.type?.isAppleConnected
  )
  const [showEmail, setShowEmail] = useState(null)
  const [showFacebookConnected, setShowFacebookConnected] = useState(
    receiptFlow?.type?.isFacebookConnected
  )
  const [showNextButton, setShowNextButton] = useState(true)
  const [hasReceiptAccount, setHasReceiptAccount] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [showUnrecognizedPhoneNumber, setShowUnrecognizedPhoneNumber] =
    useState(false)
  const [showRestrictedPhoneNumber, setShowRestrictedPhoneNumber] =
    useState(false)

  const passwordRef = React.useRef()
  const emailRef = React.useRef()
  const formikRef = React.useRef()

  const application = useMemo(
    () => router && router.query.application,
    [router]
  )

  const initialValues = {
    email: '',
    password: '',
    phoneNumber: phoneNumber
      ? internationalPhoneNumber(phoneNumber, country)
      : '',
  }

  const isCountryUs = !!country?.includes('us')

  const showAppleFields = () => {
    setShowAppleConnected(true)
    setShowNextButton(false)
    setShowPassword(false)
    setSignUpLink(false)
    setSocialButtons(false)
  }

  const showFacebookFields = () => {
    setShowFacebookConnected(true)
    setShowNextButton(false)
    setShowPassword(false)
    setSignUpLink(false)
    setSocialButtons(false)
  }

  const showRegularUserFields = isDuplicate => {
    setShowPassword(true)
    if (isDuplicate) {
      setShowEmail(true)
    }
  }

  useEffect(() => {
    if (receiptFlow?.type) {
      setHasReceiptAccount(true)
      if (receiptFlow.type.isAppleConnected) {
        showAppleFields()
      } else if (receiptFlow.type.isFacebookConnected) {
        showFacebookFields()
      } else if (receiptFlow.type.userExists) {
        showRegularUserFields(receiptFlow.type.isDuplicate)
        setSocialButtons(false)
      } else if (receiptFlow.type.guestExists && formikRef.current) {
        if (!isCountryUs && phoneNumber?.length !== 10) {
          sendCodeVerification(
            receiptFlow.countryCode,
            receiptFlow.phoneNumber,
            formikRef.current.setStatus,
            formikRef.current.setSubmitting
          )
        }
      } else {
        setHasReceiptAccount(false)
        dispatch(
          showBasicModal({
            actionButtons: {
              singleButtonOnClick: () => Intercom().sendMessage(),
              singleButtonText: t('onDemand.receipt.notRegistered.button'),
            },
            preventClose: modalProps?.preventClose,
            description: t('onDemand.receipt.notRegistered.description'),
            title: t('onDemand.receipt.notRegistered.title'),
          })
        )
      }
    }
  }, [receiptFlow, formikRef])

  useEffect(() => {
    if (
      isCountryUs &&
      phoneNumber &&
      !formikRef?.current?.state?.isSubmitting
    ) {
      handlePhoneAutoSubmit(
        phoneNumber,
        () => {},
        () => {}
      )
    }
  }, [phoneNumber])

  useEffect(() => {
    if (passwordRef.current) {
      setTimeout(() => passwordRef.current.focus(), 500)
    }
  }, [showPassword])

  useEffect(() => {
    if (emailRef.current) {
      setTimeout(() => emailRef.current.focus(), 500)
    }
  }, [emailRef])

  useEffect(() => {
    if (!isEmpty(user) && !user.isGuest) {
      if (childProps && childProps.redirectTo) {
        redirectUser(childProps.redirectTo)
      }
    }
  }, [user])

  const redirectUser = page => {
    dispatch(hideModal())
    if (page.url) {
      router.push(page.url, page.as)
    } else {
      router.push(page)
    }
  }

  const signinRetry = () => {
    dispatch(
      showModal({
        childProps: {
          countryCode: country,
          phoneNumber: phoneNumber,
          preventRedirection: Boolean(receiptFlow),
          redirectTo:
            childProps.redirectTo ||
            `${getAppRoute(application, 'as')}/my-passes`,
        },
        modalProps: {
          preventClose: modalProps?.preventClose,
          className: 'noBorders',
        },
        modalType: 'signIn',
      })
    )
  }

  const showSignInError = error => {
    let actionButtons

    if (modalProps?.preventClose) {
      actionButtons = {
        singleButtonOnClick: () => signinRetry(),
        singleButtonText: t('common.button.tryAgain'),
      }
    } else {
      actionButtons = {
        rightButtonOnClick: () => signinRetry(),
        rightButtonText: t('common.button.tryAgain'),
        singleButtonOnClick: () => {},
        singleButtonText: t('common.button.cancel'),
      }
    }

    dispatch(
      showBasicModal({
        actionButtons: actionButtons,
        description: error,
        title: t('signIn.title'),
        type: 'error',
      })
    )
  }

  const sendCodeVerification = async (
    countryCode,
    phoneNumber,
    setStatus,
    setSubmitting
  ) => {
    await API()
      .sendPhoneVerification({
        ...(isSDK && { sdk: true }),
        timeoutTryAgainMessage: true,
        body: JSON.stringify({
          method: 'textMessage',
          phoneCountryCode: countryCode,
          phoneNumber,
        }),
      })
      .then(resp => {
        setSubmitting(false)

        if (resp.success) {
          dispatch(
            showModal({
              childProps: {
                countryCode: countryCode,
                phoneNumber: phoneNumber,
                type: 'guestLogin',
                modalCallback: async phoneVerificationToken => {
                  await createGuestUser({
                    country: countryCode,
                    dispatch,
                    onSuccess: () => {
                      if (!childProps?.redirectTo) {
                        redirectUser({
                          as: `${getAppRoute(application, 'as')}/my-passes`,
                          url: `${getAppRoute(application, 'url')}/my-passes`,
                        })
                      } else {
                        redirectUser(childProps.redirectTo)
                      }
                    },
                    isSDK,
                    phoneNumber,
                    phoneVerificationToken,
                  })
                },
              },
              fullScreen: false,
              modalProps: {
                open: true,
                preventClose: modalProps?.preventClose,
              },
              modalType: 'verifyPhoneNumber',
            })
          )
        } else {
          setStatus({ message: resp.error && resp.error.message })
          if (resp.error.code === 408) {
            dispatch(
              showBasicModal({
                title: resp.error.message,
              })
            )
          } else {
            dispatch(
              showBasicModal({
                title: t('signIn.title'),
                description: resp.error && resp.error.message,
              })
            )
          }
        }
      })
      .catch(err => {
        setSubmitting(false)
        setStatus({ message: err })
        dispatch(
          showBasicModal({
            title: t('signIn.title'),
            description: err,
          })
        )
      })
  }

  const onPhoneNumberSubmit = async (phoneNumber, setSubmitting, setStatus) => {
    await API()
      .getPhoneNumber(country, phoneNumber, {
        timeoutTryAgainMessage: true,
        ...(isSDK && { sdk: true }),
      })
      .then(res => {
        const {
          phoneNumber: {
            guestExists,
            isAppleConnected,
            isDuplicate,
            isFacebookConnected,
            userExists,
          },
        } = res

        if (res?.error?.code === 408) {
          dispatch(
            showBasicModal({
              title: res.error.message,
            })
          )
        } else {
          if (isAppleConnected) {
            setSubmitting(false)

            if (isSDK) {
              showAppleUnlinkMessage(phoneNumber)
            } else {
              showAppleFields()
            }
          } else if (isFacebookConnected) {
            setSubmitting(false)
            showFacebookUnlinkMessage(phoneNumber)
          } else if (userExists) {
            showRegularUserFields(isDuplicate)
            setSubmitting(false)
          } else if (guestExists) {
            if (isGuestAvailable) {
              sendCodeVerification(
                country,
                phoneNumber,
                setStatus,
                setSubmitting
              )
            } else {
              if (isSDK) {
                setShowUnrecognizedPhoneNumber(true)
              } else {
                setShowRestrictedPhoneNumber(true)
              }
              setSubmitting(false)
            }
          } else if (!userExists && !guestExists) {
            setSubmitting(false)
            setShowUnrecognizedPhoneNumber(true)
          }
        }
      })
      .catch(err => {
        const message = err?.message ? err.message : err

        setStatus({ message })
        setSubmitting(false)

        showSignInError(message)
      })
  }

  const handleSubmit = (values, { setSubmitting, setStatus, setTouched }) => {
    setSubmitting(true)

    const phoneNumber = nationalPhoneNumber(values.phoneNumber, country)

    if (values.phoneNumber && !values.password && !values.email) {
      onPhoneNumberSubmit(phoneNumber, setSubmitting, setStatus)
      setTouched({ password: false })
    } else {
      dispatch(
        attemptLogin({
          data: {
            phoneCountryCode: country,
            ...values,
            phoneNumber,
          },
          showErrorMessage: showSignInError,
        })
      )
    }
  }

  const onClearAuthentication = () => dispatch(clearAuthentication())

  const onFocusField = (setSubmitting, setStatus) => {
    onClearAuthentication()
    setSubmitting(false)
    setStatus(null)
  }

  const showFacebookUnlinkMessage = phoneNumber =>
    dispatch(
      showBasicModal({
        actionButtons: {
          rightButtonOnClick: () =>
            dispatch(
              unlinkFacebook({
                phoneCountryCode: country,
                phoneNumber: phoneNumber,
              })
            ),
          rightButtonText: t('common.button.continue'),
          singleButtonOnClick: () => {},
          singleButtonText: t('common.button.cancel'),
        },
        description: t('signIn.facebookUnavailable.description'),
        title: t('signIn.facebookUnavailable.title'),
      })
    )

  const showAppleUnlinkMessage = phoneNumber =>
    dispatch(
      showBasicModal({
        actionButtons: {
          rightButtonOnClick: () =>
            dispatch(
              unlinkApple({
                phoneCountryCode: country,
                phoneNumber: phoneNumber,
              })
            ),
          rightButtonText: t('common.button.continue'),
          singleButtonOnClick: () => {},
          singleButtonText: t('common.button.cancel'),
        },
        description: t('signIn.appleUnavailable.description'),
        title: t('signIn.appleUnavailable.title'),
      })
    )

  const resetFormState = (setSubmitting, setStatus) => {
    if (
      showAppleConnected ||
      showFacebookConnected ||
      showPassword ||
      showUnrecognizedPhoneNumber
    ) {
      onFocusField(setSubmitting, setStatus)
      setShowAppleConnected(false)
      setShowFacebookConnected(false)
      setShowNextButton(true)
      setShowPassword(false)
      setShowUnrecognizedPhoneNumber(false)
      setShowRestrictedPhoneNumber(false)
      setSignUpLink(true)
      setSocialButtons(true)
      setHasReceiptAccount(false)
    }
  }

  const handlePhoneAutoSubmit = (value, setSubmitting, setStatus) => {
    const phoneNumber = nationalPhoneNumber(value, country)

    if (isCountryUs && phoneNumber?.length === 10) {
      setSubmitting(true)
      onPhoneNumberSubmit(phoneNumber, setSubmitting, setStatus)
    }
  }

  return (
    <SignInForm
      application={application}
      emailRef={emailRef}
      formikRef={formikRef}
      error={error}
      handlePhoneAutoSubmit={handlePhoneAutoSubmit}
      handleSubmit={handleSubmit}
      initialValues={initialValues}
      isCountryUs={isCountryUs}
      onClearAuthentication={onClearAuthentication}
      onFocusField={onFocusField}
      passwordRef={passwordRef}
      resetFormState={resetFormState}
      showAppleConnected={showAppleConnected}
      receiptFlow={receiptFlow}
      showEmail={showEmail}
      showFacebookConnected={showFacebookConnected}
      showNextButton={showNextButton}
      showPassword={showPassword}
      hasReceiptAccount={hasReceiptAccount}
      showRestrictedPhoneNumber={showRestrictedPhoneNumber}
      showUnrecognizedPhoneNumber={showUnrecognizedPhoneNumber}
      isSDK={isSDK}
      t={t}
      {...props}
    />
  )
}

FormikHOC.propTypes = {
  country: PropTypes.string,
  router: PropTypes.object,
  setSignUpLink: PropTypes.func,
  setSocialButtons: PropTypes.func,
  phoneNumber: PropTypes.string,
  isGuestAvailable: PropTypes.bool,
}

export default withRouter(FormikHOC)
