import styled from 'styled-components'
import PropTypes from 'prop-types'
import React from 'react'
import { FaDirections } from 'react-icons/fa'
import { Col, Row, Grid } from 'react-styled-flexboxgrid'

import Text from 'components/Atoms/Text'

const Component = styled.div`
  background-color: ${props => props.theme.palette.grayLight};
  padding: 14px 0px;
`

const Title = styled(Text)`
  font-size: ${props => props.theme.font.size.mobile.h4};
  line-height: 110%;
  margin-bottom: 5px;
`

const IconDirections = styled(FaDirections)`
  width: 41px;
  height: 41px;
  color: ${props => props.theme.palette.black};
`

const Header = ({ name, distance, latitude, longitude, hideDirections }) => (
  <Component>
    <Grid>
      <Row center="xs" className="main">
        <Col xs={10}>
          <Title className="bold">{name}</Title>
          <Text className="small semiBold">{distance}</Text>
        </Col>
        <Col xs={2}>
          {hideDirections && (
            <a
              href={`https://www.google.com/maps/dir/?api=1&origin=${latitude},${longitude}`}
              rel="noreferrer"
              target="_blank">
              <IconDirections />
            </a>
          )}
        </Col>
      </Row>
    </Grid>
  </Component>
)

Header.propTypes = {
  name: PropTypes.string,
  distance: PropTypes.string,
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  hideDirections: PropTypes.bool,
}

export default Header
