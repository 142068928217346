import PropTypes from 'prop-types'
import styled from 'styled-components'
import { isEmpty } from 'lodash'

import Amenity from 'components/Atoms/Amenity'

const Container = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(2, 1fr);
`

const AmenityList = ({ amenities }) => {
  if (isEmpty(amenities)) {
    return null
  }

  return (
    <Container>
      {amenities.map(({ code, label }) => (
        <Amenity code={code} label={label} key={code} />
      ))}
    </Container>
  )
}

AmenityList.propTypes = {
  amenities: PropTypes.array,
}

export default AmenityList
