import { normalize } from 'normalizr'

import { continent as continentSchema } from 'api/schema'

import { FETCH_CONTINENTS_SUCCESS, SET_COUNTRY } from './actions'

const defaultState = {
  continents: {},
  countries: {},
  country: '',
}

export const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_CONTINENTS_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const { entities } = normalize(action.payload.continents, [
        continentSchema,
      ])

      return {
        ...state,
        continents: entities.continents,
        countries: entities.countries,
      }
    case SET_COUNTRY:
      return {
        ...state,
        country: action.payload,
      }
    // case FETCH_SETTINGS_BEGIN:
    //   return {
    //     ...state,
    //     isLoading: true
    //   }
    // case FETCH_SETTINGS_ERROR:
    //   return {
    //     ...state,
    //     isLoading: false
    //   }
    default:
      return state
  }
}
