import styled from 'styled-components'
import PropTypes from 'prop-types'
import React from 'react'
import classNames from 'classnames'
import { Col, Row } from 'react-styled-flexboxgrid'

import Button from 'components/Atoms/Button'
import Text from 'components/Atoms/Text'

const Component = styled.div`
  padding-top: 50px;
  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    &.border {
      border-top: 1px solid ${props => props.theme.palette.grayLight};
      margin-top: 50px;
    }
  }
`

const Image = styled.img`
  width: 100%;
  height: auto;
  border-top: 30px solid ${props => props.theme.palette.primary};
  margin-bottom: 10px;
  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    margin-bottom: 0;
  }
`

const Title = styled(Text)`
  font-weight: 700;
  margin: 7px 0 9px 0;
  font-size: ${props => props.theme.font.size.mobile.h4};
  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    font-size: ${props => props.theme.font.size.mobile.h2};
  }
`

const Description = styled(Text)`
  line-height: 24px;
  font-size: ${props => props.theme.font.size.mobile.small};
  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    font-size: ${props => props.theme.font.size.desktop.medium};
  }
`

const CustomButton = styled(Button)`
  background-color: transparent;
  border-color: ${props => props.theme.palette.grayDark};
  border-radius: 24px;
  height: 42px;
  padding: 0 40px;
  margin-top: 35px;
  align-self: center;
  font-size: ${props => props.theme.font.size.mobile.small};
  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    align-self: flex-start;
    font-size: ${props => props.theme.font.size.desktop.medium};
  }
`

const LinkButton = styled(Text)`
  background-color: transparent;
  border: 1px solid ${props => props.theme.palette.grayDark};
  color: ${props => props.theme.palette.grayDark};
  border-radius: 24px;
  height: 42px;
  padding: 0 40px;
  display: inline-flex;
  align-items: center;
  font-weight: 600;
  transition: 0.2s;
  margin-top: 35px;
  align-self: center;
  &:hover {
    opacity: 0.8;
  }
  &:active,
  &:visited {
    color: ${props => props.theme.palette.grayDark};
  }
  font-size: ${props => props.theme.font.size.mobile.small};
  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    align-self: flex-start;
    font-size: ${props => props.theme.font.size.desktop.medium};
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`

const BenefitCard = ({
  border,
  buttonHref,
  buttonLink,
  buttonText,
  className,
  description,
  image,
  label,
  reverse,
  title,
}) => {
  const getButton = () => {
    if (!buttonText) return null

    return buttonHref ? (
      <LinkButton as="a" href={buttonHref}>
        {buttonText.toUpperCase()}
      </LinkButton>
    ) : (
      <CustomButton onClick={buttonLink}>
        {buttonText.toUpperCase()}
      </CustomButton>
    )
  }

  return (
    <Component className={classNames(className, { border: border })}>
      <Row reverse={reverse} between="xs">
        <Col xs={12} sm={6}>
          <Image src={image} />
        </Col>
        <Col xs={12} sm={5}>
          <Content>
            <Text className="primary medium bold">{label.toUpperCase()}</Text>
            <Title>{title}</Title>
            <Description>{description}</Description>
            {getButton()}
          </Content>
        </Col>
      </Row>
    </Component>
  )
}

BenefitCard.propTypes = {
  border: PropTypes.bool,
  buttonHref: PropTypes.string,
  buttonLink: PropTypes.func,
  buttonText: PropTypes.string,
  className: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  label: PropTypes.string,
  reverse: PropTypes.bool,
  title: PropTypes.string,
}

export default BenefitCard
