import * as Yup from 'yup'

import { i18n } from 'i18n-web/i18next'
import { passwordSchema } from 'i18n-web/validators'

const validationSchema = passwordRules =>
  Yup.object().shape({
    password: passwordSchema({ passwordRules }),
    confirmPassword: Yup.string().oneOf(
      [Yup.ref('password'), null],
      i18n.t('signUp.form.confirmPassword.error')
    ),
  })

export default validationSchema
