import {
  AddCard,
  AddVehicle,
  Basic,
  CancelYourPass,
  ChangePassword,
  DatePicker,
  EditAccount,
  EditCard,
  EditVehicle,
  ForgotPassword,
  LotDetails,
  LotsPreview,
  ManagePass,
  MonthlyChangeVehicle,
  ImpersonateUser,
  MonthlyLotDetails,
  MonthlyParkingFilters,
  HourlySearchFilters,
  MonthlyParkingSearch,
  HourlyParkingSearch,
  MonthlyChangeCard,
  MyAccountChangePassword,
  TwoFactorAuthentication,
  PrivacyPolicy,
  PurchaseDetails,
  ReservedParkingFilters,
  ReservedParkingSearch,
  RetransferYourPass,
  SendPassToEmail,
  SendHourlyPassToEmail,
  SignIn,
  TermsAndConditions,
  TransferYourPass,
  TransferYourPassSuccess,
  UnlinkApple,
  UnlinkFacebook,
  VerifyPhoneNumber,
  WhiteMode,
} from 'components/Organisms/Modals'

const modals = {
  addCard: AddCard,
  addVehicle: AddVehicle,
  basic: Basic,
  cancelYourPass: CancelYourPass,
  changePassword: ChangePassword,
  datePicker: DatePicker,
  editAccount: EditAccount,
  editCard: EditCard,
  editVehicle: EditVehicle,
  forgotPassword: ForgotPassword,
  lotDetails: LotDetails,
  lotsPreview: LotsPreview,
  managePass: ManagePass,
  monthlyChangeVehicle: MonthlyChangeVehicle,
  monthlyLotDetails: MonthlyLotDetails,
  monthlyParkingFilters: MonthlyParkingFilters,
  hourlySearchFilters: HourlySearchFilters,
  monthlyParkingSearch: MonthlyParkingSearch,
  hourlyParkingSearch: HourlyParkingSearch,
  monthlyChangeCard: MonthlyChangeCard,
  myAccountChangePassword: MyAccountChangePassword,
  privacyPolicy: PrivacyPolicy,
  purchaseDetails: PurchaseDetails,
  reservedParkingFilters: ReservedParkingFilters,
  reservedParkingSearch: ReservedParkingSearch,
  retransferYourPass: RetransferYourPass,
  sendPassToEmail: SendPassToEmail,
  sendHourlyPassToEmail: SendHourlyPassToEmail,
  signIn: SignIn,
  termsAndConditions: TermsAndConditions,
  transferYourPass: TransferYourPass,
  transferYourPassSuccess: TransferYourPassSuccess,
  twoFactorAuthentication: TwoFactorAuthentication,
  unlinkApple: UnlinkApple,
  unlinkFacebook: UnlinkFacebook,
  verifyPhoneNumber: VerifyPhoneNumber,
  impersonateUser: ImpersonateUser,
  whiteMode: WhiteMode,
}

export default modals
