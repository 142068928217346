import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { setSelectedCard } from 'redux-web/utils/monthly/actions'
import WithStripe from 'hocs/withStripe'

import Screen from './Screen'

const ChangeCardHOC = props => {
  const dispatch = useDispatch()
  const { selectedCard, subscription } = useSelector(state => state.monthly)
  const { cards } = useSelector(state => state.user.me)

  const currentCardId = subscription?.card?.id

  const handleChangeSelectedCard = card => dispatch(setSelectedCard(card))

  useEffect(() => {
    const defaultCard = cards?.find(card => card.id === currentCardId)

    handleChangeSelectedCard(defaultCard)
  }, [])

  return (
    <Screen
      {...props}
      selectedCard={selectedCard}
      setSelectedCard={handleChangeSelectedCard}
      cards={cards}
    />
  )
}

export default WithStripe(ChangeCardHOC)
