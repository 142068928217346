import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import Loading from 'components/Atoms/Loading'

const Component = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
`

const Backdrop = props => {
  const { application } = props

  return (
    <Component {...props}>
      <Loading className="contrastText" application={application} />
    </Component>
  )
}

Backdrop.propTypes = {
  application: PropTypes.string,
}

export default Backdrop
