import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Text from 'components/Atoms/Text'

const Label = styled(Text)`
  font-size: ${props => props.theme.font.size.mobile.small};
  pointer-events: none;

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    font-size: ${props => props.theme.font.size.desktop.regular};
  }
`

const Component = ({ children, ...rest }) => <Label {...rest}>{children}</Label>

Component.propTypes = {
  children: PropTypes.node,
}

export default Component
