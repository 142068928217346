import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'next/router'

import Redirect from 'helpers/redirect'
import { getAccessToken } from 'redux-web/utils/session/actions'
import { getAppRoute } from 'helpers/application'

const WithAuth = WrapperComponent => {
  const Wrapper = props => {
    const { application, accessToken, router } = props
    const [authToken, setAuthToken] = useState()

    useEffect(() => {
      if (!accessToken) {
        if (getAccessToken()) {
          setAuthToken(getAccessToken())
        } else {
          router.push(
            `${getAppRoute(application, 'url')}/signin?redirectTo=${
              router.asPath
            }`
          )
        }
      } else {
        setAuthToken(accessToken)
      }
    }, [])

    return <WrapperComponent {...props} accessToken={authToken} />
  }

  Wrapper.getInitialProps = async ctx => {
    const {
      query: { application, sdk },
      reduxStore,
    } = ctx

    const isSDK = sdk || reduxStore.getState().settings.isSDK

    if (!isSDK) {
      const accessToken = getAccessToken(ctx)

      if (!accessToken) {
        Redirect(
          `${getAppRoute(application, 'url')}/signin?redirectTo=${ctx.asPath}`,
          ctx
        )
      }

      const pageProps =
        WrapperComponent.getInitialProps &&
        (await WrapperComponent.getInitialProps(ctx))

      return { ...pageProps, accessToken }
    }
    const pageProps =
      WrapperComponent.getInitialProps &&
      (await WrapperComponent.getInitialProps(ctx))

    return { ...pageProps }
  }

  Wrapper.propTypes = {
    accessToken: PropTypes.string,
    application: PropTypes.string,
    router: PropTypes.object,
  }

  return withRouter(Wrapper)
}

export default WithAuth
