import PropTypes from 'prop-types'

import { useTranslation } from 'i18n-web/i18next'

import Screen from './Screen'

const LotDetails = props => {
  const { t } = useTranslation()

  return <Screen {...props} t={t} />
}

LotDetails.propTypes = {
  t: PropTypes.func,
  application: PropTypes.string,
  lot: PropTypes.object,
}

export default LotDetails
