import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Component = styled.div`
  border-radius: 3px;
  background: ${props => props.theme.palette.background};
  box-shadow: 2px 4px 10px 0 rgba(55, 56, 64, 0.3);
`
const Box = ({ className, children, ...rest }) => (
  <Component className={className} {...rest}>
    {children}
  </Component>
)

Box.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
}

export default Box
