import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { isFunction } from 'lodash'
import { FaApple } from 'react-icons/fa'

import Button from 'components/Atoms/Button'

const AppleLink = styled.a`
  align-items: center;
  justify-content: center;
  display: flex;
`

const AppleIcon = styled(FaApple)`
  margin-right: 10px;
  font-size: ${props => props.theme.font.size.mobile.h4};
`

const Component = ({
  onClick,
  appleLink,
  redirectCallback,
  className,
  children,
}) => (
  <Button
    className={classNames(className, 'applePay')}
    onClick={() => {
      if (isFunction(onClick)) {
        onClick()
      }

      redirectCallback()
    }}>
    <AppleLink href={appleLink}>
      <AppleIcon />
      {children}
    </AppleLink>
  </Button>
)

Component.propTypes = {
  appleLink: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  onClick: PropTypes.func,
  redirectCallback: PropTypes.func,
}

export default Component
