import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import Button from 'components/Atoms/Button'
import Text from 'components/Atoms/Text'
import { selectCardById } from 'redux-web/selectors/user'
import { getCardBrandIcon } from 'models/purchase'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 18px;
  margin-bottom: -12px;

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    padding: 32px 37px 0px 37px;
  }
`

const CardNumber = styled(Text)`
  flex: 1;
  margin-left: 10px;
  align-items: center;
  display: inline-flex;
  font-size: 20px;
  &:before {
    content: '\\2022 \\2022 \\2022 \\2022';
    display: inline-flex;
    margin-right: 5px;
  }
`

const TrashButton = styled(Button)`
  width: 22px;
  border: none;
  padding: 0px;
`

const DeleteCardButton = ({ cardId, onClick }) => {
  const card = useSelector(state => selectCardById(state, cardId))

  if (!card) {
    return null
  }

  const { last4, brand } = card

  return (
    <Container>
      <img src={getCardBrandIcon(brand)} width="65px" height="auto" />
      <CardNumber>{last4}</CardNumber>
      <TrashButton className="transparent" onClick={onClick}>
        <img src="/static/icons/trash.png" width="100%" />
      </TrashButton>
    </Container>
  )
}

DeleteCardButton.propTypes = {
  cardId: PropTypes.number,
  onClick: PropTypes.func,
}

export default DeleteCardButton
