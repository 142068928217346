import isEmpty from 'lodash/isEmpty'

export const CLEAR_HOURLY_FILTERS = 'CLEAR_HOURLY_FILTERS'
export const RESET_HOURLY_SEARCH = 'RESET_HOURLY_SEARCH'
export const SET_HOURLY_ACTIVE_LOT = 'SET_HOURLY_ACTIVE_LOT'
export const SET_HOURLY_ACTIVE_PIN = 'SET_HOURLY_ACTIVE_PIN'
export const SET_HOURLY_AVAILABLE_FILTERS = 'SET_HOURLY_AVAILABLE_FILTERS'
export const SET_HOURLY_ERROR = 'SET_HOURLY_ERROR'
export const SET_HOURLY_FILTERS = 'SET_HOURLY_FILTERS'
export const SET_HOURLY_LOADING = 'SET_HOURLY_LOADING'
export const SET_HOURLY_LOCATION = 'SET_HOURLY_LOCATION'
export const RESET_HOURLY_PARKING = 'RESET_HOURLY_PARKING'
export const SET_HOURLY_LOTS = 'SET_HOURLY_LOTS'
export const SET_HOURLY_PASSES = 'SET_HOURLY_PASSES'
export const SET_HOURLY_RADIUS = 'SET_HOURLY_RADIUS'
export const SET_HOURLY_RESULTS_COUNT = 'SET_HOURLY_RESULTS_COUNT'
export const SET_HOURLY_SHOW_SEARCH = 'SET_HOURLY_SHOW_SEARCH'
export const START_REDIRECTING = 'START_REDIRECTING'
export const STOP_REDIRECTING = 'STOP_REDIRECTING'

export const setLocation = location => async dispatch =>
  dispatch({
    type: SET_HOURLY_LOCATION,
    payload: location,
  })

export const setRadius = radius => dispatch =>
  dispatch({
    type: SET_HOURLY_RADIUS,
    payload: radius,
  })

export const setAvailableFilters = filters => dispatch =>
  dispatch({
    type: SET_HOURLY_AVAILABLE_FILTERS,
    payload: filters,
  })

export const setFilters = filter => dispatch => {
  if (isEmpty(filter)) {
    return dispatch({
      type: CLEAR_HOURLY_FILTERS,
    })
  }

  return dispatch({
    type: SET_HOURLY_FILTERS,
    payload: filter,
  })
}

export const setLots = lots => dispatch =>
  dispatch({
    type: SET_HOURLY_LOTS,
    payload: lots,
  })

export const setResultsCount = count => dispatch =>
  dispatch({
    type: SET_HOURLY_RESULTS_COUNT,
    payload: count,
  })

export const setActiveLot = id => dispatch =>
  dispatch({
    type: SET_HOURLY_ACTIVE_LOT,
    payload: id,
  })

export const setActivePin = id => dispatch =>
  dispatch({
    type: SET_HOURLY_ACTIVE_PIN,
    payload: id,
  })

export const resetSearch = () => dispatch =>
  dispatch({
    type: RESET_HOURLY_PARKING,
  })

export const setShowSearch = pass => dispatch =>
  dispatch({
    type: SET_HOURLY_SHOW_SEARCH,
    payload: pass,
  })

export const setLoading = loading => dispatch =>
  dispatch({
    type: SET_HOURLY_LOADING,
    payload: loading,
  })

export const setError = error => dispatch =>
  dispatch({
    type: SET_HOURLY_ERROR,
    payload: error,
  })

export const startRedirecting = () => dispatch =>
  dispatch({
    type: START_REDIRECTING,
  })

export const stopRedirecting = () => dispatch =>
  dispatch({
    type: STOP_REDIRECTING,
  })
