import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import Intercom from 'helpers/intercom'

const WithIntercomHOC = WrapperComponent => {
  const Wrapper = props => {
    const { router } = props
    const sdk = router?.query.sdk

    useEffect(() => {
      const intercomJS = document.createElement('script')

      intercomJS.src = `https://widget.intercom.io/widget/${process.env.INTERCOM_APP_KEY}`
      intercomJS.async = true
      intercomJS.onload = () =>
        !props?.pageProps?.disableIntercom && !sdk && Intercom().init()

      document.body && document.body.appendChild(intercomJS)
    }, [])

    return <WrapperComponent {...props} />
  }

  if (WrapperComponent.getInitialProps) {
    Wrapper.getInitialProps = WrapperComponent.getInitialProps
  }

  Wrapper.propTypes = {
    pageProps: PropTypes.object,
    router: PropTypes.object,
  }

  return Wrapper
}

export default WithIntercomHOC
