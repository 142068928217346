import styled from 'styled-components'
import 'react-dates/initialize'

const CustomDatePicker = styled.div`
  .CalendarDay__default,
  .CalendarMonth_table .CalendarDay__blocked_out_of_range {
    border-color: transparent;
  }

  .CalendarMonth_table {
    border-collapse: separate;

    .CalendarDay__blocked_out_of_range {
      border-color: transparent;
      font-weight: 400;
    }

    .CalendarDay__default {
      font-weight: 600;
    }

    .CalendarDay__default:hover {
      border-radius: 50%;
      background-color: ${props => props.theme.palette.quaternary}30;
      border-color: ${props => props.theme.palette.quaternary}30;
    }

    .CalendarDay__selected,
    .CalendarDay__selected:active,
    .CalendarDay__selected:hover {
      font-weight: 700;
      border-radius: 50%;
      line-height: 100%;
      background-color: ${props => props.theme.palette.quaternary};
      border-color: ${props => props.theme.palette.quaternary};
    }
  }

  .DayPicker_weekHeader {
    text-transform: uppercase;
  }

  .CalendarMonth_caption {
    text-transform: uppercase;
    color: ${props => props.theme.palette.grayDark};
  }
`

export default CustomDatePicker
