import styled from 'styled-components'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Text from 'components/Atoms/Text'
import Tooltip from 'components/Atoms/Tooltip'
import isLightMode from 'helpers/isLightMode'

const Component = styled.div`
  position: relative;
  margin-top: 4px;
  margin-left: 64px;

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    margin-left: 82px;
  }
`

const TooltipText = styled(Text)`
  display: inline-flex;
  &:before {
    content: '•';
    margin-right: 5px;
    display: block;
  }
`

const PhoneNumberTooltip = ({ application, t }) => (
  <Component>
    <Tooltip title={t('purchase.disclaimer.title')}>
      <TooltipText
        className={classNames('tiny', 'semibold', {
          contrastText: !isLightMode(application),
        })}>
        {t('purchase.disclaimer.pros.sendPassToYourPhone')}
      </TooltipText>
      <TooltipText
        className={classNames('tiny', 'semibold', {
          contrastText: !isLightMode(application),
        })}>
        {t('purchase.disclaimer.pros.preventFraud')}
      </TooltipText>
      <TooltipText
        className={classNames('tiny', 'semibold', {
          contrastText: !isLightMode(application),
        })}>
        {t('purchase.disclaimer.pros.keepYourPassSafe')}
      </TooltipText>
    </Tooltip>
  </Component>
)

PhoneNumberTooltip.propTypes = {
  application: PropTypes.string,
  t: PropTypes.func,
}

export default PhoneNumberTooltip
