import { get } from 'lodash'
import * as Cookies from 'js-cookie'

import { getAppRoute } from 'helpers/application'
import { getErrorMessage } from 'api/utils'
import { hideModal, showModal } from 'components/Atoms/Modal/actions'
import { removeGuestSession } from 'helpers/guest'
import { removeReservationSession } from 'helpers/reservation'
import { showBasicModal } from 'helpers/modal'
import { onLogout, setUser } from 'redux-web/utils/user/actions'
import { getAccessToken, setSession } from 'redux-web/utils/session/actions'
import { setLoadingRequest } from 'redux-web/utils/request/actions'
import Intercom from 'helpers/intercom'
import { i18n, Router } from 'i18n-web/i18next'

const ERROR_FACEBOOK_ACCOUNT_NOT_REGISTERED = 2517

export const ATTEMPT_LOGIN_BEGIN = 'ATTEMPT_LOGIN_BEGIN'
export const ATTEMPT_LOGIN_SUCCESS = 'ATTEMPT_LOGIN_SUCCESS'
export const AUTHENTICATION_ERROR = 'AUTHENTICATION_ERROR'
export const AUTHENTICATION_RESET = 'AUTHENTICATION_RESET'

export const attemptLogin =
  ({ data, showErrorMessage, showSupportMessage, isSignUpFlow, sessionType }) =>
  async (dispatch, getState, api) => {
    dispatch({
      type: ATTEMPT_LOGIN_BEGIN,
    })

    if (getAccessToken()) {
      dispatch(onLogout())
    }

    dispatch(setLoadingRequest(true))

    await api
      .login({
        timeoutTryAgainMessage: true,
        body: JSON.stringify({
          ...data,
          ...(sessionType && { sessionType: sessionType }),
        }),
      })
      .then(resp => {
        if (resp.user?.isAdmin && !sessionType) {
          dispatch(
            attemptLogin({
              data,
              showErrorMessage,
              showSupportMessage,
              isSignUpFlow,
              sessionType: 'management',
            })
          )

          return
        }

        // REMOVE THE SESSION TYPE FOR NON-ADMIN USERS IN CASE IT HAS BEEN PASSED WRONGLY
        if (
          !resp.twoFactorAuthentication &&
          !resp.user?.isAdmin &&
          sessionType
        ) {
          dispatch(
            attemptLogin({
              data,
              showErrorMessage,
              showSupportMessage,
              isSignUpFlow,
              sessionType: null,
            })
          )

          return
        }

        if (resp.twoFactorAuthentication) {
          dispatch(
            showModal({
              childProps: {
                credentials: data,
              },
              fullScreen: false,
              modalProps: {
                className: 'noBorders',
              },
              modalType: 'twoFactorAuthentication',
            })
          )

          dispatch(setLoadingRequest(false))

          return
        }

        dispatch(setLoadingRequest(false))

        if (resp.user) {
          removeGuestSession()
          removeReservationSession()
          dispatch({
            type: ATTEMPT_LOGIN_SUCCESS,
          })

          dispatch(setSession(resp.user))
          dispatch(setUser(resp.user))

          const {
            query: { application },
          } = Router

          const redirectTo = Cookies.get('citifyd_apple_login_redirect')

          if (isSignUpFlow) {
            Router.push(
              `${getAppRoute(application, 'as')}/events`,
              `${getAppRoute(application, 'url')}/events`
            )
          } else if (redirectTo) {
            Router.push(redirectTo)
          }

          // facebook session
          Cookies.remove('citifyd_facebook_login_redirect', {
            secure: true,
            sameSite: 'none',
          })

          // Apple session
          Cookies.remove('citifyd_apple_login_redirect')
          Cookies.remove('appleAuthToken')
          Cookies.remove('pay_by_signage_flow')
        } else {
          if (
            get(resp, 'error.code') == ERROR_FACEBOOK_ACCOUNT_NOT_REGISTERED
          ) {
            dispatch(clearAuthentication())
            dispatch(hideModal())

            const {
              query: { application },
            } = Router

            if (!showErrorMessage && !showSupportMessage) {
              Router.push(
                {
                  pathname: `${getAppRoute(application, 'as')}/signup`,
                  query: { facebookToken: data.facebookToken },
                },
                `${getAppRoute(application, 'url')}/signup`
              )
            } else if (showErrorMessage) {
              dispatch(showBasicModal({ title: resp.error.message }))
            }

            if (showSupportMessage) {
              dispatch(
                showBasicModal({
                  actionButtons: {
                    singleButtonOnClick: () => Intercom().sendMessage(),
                    singleButtonText: i18n.t(
                      'onDemand.receipt.expiredReservation.button'
                    ),
                  },
                  preventClose: true,
                  description: i18n.t(
                    'onDemand.receipt.expiredReservation.description'
                  ),
                  title: i18n.t('onDemand.receipt.expiredReservation.title'),
                })
              )
            }
          } else {
            dispatch(setError(getErrorMessage(resp.error)))

            if (showErrorMessage) showErrorMessage(resp.error.message)
          }
        }
      })
      .catch(err => {
        dispatch(setError(err))
        dispatch(setLoadingRequest(false))

        console.log(err)
        if (showErrorMessage) showErrorMessage(err)
      })
  }

export const setError = error => dispatch =>
  dispatch({
    payload: error,
    type: AUTHENTICATION_ERROR,
  })

export const clearAuthentication = () => dispatch => {
  dispatch({
    type: AUTHENTICATION_RESET,
  })
}

export const unlinkApple =
  ({ phoneCountryCode, phoneNumber }) =>
  async (dispatch, getState, api) => {
    const sendPhoneVerification = await api.sendPhoneVerification({
      body: JSON.stringify({
        method: 'textMessage',
        phoneCountryCode: phoneCountryCode,
        phoneNumber: phoneNumber,
      }),
    })

    if (sendPhoneVerification.success) {
      dispatch(
        showModal({
          childProps: {
            countryCode: phoneCountryCode,
            phoneNumber: phoneNumber,
            modalCallback: async token => onPhoneVerificationSuccess(token),
          },
          fullScreen: false,
          modalProps: {
            open: true,
          },
          modalType: 'verifyPhoneNumber',
        })
      )
    }

    const onPhoneVerificationSuccess = async phoneVerificationToken => {
      const resp = await api.getPhoneNumberAppleInfo(
        phoneCountryCode,
        phoneNumber,
        phoneVerificationToken
      )

      if (!resp.error) {
        showUnlinkAppleModal({
          ...resp,
          phoneVerificationToken: phoneVerificationToken,
        })
      } else {
        if (resp.error?.message) {
          dispatch(showBasicModal({ title: resp.error.message }))
        }
      }
    }

    const showUnlinkAppleModal = data => {
      dispatch(
        showModal({
          childProps: {
            phoneNumber: phoneNumber,
            countryCode: phoneCountryCode,
            phoneVerificationToken: data?.phoneVerificationToken,
            name: data?.name,
            email: data?.email,
          },
          fullScreen: true,
          modalProps: {
            open: true,
          },
          modalType: 'unlinkApple',
        })
      )
    }
  }

export const unlinkFacebook =
  ({ phoneCountryCode, phoneNumber }) =>
  async (dispatch, getState, api) => {
    const sendPhoneVerification = await api.sendPhoneVerification({
      body: JSON.stringify({
        method: 'textMessage',
        phoneCountryCode: phoneCountryCode,
        phoneNumber: phoneNumber,
      }),
    })

    if (sendPhoneVerification.success) {
      dispatch(
        showModal({
          childProps: {
            countryCode: phoneCountryCode,
            phoneNumber: phoneNumber,
            modalCallback: async token => onPhoneVerificationSuccess(token),
          },
          fullScreen: false,
          modalProps: {
            open: true,
          },
          modalType: 'verifyPhoneNumber',
        })
      )
    }

    const onPhoneVerificationSuccess = async phoneVerificationToken => {
      const resp = await api.getPhoneNumberFacebookInfo(
        phoneCountryCode,
        phoneNumber,
        phoneVerificationToken
      )

      if (!resp.error) {
        showUnlinkFacebookModal({
          ...resp,
          phoneVerificationToken: phoneVerificationToken,
        })
      } else {
        if (resp.error?.message) {
          dispatch(showBasicModal({ title: resp.error.message }))
        }
      }
    }

    const showUnlinkFacebookModal = data => {
      dispatch(
        showModal({
          childProps: {
            phoneNumber: phoneNumber,
            countryCode: phoneCountryCode,
            phoneVerificationToken: data?.phoneVerificationToken,
            name: data?.name,
            email: data?.email,
          },
          fullScreen: true,
          modalProps: {
            open: true,
          },
          modalType: 'unlinkFacebook',
        })
      )
    }
  }
