import { MODAL_SHOW, MODAL_HIDE, MODAL_RESET } from './actions'

const initialState = {
  childProps: {},
  isClosed: true,
  modalProps: {},
  fullScreen: true,
  modalType: null,
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case MODAL_SHOW:
      return {
        childProps: action.childProps,
        isClosed: false,
        fullScreen: action.fullScreen,
        modalProps: action.modalProps,
        modalType: action.modalType,
      }
    case MODAL_HIDE:
      return {
        ...state,
        isClosed: true,
      }
    case MODAL_RESET:
      return initialState
    default:
      return state
  }
}
