import PropTypes from 'prop-types'
import styled from 'styled-components'

import { useTranslation } from 'i18n-web/i18next'
import Text from 'components/Atoms/Text'

const Component = styled(Text)`
  font-size: ${props => props.theme.font.size.mobile.tiny};
  margin-left: -0.5rem;
  margin-top: -3px;
  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    font-size: ${props => props.theme.font.size.desktop.medium};
    position: relative;
    top: 5px;
    margin-top: 0;
    position: absolute;
    right: 0;
  }
`

const AvailableCredits = ({ credits }) => {
  const { t } = useTranslation()

  return (
    <Component className="primary bold uppercase">
      {`${t('event.creditsAvailable')} ${credits}`}
    </Component>
  )
}

AvailableCredits.propTypes = {
  credits: PropTypes.string,
}

export default AvailableCredits
