import * as Yup from 'yup'

import { i18n } from 'i18n-web/i18next'

const validationSchema = () =>
  Yup.object().shape({
    name: Yup.string()
      .min(
        4,
        i18n.t('myAccount.settings.editAccount.modal.form.name.error.min')
      )
      .required(
        i18n.t('myAccount.settings.editAccount.modal.form.name.error.required')
      ),
    email: Yup.string()
      .email(
        i18n.t('myAccount.settings.editAccount.modal.form.email.error.invalid')
      )
      .required(
        i18n.t('myAccount.settings.editAccount.modal.form.email.error.required')
      ),
  })

export default validationSchema
