export const sendPageSize = ({
  timeout = 200,
  height = null,
  previousheight,
  parentDomain,
}) => {
  if (typeof window === 'undefined') return false

  window.parent?.postMessage(
    `height:${previousheight || process.env.NEXT_PUBLIC_DEFAULT_SDK_HEIGHT}`,
    parentDomain
  )

  setTimeout(
    () =>
      window.parent?.postMessage(
        `height:${height || document.body.scrollHeight}`,
        parentDomain
      ),
    timeout
  )
}

export const isSDK = () => {
  if (typeof window === 'undefined') return false

  return window.self !== window.top
}
