import { useDispatch } from 'react-redux'
import { omit } from 'lodash'

import { useTranslation } from 'i18n-web/i18next'
import { fetchMe, updateUserDefaultVehicle } from 'redux-web/utils/user/actions'
import { showBasicModal } from 'helpers/modal'
import API from 'api'
import { hideModal } from 'components/Atoms/Modal/actions'

import AddVehicleModal from './Screen'

const AddVehicleModalWrapper = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const handleSubmit = async formValues => {
    try {
      const values = omit(formValues, 'isDefault')
      const response = await API().createVehicle({
        body: JSON.stringify({
          ...values,
        }),
      })

      if (response.error) {
        dispatch(
          showBasicModal({
            title: t('common.title.error'),
            description: response.error.message,
            type: 'error',
          })
        )

        return response
      }

      if (values?.isDefault) {
        await dispatch(updateUserDefaultVehicle(response.vehicle.id))
      } else {
        await dispatch(fetchMe())
      }

      dispatch(hideModal())

      dispatch(
        showBasicModal({
          title: t('myAccount.vehicles.modal.addVehicle.success.title'),
          description: (
            <span style={{ whiteSpace: 'pre-wrap', fontWeight: 'bold' }}>
              {t('myAccount.vehicles.modal.addVehicle.success.description')}
            </span>
          ),
        })
      )

      return response
    } catch (error) {
      return error
    }
  }

  return <AddVehicleModal onSubmit={handleSubmit} />
}

export default AddVehicleModalWrapper
