const Support = () => (
  <svg xmlns="http://www.w3.org/2000/svg">
    <g fill="#fff" fillRule="evenodd">
      <g>
        <g>
          <g>
            <g>
              <path
                d="M18 2C9.168 2 2 9.168 2 18s7.168 16 16 16 16-7.168 16-16S26.832 2 18 2zm1.6 27.2h-3.2V26h3.2v3.2zm3.312-12.4l-1.44 1.472C20.32 19.44 19.6 20.4 19.6 22.8h-3.2V22c0-1.76.72-3.36 1.872-4.528l1.984-2.016c.592-.576.944-1.376.944-2.256 0-1.76-1.44-3.2-3.2-3.2-1.76 0-3.2 1.44-3.2 3.2h-3.2c0-3.536 2.864-6.4 6.4-6.4 3.536 0 6.4 2.864 6.4 6.4 0 1.408-.576 2.688-1.488 3.6z"
                transform="translate(-40 -648) translate(18 133) translate(0 494) translate(20 19)"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default Support
