import styled from 'styled-components'
import { Form, Field } from 'formik'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Button from 'components/Atoms/Button'
import TextDivisor from 'components/Atoms/TextDivisor'
import isLightMode from 'helpers/isLightMode'
import { TextField, PhoneField } from 'components/Molecules/Form'

const Component = styled(Form)`
  margin-top: 20px;
  text-align: center;
`

const ForgotPasswordForm = ({
  application,
  isSubmitting,
  isValid,
  setFieldValue,
  setStatus,
  setTouched,
  t,
}) => (
  <Component>
    <Field
      component={PhoneField}
      disabled={isSubmitting}
      label={t('forgotPassword.form.phoneNumber.label')}
      name="phoneNumber"
      onFocus={() => {
        setTouched({ phoneNumber: false })
        setFieldValue('email', '')
        setStatus(null)
      }}
    />
    <TextDivisor text={t('forgotPassword.form.divisor')} />
    <Field
      component={TextField}
      disabled={isSubmitting}
      label={t('forgotPassword.form.email.label')}
      name="email"
      onFocus={() => {
        setTouched({ email: false })
        setFieldValue('phoneNumber', '')
        setStatus(null)
      }}
    />
    <Button
      className={classNames('extraPadding', 'full', {
        highlight: isLightMode(application),
        contrastText: !isLightMode(application),
      })}
      disabled={isSubmitting || !isValid}
      type="submit">
      {t('forgotPassword.form.button').toUpperCase()}
    </Button>
  </Component>
)

ForgotPasswordForm.propTypes = {
  application: PropTypes.string,
  isSubmitting: PropTypes.bool,
  isValid: PropTypes.bool,
  setFieldValue: PropTypes.func,
  setStatus: PropTypes.func,
  setTouched: PropTypes.func,
  t: PropTypes.func,
}

export default ForgotPasswordForm
