import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { withRouter } from 'next/router'

import { hideModal } from 'components/Atoms/Modal/actions'
import { useTranslation } from 'i18n-web/i18next'

import Screen from './Screen'

const TransferYourPassSuccess = props => {
  const { router } = props

  const {
    query: { application },
  } = router

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const onCloseModal = () => {
    dispatch(hideModal())
  }

  return (
    <Screen
      application={application}
      onCloseModal={onCloseModal}
      t={t}
      {...props}
    />
  )
}

TransferYourPassSuccess.propTypes = {
  router: PropTypes.object,
}

export default withRouter(TransferYourPassSuccess)
