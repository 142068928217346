import styled from 'styled-components'
import { useState } from 'react'

import { useTranslation } from 'i18n-web/i18next'
import PageTitle from 'components/Atoms/PageTitle'
import Loading from 'components/Atoms/Loading'

const Component = styled.div`
  height: 500px;
  display: flex;
  flex-direction: column;
  @media (max-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    flex: 1;
    height: 100%;
    padding: 0;
  }
`

const LoadingBox = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`

const IframeContainer = styled.div`
  flex: 1;
  margin-bottom: -1px;
  -webkit-overflow-scrolling: touch;
  display: ${({ showIframe }) => (showIframe ? 'block' : 'none')};
`

const Iframe = styled.iframe`
  width: 100%;
  height: 100%;
  display: flex;
  -webkit-overflow-scrolling: touch;

  @media (max-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    height: 100%;
  }
`

const Divisor = styled.div`
  margin-top: 20px;
  border-top: 1px solid ${props => props.theme.palette.gray}80;
  display: none;

  &.title {
    margin-top: 0;
  }

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    display: block;
  }
`

const TermsAndConditions = () => {
  const [isLoading, setIsLoading] = useState(true)
  const { t } = useTranslation()

  return (
    <Component>
      <PageTitle
        modal
        noBorder
        showBackButton={true}
        title={t('termsOfService')}
      />
      <Divisor />
      {isLoading && (
        <LoadingBox>
          <Loading />
        </LoadingBox>
      )}
      <IframeContainer showIframe={!isLoading}>
        <Iframe
          height="100px"
          frameBorder="0"
          onLoad={() => setIsLoading(false)}
          src="https://policies.citifyd.com/terms"></Iframe>
      </IframeContainer>
    </Component>
  )
}

export default TermsAndConditions
