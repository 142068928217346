import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const Flag = styled.img`
  display: flex;
  height: auto;
  width: 44px;
  opacity: 0;
  transition-delay: 1s;
  opacity: 1;
  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    width: auto;
    height: 60px;
  }
`

const CountryList = styled.select`
  opacity: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  border: none;
  z-index: 1;
`

const CountryBox = styled.div`
  margin-top: 4px;
  position: relative;
  display: flex;
  align-items: center;
  min-width: 64px;
  height: 44px;

  &.disabled {
    &:after {
      display: none;
    }
  }

  &:after {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid ${props => props.theme.palette.grayDark}70;
    transform: translateX(-50%);
    right: -1px;
  }

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    min-width: 82px;
  }
`

const Component = ({ countryFlag, onChange, countries, disabled }) => (
  <CountryBox className={disabled && 'disabled'}>
    {countryFlag && (
      <Flag
        src={`/static/images/country-flags/${countryFlag?.toLowerCase()}@2x.png`}
      />
    )}
    <CountryList
      disabled={disabled}
      onChange={onChange}
      value={countryFlag?.toUpperCase()}>
      {countries.map((item, index) => (
        <option key={index} value={item.value}>
          {item.name}
        </option>
      ))}
    </CountryList>
  </CountryBox>
)

Component.defaultProps = {
  countryFlag: [],
}

Component.propTypes = {
  countryFlag: PropTypes.string,
  onChange: PropTypes.func,
  countries: PropTypes.any,
  disabled: PropTypes.bool,
}

export default Component
