import styled from 'styled-components'
import React, { useState } from 'react'
import { Col, Row, Grid } from 'react-styled-flexboxgrid'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import { hideModal } from 'components/Atoms/Modal/actions'
import { useTranslation } from 'i18n-web/i18next'
import MobileDatePicker from 'components/Atoms/DatePicker/Mobile'
import Button from 'components/Atoms/Button'

const Component = styled.div`
  margin-top: 18%;
  @media (max-height: 580px) {
    margin-top: 12%;
  }
`

const ButtonContainer = styled.div`
  padding: 13px 0;
  position: absolute;
  bottom: 80px;
  left: 0;
  right: 0;
  z-index: 9;
  background-color: ${props => props.theme.palette.background};
`

const DatePicker = ({ date, modalCallback }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [currentDate, setCurrentDate] = useState(date)

  return (
    <Component>
      <MobileDatePicker
        setCurrentDate={setCurrentDate}
        currentDate={currentDate}
      />
      <ButtonContainer>
        <Grid>
          <Row>
            <Col xs={12}>
              <Button
                onClick={() => {
                  modalCallback(currentDate)
                  dispatch(hideModal())
                }}
                className="extraPadding highlight full">
                {t('common.button.done').toUpperCase()}
              </Button>
            </Col>
          </Row>
        </Grid>
      </ButtonContainer>
    </Component>
  )
}

DatePicker.propTypes = {
  date: PropTypes.object,
  modalCallback: PropTypes.func,
}

export default DatePicker
