import API from 'api'
import { getEvents, getBundles } from 'models/events'
import { showBasicModal } from 'helpers/modal'
import { getAppKey } from 'models/application'
import { isCitifydApp } from 'helpers/application'
import { convertToQueryString } from 'helpers'

export const loadEvents = (pageNumber, query = null, isSDK, application) => {
  const params = {
    page: pageNumber,
    pageSize: 6,
    loadPageCount: true,
    ...(process.env.NEXT_PUBLIC_DEFAULT_EVENTS_RADIUS && {
      radius: process.env.NEXT_PUBLIC_DEFAULT_EVENTS_RADIUS,
    }),
  }

  return API()
    .getEvents(`${convertToQueryString(params)}&${query}`, {
      ...(isSDK && { sdk: true }),
      ...(application &&
        !isCitifydApp(application) && {
          headers: { 'App-Key': getAppKey(application) },
        }),
    })
    .then(resp => {
      if (!resp.error) {
        return {
          events: getEvents(resp.events),
          bundles: getBundles(resp.bundles),
          moreResultsAvailable: resp.moreResultsAvailable,
          pageCount: resp.pageCount,
        }
      }
    })
    .catch(err => console.error(err))
}

export const joinWaitlist = (eventId, application) =>
  API()
    .joinWaitlist(eventId, {
      timeoutTryAgainMessage: true,
      ...(application &&
        !isCitifydApp(application) && {
          headers: { 'App-Key': getAppKey(application) },
        }),
    })
    .then(resp => resp)
    .catch(err => console.error(err))

export const onWaitlistSuccess = ({
  dispatch,
  t,
  preventClose,
  redirectTo,
}) => {
  dispatch(
    showBasicModal({
      preventClose: preventClose,
      title: t('events.joinWaitlist.success.title'),
      description: t('events.joinWaitlist.success.description'),
      ...(redirectTo && {
        actionButtons: {
          singleButtonOnClick: () => redirectTo(),
          singleButtonText: t('common.button.gotIt'),
        },
      }),
    })
  )
}

export const onWaitlistError = ({ dispatch, error, preventClose }) =>
  dispatch(
    showBasicModal({
      preventClose: preventClose,
      title: error.message,
    })
  )
