import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { IoMdArrowDropleft, IoMdArrowDropright } from 'react-icons/io'
import { SingleDatePicker } from 'react-dates'
import classNames from 'classnames'

import CustomDatePicker from 'components/Atoms/DatePicker/style'

const CustomDatePickerDesktop = styled(CustomDatePicker)`
  flex: 1;
  .SingleDatePickerInput,
  .DateInput,
  .DateInput_input {
    background: transparent;
    cursor: pointer;
  }

  .DateInput_input {
    padding: 0 0 0 18px;
    line-height: auto;
    border-bottom: none;
    font-weight: 400;
    margin-top: -1px;
    outline: none;
    tap-highlight-color: transparent;
    color: ${props => props.theme.palette.grayDark};
    font-size: ${props => props.theme.font.size.mobile.tiny};
    &::placeholder {
      color: ${props => props.theme.palette.grayDark};
    }
  }

  .DateInput_input__focused {
    border-bottom: none;
  }

  &.show {
    .SingleDatePicker_picker {
      max-height: 320px;
    }
  }

  .SingleDatePicker_picker {
    max-height: 0;
    z-index: 9;
    transition-property: max-height;
    transition-duration: 0.2s;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    overflow: hidden;
    margin-top: -20px;
  }

  .DayPicker_transitionContainer,
  .DayPicker__withBorder {
    border-radius: 0;
  }
  .DayPicker_weekHeader {
    padding-top: 2px;
    border-bottom: 1px solid ${props => props.theme.palette.white};
    color: ${props => props.theme.palette.grayDark};
    font-weight: 600;
  }

  .DayPicker_weekHeader_ul {
    margin-bottom: 10px;
  }

  .DayPicker_weekHeader_li small {
    font-size: ${props => props.theme.font.size.mobile.small};
  }

  .DayPicker_transitionContainer__horizontal,
  .DayPicker__horizontal,
  .CalendarMonthGrid,
  .CalendarMonth,
  .CalendarDay__default,
  .CalendarDay__blocked_out_of_range {
    background: ${props => props.theme.palette.grayLight};
  }

  .SingleDatePickerInput_calendarIcon {
    padding: 0;
    margin: 0;
  }

  .CalendarMonth_caption {
    margin-bottom: 20px;
    font-size: ${props => props.theme.font.size.mobile.tiny};
    text-transform: uppercase;
  }

  .DayPickerNavigation_button {
    position: absolute;
    top: 13px;
    color: ${props => props.theme.palette.white};
    font-size: ${props => props.theme.font.size.desktop.h3};
    &:first-of-type {
      left: 10px;
    }
    &:last-of-type {
      right: 10px;
    }
  }

  .CalendarMonth_table {
    .CalendarDay__blocked_out_of_range {
      color: ${props => props.theme.palette.gray};
    }
  }

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    .DateInput_input {
      font-size: ${props => props.theme.font.size.desktop.small};
    }
  }
  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.md}rem) {
    .DateInput_input {
      font-size: ${props => props.theme.font.size.desktop.regular};
    }
  }
`

const DatePicker = ({ label, value, onChange }) => {
  const [focused, setFocused] = useState()
  const [animation, setAnimation] = useState()

  useEffect(() => {
    setTimeout(() => setAnimation(focused), 100)
  }, [focused])

  return (
    <CustomDatePickerDesktop className={classNames({ show: animation })}>
      <SingleDatePicker
        block
        noBorder
        readOnly
        numberOfMonths={1}
        navPrev={<IoMdArrowDropleft />}
        navNext={<IoMdArrowDropright />}
        placeholder={label}
        anchorDirection="right"
        hideKeyboardShortcutsPanel
        verticalSpacing={0}
        showDefaultInputIcon={false}
        date={value || null}
        onDateChange={date => onChange({ value: date, label: label })}
        focused={focused}
        onFocusChange={({ focused }) => setFocused(focused)}
        id="date_picker"
      />
    </CustomDatePickerDesktop>
  )
}

DatePicker.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.object,
}

export default DatePicker
