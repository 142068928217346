import styled from 'styled-components'
import PropTypes from 'prop-types'

import Disclaimer from 'components/Atoms/Disclaimer'
import { useTranslation } from 'i18n-web/i18next'

const Component = styled(Disclaimer)`
  margin-bottom: 20px;
  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    margin-bottom: 50px;
  }
`

const DigitalPass = ({ className }) => {
  const { t } = useTranslation()

  return (
    <Component
      className={className}
      title={t('events.digitalPass.title')}
      description={t('events.digitalPass.description')}
    />
  )
}

DigitalPass.propTypes = {
  className: PropTypes.string,
}

export default DigitalPass
