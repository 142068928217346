import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import Intercom from 'helpers/intercom'
import { usePrevious } from 'helpers/hooks'

import { hideModal, resetModal } from './actions'
import ModalTypes from './types'
import Modal from './Screen'

const ModalContainer = props => {
  const dispatch = useDispatch()

  const { modalType, modalProps, childProps, isClosed, fullScreen } =
    useSelector(state => state.modal)
  const isSDK = useSelector(state => state.settings.isSDK)
  const prevIsClosed = usePrevious(isClosed)

  const [isOpened, setIsOpened] = useState(!isClosed)
  const [openAnimation, setOpenAnimation] = useState(false)

  const ModalElement = ModalTypes[modalType]

  useEffect(() => {
    setIsOpened(!isClosed)
    if (isClosed && prevIsClosed !== undefined && !modalType) {
      document.body.style.overflow = 'auto'
      setOpenAnimation(false)
      setTimeout(() => {
        dispatch(resetModal())
      }, 80)
    }
  }, [isClosed])

  useEffect(() => {
    if (isOpened) {
      Intercom().shutdown()
      document.body.style.overflow = 'hidden'
    }
  }, [isOpened])

  const closeModal = e => {
    e.preventDefault()
    setOpenAnimation(false)
    if (!modalProps?.disableIntercomOnClose && !isSDK) {
      Intercom().init()
    }
    setTimeout(() => {
      setIsOpened(false)
      dispatch(hideModal())
      if (modalProps?.onCloseModal) {
        modalProps?.onCloseModal()
      }
    }, 70)
  }

  const afterOpen = () => {
    setTimeout(() => setOpenAnimation(true), 50)
  }

  const afterClose = () => {
    setOpenAnimation(false)
  }

  if (ModalElement) {
    return (
      <Modal
        afterClose={afterClose}
        afterOpen={afterOpen}
        className={modalProps?.className}
        closeModal={closeModal}
        customStyle={modalProps?.customStyle}
        fullScreen={fullScreen}
        hideBackButtonDivisor={modalProps?.hideBackButtonDivisor}
        hideCloseDesktop={modalProps?.hideCloseDesktop}
        hideCloseMobile={modalProps?.hideCloseMobile}
        isOpen={isOpened}
        isSDK={isSDK}
        mobileCloseIcon={modalProps?.mobileCloseIcon}
        noPadding={modalProps?.noPadding}
        openAnimation={openAnimation}
        preventClose={modalProps?.preventClose}
        preventCloseOnBackgroundClick={
          modalProps?.preventCloseOnBackgroundClick
        }
        {...props}>
        <ModalElement
          {...childProps}
          preventClose={modalProps?.preventClose || childProps?.preventClose}
        />
      </Modal>
    )
  }

  return null
}

ModalContainer.propTypes = {
  className: PropTypes.string,
}

export default ModalContainer
