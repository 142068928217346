import * as Yup from 'yup'

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('events.receipt.sendToEmail.email.error')
    .nullable()
    .required(true),
})

export default validationSchema
