import { SET_SESSION, SET_ACCESS_TOKEN, CLEAR_SESSION } from './actions'

const defaultState = {
  loggedIn: false,
  userId: null,
  accessToken: null,
}

export const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_SESSION:
      return {
        ...state,
        loggedIn: true,
        userId: action.payload.userId,
      }
    case SET_ACCESS_TOKEN:
      return {
        ...state,
        accessToken: action.payload.accessToken,
      }
    case CLEAR_SESSION:
      return {
        ...state,
        loggedIn: false,
        userId: null,
        accessToken: null,
      }
    default:
      return state
  }
}
