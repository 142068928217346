import React from 'react'
import { loadStripe } from '@stripe/stripe-js'

const WithStripeHOC = WrapperComponent => {
  const Wrapper = props => {
    const stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_API_KEY)

    return <WrapperComponent {...props} stripeKey={stripePromise} />
  }

  if (WrapperComponent.getInitialProps) {
    Wrapper.getInitialProps = WrapperComponent.getInitialProps
  }

  return Wrapper
}

export default WithStripeHOC
