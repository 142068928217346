import styled from 'styled-components'
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Col, Row, Grid } from 'react-styled-flexboxgrid'

import Button from 'components/Atoms/Button'
import isLightMode from 'helpers/isLightMode'

const ButtonContainer = styled.div`
  background-color: ${props => props.theme.palette.background};
  padding-bottom: 45px;
  position: relative;
  padding-top: 10px;
  z-index: 9;
  &.shadow {
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
  }
`

const ButtonComponent = ({ application, onClick, showButtonShadow, t }) => (
  <ButtonContainer className={classNames({ shadow: showButtonShadow })}>
    <Grid>
      <Row>
        <Col xs={12}>
          <Button
            className={classNames('extraPadding', 'full', {
              highlight: isLightMode(application),
              contrastText: !isLightMode(application),
            })}
            onClick={onClick}>
            {t('common.button.purchase').toUpperCase()}
          </Button>
        </Col>
      </Row>
    </Grid>
  </ButtonContainer>
)

ButtonComponent.propTypes = {
  application: PropTypes.string,
  onClick: PropTypes.func,
  showButtonShadow: PropTypes.bool,
  t: PropTypes.func,
}

export default ButtonComponent
