import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { filter, isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import { withRouter } from 'next/router'

import { setIsSubmitting } from 'redux-web/utils/purchase/actions'
import { createGuestUser } from 'helpers/guest'
import { setReservationSession } from 'helpers/reservation'

import Screen from './Screen'

const PurchaseButton = WrapperComponent => {
  const Wrapper = props => {
    const {
      formObject,
      isBundleEvent,
      onPurchase,
      onSubmitAttempt,
      onSubmitDenied,
      router,
      setSubmitCallback,
      requiredFieldIsEmpty,
      submitCallback,
      countryCode,
      phoneNumber,
      guestUser,
      guestSession,
      isExtendingParking,
      hasReservationCard = false,
      isFreeRate,
      order,
      onCustomPurchaseMethod,
    } = props

    const dispatch = useDispatch()
    const user = useSelector(state => state.user.me)
    const { termsAndConditionsAgreement } = useSelector(state => state.purchase)

    const {
      query: { application },
    } = router

    useEffect(() => {
      if (submitCallback.card || submitCallback.paymentToken) {
        onPurchaseButton({
          newCard: false,
          currentCard: submitCallback.card,
          paymentToken: submitCallback.paymentToken,
          accessToken: submitCallback.accessToken,
        })
      }
    }, [submitCallback])

    const onSubmit = async ({
      reservationAccessToken,
      accessToken,
      currentCard,
      paymentToken,
    }) => {
      dispatch(setIsSubmitting(true))

      if (!guestUser && guestSession && !accessToken && !isExtendingParking) {
        await createGuestUser({
          country: countryCode,
          phoneNumber: phoneNumber.number,
          phoneVerificationToken: phoneNumber.phoneVerificationToken,
          dispatch,
        })
      } else if (isEmpty(user) && !accessToken && !isExtendingParking) {
        const reservation = await setReservationSession()

        reservationAccessToken = reservation.accessToken
      }

      if (onCustomPurchaseMethod) {
        onCustomPurchaseMethod({
          paymentToken: paymentToken,
          cardId: currentCard?.id,
          ...(accessToken && {
            accessToken: accessToken,
          }),
          sendPhoneNumber: Boolean(accessToken),
        })
      } else {
        onPurchase({
          currentCard,
          paymentToken,
          isBundleEvent,
          accessToken: accessToken || reservationAccessToken,
          sendPhoneNumber: Boolean(accessToken || reservationAccessToken),
        }).then(resp => {
          if (resp?.error) {
            setSubmitCallback({ error: true })
          }
        })
      }
    }

    const onPurchaseButton = async ({
      newCard,
      currentCard,
      paymentToken,
      accessToken,
    }) => {
      let reservationAccessToken

      if (onSubmitAttempt) onSubmitAttempt()

      if (
        !hasReservationCard &&
        (requiredFieldIsEmpty || (!termsAndConditionsAgreement && !isFreeRate))
      ) {
        onSubmitDenied()

        // SCROLL TO TERMS AND CONDITIONS
        if (!termsAndConditionsAgreement) {
          if (!requiredFieldIsEmpty) {
            document
              .querySelector('#termsAndConditions')
              .scrollIntoView({ behavior: 'smooth' })
          }
        }

        // FORM VALIDATION
        if (formObject) {
          const validFields = filter(formObject?.state?.values, item => item)

          if (validFields.length < 3) {
            Object.keys(formObject?.state?.values).map(item =>
              formObject.setFieldTouched(item)
            )

            if (!requiredFieldIsEmpty) {
              document
                .querySelector('#cardForm')
                .scrollIntoView({ behavior: 'smooth' })
            }
          }
        }
      } else {
        if (
          (!newCard && (currentCard || paymentToken)) ||
          order?.raw?.total === 0 ||
          (isExtendingParking && hasReservationCard)
        ) {
          onSubmit({
            reservationAccessToken,
            accessToken,
            currentCard,
            paymentToken,
          })
        } else if (formObject) {
          formObject.submitForm()
        }
      }
    }

    return (
      <WrapperComponent
        {...props}
        application={application}
        onPurchaseButton={onPurchaseButton}
      />
    )
  }

  Wrapper.propTypes = {
    countryCode: PropTypes.string,
    formObject: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
    guestSession: PropTypes.string,
    guestUser: PropTypes.bool,
    hasReservationCard: PropTypes.bool,
    isBundleEvent: PropTypes.bool,
    isExtendingParking: PropTypes.bool,
    isFreeRate: PropTypes.bool,
    onCustomPurchaseMethod: PropTypes.func,
    onPurchase: PropTypes.func,
    onSubmitAttempt: PropTypes.func,
    onSubmitDenied: PropTypes.func,
    order: PropTypes.object,
    phoneNumber: PropTypes.any,
    requiredFieldIsEmpty: PropTypes.bool,
    router: PropTypes.object,
    setSubmitCallback: PropTypes.func,
    submitCallback: PropTypes.object,
  }

  return Wrapper
}

export default withRouter(PurchaseButton(Screen))
