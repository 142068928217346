import moment from 'moment'

import { getReadableText as getRateReadableText } from 'models/rate'
import { i18n } from 'i18n-web/i18next'

export const getMaxRate = availability => {
  if (!availability) {
    return null
  }

  const { rate, startTime } = availability
  const { discount } = rate

  if (
    discount &&
    moment(startTime).isSameOrBefore(moment(discount.arriveBefore))
  ) {
    return discount.maxRate
  }

  return rate.maxRate
}

// For hourly, returns `$0.00/hr ($0.00 max)`
// For fixed, returns `$0.00 fixed rate`
export const getLongText = (availability, currency) => {
  const { rate } = availability

  const maxRate = getMaxRate(availability)
  const maxText =
    maxRate && i18n.t('onDemand.plan.maxRate', { maxRate, currency })

  const rateText = getRateReadableText(rate, currency)

  return [rateText, maxText].filter(Boolean).join(' ')
}

export const isAvailabilityRunning = availability => {
  if (!availability) {
    return false
  }

  return moment().isBetween(
    moment(availability.start),
    moment(availability.end)
  )
}

export const differenceBetweenAvailabityStartAndEventStart = (
  availability,
  event
) => {
  if (!availability || !event) {
    return false
  }

  let unit = 'hours'

  const availabilityStart = moment(availability.start).tz(
    availability.timezoneName
  )

  const eventStart = moment(event.start).tz(event.timezoneName)

  if (eventStart.diff(availabilityStart, 'hours') <= 1) {
    unit = 'minutes'
  }

  return { time: eventStart.diff(availabilityStart, unit), unit: unit }
}
