import { useDispatch } from 'react-redux'
import { withRouter } from 'next/router'

import { hideModal } from 'components/Atoms/Modal/actions'
import { useTranslation } from 'i18n-web/i18next'

import TransferYourPass from './Screen'

const TransferYourPassHOC = props => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const onCancelLink = () => {
    dispatch(hideModal())
  }

  return <TransferYourPass onCancelLink={onCancelLink} t={t} {...props} />
}

export default withRouter(TransferYourPassHOC)
