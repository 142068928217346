import { normalize } from 'normalizr'

import { continent as continentSchema } from 'api/schema'

import { FETCH_CONTINENTS_SUCCESS } from '../countries/actions'

import {
  FETCH_SETTINGS_BEGIN,
  FETCH_SETTINGS_ERROR,
  SET_APPLICATION,
  SET_PARENT_DOMAIN,
  SET_SDK,
  SET_SETTINGS,
  SET_CONTACT_SUPPORT,
  SET_DISABLE_HEADER_AND_FOOTER,
  SET_SHARED_COORDINATES,
  SET_HAS_BROWSER_PERMISSION,
} from './actions'

const defaultState = {
  application: null,
  geoLocation: {
    hasBrowserPermission: null, // set to null to handle loading state
    sharedCoordinates: null,
  },
  continentsDataset: [],
  isLoading: true,
  isSDK: false,
  parentDomain: null,
  errorStatus: null,
}

export const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_SETTINGS:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
        updatedAt: new Date().toISOString(),
      }
    case FETCH_SETTINGS_BEGIN:
      return {
        ...state,
        isLoading: true,
        errorStatus: null,
      }
    case FETCH_SETTINGS_ERROR:
      return {
        ...state,
        isLoading: false,
        errorStatus: action.payload,
      }
    case FETCH_CONTINENTS_SUCCESS: {
      const { continents } = action.payload
      const { result } = normalize(continents, [continentSchema])

      return {
        ...state,
        continentsDataset: result,
      }
    }
    case SET_APPLICATION:
      return {
        ...state,
        application: action.payload,
      }
    case SET_PARENT_DOMAIN:
      return {
        ...state,
        parentDomain: action.payload,
      }
    case SET_DISABLE_HEADER_AND_FOOTER:
      return {
        ...state,
        disableHeaderAndFooter: action.payload,
      }
    case SET_SDK:
      return {
        ...state,
        isSDK: action.payload,
      }
    case SET_CONTACT_SUPPORT:
      return {
        ...state,
        emailSupport: action.payload.emailSupport,
        phoneNumberSupport: action.payload.phoneNumberSupport,
      }
    case SET_SHARED_COORDINATES: {
      const { latitude, longitude } = action.payload

      if (!latitude || !longitude) return state

      return {
        ...state,
        geoLocation: {
          hasBrowserPermission: true,
          sharedCoordinates: {
            latitude,
            longitude,
          },
        },
      }
    }
    case SET_HAS_BROWSER_PERMISSION:
      return {
        ...state,
        geoLocation: {
          ...state.geoLocation,
          hasBrowserPermission: action.payload,
        },
      }
    default:
      return state
  }
}
