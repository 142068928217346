import PropTypes from 'prop-types'
import classNames from 'classnames'
import styled from 'styled-components'
import { Formik, Form, Field } from 'formik'

import BulletPoint from 'components/Atoms/BulletPoint'
import Button from 'components/Atoms/Button'
import isLightMode from 'helpers/isLightMode'
import { TextField } from 'components/Molecules/Form'
import { useTranslation } from 'i18n-web/i18next'

import validationSchema from './validationSchema'

const Component = styled(Form)`
  .textDivisor {
    display: block;
    margin: 20px 0 12px;
    height: 1px;
  }

  .confirmPassword {
    padding-top: 5px;
  }
`

const ChangePasswordForm = ({
  application,
  formikRef,
  handlePasswordOnKeyUp,
  handleSubmit,
  initialValues,
  passwordRules,
}) => {
  const { t } = useTranslation()

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      ref={ref => formikRef(ref)}
      render={props => {
        const { isSubmitting, isValid } = props

        return (
          <Component>
            <Field
              component={TextField}
              disabled={isSubmitting}
              label={t(
                'myAccount.settings.changePassword.modal.form.currentPassword.label'
              )}
              name="currentPassword"
              type="password"
            />
            <Field
              component={TextField}
              disabled={isSubmitting}
              label={t(
                'myAccount.settings.changePassword.modal.form.password.label'
              )}
              name="password"
              onKeyUp={handlePasswordOnKeyUp}
              type="password"
            />
            <BulletPoint
              isChecked={
                passwordRules?.missingFeatures &&
                !passwordRules?.missingFeatures.includes('minimum')
              }>
              {t('signUp.form.password.requirements.minimum')}
            </BulletPoint>
            <Field
              className="confirmPassword"
              component={TextField}
              disabled={isSubmitting}
              label={t(
                'myAccount.settings.changePassword.modal.form.confirmPassword.label'
              )}
              name="confirmPassword"
              type="password"
            />
            <Button
              className={classNames('extraPadding', 'full', {
                highlight: isLightMode(application),
                contrastText: !isLightMode(application),
              })}
              disabled={isSubmitting || !isValid}
              type="submit">
              {t(
                'myAccount.settings.changePassword.modal.form.submit'
              ).toUpperCase()}
            </Button>
          </Component>
        )
      }}
      validationSchema={() => validationSchema(passwordRules)}
    />
  )
}

ChangePasswordForm.propTypes = {
  application: PropTypes.string,
  formikRef: PropTypes.func,
  handlePasswordOnKeyUp: PropTypes.func,
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  isSubmitting: PropTypes.bool,
  isValid: PropTypes.bool,
  passwordRules: PropTypes.object,
}

export default ChangePasswordForm
