import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { IoIosClose } from 'react-icons/io'

import { hideModal } from 'components/Atoms/Modal/actions'
import Text from 'components/Atoms/Text'

const Component = styled.div`
  padding: 15px;
  position: relative;
  border-radius: 4px;
  background: ${props => props.theme.palette.background};
`

const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  margin-right: ${props => (props.withMargin ? '30px' : '0')};
`

const CloseButton = styled.button`
  position: absolute;
  padding: 0;
  right: 0;
  top: 5px;
  background: none;
  border: none;
  .icon {
    transition: 150ms;
  }
  .icon {
    font-size: 48px;
    color: ${props => props.theme.palette.gray};
  }
  &:hover {
    .icon {
      color: ${props => props.theme.palette.grayDark};
    }
  }

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    right: 2px;

    .icon {
      font-size: 38px;
    }
  }
`

const WhiteMode = ({ title, preventClose, children }) => {
  const dispatch = useDispatch()

  const handleCloseClick = () => {
    dispatch(hideModal())
  }

  return (
    <Component>
      {title && (
        <TitleContainer withMargin={!preventClose}>
          <Text className="bold">{title}</Text>
        </TitleContainer>
      )}
      {!preventClose && (
        <CloseButton onClick={handleCloseClick}>
          <IoIosClose className="icon" />
        </CloseButton>
      )}
      {children}
    </Component>
  )
}

WhiteMode.propTypes = {
  title: PropTypes.string,
  preventClose: PropTypes.bool,
  children: PropTypes.object,
}

export default WhiteMode
