import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import { i18n } from 'i18n-web/i18next'

const WithMultiLanguage = WrapperComponent => {
  const Wrapper = props => {
    const { router } = props

    const setDefaultLanguage = () => {
      if (i18n.language !== 'en-US' || i18n.language !== 'en') {
        i18n.changeLanguage('en-US')
        moment.locale('en-us')
      }
    }

    const payBySignageURL =
      /^\/parking-lots\/(?:([^/]+?))\/(?:([^/]+?))\/book(.*)\/?$/i
    const payBySignageReceiptURL =
      /^\/parking-lots\/(?:([^/]+?))\/(?:([^/]+?))\/reservation(.*)\/?$/i
    const payBySignageReceiptSlug = /^\/receipts\/on-demand(.*)\/?$/i
    const payBySignageRedirect =
      /^\/pay-by-signage\/(?:([^/]+?)((?:[/].+?)?))\/?$/i
    const signup = /^\/signup(.*)\/?$/i
    const downloadApp = /^\/download-app\/?$/i
    const deeplink = /^\/deeplink(.*)\/?$/i

    const allowedURLs =
      !router.route.match(payBySignageURL) &&
      !router.route.match(payBySignageReceiptURL) &&
      !router.route.match(payBySignageReceiptSlug) &&
      !router.route.match(payBySignageRedirect) &&
      !router.route.match(downloadApp) &&
      !router.route.match(deeplink)

    if (router.route.match(signup)) {
      if (!router.query?.pbs) {
        setDefaultLanguage()
      }
    } else if (allowedURLs) {
      setDefaultLanguage()
    }

    return <WrapperComponent {...props} />
  }

  if (WrapperComponent.getInitialProps) {
    Wrapper.getInitialProps = WrapperComponent.getInitialProps
  }

  Wrapper.propTypes = {
    router: PropTypes.object,
    reduxStore: PropTypes.object,
  }

  return Wrapper
}

export default WithMultiLanguage
