import { isEmpty } from 'lodash'

import { formatCurrency } from 'i18n-web/formatters'
import {
  isAvailabilityRunning,
  differenceBetweenAvailabityStartAndEventStart,
} from 'models/availability'

export const getPrimaryTicket = event => {
  if (!event) {
    return null
  }

  const primaryTicket =
    event.tickets && event.tickets.find(ticket => ticket.primary)
  const primaryTicketVoucher =
    event.vouchers &&
    event.vouchers.find(voucher => voucher.ticket && voucher.ticket.primary)

  return primaryTicket || (primaryTicketVoucher && primaryTicketVoucher.ticket)
}

export const getTicket = (event, ticketId) =>
  getAllTickets(event).find(({ id }) => id == ticketId)

export const getVouchers = event => event.vouchers || []

export const getVoucherForTicket = (event, ticketId) =>
  getVouchers(event).find(({ ticket }) => ticket && ticket.id == ticketId)

export const ticketIsPendingTransfer = (event, ticketId) =>
  Boolean(getVoucherForTicket(event, ticketId))

export const getAllTickets = event => {
  if (!event) {
    return []
  }

  const primaryTicket = getPrimaryTicket(event)
  const activeTickets = event.tickets || []
  const voucherTickets = getVouchers(event)
    .filter(({ ticket }) => Boolean(ticket))
    .map(({ ticket }) => ticket)

  const primaryTicketId = primaryTicket && primaryTicket.id

  return [...activeTickets, ...voucherTickets].sort(
    ({ id: id1 }, { id: id2 }) => {
      if (id1 == primaryTicketId) {
        return -1
      }
      if (id2 == primaryTicketId) {
        return 1
      }

      return 0
    }
  )
}

export const getListItemForAdditionalPass = (event, ticket) => {
  const isExpired = ticketIsExpired(event, ticket.id)
  const lot = event.lots.filter(item => item.id === ticket.lotId)[0]

  return {
    lot: {
      name: lot.name,
      address: lot.address,
      availability: lot.availability,
    },
    event: event,
    ticket: ticket,
    duplicate: true,
    isExpired,
  }
}

export const getListItem = (event, ticket) => {
  const lot = event.lots.filter(item => item.id === ticket.lotId)[0]

  return {
    lot: {
      name: lot.name,
      countryCode: lot.countryCode,
      address: lot.address,
      availability: lot.availability,
    },
    event: event,
    ticket: ticket,
  }
}

export const ticketIsExpired = (event, ticketId) => {
  if (!event) {
    return false
  }

  // const ticket = getTicket(event, ticketId)

  const isPendingTransfer = ticketIsPendingTransfer(event, ticketId)
  // const canTransfer = canTransferNow(ticket)
  // const canExchange = canExchangeNow(ticket)
  // const canCancel = canCancelNow(ticket)

  return !isPendingTransfer
}

export const getFormattedTicket = ({ ticket }) => {
  const {
    event,
    currency,
    card,
    lot,
    financial,
    availability,
    vouchers,
    isPendingTransfer,
    gateEntries,
  } = ticket

  const hasVouchers =
    vouchers?.length > 0
      ? Boolean(vouchers.filter(item => !item.deletedAt))
      : false

  return {
    ...ticket,
    isPendingTransfer: isPendingTransfer || hasVouchers,
    isVerified: !isEmpty(gateEntries),
    availability: {
      ...availability,
      isRunning: isAvailabilityRunning(availability),
      timeLeftToRunAvailability: differenceBetweenAvailabityStartAndEventStart(
        availability,
        event
      ),
    },
    card: {
      ...card,
      nativePayment:
        card?.tokenizationMethod === 'apple_pay'
          ? 'apple-pay'
          : card?.tokenizationMethod === 'android_pay'
          ? 'google-pay'
          : null,
    },
    lot: {
      ...lot,
      mapLink: `https://www.google.com/maps/dir/?api=1&destination=${lot.latitude},${lot.longitude}`,
    },
    financialFormatted: {
      value:
        financial?.baseAmount > 0 &&
        formatCurrency(financial.baseAmount, currency),
      fee:
        financial?.citifydAmount > 0 &&
        formatCurrency(financial.citifydAmount, currency),
      credits:
        financial?.incentiveAmount > 0 &&
        formatCurrency(financial.incentiveAmount, currency),
      total:
        financial?.userChargeAmount &&
        formatCurrency(financial?.userChargeAmount, currency),
    },
  }
}
