import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import PlacesAutocomplete from 'react-places-autocomplete'
import classNames from 'classnames'
import isEmpty from 'lodash/isEmpty'
import { useRouter } from 'next/router'
import { trim } from 'lodash'

import Text from 'components/Atoms/Text'
import useGoogleMaps from 'hooks/useGoogleMaps'
import { useClientResponsive } from 'helpers/hooks'
import { useTranslation } from 'i18n-web/i18next'
import { hideModal } from 'components/Atoms/Modal/actions'
import { selectGeolocation } from 'redux-web/selectors/settings'

import { startRedirecting } from '../../../../redux-web/utils/hourlySearch/actions'
import {
  setHasGeolocationBrowserPermission,
  setSharedCoordinates,
} from '../../../../redux-web/utils/settings/actions'
import { getPayBySignageUrl } from '../../../../models/payBySignage'

import {
  AlertBox,
  Component,
  LocationError,
  LocationHint,
  LocationIcon,
  SubmitButton,
  SuggestionItem,
  SuggestionItemSecondary,
  Suggestions,
  TextInput,
  Wrapper,
} from './styledComponents'

import Lots from '@/graphql/hourlyParking/queries/Lots'

const HourlyParkingSearch = ({
  address,
  getUserCurrentLocation,
  inputRef,
  isLocationInputFocused,
  loadingMyLocation,
  locationError,
  onLocationChange,
  onLocationSelect,
  onSubmitClick,
  setIsLocationInputFocused,
  apolloClient,
}) => {
  const dispatch = useDispatch()
  const router = useRouter()
  const { gmapsLoaded } = useGoogleMaps()
  const { isMobile } = useClientResponsive()
  const { t } = useTranslation()

  const { hasBrowserPermission: geolocationBrowserPermission } = useSelector(
    state => selectGeolocation(state)
  )

  const [showMyLocationOption, setShowMyLocationOption] = useState(
    geolocationBrowserPermission ?? true
  )
  const [lots, setLots] = useState([])
  const [loadingBrowserPermission, setLoadingBrowserPermission] =
    useState(false)

  const getCoordinates = async () => {
    const { coords } = await new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject, {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      })
    })

    return {
      latitude: coords.latitude,
      longitude: coords.longitude,
    }
  }

  const getLots = async (newAddress = null) => {
    try {
      setLots([])

      if (geolocationBrowserPermission === null) {
        setLoadingBrowserPermission(true)
      }

      const { latitude, longitude } = await getCoordinates()

      dispatch(
        setSharedCoordinates({
          latitude,
          longitude,
        })
      )

      const { data } = await apolloClient.query({
        query: Lots,
        variables: {
          latitude,
          longitude,
          radius: 1,
          searchTerm: trim(newAddress || address),
        },
      })

      setLots(data)
    } catch (err) {
      // explicity set from null to false to allow better loading handling
      dispatch(setHasGeolocationBrowserPermission(false))
      console.log({ err })
    } finally {
      setLoadingBrowserPermission(false)
    }
  }

  useEffect(() => {
    getLots()
  }, [])

  const handleShowFindParkingButton = ({ loading, suggestions }) =>
    !isLocationInputFocused && !loading && isEmpty(suggestions) && address

  const onBookButtonClick = lot => {
    dispatch(startRedirecting())
    const url = getPayBySignageUrl(lot)

    router.push(`/parking-lots/${url}/book`)
    dispatch(hideModal())
  }

  useEffect(() => {
    if (!geolocationBrowserPermission) return

    getUserCurrentLocation(getLots)

    setShowMyLocationOption(false)
  }, [geolocationBrowserPermission])

  return (
    <form onSubmit={() => {}}>
      <Wrapper>
        {gmapsLoaded && (
          <PlacesAutocomplete
            debounce={500}
            value={address || ''}
            onChange={onLocationChange}
            onSelect={locationAddress => {
              onLocationSelect(locationAddress)
              getLots(locationAddress)
              setShowMyLocationOption(false)
            }}>
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading,
            }) => {
              const showFindParkingButton = handleShowFindParkingButton({
                loading,
                suggestions,
              })

              return (
                <>
                  {isMobile && !address && (
                    <>
                      <Component>
                        <AlertBox id="container" color="red">
                          <Text className="small bold gutterBottomSmall">
                            {t('hourlyParking.correctLotBeforeProceeding')}
                          </Text>

                          <Text className="small bold">
                            {t('hourlyParking.ticketWillBeCountedInstantly')}
                          </Text>
                        </AlertBox>
                      </Component>
                      <LocationHint
                        className={classNames('bold medium grayDark')}>
                        {t('reservedParking.search.form.locationHint')}
                      </LocationHint>
                    </>
                  )}

                  <TextInput
                    {...getInputProps({
                      id: 'location',
                      onBlur: () => {
                        setTimeout(() => setShowMyLocationOption(false), 150)
                        setTimeout(() => setIsLocationInputFocused(false), 150)
                      },
                      onFocus: () => {
                        setShowMyLocationOption(true)
                        setIsLocationInputFocused(true)
                      },
                      placeholder: t(
                        'reservedParking.search.form.whereAreYouGoing'
                      ),
                      ref: inputRef,
                    })}
                  />

                  <Suggestions className="autocomplete-dropdown-container">
                    {(loading ||
                      loadingMyLocation ||
                      loadingBrowserPermission) &&
                      !showFindParkingButton && (
                        <SuggestionItem isLoading>
                          {t('reservedParking.search.form.loading')}
                        </SuggestionItem>
                      )}

                    {showMyLocationOption &&
                      !loadingMyLocation &&
                      geolocationBrowserPermission && (
                        <SuggestionItem
                          onClick={() => {
                            setTimeout(
                              () => setShowMyLocationOption(false),
                              150
                            )
                            getLots(t('reservedParking.myLocation'))
                            getUserCurrentLocation()
                          }}
                          myLocation>
                          {t('reservedParking.myLocation')}
                          <LocationIcon />
                        </SuggestionItem>
                      )}

                    {showMyLocationOption &&
                      lots?.lots?.edges.slice(0, 2).map(({ node }) => (
                        <SuggestionItem
                          key={node.id}
                          onClick={() => onBookButtonClick(node)}>
                          {node.name}
                        </SuggestionItem>
                      ))}

                    {suggestions.slice(0, 2).map(suggestion => {
                      const { formattedSuggestion, placeId } = suggestion

                      return (
                        <SuggestionItem
                          key={placeId}
                          {...getSuggestionItemProps(suggestion)}>
                          {formattedSuggestion?.mainText}
                          {formattedSuggestion?.secondaryText && (
                            <SuggestionItemSecondary>
                              {' '}
                              {formattedSuggestion?.secondaryText}
                            </SuggestionItemSecondary>
                          )}
                        </SuggestionItem>
                      )
                    })}
                  </Suggestions>

                  {showFindParkingButton && (
                    <SubmitButton
                      className={classNames('full', {
                        grayLight: isMobile,
                        normal: isMobile,
                      })}
                      onClick={onSubmitClick}>
                      {t('reservedParking.search.form.submit')}
                    </SubmitButton>
                  )}

                  {locationError && !showMyLocationOption && (
                    <LocationError
                      className={classNames('bold contrastText error', {
                        medium: isMobile,
                      })}>
                      {locationError}
                    </LocationError>
                  )}

                  {!isMobile && (
                    <LocationHint className="bold contrastText">
                      {t('reservedParking.search.form.locationHint')}
                    </LocationHint>
                  )}
                </>
              )
            }}
          </PlacesAutocomplete>
        )}
      </Wrapper>
    </form>
  )
}

HourlyParkingSearch.propTypes = {
  address: PropTypes.string,
  apolloClient: PropTypes.object,
  getUserCurrentLocation: PropTypes.func,
  inputRef: PropTypes.object,
  isLocationInputFocused: PropTypes.bool,
  loadingMyLocation: PropTypes.bool,
  locationError: PropTypes.string,
  onLocationChange: PropTypes.func,
  onLocationSelect: PropTypes.func,
  onSubmitClick: PropTypes.func,
  setIsLocationInputFocused: PropTypes.func,
}

export default HourlyParkingSearch
