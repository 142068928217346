import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import GoogleMapReact from 'google-map-react'
import classNames from 'classnames'
import isEmpty from 'lodash/isEmpty'
import isNull from 'lodash/isNull'
import styled from 'styled-components'
import { withRouter } from 'next/router'

import { getThemePath } from 'models/application'
import { isCitifydApp } from 'helpers/application'

import Marker from './Marker'
import styles from './styles'

const Component = styled.div`
  overflow: hidden;
  border: none;
  flex: 1;

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    width: 100%;
    height: 100%;
    flex: auto;
  }
`

const Map = ({
  activeMarker,
  center,
  containerStyle,
  defaultCenter,
  items,
  onChildClick,
  options,
  pointOfInterest,
  router,
  zoom,
  fitBounds,
}) => {
  const [map, setMap] = useState(null)
  const [maps, setMaps] = useState(null)

  const {
    query: { application },
  } = router

  const mapOptions = maps => ({
    ...options,
    clickableIcons: false,
    fullscreenControl: false,
    maxZoom: 18,
    overviewMapControl: false,
    streetViewControl: false,
    styles: styles,
    zoomControl: true,
    ...(options?.zoomControlOptions && {
      zoomControlOptions: {
        position: maps.ControlPosition[options.zoomControlOptions?.position],
      },
    }),
  })

  useEffect(() => {
    if (!fitBounds || isEmpty(items) || !map || !maps) {
      return
    }

    const bounds = getMapBounds([...items, pointOfInterest])

    map.fitBounds(bounds)
    bindResizeListener(map, maps, bounds)
  }, [items, pointOfInterest, fitBounds, activeMarker])

  const places = (map, maps) => {
    const places = items?.map(item => ({ lat: item.lat, lng: item.lng }))

    places?.push({ lat: pointOfInterest.lat, lng: pointOfInterest.lng })

    setMap(map)
    setMaps(maps)
  }

  // Re-center map when resizing the window
  const bindResizeListener = (map, maps, bounds) => {
    maps.event.addDomListenerOnce(map, 'idle', () => {
      maps.event.addDomListener(window, 'resize', () => {
        map.fitBounds(bounds)
      })
    })
  }

  const getMapBounds = places => {
    const bounds = new maps.LatLngBounds()

    places.forEach(place => {
      if (!isNull(place)) {
        bounds.extend(new maps.LatLng(place.lat, place.lng))
      }
    })

    return bounds
  }

  const getParkingSpotSelectedIcon = () =>
    `/static/icons/${
      !isCitifydApp(application) ? `${getThemePath(application)}/` : ''
    }maps/parking-spot-selected.svg`

  const getTargetPlaceIcon = () =>
    `/static/icons/${
      !isCitifydApp(application) ? `${getThemePath(application)}/` : ''
    }maps/target-place.svg`

  const getMarkers = () => {
    if (isEmpty(items)) {
      return null
    }

    const getMarkerDefaultIcon = index =>
      activeMarker === index
        ? getParkingSpotSelectedIcon()
        : '/static/icons/maps/parking-spot.svg'

    return items?.map((item, index) => (
      <Marker
        className={classNames({
          active: activeMarker === index,
          spot: activeMarker !== index,
        })}
        key={index}
        lat={item.lat}
        lng={item.lng}
        lotId={item.id}
        src={item?.markerImage || getMarkerDefaultIcon(index)}
        text={item?.markerText}
      />
    ))
  }

  const activeItem = useMemo(() => items?.[activeMarker], [items, activeMarker])

  return (
    <Component style={containerStyle}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.NEXT_PUBLIC_GOOGLE_API_KEY }}
        center={center || { lat: activeItem?.lat, lng: activeItem?.lng }}
        defaultCenter={
          defaultCenter || { lat: activeItem?.lat, lng: activeItem?.lng }
        }
        defaultZoom={zoom}
        onChildClick={onChildClick}
        onGoogleApiLoaded={({ map, maps }) => places(map, maps)}
        options={mapOptions}
        yesIWantToUseGoogleMapApiInternals={true}>
        {!!pointOfInterest?.lat && !!pointOfInterest?.lng && (
          <Marker
            src={getTargetPlaceIcon()}
            lat={pointOfInterest?.lat}
            lng={pointOfInterest?.lng}
            className="target"
          />
        )}
        {getMarkers()}
      </GoogleMapReact>
    </Component>
  )
}

Map.propTypes = {
  activeMarker: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  center: PropTypes.object,
  containerStyle: PropTypes.object,
  defaultCenter: PropTypes.object,
  items: PropTypes.array,
  onChildClick: PropTypes.func,
  options: PropTypes.object,
  pointOfInterest: PropTypes.object,
  router: PropTypes.object,
  zoom: PropTypes.number,
  fitBounds: PropTypes.bool,
}

Map.defaultProps = {
  activeMarker: 0,
  onChildClick: () => {},
  options: {},
  zoom: 16,
}

export default withRouter(Map)
