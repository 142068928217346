import { SET_RECEIPT_TYPE, RESET_RECEIPT, SET_RECEIPT_ACCOUNT } from './actions'

const defaultState = {
  type: null,
  account: null,
}

export const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_RECEIPT_TYPE:
      return {
        ...state,
        type: action.payload,
      }
    case SET_RECEIPT_ACCOUNT:
      return {
        ...state,
        account: action.payload,
      }
    case RESET_RECEIPT:
      return {
        ...defaultState,
      }
    default:
      return state
  }
}
