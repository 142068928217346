import SelectSearch from 'components/Atoms/SelectSearch'

import CountryInputTemplate from './inputTemplate'
import CountrySuggestionTemplate from './suggestionTemplate'

const CountrySelectSearch = props => (
  <SelectSearch
    {...props}
    SuggestionTemplate={CountrySuggestionTemplate}
    InputTemplate={CountryInputTemplate}
  />
)

export default CountrySelectSearch
