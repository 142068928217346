import React from 'react'
import PropTypes from 'prop-types'

import LotDetails from 'components/Molecules/Monthly/LotDetails'

import WhiteMode from '../WhiteMode'

const MonthlyLotDetails = ({ lot, hidePlans }) => (
  <WhiteMode title={lot?.name}>
    <LotDetails
      hidePlans={hidePlans}
      isModal
      lot={lot}
      shouldUseLocation={false}
    />
  </WhiteMode>
)

MonthlyLotDetails.propTypes = {
  hidePlans: PropTypes.bool,
  lot: PropTypes.object,
}

export default MonthlyLotDetails
