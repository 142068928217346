import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import styled from 'styled-components'

import Text from 'components/Atoms/Text'

const Hint = styled(Text)`
  margin-top: 11px;
`

const FieldHint = ({ hint }) => {
  if (isEmpty(hint)) {
    return null
  }

  return <Hint className={'small'}>{hint}</Hint>
}

FieldHint.propTypes = {
  hint: PropTypes.string,
}

export default FieldHint
