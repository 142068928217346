import styled from 'styled-components'
import PropTypes from 'prop-types'

const List = styled.ul`
  width: 100%;
  background-color: ${props => props.theme.palette.background};
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border: 1px solid ${props => props.theme.palette.background};
  overflow: hidden;
`

const ListItem = styled.li`
  width: 100%;
  padding: 9px 14px;
  cursor: pointer;
  background-color: ${props => props.theme.palette.grayLight};
  margin-bottom: 2px;
  height: 50px;
  align-items: center;
  display: flex;
  transition: 0.2s;
  font-size: ${props => props.theme.font.size.desktop.small};
  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.md}rem) {
    font-size: ${props => props.theme.font.size.desktop.regular};
    padding: 11px 17px;
  }
  &:hover,
  &.active {
    background-color: ${props => props.theme.palette.primary};
    color: ${props => props.theme.palette.white};
  }
  &:last-of-type {
    margin-bottom: 0;
  }
`

const Component = ({ items, value, onChange }) => (
  <List>
    {items.map((item, key) => (
      <ListItem
        className={item.label === value && 'active'}
        onClick={() => item.label !== value && onChange(item)}
        key={key}>
        {item.label}
        {item.icon}
      </ListItem>
    ))}
  </List>
)

Component.propTypes = {
  items: PropTypes.array,
  onChange: PropTypes.func,
  value: PropTypes.string,
}

export default Component
