import { useEffect, useState } from 'react'

var gmapsLoaded = false
var loadingStarted = false

const useGoogleMaps = () => {
  const [isGmapsLoaded, setIsGmapsLoaded] = useState(gmapsLoaded)
  const initMap = () => {
    gmapsLoaded = true
    setIsGmapsLoaded(true)
  }

  useEffect(() => {
    if (loadingStarted) {
      return
    }

    loadingStarted = true
    window.initMap = initMap
    const script = document.createElement('script')

    script.async = true
    script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.NEXT_PUBLIC_GOOGLE_API_KEY}&libraries=places&callback=initMap`
    document.head.appendChild(script)
  }, [])

  return { gmapsLoaded: gmapsLoaded || isGmapsLoaded }
}

export default useGoogleMaps
