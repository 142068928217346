import { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import classNames from 'classnames'

import NavItemComponent from 'components/Molecules/Menu/NavItem'
import ItemLinkComponent from 'components/Molecules/Menu/ItemLink'

const Container = styled.div`
  outline: 0;
`

const DropdownItemLink = styled(ItemLinkComponent)`
  &:after {
    display: inline-block;
    width: 0;
    height: 0;
    content: '';
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
    margin-left: 0.255em;
    vertical-align: 0.255em;
  }

  &.active,
  &:hover {
    color: ${props => props.theme.palette.grayDark};
    background-color: ${props => props.theme.palette.highlight};
    cursor: pointer;
  }
`

const Dropdown = styled.div`
  display: none;
  position: static;
  background-color: ${props => props.theme.palette.grayDark};
  color: ${props => props.theme.palette.white};
  float: none;
  background-clip: border-box;
  text-align: left;
  list-style: none;

  &.show {
    display: block;
    min-width: 10rem;
    margin-top: 10px;
  }

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.md}rem) {
    position: absolute;
    background-color: ${props => props.theme.palette.white};
    color: ${props => props.theme.palette.grayDark};
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    margin-top: 0px;

    &.show {
      top: 100%;
      left: 0;
      z-index: 1000;
      padding: 8px 0;
      margin: 2px 0 0;
    }
  }
`

const MenuDropDown = ({ active, children, text }) => {
  const [isVisible, setIsVisible] = useState(false)

  const handleOnClick = () => {
    setIsVisible(!isVisible)
  }

  return (
    <NavItemComponent>
      <Container tabIndex="1" onBlur={handleOnClick} onClick={handleOnClick}>
        <DropdownItemLink
          className={classNames({ active: active || isVisible })}>
          {text}
        </DropdownItemLink>
        <Dropdown className={classNames({ show: isVisible })}>
          {children}
        </Dropdown>
      </Container>
    </NavItemComponent>
  )
}

MenuDropDown.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.arrayOf(PropTypes.element),
  text: PropTypes.string,
}

export default MenuDropDown
