const Reclaim = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="35"
    height="35"
    viewBox="0 0 35 35">
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g>
            <g>
              <g fill="#FFF" fillRule="nonzero">
                <path
                  d="M23.017 0v22.016h-3.003l-.334-.55c-1.953-3.198-5.357-4.328-7.506-4.728l1.595 5.273h-7.08L5.04 16.563c-2.774-.41-4.927-2.657-5.036-5.386L0 10.96c0-3.049 2.553-5.55 5.725-5.665l.228-.004h5.567c.366-.088.791-.208 1.325-.402 1.676-.61 4.028-1.82 6.698-4.267l.321-.3.342-.322h2.81z"
                  transform="translate(-38 -234) translate(18 215) translate(20 19) translate(1.75 6.125)"
                />
              </g>
              <path
                stroke="#FFF"
                strokeWidth="2"
                d="M24.818 13.443c1.142 0 2.068-.926 2.068-2.068s-.926-2.068-2.068-2.068"
                transform="translate(-38 -234) translate(18 215) translate(20 19) translate(1.75 6.125)"
              />
              <path
                stroke="#FFF"
                strokeWidth="2"
                d="M24.818 17.58c3.427 0 6.205-2.778 6.205-6.205S28.245 5.17 24.818 5.17"
                transform="translate(-38 -234) translate(18 215) translate(20 19) translate(1.75 6.125)"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default Reclaim
