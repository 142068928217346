import styled from 'styled-components'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import Preview from 'components/Organisms/Lot/Preview'

const Component = styled.div`
  display: ${props => (props.show ? 'block' : 'none')};
`

const LotsPreview = props => {
  const [showPreview, setShowPreview] = useState(false)

  useEffect(() => {
    setTimeout(() => setShowPreview(true), 500)
  }, [])

  return (
    <Component show={showPreview}>
      <Preview {...props} showPreview={showPreview} />
    </Component>
  )
}

LotsPreview.propTypes = {
  modalCallback: PropTypes.func,
}

export default LotsPreview
