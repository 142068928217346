import styled from 'styled-components'
import PropTypes from 'prop-types'

import { Trans } from 'i18n-web/i18next'
import { Checkbox } from 'components/Molecules/Form'
import Text from 'components/Atoms/Text'

const TermsLink = styled(Text)`
  display: inline-block;
  font-size: ${props => props.theme.font.size.mobile.tiny};
  text-decoration: underline;

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    font-size: ${props => props.theme.font.size.desktop.small};
  }
`

const Screen = ({
  onModalTermsClick,
  onTermsClick,
  checked,
  termsAndConditionsError,
  t,
}) => (
  <Checkbox
    id="termsAndConditions"
    name="acceptedTerms"
    checked={checked}
    error={termsAndConditionsError ? t('purchase.form.terms.error') : false}
    onClick={e => onTermsClick(e.target.checked)}
    label={
      <Trans i18nKey="purchase.form.terms.label">
        <TermsLink
          as="strong"
          className="link bold"
          onClick={() => onModalTermsClick()}
          target="_blank"
        />
      </Trans>
    }
    value="agree"
  />
)

Screen.propTypes = {
  t: PropTypes.func,
  onModalTermsClick: PropTypes.func,
  onTermsClick: PropTypes.func,
  checked: PropTypes.bool,
  termsAndConditionsError: PropTypes.bool,
}

export default Screen
