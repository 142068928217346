import styled from 'styled-components'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { useTranslation } from 'i18n-web/i18next'
import Text from 'components/Atoms/Text'
import Label from 'components/Atoms/Label'
import PhoneInput from 'components/Atoms/PhoneInput'

const Error = styled(Text)`
  margin-left: 64px;

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    margin-left: 84px;
  }
`

const Screen = ({
  onFieldChange,
  onBlur,
  onFocus,
  focused,
  isValid,
  isLoading,
  disabled,
  value,
  hideLabel,
  country,
  phoneNumberRef,
  submitOnKeyUp,
}) => {
  const { t } = useTranslation()

  return (
    <div>
      {!hideLabel && <Label>{t('purchase.form.phoneNumber.label')}</Label>}
      <PhoneInput
        country={country}
        inputRef={phoneNumberRef}
        onFocus={onFocus}
        onBlur={onBlur}
        onKeyUp={submitOnKeyUp}
        disabled={isLoading || disabled}
        onFieldChange={onFieldChange}
        value={value}
        className={classNames({
          error: !isValid && !focused,
        })}
      />
      {!isValid && !focused && (
        <Error className="tiny error">
          {t('purchase.form.phoneNumber.error')}
        </Error>
      )}
    </div>
  )
}

Screen.propTypes = {
  onFieldChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  submitOnKeyUp: PropTypes.func,
  focused: PropTypes.bool,
  country: PropTypes.string,
  isLoading: PropTypes.bool,
  hideLabel: PropTypes.bool,
  disabled: PropTypes.bool,
  isValid: PropTypes.bool,
  value: PropTypes.string,
  phoneNumberRef: PropTypes.object,
}

export default Screen
