import React from 'react'
import styled from 'styled-components'

import ChangePasswordForm from 'components/Molecules/MyAccount/ChangePasswordForm'
import PageTitle from 'components/Atoms/PageTitle'
import { useTranslation } from 'i18n-web/i18next'

const Component = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    flex: 1;
    height: 100%;
    padding: 0;
  }
`

const Content = styled.div`
  margin: 20px 0;

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    margin-left: 35px;
    margin-right: 35px;
  }
`

const Divisor = styled.div`
  margin-top: 20px;
  border-top: 1px solid ${props => props.theme.palette.gray}80;
  display: none;

  &.title {
    margin-top: 0;
  }

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    display: block;
  }
`

const ChangePassword = () => {
  const { t } = useTranslation()

  return (
    <Component>
      <PageTitle
        modal
        noBorder
        shadow
        showBackButton={true}
        title={t('myAccount.settings.changePassword.cardTitle')}
      />
      <Divisor />
      <Content>
        <ChangePasswordForm />
      </Content>
    </Component>
  )
}

export default ChangePassword
