import React from 'react'
import PropTypes from 'prop-types'
import styled, { keyframes } from 'styled-components'
import { Col, Row, Grid } from 'react-styled-flexboxgrid'
import { useSelector } from 'react-redux'
import dynamic from 'next/dynamic'

import { getImpersonateUserId } from 'redux-web/utils/session/actions'
import BackButton from 'components/Atoms/BackButton'
import Backdrop from 'components/Atoms/Backdrop'
import WithTemplate from 'hocs/withTemplate'
import { Header, Footer } from 'components/Organisms'

const ImpersonateUserAlert = dynamic(
  () => import('components/Atoms/ImpersonateUserAlert'),
  { ssr: false }
)

const Component = styled.div`
  ${props => props.customStyle}
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  .main {
    margin-top: 15px;
  }

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    .main {
      margin-top: 30px;
      &:first-of-type {
        margin-top: 40px;
      }
    }
  }
`

const slideUpFadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translate(0px, 20px);
  }
  100% {
    opacity: 1;
    transform: translate(0px, 0px);
  }
`

const Slide = styled.div`
  padding-top: ${props => (props.spacingTop ? '30px' : 0)};
  padding-bottom: 45px;
  flex: 1 0 auto;
  background-color: ${props => props.theme.palette.background};
  animation: ${slideUpFadeIn} ease 0.6s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  transform-origin: 50% 50%;
  opacity: 0;
`

const GridComponent = styled(Grid)`
  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    position: relative;
  }
`

const MainCol = styled(Col)`
  @media print {
    width: 100%;
  }
`

const Template = ({
  authLoading,
  children,
  customStyle,
  hideFooter,
  hideHeader,
  hideMenu,
  hideLanguageSelector,
  loading,
  noBackButtonBorder,
  onBackButton,
  onLogoutRedirection,
  showBackButton,
  spacingTop,
  application,
  className,
}) => {
  const disableHeaderAndFooter = useSelector(
    state => state.settings.disableHeaderAndFooter
  )
  const impersonateUserId = getImpersonateUserId()

  return (
    <Component customStyle={customStyle} className={className}>
      {impersonateUserId && <ImpersonateUserAlert />}
      {!hideHeader && !disableHeaderAndFooter && (
        <Header
          hideLanguageSelector={
            hideLanguageSelector ||
            process.env.NEXT_PUBLIC_HIDE_LANGUAGE_SELECTOR
          }
          onLogoutRedirection={onLogoutRedirection}
          loading={loading}
          hideMenu={hideMenu}
        />
      )}
      <Slide spacingTop={spacingTop}>
        <GridComponent>
          {showBackButton && (
            <Row className="main" start="xs">
              <Col xs={12}>
                <BackButton
                  noBorder={noBackButtonBorder}
                  onBackButton={onBackButton ? () => onBackButton() : null}
                />
              </Col>
            </Row>
          )}
          <Row center="xs" className="main">
            <MainCol xs={12}>{children}</MainCol>
          </Row>
        </GridComponent>
      </Slide>
      {!hideFooter && !disableHeaderAndFooter && <Footer />}
      {(loading || authLoading) && <Backdrop application={application} />}
    </Component>
  )
}

Template.propTypes = {
  application: PropTypes.string,
  authLoading: PropTypes.bool,
  children: PropTypes.node,
  customStyle: PropTypes.object,
  hideFooter: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  hideHeader: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  hideMenu: PropTypes.bool,
  loading: PropTypes.bool,
  noBackButtonBorder: PropTypes.bool,
  onBackButton: PropTypes.func,
  onLogoutRedirection: PropTypes.string,
  showBackButton: PropTypes.bool,
  spacingTop: PropTypes.bool,
  hideLanguageSelector: PropTypes.bool,
  className: PropTypes.string,
}

Template.defaultProps = {
  hideFooter: false,
  hideHeader: false,
  hideMenu: false,
  logoImage: undefined,
  noBackButtonBorder: false,
  spacingTop: false,
  hideLanguageSelector: true,
}

export default WithTemplate(Template)
