import styled from 'styled-components'
import { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRouter } from 'next/router'

import { useTranslation } from 'i18n-web/i18next'
import API from 'api'
import Text from 'components/Atoms/Text'
import Loading from 'components/Atoms/Loading'
import { setUser, setOriginalUser } from 'redux-web/utils/user/actions'
import PageTitle from 'components/Atoms/PageTitle'
import BasicTextField from 'components/Molecules/Form/BasicTextField'
import { convertToQueryString } from 'helpers'
import { formatPhoneNumber } from 'helpers/phoneFormat'
import { setImpersonateUserId } from 'redux-web/utils/session/actions'
const Component = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    flex: 1;
    height: 100%;
    padding: 0;
  }
`

const NoResults = styled(Text)`
  margin-top: 20px;
`

const Item = styled.div`
  display: flex;
  height: 40px;
  padding: 0 10px;
  border-bottom: 1px solid ${props => props.theme.palette.grayLight};
  cursor: pointer;
  &:hover {
    background-color: ${props => props.theme.palette.grayLighter};
  }
  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    font-size: ${props => props.theme.font.size.desktop.medium};
  }
`

const TextItem = styled(Text)`
  line-height: 40px;
  width: 26%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: ${props => props.theme.font.size.mobile.small};

  &.email {
    width: 42%;
    margin-left: 3%;
  }
  &.phoneNumber {
    width: 27%;
    margin-left: 3%;
    text-align: right;
  }
`

const Results = styled.div`
  margin-top: 20px;
  overflow: auto;

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    max-height: 300px;
  }
`

const LoadingWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
`

const Content = styled.div`
  margin: 20px 0;

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    margin-left: 15px;
    margin-right: 15px;
  }
`

const Divisor = styled.div`
  margin-top: 20px;
  border-top: 2px solid ${props => props.theme.palette.gray};
  display: none;

  &.title {
    margin-top: 0;
  }

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    display: block;
    margin-top: 12px;
    margin-bottom: 10px;
  }
`

const ImpersonateUser = () => {
  const [term, setTerm] = useState('')
  const [results, setResults] = useState()
  const user = useSelector(state => state.user.me)
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()
  const router = useRouter()
  const dispatch = useDispatch()
  let timer = useRef(null)

  const onItemSelected = item => {
    dispatch(setOriginalUser(user))
    setImpersonateUserId(item.id)
    dispatch(setUser(item))
    router.reload()
  }

  useEffect(() => {
    if (timer) {
      clearTimeout(timer.current)
    }

    if (term === '') {
      setResults(term)

      return
    }

    timer.current = setTimeout(() => {
      setLoading(true)
      API()
        .getUsers(convertToQueryString({ search: term.trim() }))
        .then(resp => setResults(resp.users))
        .finally(() => setLoading(false))
    }, 500)
  }, [term])

  return (
    <Component>
      <PageTitle
        modal
        noBorder
        showBackButton={true}
        shadow
        title={t('impersonateUser.title')}
      />
      <Divisor />
      <Content>
        <BasicTextField
          label={t('impersonateUser.subtitle')}
          name={'parkingCode'}
          validateOnFocus={true}
          value={term}
          onChange={value => setTerm(value)}
        />
        {loading && (
          <LoadingWrapper>
            <Loading />
          </LoadingWrapper>
        )}
        {!loading && typeof results === 'object' && results.length === 0 && (
          <NoResults className="center medium semiBold">
            No results found.
          </NoResults>
        )}
        {!loading && results?.length > 0 && (
          <Results>
            <Item>
              <TextItem className="bold">Name</TextItem>
              <TextItem className="bold email">Email</TextItem>
              <TextItem className="bold phoneNumber">Phone number</TextItem>
            </Item>
            {results?.map(item => (
              <Item key={item.id} onClick={() => onItemSelected(item)}>
                <TextItem title={item.name}>{item.name}</TextItem>
                <TextItem title={item.email} className="email">
                  {item.email}
                </TextItem>
                <TextItem
                  title={formatPhoneNumber(
                    item.phoneNumber,
                    item.phoneCountryCode
                  )}
                  className="phoneNumber">
                  {formatPhoneNumber(item.phoneNumber, item.phoneCountryCode)}
                </TextItem>
              </Item>
            ))}
          </Results>
        )}
      </Content>
    </Component>
  )
}

export default ImpersonateUser
