import { withRouter } from 'next/router'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import API from 'api'
import { hideModal } from 'components/Atoms/Modal/actions'
import { showModal } from 'components/Atoms/Modal/actions'
import { useTranslation } from 'i18n-web/i18next'
import { showBasicModal } from 'helpers/modal'

import VerifyPhoneNumberForm from './Form'

const FormikHOC = props => {
  const {
    countryCode,
    description,
    email,
    isGuestLogin,
    isPasswordChange,
    isVerifyPhoneNumber,
    modalCallback,
    phoneNumber,
    preventClose,
    redirectTo,
    router,
    type,
  } = props

  const {
    query: { application },
  } = router

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const isSDK = useSelector(state => state.settings.isSDK)
  const receiptFlow = useSelector(state => state.receipt.account)
  const initialValues = {
    code: '',
  }

  const handleSubmit = (values, { setSubmitting, setStatus }) => {
    setSubmitting(true)
    if (isVerifyPhoneNumber || isGuestLogin)
      verifyPhoneNumber(values.code, setStatus, setSubmitting)

    if (isPasswordChange)
      sendChangePasswordCode(values.code, setStatus, setSubmitting)
  }

  const verifyPhoneNumber = (code, setStatus, setSubmitting) => {
    API()
      .verifyPhoneNumber(countryCode, phoneNumber, {
        ...(isSDK && { sdk: true }),
        timeoutTryAgainMessage: true,
        body: JSON.stringify({
          code,
        }),
      })
      .then(resp => {
        if (resp.success) {
          modalCallback(resp.success.token)
          dispatch(hideModal())
        } else if (resp.error) {
          setSubmitting(false)
          setStatus({ message: resp.error && resp.error.message })
          if (resp.error?.code === 408) {
            dispatch(
              showBasicModal({
                title: resp.error.message,
              })
            )
          }
        }
      })
      .catch(err => {
        setSubmitting(false)
        setStatus({ message: err })
      })
  }

  const sendChangePasswordCode = (code, setStatus, setSubmitting) => {
    let queryString

    const hasPhoneNumber = phoneNumber && !email
    const hasEmail = !hasPhoneNumber

    if (hasPhoneNumber) {
      queryString = `phoneCountryCode=${countryCode}&phoneNumber=${phoneNumber}`
    } else if (hasEmail) {
      queryString = `email=${email}`
    }

    API()
      .sendChangePasswordCode(code, queryString, {
        ...(isSDK && { sdk: true }),
      })
      .then(res => {
        setSubmitting(false)

        if (res?.error) {
          setStatus({ message: res?.error?.message })
        } else {
          dispatch(
            showModal({
              childProps: {
                preventClose: preventClose,
                ...(redirectTo && { redirectTo: redirectTo }),
                ...(hasPhoneNumber && { countryCode }),
                ...(hasPhoneNumber && { phoneNumber }),
                ...(hasEmail && { email }),
                code,
              },
              fullScreen: true,
              modalProps: {
                className: 'noBorders',
                open: true,
                ...(Boolean(receiptFlow) && {
                  onCloseModal: () => {
                    dispatch(
                      showModal({
                        childProps: {
                          ...(redirectTo && { redirectTo: redirectTo }),
                          ...(hasPhoneNumber && { countryCode }),
                          ...(hasPhoneNumber && { phoneNumber }),
                          ...(hasEmail && { email }),
                          description: description,
                          type: type,
                        },
                        fullScreen: false,
                        modalProps: {
                          open: true,
                          preventClose: preventClose,
                        },
                        modalType: 'verifyPhoneNumber',
                      })
                    )
                  },
                }),
              },
              modalType: 'changePassword',
            })
          )
        }
      })
      .catch(err => {
        setSubmitting(false)
        setStatus({ message: err })
      })
  }

  return (
    <VerifyPhoneNumberForm
      {...props}
      application={application}
      handleSubmit={handleSubmit}
      initialValues={initialValues}
      t={t}
    />
  )
}

FormikHOC.propTypes = {
  countryCode: PropTypes.string,
  description: PropTypes.string,
  email: PropTypes.string,
  isGuestLogin: PropTypes.bool,
  isPasswordChange: PropTypes.bool,
  isVerifyPhoneNumber: PropTypes.bool,
  modalCallback: PropTypes.func,
  phoneNumber: PropTypes.string,
  preventClose: PropTypes.bool,
  redirectTo: PropTypes.string,
  router: PropTypes.object,
  type: PropTypes.string,
}

export default withRouter(FormikHOC)
