import { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Elements } from '@stripe/react-stripe-js'

import { useTranslation } from 'i18n-web/i18next'
import PageTitle from 'components/Atoms/PageTitle'
import CreditCardForm from 'components/Molecules/MyAccount/Cards/CreditCardForm'
import Backdrop from 'components/Atoms/Backdrop'

const Loading = styled(Backdrop)`
  top: 0px;
  left: 0px;
  background: rgba(255, 255, 255, 0.5);
`

const Divisor = styled.div`
  margin-top: 18px;
  border-top: 2px solid ${props => props.theme.palette.grayDard};
  display: none;

  &.title {
    margin-top: 0;
  }

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    display: block;
  }
`

const AddCardModal = ({ onSubmit, stripeKey }) => {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)

  return (
    <>
      <PageTitle
        modal
        noBorder
        shadow
        showBackButton={true}
        title={t('myAccount.cards.modal.addCard.title')}
      />
      <Divisor />
      <Elements stripe={stripeKey}>
        <CreditCardForm onSubmit={onSubmit} setIsLoading={setIsLoading} />
      </Elements>

      {isLoading && <Loading />}
    </>
  )
}

AddCardModal.propTypes = {
  onSubmit: PropTypes.func,
  stripeKey: PropTypes.object,
}

export default AddCardModal
