import * as Yup from 'yup'

import { i18n } from 'i18n-web/i18next'

const validationSchema = () =>
  Yup.object().shape({
    countryCode: Yup.string().required(i18n.t('common.form.error.required')),
  })

export default validationSchema
