import isEmpty from 'lodash/isEmpty'

export const CLEAR_MONTHLY_FILTERS = 'CLEAR_MONTHLY_FILTERS'
export const RESET_MONTHLY_PARKING = 'RESET_MONTHLY_PARKING'
export const SET_MONTHLY_ACTIVE_LOT = 'SET_MONTHLY_ACTIVE_LOT'
export const SET_MONTHLY_ACTIVE_PIN = 'SET_MONTHLY_ACTIVE_PIN'
export const SET_MONTHLY_AVAILABLE_FILTERS = 'SET_MONTHLY_AVAILABLE_FILTERS'
export const SET_MONTHLY_ERROR = 'SET_MONTHLY_ERROR'
export const SET_MONTHLY_FILTERS = 'SET_MONTHLY_FILTERS'
export const SET_MONTHLY_LOADING = 'SET_MONTHLY_LOADING'
export const SET_MONTHLY_LOCATION = 'SET_MONTHLY_LOCATION'
export const SET_MONTHLY_LOT = 'SET_MONTHLY_LOT'
export const SET_MONTHLY_LOTS = 'SET_MONTHLY_LOTS'
export const SET_MONTHLY_LICENSE_PLATE = 'SET_MONTHLY_LICENSE_PLATE'
export const SET_MONTHLY_PASS = 'SET_MONTHLY_PASS'
export const SET_MONTHLY_PASSES = 'SET_MONTHLY_PASSES'
export const SET_MONTHLY_PLAN_DATA = 'SET_MONTHLY_PLAN_DATA'
export const SET_MONTHLY_RADIUS = 'SET_MONTHLY_RADIUS'
export const SET_MONTHLY_RESULTS_COUNT = 'SET_MONTHLY_RESULTS_COUNT'
export const SET_MONTHLY_SELECTED_VEHICLE = 'SET_MONTHLY_SELECTED_VEHICLE'
export const SET_MONTHLY_SELECTED_CARD = 'SET_MONTHLY_SELECTED_CARD'
export const SET_MONTHLY_SHOW_SEARCH = 'SET_MONTHLY_SHOW_SEARCH'
export const SET_MONTHLY_SUBSCRIPTION_DATA = 'SET_MONTHLY_SUBSCRIPTION_DATA'

export const setLocation = location => async dispatch => {
  dispatch({
    type: SET_MONTHLY_LOCATION,
    payload: location,
  })
}

export const setRadius = radius => dispatch =>
  dispatch({
    type: SET_MONTHLY_RADIUS,
    payload: radius,
  })

export const setAvailableFilters = filters => dispatch =>
  dispatch({
    type: SET_MONTHLY_AVAILABLE_FILTERS,
    payload: filters,
  })

export const setFilters = filter => dispatch => {
  if (isEmpty(filter)) {
    return dispatch({
      type: CLEAR_MONTHLY_FILTERS,
    })
  }

  return dispatch({
    type: SET_MONTHLY_FILTERS,
    payload: filter,
  })
}

export const setLots = lots => dispatch =>
  dispatch({
    type: SET_MONTHLY_LOTS,
    payload: lots,
  })

export const setLot = lot => dispatch =>
  dispatch({
    type: SET_MONTHLY_LOT,
    payload: lot,
  })

export const setPlanData = plan => dispatch =>
  dispatch({
    type: SET_MONTHLY_PLAN_DATA,
    payload: plan,
  })

export const setSubscriptionData = subscription => dispatch =>
  dispatch({
    type: SET_MONTHLY_SUBSCRIPTION_DATA,
    payload: subscription,
  })

export const setResultsCount = count => dispatch =>
  dispatch({
    type: SET_MONTHLY_RESULTS_COUNT,
    payload: count,
  })

export const setActiveLot = id => dispatch =>
  dispatch({
    type: SET_MONTHLY_ACTIVE_LOT,
    payload: id,
  })

export const setActivePin = id => dispatch =>
  dispatch({
    type: SET_MONTHLY_ACTIVE_PIN,
    payload: id,
  })

export const resetMonthlyParking = () => dispatch =>
  dispatch({
    type: RESET_MONTHLY_PARKING,
  })

export const setPass = pass => dispatch =>
  dispatch({
    type: SET_MONTHLY_PASS,
    payload: pass,
  })

export const setSelectedVehicle = vehicle => dispatch =>
  dispatch({
    type: SET_MONTHLY_SELECTED_VEHICLE,
    payload: vehicle,
  })

export const setSelectedCard = card => dispatch =>
  dispatch({
    type: SET_MONTHLY_SELECTED_CARD,
    payload: card,
  })

export const setLicensePlate = licensePlate => dispatch =>
  dispatch({
    type: SET_MONTHLY_LICENSE_PLATE,
    payload: licensePlate,
  })

export const setShowSearch = pass => dispatch =>
  dispatch({
    type: SET_MONTHLY_SHOW_SEARCH,
    payload: pass,
  })

export const setLoading = loading => dispatch =>
  dispatch({
    type: SET_MONTHLY_LOADING,
    payload: loading,
  })

export const setError = error => dispatch =>
  dispatch({
    type: SET_MONTHLY_ERROR,
    payload: error,
  })
