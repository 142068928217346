import styled from 'styled-components'
import PropTypes from 'prop-types'

import Text from 'components/Atoms/Text'
import { SendHourlyPassToEmailForm } from 'components/Molecules'
import Button from 'components/Atoms/Button'

const Component = styled.div`
  text-align: center;
  min-height: 130px;
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.palette.white};
  border-radius: 6px;
`
const Description = styled(Text)`
  margin: 20px 20px 0;
  font-size: ${props => props.theme.font.size.mobile.medium};

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    font-size: ${props => props.theme.font.size.desktop.regular};
  }
`

const TitleBox = styled.div`
  flex: 1;
  display: flex;
  padding: 12px 20px;
  align-items: center;
  justify-content: center;
  border: solid 3px ${props => props.theme.palette.white};
  border-bottom: none;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  background-color: ${props => props.theme.palette.primary};

  &.error {
    background-color: ${props => props.theme.palette.error};
  }

  &.facebook {
    background-color: ${props => props.theme.palette.facebookBlue};
  }
`

const Title = styled(Text)`
  font-size: ${props => props.theme.font.size.mobile.h4};
  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    font-size: ${props => props.theme.font.size.mobile.h3};
  }
`

const ActionContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${props =>
    props.multipleButtons ? 'space-between' : 'center'};
  height: 50px;
  line-height: 40px;
  margin-top: 25px;
  border-top: 1px solid ${props => props.theme.palette.grayDark}30;
`

const ActionContentButton = styled(Button)`
  flex: 1;
  height: 100%;

  &:not(:first-child) {
    border-left: solid 1px ${props => props.theme.palette.grayDark}30;
  }
`
const SendHourlyPassToEmail = ({ application, onCancelLink, t }) => (
  <Component>
    <TitleBox>
      <Title className="center contrastText bold">
        {t('modals.sendGuestEmail.title')}
      </Title>
    </TitleBox>
    <Description>{t('modals.sendGuestEmail.description')}</Description>
    <SendHourlyPassToEmailForm application={application} />
    <ActionContent>
      <ActionContentButton
        className="transparent noBorders semiBold"
        onClick={() => onCancelLink()}>
        {t('modals.sendGuestEmail.button')}
      </ActionContentButton>
    </ActionContent>
  </Component>
)

SendHourlyPassToEmail.propTypes = {
  t: PropTypes.func,
  onCancelLink: PropTypes.func,
  application: PropTypes.string,
}

export default SendHourlyPassToEmail
