import * as Sentry from '@sentry/nextjs'

import {
  getAccessToken,
  getConversionId,
  getImpersonateUserId,
} from 'redux-web/utils/session/actions'
import { i18n } from 'i18n-web/i18next'
import {
  reservationTokenWhitelist,
  fetchWithTimeout,
  heathCheckAPI,
} from 'api/utils'
import { getFetchSettingsError } from 'redux-web/utils/settings/actions'

import Info from '../info'

const isJSON = response => {
  const contentType = response.headers.get('content-type')

  return contentType && contentType.indexOf('application/json') !== -1
}

const fetchAPI = (url, options = [], method = 'GET') => {
  const baseURL = process.env.NEXT_PUBLIC_API_URL
  const disableAccessToken = options.disableAccessToken || false

  const accessToken =
    options.accessToken || getAccessToken() || reservationTokenWhitelist(url)
  const impersonateUserId = getImpersonateUserId()
  const conversionId = getConversionId()

  if (accessToken && !disableAccessToken) {
    if (options.headers) {
      options.headers.Authorization = `Bearer ${accessToken}`
    } else {
      options.headers = {
        Authorization: `Bearer ${accessToken}`,
        ...(impersonateUserId && { 'Impersonate-user-id': impersonateUserId }),
      }
    }
  }

  const fetchOptions = {
    ...options,
    method: method,
    mode: 'cors',
    cache: 'no-store',
    headers: {
      ...(options.sdk && { 'Citifyd-web-sdk-version': Info.sdkVersion }),
      'Citifyd-app-version': `Web ${Info.version}`,
      'Content-Type': 'application/json',
      'Citifyd-accept-language': i18n.language,
      ...(conversionId && { 'Citifyd-Conversion-Id': conversionId }),
      ...options.headers,
    },
  }

  return new Promise((resolve, reject) => {
    fetchWithTimeout(
      new URL(url, baseURL),
      { ...fetchOptions },
      options.timeout
    )
      .then(async response => {
        if (options.isBlob) {
          return resolve(response.blob())
        }
        if (response.ok) {
          return resolve(isJSON(response) ? response.json() : response.text())
        }
        if (isJSON(response)) {
          if (response?.status !== 408) {
            Sentry.captureMessage(
              `API Error ${response?.status} - ${
                response?.url || baseURL + url
              }`
            )
          } else {
            Sentry.captureMessage(
              `Timeout Error ${response?.status} - ${baseURL + url}`
            )
          }

          return resolve(response.json())
        }

        const message = await response.text()

        Sentry.captureMessage(
          `API Error ${response?.status} - ${response?.url}`
        )

        return resolve({
          error: {
            type: response.statusText,
            code: response.status,
            message,
          },
        })
      })
      .catch(error => {
        if (getFetchSettingsError() === null) {
          heathCheckAPI(fetchOptions).finally(() => {
            reject(error)
          })
        }
      })
  })
}

const API = () => {
  const createAccount = payload => fetchAPI('users', payload, 'POST')
  const getNow = payload => fetchAPI('now', payload)

  // Auth
  const getPhoneNumber = (countryCode, phoneNumber, payload) =>
    fetchAPI(`phone-numbers/${countryCode?.toLowerCase()}/${phoneNumber}`, {
      disableAccessToken: true,
      ...payload,
    })
  const login = payload => fetchAPI('login', payload, 'POST')
  const resetPassword = payload => fetchAPI('password', payload, 'POST')
  const passwordRules = payload => fetchAPI('password-rules', payload)
  const sendPhoneVerification = payload =>
    fetchAPI('verify/send', payload, 'POST')
  const verifyPhoneNumber = (countryCode, phoneNumber, payload) =>
    fetchAPI(
      `phone-numbers/${countryCode}/${phoneNumber}/verify`,
      payload,
      'POST'
    )
  const changePassword = payload => fetchAPI('password', payload, 'PUT')
  const sendChangePasswordCode = (code, queryString, payload) =>
    fetchAPI(`password/codes/${code}?${queryString}`, payload)
  const unlinkPhoneApple = (countryCode, phoneNumber, payload) =>
    fetchAPI(
      `phone-numbers/${countryCode}/${phoneNumber}/apple-unlink`,
      payload,
      'POST'
    )
  const getPhoneNumberAppleInfo = (
    countryCode,
    phoneNumber,
    phoneVerificationToken
  ) =>
    fetchAPI(
      `phone-numbers/${countryCode}/${phoneNumber}/apple-info?phoneVerificationToken=${phoneVerificationToken}`
    )
  const unlinkPhoneFacebook = (countryCode, phoneNumber, payload) =>
    fetchAPI(
      `phone-numbers/${countryCode}/${phoneNumber}/facebook-unlink`,
      payload,
      'POST'
    )
  const getPhoneNumberFacebookInfo = (
    countryCode,
    phoneNumber,
    phoneVerificationToken
  ) =>
    fetchAPI(
      `phone-numbers/${countryCode}/${phoneNumber}/facebook-info?phoneVerificationToken=${phoneVerificationToken}`
    )

  // User
  const getMe = payload => fetchAPI('me', payload)
  const putMe = payload => fetchAPI('me', payload, 'PUT')
  const getUserCredits = payload => fetchAPI('me/credits-left', payload)
  const magicLink = payload => fetchAPI('me/magic-links', payload, 'POST')
  const addCard = payload => fetchAPI('me/cards', payload, 'POST')
  const deleteCard = (id, payload) =>
    fetchAPI(`me/cards/${id}`, payload, 'DELETE')
  const editCard = (id, payload) => fetchAPI(`me/cards/${id}`, payload, 'PUT')
  const hash = payload => fetchAPI('me/hash', payload)

  // My Account
  const createVehicle = payload => fetchAPI('me/vehicles', payload, 'POST')
  const editVehicle = (id, payload) =>
    fetchAPI(`me/vehicles/${id}`, payload, 'PUT')
  const deleteVehicle = id => fetchAPI(`me/vehicles/${id}`, {}, 'DELETE')
  const validateUserNewPhoneNumber = (countryCode, phoneNumber) =>
    fetchAPI(
      `phone-numbers/${countryCode.toLowerCase()}/${phoneNumber}?checkOtherUsersOnly=true`
    )

  // Tickets
  const getMyTicket = (ticketId, payload) =>
    fetchAPI(`me/tickets/${ticketId}`, payload, 'GET')
  const transferTicket = (ticketId, payload) =>
    fetchAPI(`me/tickets/${ticketId}/transfer`, payload, 'POST')
  const confirmTransferTicket = (ticketId, payload) =>
    fetchAPI(`me/tickets/${ticketId}/transfer`, payload, 'PUT')
  const changeTicket = (ticketId, payload) =>
    fetchAPI(`me/tickets/${ticketId}`, payload, 'PUT')
  const getExchangeableEvents = ticketId =>
    fetchAPI(`me/tickets/${ticketId}/transfer`)
  const cancelTicket = (ticketId, payload) =>
    fetchAPI(`me/tickets/${ticketId}/cancel`, payload, 'POST')
  const cancelTicketPreview = (ticketId, payload) =>
    fetchAPI(`me/tickets/${ticketId}/cancel`, payload)
  const countTickets = () =>
    fetchAPI('me/tickets/count?includeTransferred=true')
  const sendTicketBySMS = (ticketId, payload) =>
    fetchAPI(`me/tickets/${ticketId}/sessions`, payload, 'POST')
  const purchaseTicket = payload => fetchAPI('me/tickets', payload, 'POST')
  const exportTicket = (ticketId, payload) =>
    fetchAPI(`me/tickets/${ticketId}/exports`, payload, 'POST')
  const exportTicketReceipt = (ticketId, payload) =>
    fetchAPI(`tickets/${ticketId}/receipts`, payload, 'POST')
  const ticketExportStats = (ticketId, payload) =>
    fetchAPI(`me/tickets/${ticketId}/exports/stats`, payload)
  const ticketEventsPriceAllocation = data =>
    fetchAPI('me/tickets/price-allocation', data, 'POST')

  // Bundles
  const getBundle = (id, payload) => fetchAPI(`bundles/${id}`, payload)
  const purchaseBundle = (bundleId, payload) =>
    fetchAPI(`me/bundles/${bundleId}/purchase`, payload, 'POST')

  // Sessions
  const updateSession = payload =>
    fetchAPI('me/sessions/current', payload, 'PUT')

  // Events
  const getEvents = (querystring, payload) =>
    fetchAPI(querystring ? `events?${querystring}` : 'events', payload)
  const getEvent = (id, payload) => fetchAPI(`events/${id}`, payload)
  const getEventsFilters = payload => fetchAPI('events/filters', payload)
  const joinWaitlist = (eventId, payload) =>
    fetchAPI(`me/events/${eventId}/waitlist`, payload, 'PUT')
  const leaveWaitlist = eventId =>
    fetchAPI(`me/events/${eventId}/waitlist`, {}, 'DELETE')
  const getLotDistance = (eventId, payload) =>
    fetchAPI(`events/${eventId}/lots-distances`, payload)
  // Settings
  const getSettings = payload => fetchAPI('settings', payload)

  // Countries
  const getCountries = payload => fetchAPI('countries', payload)
  const getCountry = (isoCode, payload) =>
    fetchAPI(`countries/${isoCode}`, payload)

  // My Events
  const getMyEvent = (id, payload) => fetchAPI(`me/events/${id}`, payload)
  const getMyEvents = (payload, querystring) =>
    fetchAPI(querystring ? `me/events?${querystring}` : 'me/events', payload)

  // SDK
  const getApplicationInfo = (key, payload) =>
    fetchAPI(`applications/${key}`, payload)

  // Guests
  const createGuestUser = payload => fetchAPI('guests', payload, 'POST')

  // onDemand
  const getLot = id => fetchAPI(`lots/${id}`)

  // Reservations
  const extendReservation = payload =>
    fetchAPI('me/reservations/current', payload, 'PUT')
  const extendReservationWithAccessToken = (id, payload) =>
    fetchAPI(`me/reservations/${id}`, payload, 'PUT')
  const endReservation = payload =>
    fetchAPI('me/reservations/current/end', payload, 'POST')
  const createReservation = payload =>
    fetchAPI('me/reservations', payload, 'POST')
  const createReservationSession = payload =>
    fetchAPI('reservations/sessions', payload, 'POST')
  const getReservation = ({ id, payload }) =>
    fetchAPI(`reservations/${id}`, payload)
  const sendReservationReceipt = (secret, payload) =>
    fetchAPI(`me/reservations/${secret}/receipts`, payload, 'POST')
  const sendActiveReservationReceipt = payload =>
    fetchAPI('me/reservations/current/receipts', payload, 'POST')
  const getReservationFeeBreakdown = (id, payload) =>
    fetchAPI(`me/reservations/${id}/fee-breakdown`, payload)

  const getDeepLinkData = (token, payload) =>
    fetchAPI(`deep-links/${token}`, payload)

  const reportConversion = payload => fetchAPI('conversions', payload, 'POST')

  // POS
  const getReceiptByCode = code =>
    fetchAPI(`tickets/receipts/${code}`, {}, 'GET')
  const exportReceipt = (code, payload) =>
    fetchAPI(`tickets/receipts/${code}/emails`, payload, 'POST')

  // Admin
  const getUsers = querystring => fetchAPI(`/v2/admin/users?${querystring}`)

  return {
    addCard,
    cancelTicket,
    cancelTicketPreview,
    changePassword,
    changeTicket,
    confirmTransferTicket,
    countTickets,
    createAccount,
    createGuestUser,
    createReservation,
    createReservationSession,
    createVehicle,
    deleteCard,
    deleteVehicle,
    editCard,
    editVehicle,
    endReservation,
    exportReceipt,
    exportTicket,
    exportTicketReceipt,
    extendReservation,
    extendReservationWithAccessToken,
    getApplicationInfo,
    getBundle,
    getCountries,
    getCountry,
    getDeepLinkData,
    getEvent,
    getEvents,
    getEventsFilters,
    getExchangeableEvents,
    getLot,
    getLotDistance,
    getMe,
    getMyEvent,
    getMyEvents,
    ticketEventsPriceAllocation,
    getMyTicket,
    getNow,
    getPhoneNumber,
    getPhoneNumberAppleInfo,
    getPhoneNumberFacebookInfo,
    getReceiptByCode,
    getReservation,
    getSettings,
    getUserCredits,
    hash,
    joinWaitlist,
    leaveWaitlist,
    login,
    magicLink,
    passwordRules,
    purchaseBundle,
    purchaseTicket,
    putMe,
    reportConversion,
    resetPassword,
    sendActiveReservationReceipt,
    getReservationFeeBreakdown,
    sendChangePasswordCode,
    sendPhoneVerification,
    sendReservationReceipt,
    sendTicketBySMS,
    ticketExportStats,
    transferTicket,
    unlinkPhoneApple,
    unlinkPhoneFacebook,
    updateSession,
    validateUserNewPhoneNumber,
    verifyPhoneNumber,
    getUsers,
  }
}

export default API
