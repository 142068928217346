import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Text from 'components/Atoms/Text'
import Label from 'components/Atoms/Label'

const Component = styled.div`
  display: flex;
  flex-direction: column;

  .label {
    margin-bottom: 6px;
  }

  p.error {
    margin-top: 4px;
    animation: fadein 0.2s;
  }

  @keyframes fadein {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
`

Component.displayName = 'BaseTextField__Component'

const Error = styled(Text)`
  margin-left: ${props => (props.extraMargin ? '64px' : 0)};

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    margin-left: ${props => (props.extraMargin ? '84px' : 0)};
  }
`

Error.displayName = 'BaseTextField__Error'

const BaseTextField = ({
  className,
  label,
  isInvalid,
  children,
  error,
  extraMarginError,
}) => (
  <Component className={className}>
    <Label>{label}</Label>
    {children}
    {isInvalid && (
      <Error extraMargin={extraMarginError} className="tiny error">
        {error}
      </Error>
    )}
  </Component>
)

BaseTextField.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  isInvalid: PropTypes.bool,
  error: PropTypes.string,
  extraMarginError: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.object),
  ]),
}

export default BaseTextField
