import styled from 'styled-components'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { useTranslation } from 'i18n-web/i18next'
import Text from 'components/Atoms/Text'
import { useClientResponsive } from 'helpers/hooks'

const Component = styled.div`
  margin-bottom: 30px;
  padding: 16px 16px 8px 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background: ${props => props.theme.palette.grayLighter};

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    padding: 30px;
    padding-bottom: 15px;
  }
`

const BreakdownText = styled(Text)`
  min-width: 110px;
  z-index: 1;
  position: relative;
  padding-right: 10px;
  background: ${props => props.theme.palette.grayLighter};
  font-size: ${props => props.theme.font.size.mobile.medium};
  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    min-width: 180px;
    font-size: ${props => props.theme.font.size.desktop.regular};
  }
`

const BreakdownValue = styled(Text)`
  text-align: right;
  z-index: 1;
  position: relative;
  min-width: 50px;
  padding-left: 10px;
  background: ${props => props.theme.palette.grayLighter};

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    min-width: 80px;
  }
`

const BreakdownTotal = styled(BreakdownValue)`
  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    font-size: 28px;
  }
`

const ValueRow = styled.div`
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  padding: 6px 0;
  margin: 5px 0;
  &:first-of-type {
    margin-top: 4px;
  }

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    height: 50px;

    &:last-of-type {
      &:before {
        border-bottom: 2px solid ${props => props.theme.palette.grayLight};
      }
    }
  }
`

const Divisor = styled.div`
  border-top: 1px solid ${props => props.theme.palette.grayLight};
`

const Breakdown = ({ amount, parkingFeeText }) => {
  const { t } = useTranslation()
  const { isMobile } = useClientResponsive()

  return (
    <Component>
      <Text className={'semiBold uppercase small'}>
        {t('receipt.breakdown.feeBreakdowns')}
      </Text>
      <ValueRow>
        <BreakdownText className="opacity">
          {parkingFeeText || t('receipt.breakdown.parkingFee')}
        </BreakdownText>
        <BreakdownValue className="large bold opacity">
          {amount.value}
        </BreakdownValue>
      </ValueRow>
      <Divisor />
      {amount.fee && (
        <>
          <ValueRow>
            <BreakdownText className="opacity">
              {t('receipt.breakdown.citifydServiceFee')}
            </BreakdownText>
            <BreakdownValue className="large bold opacity">
              {amount.fee}
            </BreakdownValue>
          </ValueRow>
          <Divisor />
        </>
      )}
      {amount.credits && (
        <>
          <ValueRow>
            <BreakdownText className={'regular'}>
              {t('receipt.breakdown.creditsApplied')}
            </BreakdownText>
            <BreakdownValue className="large bold">
              {amount.credits}
            </BreakdownValue>
          </ValueRow>
          <Divisor />
        </>
      )}
      <ValueRow between="xs">
        <BreakdownText className={classNames('bold', { regular: !isMobile })}>
          {t('receipt.breakdown.totalFees')}
        </BreakdownText>
        <BreakdownTotal
          className={classNames('bold', {
            h2: isMobile,
          })}>
          {amount.total}
        </BreakdownTotal>
      </ValueRow>
    </Component>
  )
}

Breakdown.propTypes = {
  amount: PropTypes.object,
  parkingFeeText: PropTypes.string,
}

export default Breakdown
