import { useEffect, useState } from 'react'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Text from 'components/Atoms/Text'
import { getAppRoute } from 'helpers/application'
import { getEventUrl } from 'models/events'
import { withTranslation, Router } from 'i18n-web/i18next'

import Group from './Group'
import { Left, Right } from './Columns'

const Component = styled.div`
  margin-bottom: 34px;
  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    margin-bottom: 30px;
  }
`

const SummaryLink = styled(Text)`
  display: inline-block;
`

const Title = styled(Text)`
  font-size: ${props => props.theme.font.size.mobile.medium};
  margin-bottom: 5px;

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    font-size: ${props => props.theme.font.size.desktop.large};
    margin-bottom: 0;
  }
`

const Content = styled(Text)`
  font-size: ${props => props.theme.font.size.mobile.small};

  &.marginTop {
    margin-top: 15px;
  }

  &.marginBottom {
    margin-bottom: 15px;
  }

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    font-size: ${props => props.theme.font.size.desktop.regular};
  }
`

const Value = styled(Text)`
  font-size: ${props => props.theme.font.size.mobile.regular};
`

const BundleIncludedText = styled(Content)`
  width: 100%;
`

const OrderSummary = ({
  application,
  credits,
  event,
  isBundleEvent,
  hideForFreeParking,
  lot,
  order,
  t,
}) => {
  const [showOrderItem, setShowOrderItem] = useState(false)

  useEffect(() => {
    setShowOrderItem(!hideForFreeParking ? true : order?.raw?.total > 0)
  }, [order])

  return (
    <Component>
      {event && (
        <Group className="spacingLarge">
          <Left>
            <Title className="bold">{event.name}</Title>
            {!isBundleEvent && (
              <>
                <Content>{event.venueName}</Content>
                <Content>
                  {moment(event.start)
                    .tz(event.timezoneName)
                    .format('MMM D, YYYY')}
                </Content>
                <Content>
                  {moment(event.start).tz(event.timezoneName).format('LT')}
                </Content>
              </>
            )}
          </Left>
          <Right>
            <SummaryLink
              className="primary medium bold right pointer"
              onClick={() =>
                Router.push(
                  `${getAppRoute(application, 'as')}/events`,
                  `${getAppRoute(application, 'url')}/events`
                )
              }>
              ({t('purchase.orderSummary.link')})
            </SummaryLink>
          </Right>
        </Group>
      )}
      {!isBundleEvent && (
        <Group className="spacingLarge">
          <Left full={!event}>
            <Title className="bold">{lot.name}</Title>
            <Content>{lot.addressFormatted}</Content>
            <Content>
              {lot.availability &&
                `${moment(lot.availability?.start)
                  .tz(lot.availability?.timezoneName)
                  .format('MMM D, LT')} - ${moment(lot.availability?.end)
                  .tz(lot.availability?.timezoneName)
                  .format('LT')}`}
            </Content>
            {lot?.startTime && lot?.endTime && (
              <>
                <Content className="marginTop marginBottom">
                  <Title className="bold">
                    {t('reservedParking.search.form.label.startDate')}:
                  </Title>
                  <Content>
                    {moment(lot.startTime).tz(lot.timezoneName).format('LLL')}
                  </Content>
                </Content>
                <Content>
                  <Title className="bold">
                    {t('reservedParking.search.form.label.endDate')}:
                  </Title>
                  <Content>
                    {moment(lot.endTime).tz(lot.timezoneName).format('LLL')}
                  </Content>
                </Content>
              </>
            )}
          </Left>
          {event && (
            <>
              <Right>
                <SummaryLink
                  className="primary medium bold right pointer"
                  onClick={() =>
                    Router.push(
                      `${getAppRoute(application, 'as')}/events/[event]`,
                      `${getAppRoute(application, 'url')}/events/${getEventUrl(
                        event
                      )}`
                    )
                  }>
                  ({t('purchase.orderSummary.link')})
                </SummaryLink>
              </Right>
            </>
          )}
        </Group>
      )}
      {isBundleEvent &&
        event?.information.map((item, index) => (
          <Group key={index} className="spacingLarge">
            <Title className="bold">{item.title}</Title>
            {item.content.split('\n').map((item, i) => (
              <BundleIncludedText key={i}>{item}</BundleIncludedText>
            ))}
          </Group>
        ))}
      {showOrderItem && order.value && (
        <Group className="spacingSmall">
          <Left>
            <Content>{t('purchase.orderSummary.parkingFee')}</Content>
          </Left>
          <Right>
            <Value className="right">{order.value}</Value>
          </Right>
        </Group>
      )}
      {showOrderItem && order.fee && (
        <Group className="spacingSmall">
          <Left>
            <Content>{t('purchase.orderSummary.serviceFee')}</Content>
          </Left>
          <Right>
            <Value className="right">{order.fee}</Value>
          </Right>
        </Group>
      )}
      {showOrderItem && credits && (
        <Group className="spacingSmall">
          <Left>
            <Content>{t('purchase.orderSummary.credits')}</Content>
          </Left>
          <Right>
            <Value className="right">{`(${credits})`}</Value>
          </Right>
        </Group>
      )}
      {showOrderItem && (
        <Group className="border">
          <Left>
            <Title className="regular bold">
              {t('purchase.orderSummary.amount').toUpperCase()}
            </Title>
          </Left>
          <Right>
            <Value className="regular bold right">{order.total}</Value>
          </Right>
        </Group>
      )}
    </Component>
  )
}

OrderSummary.propTypes = {
  application: PropTypes.string,
  credits: PropTypes.any,
  event: PropTypes.object,
  isBundleEvent: PropTypes.bool,
  hideForFreeParking: PropTypes.bool,
  lot: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  order: PropTypes.object,
  t: PropTypes.func,
}

export default withTranslation()(OrderSummary)
