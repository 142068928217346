import PropTypes from 'prop-types'
import styled from 'styled-components'

const NavItemComponent = styled.li`
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.md}rem) {
    padding: 0;
    margin-bottom: 0;
    margin-left: 10px;

    &:first-child {
      margin-left: 0;
    }
  }
`

const NavItem = ({ application, children, className }) => (
  <NavItemComponent application={application} className={className}>
    {children}
  </NavItemComponent>
)

NavItem.propTypes = {
  application: PropTypes.string,
  children: PropTypes.element,
  className: PropTypes.string,
}

export default NavItem
