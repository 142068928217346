import styled from 'styled-components'
import PropTypes from 'prop-types'

import PageTitle from 'components/Atoms/PageTitle'
import { ChangePasswordForm } from 'components/Molecules'

const Content = styled.div`
  margin: 20px 0;

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    margin-left: 35px;
    margin-right: 35px;
  }
`

const Divisor = styled.div`
  margin-top: 20px;
  border-top: 1px solid ${props => props.theme.palette.gray}80;
  display: none;

  &.title {
    margin-top: 0;
  }

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    display: block;
  }
`

const ChangePassword = ({ t, ...props }) => (
  <>
    <PageTitle
      modal
      noBorder
      showBackButton={true}
      shadow
      title={t('changePassword.title')}
    />
    <Divisor />
    <Content>
      <ChangePasswordForm t={t} {...props} />
    </Content>
  </>
)

ChangePassword.propTypes = {
  phoneNumber: PropTypes.string,
  t: PropTypes.func,
}

export default ChangePassword
