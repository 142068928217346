export const SET_RESERVATION = 'SET_RESERVATION'
export const RESET_RESERVATION = 'RESET_RESERVATION'
export const setReservation = data => dispatch => {
  dispatch({
    type: SET_RESERVATION,
    payload: data,
  })
}

export const resetReservation = () => dispatch => {
  dispatch({
    type: RESET_RESERVATION,
  })
}
