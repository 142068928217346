import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'next/router'
import { useDispatch, useSelector } from 'react-redux'

import { removeGuestSession } from 'helpers/guest'
import { removeReservationSession } from 'helpers/reservation'
import { setUser } from 'redux-web/utils/user/actions'
import { setSession } from 'redux-web/utils/session/actions'
import Intercom from 'helpers/intercom'
import { showBasicModal } from 'helpers/modal'
import API from 'api'
import { clearAuthentication } from 'redux-web/utils/authentication/actions'
import { getPasswordDefinitions } from 'models/password'
import { useTranslation } from 'i18n-web/i18next'

import Form from './Form'

const UnlinkForm = props => {
  const { phoneNumber, phoneVerificationToken, countryCode, name, email } =
    props

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const isSDK = useSelector(state => state.settings.isSDK)
  const phoneNumberSupportSDK = useSelector(
    state => state.settings.phoneNumberSupport
  )
  const emailSupportSDK = useSelector(state => state.settings.emailSupport)

  const [passwordRules, setPasswordRules] = useState({})
  const [error, setError] = useState()

  const initialValues = {
    name: name,
    email: email,
    password: '',
    confirmPassword: '',
  }

  useEffect(() => {
    API()
      .passwordRules({ ...(isSDK && { sdk: true }) })
      .then(res => setPasswordRules(res.passwordRules))
      .catch(err => console.error(err))
  }, [])

  const handleSubmit = (values, { setSubmitting, setStatus }) => {
    setSubmitting(true)
    setStatus(null)

    API()
      .unlinkPhoneFacebook(countryCode, phoneNumber, {
        timeoutTryAgainMessage: true,
        ...(isSDK && { sdk: true }),
        body: JSON.stringify({
          ...values,
          phoneVerificationToken: phoneVerificationToken,
        }),
      })
      .then(resp => {
        if (!resp.error) {
          dispatch(
            showBasicModal({
              title: t('unlinkFacebook.alert.success.title'),
              description: t('unlinkFacebook.alert.success.description'),
            })
          )

          removeGuestSession()
          removeReservationSession()
          dispatch(setSession(resp.user))
          dispatch(setUser(resp.user))
        } else {
          setError(resp.error?.message)
          if (resp.error?.code === 408) {
            dispatch(
              showBasicModal({
                title: resp.error.message,
              })
            )
          } else {
            dispatch(
              showBasicModal({
                actionButtons: {
                  rightButtonOnClick: () => {
                    if (!isSDK) {
                      Intercom().sendMessage()
                    } else {
                      if (phoneNumberSupportSDK) {
                        window.open(`tel:${phoneNumberSupportSDK}`, '_self')
                      } else if (emailSupportSDK) {
                        window.open(`mailto:${phoneNumberSupportSDK}`, '_self')
                      }
                    }
                  },
                  rightButtonText: t('unlinkFacebook.alert.error.button'),
                },
                description: t('unlinkFacebook.alert.error.description'),
                title: t('unlinkFacebook.alert.error.title'),
              })
            )
          }
        }
      })
  }

  const onFocusField = setStatus => {
    setStatus(null)
    dispatch(clearAuthentication())
  }

  const validatePassword = password => {
    password = (password || '').toString()

    const validations = [...passwordRules.features, 'minimum']

    let result = { missingFeatures: [] }

    for (const feature of validations) {
      const { pattern } = getPasswordDefinitions[feature]

      if (!pattern.test(password)) {
        result.missingFeatures.push(feature)
      }
    }

    setPasswordRules({
      ...passwordRules,
      ...result,
    })
  }

  const handlePasswordOnKeyUp = e => validatePassword(e.target.value)

  return (
    <Form
      handlePasswordOnKeyUp={handlePasswordOnKeyUp}
      handleSubmit={handleSubmit}
      initialValues={initialValues}
      onFocusField={onFocusField}
      passwordRules={passwordRules}
      error={error}
      t={t}
      {...props}
    />
  )
}

UnlinkForm.propTypes = {
  router: PropTypes.object,
  setVerifyEmailMessage: PropTypes.func,
  setVerifiedEmailMessage: PropTypes.func,
  phoneNumber: PropTypes.string,
  countryCode: PropTypes.string,
  name: PropTypes.string,
  email: PropTypes.string,
  phoneVerificationToken: PropTypes.string,
}

export default withRouter(UnlinkForm)
