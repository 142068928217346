import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Autosuggest from 'react-autosuggest'
import AutosuggestHighlightMatch from 'autosuggest-highlight/match'
import AutosuggestHighlightParse from 'autosuggest-highlight/parse'
import classNames from 'classnames'
import { isFunction, get } from 'lodash'

import Input from 'components/Atoms/Input'
import Text from 'components/Atoms/Text'

const AutosuggestComponent = styled.div`
  position: relative;
`

const Suggestion = styled(Text)`
  cursor: pointer;
  padding: 10px 20px;
`

const SuggestionList = styled.div`
  width: 100%;
  display: none;
  position: absolute;
  top: 45px;
  border: 1px solid ${props => props.theme.palette.gray};
  background-color: ${props => props.theme.palette.white};
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
  max-height: 300px;
  overflow-y: auto;

  &.react-autosuggest__suggestions-container--open {
    display: block;
  }
`

// https://developer.mozilla.org/en/docs/Web/JavaScript/Guide/Regular_Expressions#Using_Special_Characters
const escapeRegexCharacters = str => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')

const getSuggestionValue = suggestion => suggestion.label

const renderSuggestionsContainer = ({ containerProps, children }) => (
  <SuggestionList {...containerProps}>{children}</SuggestionList>
)

const renderSuggestion = (suggestion, { query }, SuggestionTemplate) => {
  const matches = AutosuggestHighlightMatch(suggestion?.label, query)
  const parts = AutosuggestHighlightParse(suggestion?.label, matches)

  if (isFunction(SuggestionTemplate)) {
    return (
      <SuggestionTemplate
        suggestion={suggestion}
        query={query}
        matches={matches}
        parts={parts}
      />
    )
  }

  return (
    <Suggestion>
      {parts.map((part, index) => (
        <Text
          as="span"
          className={classNames({
            bold: part.highlight,
          })}
          key={index}>
          {part.text}
        </Text>
      ))}
    </Suggestion>
  )
}

const Component = ({
  data = [],
  onFocus,
  onKeyUp,
  onBlur,
  onChangeCallback,
  fieldRef,
  isValid,
  uppercase,
  openOnFocus,
  onSuggestionSelected,
  SuggestionTemplate,
  InputTemplate,
  selectedItem,
  resetValueOnBlur,
  focusInputOnSuggestionClick,
  disabled,
}) => {
  const [value, setValue] = useState('')
  const [suggestions, setSuggestions] = useState([])
  const InputComponent = InputTemplate || Input

  useEffect(() => {
    const newValue = get(selectedItem, 'label', '')

    setValue(newValue || '')
  }, [selectedItem])

  const onChange = (event, { newValue }) => {
    if (isFunction(onChangeCallback)) {
      onChangeCallback(newValue?.toUpperCase())
    }
    setValue(newValue?.toUpperCase())
  }

  const getSuggestions = value => {
    const escapedValue = escapeRegexCharacters(value.trim())

    if (escapedValue === '') return []

    const regex = new RegExp('^' + escapedValue, 'i')

    return data.filter(item => regex.test(item.label) || regex.test(item.value))
  }

  const onSuggestionsFetchRequested = ({ value, reason }) => {
    if (openOnFocus && reason === 'input-focused') {
      setSuggestions(data)
    } else {
      setSuggestions(getSuggestions(value))
    }
  }

  const onSuggestionsClearRequested = () => setSuggestions([])

  const handleOnBlur = event => {
    if (isFunction(onBlur)) {
      onBlur(event)
    }

    if (resetValueOnBlur) {
      setValue(get(selectedItem, 'label', '') || '')
    }
  }

  return (
    <AutosuggestComponent>
      <Autosuggest
        suggestions={suggestions}
        renderInputComponent={inputProps => <InputComponent {...inputProps} />}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestionsContainer={renderSuggestionsContainer}
        renderSuggestion={(...args) =>
          renderSuggestion(...args, SuggestionTemplate)
        }
        shouldRenderSuggestions={() => true}
        onSuggestionSelected={onSuggestionSelected}
        focusInputOnSuggestionClick={focusInputOnSuggestionClick}
        inputProps={{
          value: uppercase ? value.toUpperCase() : value,
          error: !isValid,
          onChange: onChange,
          onFocus: onFocus,
          onKeyUp: onKeyUp,
          onBlur: handleOnBlur,
          className: classNames('full', {
            error: !isValid,
          }),
          ref: fieldRef,
          selectedItem: selectedItem,
          disabled: disabled,
        }}
      />
    </AutosuggestComponent>
  )
}

Component.propTypes = {
  data: PropTypes.array,
  onFocus: PropTypes.func,
  onKeyUp: PropTypes.func,
  onBlur: PropTypes.func,
  onChangeCallback: PropTypes.func,
  fieldRef: PropTypes.object,
  isValid: PropTypes.bool,
  uppercase: PropTypes.bool,
  filterBy: PropTypes.string,
  openOnFocus: PropTypes.bool,
  onSuggestionSelected: PropTypes.func,
  SuggestionTemplate: PropTypes.func,
  InputTemplate: PropTypes.object,
  selectedItem: PropTypes.object,
  resetValueOnBlur: PropTypes.bool,
  focusInputOnSuggestionClick: PropTypes.bool,
  disabled: PropTypes.bool,
}

Component.defaultProps = {
  openOnFocus: false,
  resetValueOnBlur: false,
}

renderSuggestionsContainer.propTypes = {
  containerProps: PropTypes.object,
  children: PropTypes.node,
}

export default Component
