import { kebabCase, isEmpty } from 'lodash'

import getImageUrl from 'helpers/image'
import { formatAddress, formatCurrency } from 'i18n-web/formatters'
import { getDistanceByGeoLib } from 'models/lots'
import { selectCurrencyForCountry } from 'redux-web/selectors/countries'

export const sortByDistance = lots =>
  lots?.slice().sort((a, b) => a?.node?.lot?.distance - b?.node?.lot?.distance)

export const sortByPrice = lots =>
  lots
    ?.slice()
    .sort(
      (a, b) =>
        a?.node?.calculatedPrice?.total - b?.node?.calculatedPrice?.total
    )

export const getHourlySearchUrl = address => {
  const locationSegment = kebabCase(address)

  return `/hourly-parking/${locationSegment}`
}

export const getFormattedLot = ({
  node,
  countries,
  lat,
  lng,
  addressFormat = 'full_inline',
}) => {
  if (isEmpty(node) || isEmpty(countries)) {
    return {}
  }

  const {
    about,
    additionalPhotos,
    address,
    amenities,
    country,
    countryCode,
    directionsNotes,
    id,
    latitude,
    longitude,
    mainPhoto,
    name,
    notes,
    soldOut,
    currentAvailability,
  } = node ?? {}

  const currency = selectCurrencyForCountry(countries, countryCode)

  const { rate } = node.currentAvailability

  let minimumPrice

  if (rate.type === 'variable') {
    minimumPrice = rate.rules[0].value + rate.fee
  } else if (rate.type === 'fixed') {
    minimumPrice = rate.value + rate.fee
  } else if (rate.type === 'hourly') {
    minimumPrice = rate.value + rate.fee
  }

  const minimumPriceFormatted = formatCurrency(minimumPrice, country.currency)

  return {
    about,
    additionalPhotos,
    address,
    addressFormatted: formatAddress(address, countryCode, addressFormat),
    minimumPriceFormatted,
    currentAvailability,
    amenities,
    countryCode,
    currency,
    directionsNotes,
    id,
    image: getImageUrl(mainPhoto?.image?.info, 'c_thumb,w_460,h_270,g_center'),
    language: country?.language,
    latitude,
    longitude,
    mainPhoto,
    name,
    notes,
    soldOut,
    thumbnail: getImageUrl(
      mainPhoto?.image?.info,
      'c_thumb,w_120,h_120,g_center'
    ),
    ...(lat && lng
      ? {
          distance: getDistanceByGeoLib(latitude, longitude, lat, lng),
        }
      : {}),
  }
}
