import moment from 'moment'
import { get } from 'lodash'

import API from 'api'
import { Router } from 'i18n-web/i18next'
import { getTheme } from 'models/application'
import { internationalPhoneNumber } from 'helpers/phoneFormat'

const Intercom = () => {
  let userHash = null

  const theme = getTheme(get(Router, 'query.application'))

  const settings = user => {
    const data = {
      created_at: moment(user.createdAt).unix(),
      email: user.email,
      is_facebook_user: user.isFacebookUser,
      language_override: user.language,
      name: user.name,
      phone: internationalPhoneNumber(user.phoneNumber, user.phoneCountryCode),
      unsubscribed_from_emails: !user.receiveEmailNotifications,
      user_id: String(user.id),
    }

    if (user.organization) {
      data.companies = [
        {
          company_id: user.organization.id,
          country: user.organization.countryCode,
          has_cashier_report: user.organization.hasCashierReport,
          has_crm_access: user.organization.hasCashierReport,
          has_event_creation_access: user.organization.hasEventCreationAccess,
          name: user.organization.name,
        },
      ]
    }

    return data
  }

  const runOnIntercom = callback => window.Intercom && callback(window.Intercom)

  const saveUser = data => {
    runOnIntercom(Intercom => {
      const user = settings(data)

      if (user && !userHash) {
        API()
          .hash()
          .then(resp => {
            if (!resp.error) {
              userHash = resp.hash
              Intercom('boot', {
                user_hash: userHash,
                ...user,
              })
            }
          })
          .catch(err => console.error(err))
      } else {
        update(user)
      }
    })
  }

  const shutdown = () =>
    runOnIntercom(Intercom => {
      Intercom('shutdown')
      userHash = null
    })

  const sendMessage = message =>
    runOnIntercom(Intercom => {
      Intercom('showNewMessage', message)
    })

  const update = (user = null) =>
    runOnIntercom(Intercom => {
      if (user) {
        Intercom('update', user)
      } else {
        Intercom('update')
      }
    })

  const init = () =>
    runOnIntercom(Intercom =>
      Intercom('boot', {
        action_color: get(theme, 'palette.intercom.action'),
        app_id: process.env.NEXT_PUBLIC_INTERCOM_APP_KEY,
        background_color: get(theme, 'palette.intercom.background'),
      })
    )

  return {
    init,
    saveUser,
    shutdown,
    update,
    sendMessage,
  }
}

export default Intercom
