import Router from 'next/router'

const Redirect = (target, ctx = {}) => {
  if (ctx.res) {
    ctx.res.redirect(target)
  } else {
    // On the browser, next/router is used to "replace" the current
    // location for the new one, removing it from history.
    Router.replace(target)
  }
}

export default Redirect
