import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Formik, Form, Field } from 'formik'

import BulletPoint from 'components/Atoms/BulletPoint'
import Button from 'components/Atoms/Button'
import { TextField } from 'components/Molecules/Form'

import validationSchema from './validationSchema'

const Component = styled.div`
  margin-bottom: 20px;

  .phoneDisclaimer {
    margin-top: -8px;
    margin-bottom: 15px;
  }

  .bold {
    font-weight: bold;
  }

  .extraMargin {
    margin-bottom: 12px;
  }
`

const SubmitButton = styled(Button)`
  margin-top: 20px;
`

const Unlink = ({
  error,
  name,
  handlePasswordOnKeyUp,
  handleSubmit,
  initialValues,
  onFocusField,
  passwordRules,
  t,
}) => (
  <Formik
    initialValues={initialValues}
    onSubmit={handleSubmit}
    render={props => {
      const { isSubmitting, setStatus } = props

      return (
        <Component>
          <Form>
            {!name && (
              <Field
                component={TextField}
                disabled={isSubmitting && !error}
                label={t('signUp.form.name.label')}
                name="name"
                onFocus={() => onFocusField(setStatus)}
              />
            )}

            <Field
              component={TextField}
              disabled={isSubmitting && !error}
              label={t('signUp.form.email.label')}
              name="email"
              onFocus={() => onFocusField(setStatus)}
            />

            <Field
              component={TextField}
              disabled={isSubmitting && !error}
              label={t('signUp.form.password.label')}
              name="password"
              onFocus={() => onFocusField(setStatus)}
              onKeyUp={handlePasswordOnKeyUp}
              type="password"
            />
            <BulletPoint
              isChecked={
                passwordRules?.missingFeatures &&
                !passwordRules?.missingFeatures.includes('minimum')
              }>
              {t('signUp.form.password.requirements.minimum')}
            </BulletPoint>
            <Field
              component={TextField}
              disabled={isSubmitting && !error}
              label={t('signUp.form.confirmPassword.label')}
              name="confirmPassword"
              onFocus={() => onFocusField(setStatus)}
              type="password"
            />

            <SubmitButton
              className="full highlight"
              disabled={isSubmitting}
              type="submit">
              {t('signUp.form.button').toUpperCase()}
            </SubmitButton>
          </Form>
        </Component>
      )
    }}
    validationSchema={() => validationSchema(name, passwordRules)}
  />
)

Unlink.propTypes = {
  name: PropTypes.string,
  error: PropTypes.string,
  handlePasswordOnKeyUp: PropTypes.func,
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  onFocusField: PropTypes.func,
  passwordRules: PropTypes.object,
  t: PropTypes.func,
  isSubmitting: PropTypes.bool,
  setStatus: PropTypes.func,
}

export default Unlink
