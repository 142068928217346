import { get, find, lowerCase } from 'lodash'
import { denormalize } from 'normalizr'

import {
  continent as continentSchema,
  country as countrySchema,
} from 'api/schema'

export const selectContinents = (state, names) =>
  state.countries.continents
    ? denormalize(names, [continentSchema], state.countries)
    : []

export const selectCountry = (countries, isoCode) => {
  if (!countries || !isoCode) return null

  return (
    countries.countries &&
    denormalize(isoCode.toLowerCase(), countrySchema, countries)
  )
}

export const selectAllCountries = state => {
  const continentsDataset = get(state.settings, 'continentsDataset')
  const continents = selectContinents(state, continentsDataset)

  return continents.reduce(
    (result, continent) => [...result, ...continent.countries],
    []
  )
}

export const selectPhoneNumberAllowedCountries = state =>
  selectAllCountries(state).filter(country => country.phoneNumberAllowed)

export const selectVehicleAllowedCountries = state =>
  selectAllCountries(state).filter(country => country.vehiclesAllowed)

export const selectCurrencyForCountry = (countries, countryCode) => {
  const country = find(
    countries,
    ({ isoCode }) => isoCode === lowerCase(countryCode)
  )

  return country && country.currency
}

export const selectUserAvailableCountry = (
  availableCountryCodes,
  userCountry,
  fallback = 'us'
) => {
  const guessedCountry = userCountry ? userCountry.toLowerCase() : ''

  if (availableCountryCodes.find(item => item == guessedCountry)) {
    return guessedCountry
  }
  if (availableCountryCodes.find(item => item == fallback)) {
    return fallback
  }

  return 'us'
}
