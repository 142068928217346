import React, { useState, useMemo, useEffect } from 'react'
import { omit, isEmpty } from 'lodash'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import styled, { withTheme } from 'styled-components'
import MobileDetect from 'mobile-detect'

import Label from 'components/Atoms/Label'
import Text from 'components/Atoms/Text'

const Component = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 14px;
  flex: 1;

  p.error {
    margin-top: 4px;
    animation: fadein 0.2s;
  }

  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .input {
    outline: none;
    pointer-events: ${props => (props.iosVersion >= 13 ? 'none' : 'auto')};
    padding: 12px 10px;
    margin-top: 4px;
    height: 44px;
    border-radius: 4px;
    border: 1px solid ${props => props.theme.palette.grayDark}30;
    background-color: ${props => props.theme.palette.white};
    transition: 0.2s;

    iframe {
      pointer-events: auto;
    }

    &.error {
      border-color: ${props => props.theme.palette.error};
    }

    &.StripeElement--focus {
      border: 1px solid ${props => props.theme.palette.grayDark};
    }
    &.StripeElement--invalid {
      border: 1px solid ${props => props.theme.palette.error};
    }
  }
`

const StripeField = ({
  field,
  form,
  onChangeCallback,
  StripeComponent,
  label,
  inputRef,
  theme,
  ...props
}) => {
  const { touched, errors } = form
  const [error, setError] = useState({})
  const [IOSVersion, setIOSVersion] = useState()

  useEffect(() => {
    const md = new MobileDetect(window.navigator.userAgent)

    setIOSVersion(md.version('iOS'))
  }, [])

  const isInvalid = useMemo(
    () =>
      Boolean(
        (touched[field.name] &&
          errors[field.name] &&
          errors[field.name].length) ||
          !isEmpty(error)
      ),
    [touched, errors, error]
  )

  const handleChange = event => {
    event.error !== error && setError(event.error || {})
  }

  /* 
    NOTE: The iOS version is needed because there is an issue on Stripe Elements that is affecting iOS 13:
    https://github.com/stripe/react-stripe-elements/issues/453#issuecomment-590519261
    The current recommendation to fix it is adding the `pointer-events: none` to the input field, but at same time it's fixing the iOS 13 behaviour, the old versions of iOS is breaking.
    The workaround to have both iOS versions working correctly was checking the iOS version and applying `pointer-events: none` only for iOS >= 13
  */

  return (
    <Component iosVersion={IOSVersion}>
      <Label>{label}</Label>
      <StripeComponent
        {...omit(props, 'label')}
        options={{
          style: {
            base: {
              color: theme.palette.grayDark,
              fontSize: theme.font.size.mobile.medium,
              '::placeholder': {
                color: '#aab7c4',
              },
            },
          },
        }}
        className={classNames('input', {
          error: isInvalid,
        })}
        onChange={e => {
          handleChange(e)
          onChangeCallback(e)
        }}
        ref={inputRef}
      />
      {isInvalid && (!isEmpty(error) || errors) && (
        <Text className={'tiny error'}>
          {error.message || errors[field.name]}
        </Text>
      )}
    </Component>
  )
}

StripeField.propTypes = {
  StripeComponent: PropTypes.any,
  field: PropTypes.object,
  form: PropTypes.object,
  inputRef: PropTypes.object,
  theme: PropTypes.object,
  label: PropTypes.string,
  onChangeCallback: PropTypes.func,
}

export default withTheme(StripeField)
