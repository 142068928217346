export const SET_RECEIPT_TYPE = 'SET_RECEIPT_TYPE'
export const RESET_RECEIPT = 'RESET_RECEIPT'
export const SET_RECEIPT_ACCOUNT = 'SET_RECEIPT_ACCOUNT'

export const setReceiptType = data => async dispatch => {
  dispatch({
    type: SET_RECEIPT_TYPE,
    payload: data,
  })
}

export const resetReceipt = () => async dispatch => {
  dispatch({
    type: RESET_RECEIPT,
  })
}

export const setReceiptAccount = data => async dispatch => {
  dispatch({
    type: SET_RECEIPT_ACCOUNT,
    payload: data,
  })
}
