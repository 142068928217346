import React from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment-timezone'
import { withRouter } from 'next/router'

import API from 'api'
import Intercom from 'helpers/intercom'
import { getAppRoute } from 'helpers/application'
import { getEventUrl } from 'models/events'
import { getFormattedTicket } from 'models/tickets'
import { setLoadingRequest } from 'redux-web/utils/request/actions'
import { setTicket } from 'redux-web/utils/ticket/actions'
import { showBasicModal } from 'helpers/modal'
import { showModal, hideModal } from 'components/Atoms/Modal/actions'
import { useTranslation } from 'i18n-web/i18next'

const WithManagePass = WrapperComponent => {
  const Wrapper = props => {
    const { router, sdk } = props
    const ticket = useSelector(state => state.ticket)
    const user = useSelector(state => state.user.me)
    const isSDK = sdk || useSelector(state => state.settings.isSDK)
    const phoneNumberSupportSDK = useSelector(
      state => state.settings.phoneNumberSupport
    )
    const emailSupportSDK = useSelector(state => state.settings.emailSupport)

    const { application } = router?.query

    const dispatch = useDispatch()
    const { t } = useTranslation()

    const checkExportStats = async () =>
      await API().ticketExportStats(ticket.id, { ...(isSDK && { sdk: true }) })

    const retransferYourPass = async () =>
      await API()
        .confirmTransferTicket(ticket.id, {
          timeoutTryAgainMessage: true,
          ...(isSDK && { sdk: true }),
          body: JSON.stringify({ confirm: false }),
        })
        .then(resp => {
          if (!resp.error) {
            dispatch(
              setTicket(
                getFormattedTicket({
                  ticket: { ...ticket, isPendingTransfer: false, vouchers: [] },
                })
              )
            )

            openTransferModal()
          } else {
            if (resp.error.code === 408) {
              dispatch(
                showBasicModal({
                  title: resp.error.message,
                })
              )
            }
          }
        })
        .catch(() => {})

    const reclaimYourPass = async () => {
      dispatch(setLoadingRequest(true))

      await API()
        .confirmTransferTicket(ticket.id, {
          ...(isSDK && { sdk: true }),
          body: JSON.stringify({ confirm: false }),
        })
        .then(resp => {
          dispatch(setLoadingRequest(false))

          if (!resp.error) {
            dispatch(
              setTicket(
                getFormattedTicket({
                  ticket: { ...ticket, isPendingTransfer: false, vouchers: [] },
                })
              )
            )

            showBasicModal({
              description: t('reclaimYourPass.success.description'),
              title: t('reclaimYourPass.success.title'),
            })
          }
        })
        .catch(err => {
          dispatch(setLoadingRequest(false))

          console.error(err)
        })
    }

    const onDownloadApp = () => {
      dispatch(hideModal())
      router.push('/download-app')
    }

    const onGetDirections = () => window.open(ticket?.lot?.mapLink)

    const onManagePass = () => {
      dispatch(
        showModal({
          modalProps: {
            open: true,
            className: 'noBorders',
            noPadding: true,
          },
          childProps: {
            ticket: ticket,
            user: user,
          },
          fullScreen: true,
          modalType: 'managePass',
        })
      )
    }

    const onSendPassToEmail = () => {
      dispatch(
        showModal({
          modalProps: {
            open: true,
            className: 'noBorders',
            noPadding: true,
          },
          fullScreen: true,
          modalType: 'sendPassToEmail',
        })
      )
    }

    const openTransferModal = () =>
      dispatch(
        showModal({
          modalProps: {
            open: true,
            className: 'noBorders',
            noPadding: true,
          },
          childProps: {
            ticket: ticket,
          },
          modalType: 'transferYourPass',
        })
      )

    const onExchange = () => {
      if (user.isGuest) {
        dispatch(
          showBasicModal({
            title: t('exchangeYourPass.title'),
            description: t('exchangeYourPass.guest.description'),
          })
        )
      } else {
        dispatch(
          showBasicModal({
            actionButtons: {
              singleButtonOnClick: () => router.push('/download-app'),
              singleButtonText: t('exchangeYourPass.button'),
            },
            description: t('exchangeYourPass.description'),
            title: t('exchangeYourPass.title'),
          })
        )
      }
    }

    const onTransferAgain = () => {
      dispatch(
        showBasicModal({
          actionButtons: {
            rightButtonOnClick: async () => await retransferYourPass(),
            rightButtonText: t('retransferYourPass.button.continue'),
            singleButtonOnClick: () => {},
            singleButtonText: t('common.button.cancel'),
          },
          title: t('retransferYourPass.title'),
          description: t('retransferYourPass.description'),
        })
      )
    }

    const onReclaim = () => {
      dispatch(
        showBasicModal({
          actionButtons: {
            rightButtonOnClick: async () => await reclaimYourPass(),
            rightButtonText: t('reclaimYourPass.button.continue'),
            singleButtonOnClick: () => {},
            singleButtonText: t('common.button.cancel'),
          },
          title: t('reclaimYourPass.title'),
          description: t('reclaimYourPass.description'),
        })
      )
    }

    const onViewMyPass = () => {
      dispatch(hideModal())

      router.push(
        `${getAppRoute(
          application,
          'as'
        )}/events/[event]/tickets/[ticketId]/pass`,
        `${getAppRoute(application, 'url')}/events/${getEventUrl(
          ticket.event
        )}/tickets/${ticket.id}/pass`
      )
    }

    const onTransfer = async () => {
      if (user.isGuest) {
        dispatch(
          showBasicModal({
            actionButtons: {
              rightButtonOnClick: () =>
                router.push(
                  `${getAppRoute(application, 'as')}/signup`,
                  `${getAppRoute(application, 'url')}/signup`
                ),
              rightButtonText: t('transferYourPass.guest.button'),
              singleButtonOnClick: () => {},
              singleButtonText: t('common.button.notNow'),
            },
            title: t('transferYourPass.title'),
            description: t('transferYourPass.guest.description'),
          })
        )
      } else {
        const exportStats = await checkExportStats()

        if (exportStats?.stats?.exportedForPrintingAt) {
          dispatch(
            showBasicModal({
              actionButtons: {
                rightButtonOnClick: async () => await openTransferModal(),
                rightButtonText: t('common.button.continue'),
                singleButtonOnClick: () => {},
                singleButtonText: t('common.button.cancel'),
              },
              title: t('transferYourPass.warning.title'),
              description: t('transferYourPass.warning.print', {
                date: moment(exportStats?.stats?.exportedForPrintingAt),
              }),
            })
          )
        } else if (
          exportStats?.stats?.exportedToGoogleWalletAt ||
          exportStats?.stats?.exportedToAppleWalletAt
        ) {
          dispatch(
            showBasicModal({
              actionButtons: {
                rightButtonOnClick: async () => await openTransferModal(),
                rightButtonText: t('common.button.continue'),
                singleButtonOnClick: () => {},
                singleButtonText: t('common.button.cancel'),
              },
              title: t('transferYourPass.warning.title'),
              description: t('transferYourPass.warning.wallet', {
                date: moment(
                  exportStats?.stats?.exportedToAppleWalletAt ||
                    exportStats?.stats?.exportedToGoogleWalletAt
                ),
                wallet: exportStats?.stats?.exportedToAppleWalletAt
                  ? 'Apple Wallet'
                  : 'Google Wallet',
              }),
            })
          )
        } else {
          openTransferModal()
        }
      }
    }

    const onPurchaseDetails = () => {
      dispatch(
        showModal({
          modalProps: {
            open: true,
            className: 'noBorders',
            noPadding: true,
          },
          childProps: {
            ticket: ticket,
          },
          modalType: 'purchaseDetails',
        })
      )
    }

    const onCancel = () => {
      if (user.isGuest) {
        dispatch(
          showBasicModal({
            actionButtons: {
              rightButtonOnClick: () =>
                router.push(
                  `${getAppRoute(application, 'as')}/signup`,
                  `${getAppRoute(application, 'url')}/signup`
                ),
              rightButtonText: t('cancelYourPass.guest.button'),
              singleButtonOnClick: () => {},
              singleButtonText: t('common.button.notNow'),
            },
            title: t('cancelYourPass.title'),
            description: t('cancelYourPass.guest.description'),
          })
        )
      } else {
        dispatch(setLoadingRequest(true))

        API()
          .cancelTicketPreview(ticket.id, { ...(isSDK && { sdk: true }) })
          .then(resp => {
            dispatch(setLoadingRequest(false))

            if (!resp.error) {
              dispatch(
                showModal({
                  modalProps: {
                    open: true,
                    className: 'noBorders',
                    noPadding: true,
                  },
                  childProps: {
                    ticket: ticket,
                    preview: { ...resp.ticketCancellationPreview },
                  },
                  modalType: 'cancelYourPass',
                })
              )
            }
          })
          .catch(err => {
            dispatch(setLoadingRequest(false))

            console.error(err)
          })
      }
    }

    const onCustomerSupport = () => {
      if (!isSDK) {
        Intercom().init()
        setTimeout(() => Intercom().sendMessage(), 10)
      } else {
        if (phoneNumberSupportSDK) {
          window.open(`tel:${phoneNumberSupportSDK}`, '_self')
        } else if (emailSupportSDK) {
          window.open(`mailto:${phoneNumberSupportSDK}`, '_self')
        }
      }
    }

    const onPurchaseAnotherPass = () => {
      dispatch(hideModal())
      router.push(
        `${getAppRoute(application, 'as')}/events`,
        `${getAppRoute(application, 'url')}/events`
      )
    }

    const onUpdateVehicleButtonClick = () => {
      dispatch(hideModal())

      if (router.pathname !== '/events/[event]/tickets/[ticketId]/receipt') {
        router.push({
          pathname: `/events/${getEventUrl(ticket.event)}/tickets/${
            ticket.id
          }/receipt`,
          query: { updateVehicle: true },
        })
      } else {
        document.getElementById('licensePlateComponent').scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        })
      }
    }

    return (
      <WrapperComponent
        {...props}
        isSDK={isSDK}
        onCancel={onCancel}
        onCustomerSupport={onCustomerSupport}
        onDownloadApp={onDownloadApp}
        onExchange={onExchange}
        onGetDirections={onGetDirections}
        onManagePass={onManagePass}
        onPurchaseAnotherPass={onPurchaseAnotherPass}
        onPurchaseDetails={onPurchaseDetails}
        onReclaim={onReclaim}
        onSendPassToEmail={onSendPassToEmail}
        onTransfer={onTransfer}
        onTransferAgain={onTransferAgain}
        onUpdateVehicleButtonClick={onUpdateVehicleButtonClick}
        onViewMyPass={onViewMyPass}
        ticket={ticket}
        user={user}
      />
    )
  }

  if (WrapperComponent.getInitialProps) {
    Wrapper.getInitialProps = WrapperComponent.getInitialProps
  }

  Wrapper.propTypes = {
    application: PropTypes.string,
    router: PropTypes.object,
    hasPrinted: PropTypes.bool,
    hasAddedToWallet: PropTypes.bool,
    sdk: PropTypes.bool,
  }

  return withRouter(Wrapper)
}

export default WithManagePass
