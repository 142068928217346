import PropTypes from 'prop-types'
import styled from 'styled-components'

import Text from 'components/Atoms/Text'

const Component = styled(Text)`
  margin-bottom: 15px;
  font-weight: 700;
  text-transform: uppercase;
  font-size: ${props => props.theme.font.size.mobile.medium};

  strong {
    font-weight: 700;
    margin-right: 3px;
  }

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    font-size: ${props => props.theme.font.size.desktop.h4};
    margin-right: 10px;
    font-weight: 800;

    strong {
      font-weight: 800;
    }
  }
`

const Subtitle = ({ children }) => <Component>{children}</Component>

Subtitle.propTypes = {
  children: PropTypes.node,
}

export default Subtitle
