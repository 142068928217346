import {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
  useLayoutEffect,
} from 'react'
import moment from 'moment'
import throttle from 'lodash/throttle'
import { useDispatch } from 'react-redux'

import { hideModal } from 'components/Atoms/Modal/actions'
import { showBasicModal } from 'helpers/modal'
import { showModal } from 'components/Atoms/Modal/actions'
import { useTranslation } from 'i18n-web/i18next'

export function useMomentMemo(value) {
  return useMemo(() => moment(value), [value])
}

export const usePrevious = value => {
  const ref = useRef()

  useEffect(() => {
    ref.current = value
  }, [value])

  return ref.current
}

export const useClientResponsive = () => {
  const [isMobile, setIsMobile] = useState()
  const [isTablet, setIsTablet] = useState()
  const [isDesktop, setIsDesktop] = useState()
  const handleResize = useCallback(
    throttle(() => {
      const width = window.innerWidth

      setIsMobile(width < 768)
      setIsTablet(width >= 768 && width < 1024)
      setIsDesktop(width >= 1024)
    }, 200),
    []
  )

  if (typeof window !== 'undefined') {
    useLayoutEffect(() => {
      handleResize()
    }, [])

    useEffect(() => {
      window.addEventListener('resize', handleResize)

      return () => {
        window.removeEventListener('resize', handleResize)
      }
    }, [])
  }

  return { isDesktop, isMobile, isTablet }
}

const getDimensionObject = node => {
  const rect = node.getBoundingClientRect()

  return {
    width: rect.width,
    height: rect.height,
    top: 'x' in rect ? rect.x : rect.top,
    left: 'y' in rect ? rect.y : rect.left,
    x: 'x' in rect ? rect.x : rect.left,
    y: 'y' in rect ? rect.y : rect.top,
    right: rect.right,
    bottom: rect.bottom,
  }
}

export const useDimensions = () => {
  const [dimensions, setDimensions] = useState({})
  const [node, setNode] = useState(null)

  const ref = useCallback(node => {
    setNode(node)
  }, [])

  useLayoutEffect(() => {
    if (node) {
      const measure = () =>
        window.requestAnimationFrame(() =>
          setDimensions(getDimensionObject(node))
        )

      measure()

      window.addEventListener('resize', measure)
      window.addEventListener('scroll', measure)

      return () => {
        window.removeEventListener('resize', measure)
        window.removeEventListener('scroll', measure)
      }
    }
  }, [node])

  return [ref, dimensions, node]
}

export const useShowError = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const showError = (message, modalType, childProps) => {
    dispatch(hideModal())
    dispatch(
      showBasicModal({
        actionButtons: {
          rightButtonOnClick: dispatch(
            showModal({
              fullScreen: true,
              modalProps: {
                className: 'noBorders',
                open: true,
              },
              modalType: modalType,
              childProps,
            })
          ),
          rightButtonText: t('common.button.tryAgain'),
          singleButtonOnClick: () => {},
          singleButtonText: t('common.button.cancel'),
        },
        description: message,
        title: t('common.title.error'),
        type: 'error',
      })
    )
  }

  return [showError]
}

export const useUserCurrentLocation = () => {
  const [latitude, setLatitude] = useState(null)
  const [longitude, setLongitude] = useState(null)
  const [error, setError] = useState(null)

  const options = {
    enableHighAccuracy: true,
    timeout: 3000,
    maximumAge: 0,
  }

  navigator?.geolocation.getCurrentPosition(
    position => {
      const {
        coords: { latitude: lat, longitude: lng },
      } = position

      setLatitude(lat)
      setLongitude(lng)
      setError(null)
    },
    error => {
      setLatitude(null)
      setLongitude(null)
      setError(error)
    },
    options
  )

  return [latitude, longitude, error]
}
