import * as Yup from 'yup'

import { passwordSchema } from 'i18n-web/validators'
import { i18n } from 'i18n-web/i18next'

const validationSchema = (name, passwordRules) =>
  Yup.object().shape({
    name: Yup.string()
      .min(2, i18n.t('signUp.form.name.error'))
      .required(name && i18n.t('common.form.error.required')),
    email: Yup.string()
      .email(i18n.t('signUp.form.email.error'))
      .required(i18n.t('common.form.error.required')),
    password: passwordSchema({ passwordRules }),
    confirmPassword: Yup.string()
      .oneOf(
        [Yup.ref('password'), null],
        i18n.t('signUp.form.confirmPassword.error')
      )
      .required(i18n.t('common.form.error.required')),
  })

export default validationSchema
