import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styled from 'styled-components'
import { FaBars } from 'react-icons/fa'
import { Grid, Col, Row } from 'react-styled-flexboxgrid'
import { isEmpty } from 'lodash'
import { useRouter, withRouter } from 'next/router'
import { useSelector, useDispatch } from 'react-redux'

import { resetSearch as resetHourlySearch } from 'redux-web/utils/hourlySearch/actions'
import { Link } from 'i18n-web/i18next'
import { getAppRoute } from 'helpers/application'
import { getPayBySignageUrl } from 'models/payBySignage'
import { useClientResponsive } from 'helpers/hooks'
import {
  MenuDropdown,
  MenuDropdownItem,
} from 'components/Molecules/MenuDropdown'
import { resetReservedParking } from 'redux-web/utils/reservedParking/actions'
import { showModal } from 'components/Atoms/Modal/actions'
import { resetMonthlyParking } from 'redux-web/utils/monthly/actions'

import NavItemComponent from './NavItem'
import ItemLinkComponent from './ItemLink'

const NavComponent = styled.ul`
  display: none;

  &.active {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: absolute;
    left: 0;
    right: 0;
    top: 55px;
    padding: 10px 0;
    background-color: ${props => props.theme.palette.grayDark};
    border-top: solid 1px rgba(185, 185, 185, 0.2);
    z-index: 100;
  }

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.md}rem) {
    &,
    &.active {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      top: 0;
      padding: 0;
      border: none;
    }
  }
`

const GridComponent = styled(Grid)`
  @media (max-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    width: 100%;
  }
`

const HamburgerButtonComponent = styled.button`
  display: flex;
  padding: 0;
  background: none;
  border: none;
  margin-left: 20px;

  .icon {
    width: 22px;
    height: auto;
    color: ${props => props.theme.palette.primary};
  }

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.md}rem) {
    display: none;
  }

  @media print {
    display: none;
  }
`

const Nav = styled.nav`
  @media print {
    display: none;
  }
`

const Menu = ({
  application,
  isAuthenticated,
  isGuest,
  isPageLoaded,
  onLoginButton,
  onLogoutUser,
  onlyLogoutButton,
  t,
}) => {
  const dispatch = useDispatch()
  const [isActive, setActive] = useState(false)
  const { pathname } = useRouter()
  const { isMobile, isTablet } = useClientResponsive()
  const { activeReservation } = useSelector(state => state.user.me)

  const handleOnClick = (e, customEvent) => {
    if (customEvent) customEvent()

    setActive(false)
  }

  const commonModalProps = {
    fullScreen: false,
    modalProps: {
      className: 'noBorders',
      customStyle: {
        alignSelf: 'flex-start',
        backgroundColor: 'transparent',
        top: isMobile ? '58px' : '200px',
        width: isMobile ? '100%' : '590px',
        ...(isMobile && { padding: '0 20px !important' }),
      },
      hideCloseMobile: true,
      noPadding: true,
      open: true,
      preventCloseOnBackgroundClick: false,
    },
  }

  const onHourlyClick = async e => {
    e.preventDefault()

    dispatch(resetHourlySearch())
    dispatch(
      showModal({
        ...commonModalProps,
        modalType: 'hourlyParkingSearch',
      })
    )
  }

  const onMonthlyButtonClick = e => {
    e.preventDefault()

    dispatch(resetMonthlyParking())
    dispatch(
      showModal({
        ...commonModalProps,
        modalType: 'monthlyParkingSearch',
      })
    )
  }

  const handleOnReservedParkingClick = (e, customEvent) => {
    e.preventDefault()
    dispatch(resetReservedParking())
    dispatch(
      showModal({
        ...commonModalProps,
        modalType: 'reservedParkingSearch',
      })
    )
    handleOnClick(e, customEvent)
  }

  const isMyPassesActive = () =>
    pathname === '/my-passes' ||
    pathname === '/events/[event]/tickets/[ticketId]/receipt' ||
    pathname === '/reserved-parking/tickets/[passId]/receipt'

  const getUserMenuItems = () => {
    if (!isPageLoaded) return null

    if (isAuthenticated) {
      return (
        <>
          {!onlyLogoutButton && (
            <>
              {!isGuest && (
                <NavItemComponent application={application}>
                  <Link href={`${getAppRoute(application, 'url')}/my-account`}>
                    <ItemLinkComponent
                      className={classNames('itemLink highlight', {
                        active: pathname === '/my-account',
                      })}
                      onClick={handleOnClick}>
                      {t('header.menu.buttons.myAccount')}
                    </ItemLinkComponent>
                  </Link>
                </NavItemComponent>
              )}
              <NavItemComponent application={application}>
                <Link href={`${getAppRoute(application, 'url')}/my-passes`}>
                  <ItemLinkComponent
                    className={classNames('itemLink highlight', {
                      active: isMyPassesActive(),
                    })}
                    onClick={handleOnClick}>
                    {t('header.menu.buttons.passesAndReceipts')}
                  </ItemLinkComponent>
                </Link>
              </NavItemComponent>
            </>
          )}
          {!isGuest && (isMobile || isTablet) && (
            <NavItemComponent application={application}>
              <ItemLinkComponent
                className="itemLink highlight logoutButton"
                onClick={e => handleOnClick(e, onLogoutUser)}>
                {t('header.menu.buttons.logout')}
              </ItemLinkComponent>
            </NavItemComponent>
          )}
          {isGuest && (
            <NavItemComponent application={application}>
              <Link href={`${getAppRoute(application, 'url')}/signup`}>
                <ItemLinkComponent
                  className="itemLink highlight signUpButton"
                  onClick={handleOnClick}>
                  {t('header.menu.buttons.signup')}
                </ItemLinkComponent>
              </Link>
            </NavItemComponent>
          )}
        </>
      )
    }

    return (
      <>
        <NavItemComponent application={application}>
          <Link href={`${getAppRoute(application, 'url')}/signup`}>
            <ItemLinkComponent
              className="itemLink highlight signUpButton"
              onClick={handleOnClick}>
              {t('header.menu.buttons.signup')}
            </ItemLinkComponent>
          </Link>
        </NavItemComponent>
        <NavItemComponent application={application}>
          <ItemLinkComponent
            className="itemLink highlight loginButton"
            onClick={e => {
              handleOnClick(e, () => {
                onLoginButton(`${getAppRoute(application, 'url')}/my-passes`)
              })
            }}>
            {t('header.menu.buttons.login')}
          </ItemLinkComponent>
        </NavItemComponent>
      </>
    )
  }

  const getMenuItems = () => (
    <>
      {!isEmpty(activeReservation) && isMobile && (
        <NavItemComponent application={application}>
          <Link
            href={`/parking-lots/${getPayBySignageUrl(
              activeReservation.lot
            )}/reservation/${activeReservation.id}/receipt`}>
            <ItemLinkComponent
              className={classNames('itemLink highlight', {
                active: pathname.includes('/parking-lots/'),
              })}
              onClick={handleOnClick}>
              {t('header.menu.buttons.activeParkingPass')}
            </ItemLinkComponent>
          </Link>
        </NavItemComponent>
      )}
      {!onlyLogoutButton && (
        <>
          <MenuDropdown
            text={t('header.menu.buttons.buyParking')}
            active={[
              '/events',
              '/monthly',
              '/reserved-parking',
              '/hourly-parking',
            ].includes(pathname)}>
            <MenuDropdownItem
              href={`${getAppRoute(application, 'url')}/events`}
              onClick={handleOnClick}>
              {t('header.menu.buttons.events')}
            </MenuDropdownItem>
            <MenuDropdownItem
              href={`${getAppRoute(application, 'url')}/monthly`}
              onClick={onMonthlyButtonClick}>
              {t('header.menu.buttons.monthly')}
            </MenuDropdownItem>
            <MenuDropdownItem
              href={`${getAppRoute(application, 'url')}/reserved-parking`}
              onClick={handleOnReservedParkingClick}>
              {t('header.menu.buttons.reservedParking')}
            </MenuDropdownItem>
            {/* Hourly menu item */}
            <MenuDropdownItem
              href={`${getAppRoute(application, 'url')}/hourly-parking`}
              onClick={onHourlyClick}>
              {t('header.menu.buttons.hourlyParking')}
            </MenuDropdownItem>
          </MenuDropdown>
          <NavItemComponent application={application}>
            <Link href={`${getAppRoute(application, 'url')}/how-it-works`}>
              <ItemLinkComponent
                className={classNames('itemLink', {
                  active: pathname === '/how-it-works',
                })}
                onClick={handleOnClick}>
                {t('header.menu.buttons.howItWorks')}
              </ItemLinkComponent>
            </Link>
          </NavItemComponent>
          <NavItemComponent application={application}>
            <Link
              href={`${getAppRoute(
                application,
                'url'
              )}/download-app?hideBackButton=true`}>
              <ItemLinkComponent
                className={classNames('itemLink', {
                  active: pathname === '/download-app',
                })}
                onClick={handleOnClick}>
                {t('header.menu.buttons.getOurApp')}
              </ItemLinkComponent>
            </Link>
          </NavItemComponent>
          <NavItemComponent application={application}>
            <ItemLinkComponent
              href="https://www.citifyd.biz"
              className="itemLink">
              {t('header.menu.buttons.parkingProvider')}
            </ItemLinkComponent>
          </NavItemComponent>
        </>
      )}
      {getUserMenuItems()}
    </>
  )

  return (
    <Nav>
      <HamburgerButtonComponent onClick={() => setActive(!isActive)}>
        <FaBars className="icon" />
      </HamburgerButtonComponent>
      <NavComponent
        application={application}
        className={classNames({
          active: isActive,
        })}>
        {isMobile || isTablet ? (
          <GridComponent>
            <Row>
              <Col xs={12} md={12}>
                {getMenuItems()}
              </Col>
            </Row>
          </GridComponent>
        ) : (
          getMenuItems()
        )}
      </NavComponent>
    </Nav>
  )
}

Menu.propTypes = {
  application: PropTypes.string,
  isAuthenticated: PropTypes.bool,
  isGuest: PropTypes.bool,
  isPageLoaded: PropTypes.bool,
  onLoginButton: PropTypes.func,
  onLogoutUser: PropTypes.func,
  onlyLogoutButton: PropTypes.bool,
  t: PropTypes.func,
}

export default withRouter(Menu)
