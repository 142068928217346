import moment from 'moment'
import { slice } from 'lodash'

const getTimeSince = ({
  expirationTime,
  reservationStartTime,
  availabilityStartTime,
}) => {
  const expirationMoment = moment(expirationTime)
  const availabilityStartMoment = moment(availabilityStartTime)
  const reservationStartMoment = moment(reservationStartTime)

  const timeSinceAvailabilityStart = expirationMoment.diff(
    availabilityStartMoment,
    'minutes'
  )
  const timeSinceReservationStart = expirationMoment.diff(
    reservationStartMoment,
    'minutes'
  )

  return { timeSinceAvailabilityStart, timeSinceReservationStart }
}

const getRuleStartMoment = ({
  rule,
  availabilityStart,
  reservationStartTime,
  timezoneName,
}) => {
  const { start } = rule

  const reservationStartMoment = moment.tz(reservationStartTime, timezoneName)

  switch (start.type) {
    case 'reservationDuration':
      return reservationStartMoment.clone().add(start.minute, 'minutes')
    case 'sinceAvailabilityStart': {
      return moment
        .tz(availabilityStart, timezoneName)
        .add(start.minute, 'minutes')
    }
    default:
      throw new Error(`Unknown rate rule start type ${start.type}`)
  }
}

const isRuleAvailable = ({
  rule,
  timeSinceReservationStart,
  timeSinceAvailabilityStart,
}) => {
  const { start } = rule
  const { type, minute } = start

  switch (type) {
    case 'reservationDuration':
      return minute >= timeSinceReservationStart
    case 'sinceAvailabilityStart':
      return minute >= timeSinceAvailabilityStart
    default:
      throw new Error(`Unknown start type ${type}`)
  }
}

export const isRuleBasedOnDuration = ({ rules, ruleIndex }) => {
  const nextRule = rules[ruleIndex + 1]

  return nextRule && nextRule.start.type === 'reservationDuration'
}

export const getRuleAggregatedPrice = ({ rules, ruleIndex }) => {
  const appliedRules = slice(rules, 0, ruleIndex + 1)

  return appliedRules.reduce((result, rule, i) => {
    const { replace, value } = rule

    if (i === 0 || replace) {
      return value
    }

    return result + value
  }, 0)
}

export const getVariableRuleExpirationMoment = ({
  rules,
  ruleIndex,
  reservationStartTime,
  availabilityStart,
  availabilityEnd,
  timezoneName,
}) => {
  const nextRule = rules[ruleIndex + 1]
  const endTime = nextRule
    ? getRuleStartMoment({
        rule: nextRule,
        availabilityStart,
        reservationStartTime,
        timezoneName,
      })
    : moment.tz(availabilityEnd, timezoneName)

  return endTime.add(-1, 'seconds')
}

export const getAvailableRulesForExtension = ({
  rules,
  expirationTime,
  reservationStartTime,
  availabilityStartTime,
  timezoneName,
}) => {
  const { timeSinceAvailabilityStart, timeSinceReservationStart } =
    getTimeSince({
      expirationTime,
      reservationStartTime,
      availabilityStartTime,
      timezoneName,
    })

  return rules.filter(rule =>
    isRuleAvailable({
      rule,
      timeSinceAvailabilityStart,
      timeSinceReservationStart,
    })
  )
}
