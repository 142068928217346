import { useMemo } from 'react'
import merge from 'deepmerge'
import { ApolloClient, from, HttpLink, InMemoryCache } from '@apollo/client'
import { isEqual } from 'lodash'
import { onError } from '@apollo/client/link/error'
import { setContext } from '@apollo/client/link/context'

import {
  getAccessToken,
  getImpersonateUserId,
} from 'redux-web/utils/session/actions'

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__'

let apolloClient

const errorLink = onError(({ networkError, graphqlErrors }) => {
  if (graphqlErrors) {
    graphqlErrors.map(({ message }) => {
      console.error(`GraphQL caught an error: ${message}`)
    })
  }

  if (networkError) {
    console.error(`GraphQL caught an error: ${networkError}`)
  }
})

const authLink = setContext((_, { headers }) => {
  const token = getAccessToken()
  const impersonateUserId = getImpersonateUserId()

  return {
    headers: {
      ...headers,
      ...(token && {
        Authorization: `Bearer ${token}`,
      }),
      ...(impersonateUserId && {
        'Impersonate-user-id': impersonateUserId,
      }),
    },
  }
})

const link = from([
  errorLink,
  authLink,
  new HttpLink({
    uri: process.env.NEXT_PUBLIC_GRAPHQL_API_URL,
    credentials: 'same-origin',
  }),
])

function createApolloClient() {
  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link,
    cache: new InMemoryCache({
      // typePolicies: {
      //   Query: {
      //     fields: {
      //       allPosts: concatPagination(),
      //     },
      //   },
      // },
    }),
  })
}

export function initializeApollo(initialState = null) {
  const _apolloClient = apolloClient ?? createApolloClient()

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract()

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache, {
      // Combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter(d => sourceArray.every(s => !isEqual(d, s))),
      ],
    })

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data)
  }

  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient

  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient

  return _apolloClient
}

export function addApolloState(client, pageProps) {
  if (pageProps?.props) {
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract()
  }

  return pageProps
}

export function useApollo(pageProps) {
  const state = pageProps[APOLLO_STATE_PROP_NAME]
  const store = useMemo(() => initializeApollo(state), [state])

  return store
}
