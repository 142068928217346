import { withRouter } from 'next/router'
import { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import { getTicketSession } from 'redux-web/utils/session/actions'
import { useTranslation } from 'i18n-web/i18next'
import { showModal } from 'components/Atoms/Modal/actions'
import { showBasicModal } from 'helpers/modal'
import API from 'api'

import Screen from './Screen'

const FormikHOC = props => {
  const { barcodeActivation, exportReceipt, router, signUpLink } = props

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const ticketId = useSelector(state => state.ticket.id)
  const ticketCode = useSelector(state => state.ticket.code)

  const isSDK = useSelector(state => state.settings.isSDK)
  const user = useSelector(state => state.user.me)
  const formikRef = useRef()
  const initialValues = {
    email: '',
  }

  useEffect(() => {
    formikRef.current.setFieldValue('email', user?.email)
  }, [user])

  const handleSubmit = async (values, { setSubmitting, setStatus }) => {
    setSubmitting(true)
    const ticketSession = getTicketSession()

    try {
      let resp

      if (exportReceipt) {
        resp = await API().exportReceipt(ticketCode, {
          body: JSON.stringify({
            email: values.email,
          }),
        })
      } else if (barcodeActivation) {
        resp = await API().exportTicket(ticketId, {
          timeoutTryAgainMessage: true,
          ...(ticketSession && { accessToken: ticketSession }),
          ...(isSDK && { sdk: true }),
          body: JSON.stringify({
            ticketExport: {
              type: 'print',
              rendering: 'email',
            },
            email: values.email,
          }),
        })
      } else {
        resp = await API().exportTicketReceipt(ticketId, {
          timeoutTryAgainMessage: true,
          ...(ticketSession && { accessToken: ticketSession }),
          ...(isSDK && { sdk: true }),
          body: JSON.stringify({
            email: values.email,
          }),
        })
      }

      if (!resp.error) {
        setSubmitting(false)
        let modalContent

        if (!user?.isGuest) {
          modalContent = {
            actionButtons: {
              singleButtonOnClick: () => {},
              singleButtonText: t('common.button.close'),
            },
            title: t('events.receipt.emailSent.user.title'),
            description: t('events.receipt.emailSent.user.description'),
          }
        } else if (isSDK) {
          modalContent = {
            actionButtons: {
              singleButtonOnClick: () => {},
              singleButtonText: t('common.button.close'),
            },
            title: t('events.receipt.emailSent.guests.title'),
            description: t('events.receipt.emailSent.SDKguests.description'),
          }
        } else {
          modalContent = {
            actionButtons: {
              rightButtonOnClick: () => signUpLink(values.email),
              rightButtonText: t(
                'events.receipt.emailSent.guests.buttons.createAccount'
              ),
              singleButtonOnClick: () => {},
              singleButtonText: t('common.button.close'),
            },
            title: t('events.receipt.emailSent.guests.title'),
            description: t('events.receipt.emailSent.guests.description'),
          }
        }

        dispatch(showBasicModal({ ...modalContent }))
      } else if (resp.error.code === 401) {
        setSubmitting(false)
        dispatch(
          showModal({
            childProps: {
              preventRedirection: true,
              redirectTo: router.asPath,
            },
            modalProps: {
              preventClose: true,
              className: 'noBorders',
            },
            modalType: 'signIn',
          })
        )
      } else if (resp.error.code === 408) {
        dispatch(
          showBasicModal({
            title: resp.error.message,
          })
        )
      } else {
        setSubmitting(false)
        if (resp.error.message) {
          dispatch(showBasicModal({ title: resp.error.message }))
        }
      }
    } catch (err) {
      setStatus({ message: err })
    }
  }

  return (
    <Screen
      {...props}
      t={t}
      formikRef={formikRef}
      initialValues={initialValues}
      handleSubmit={handleSubmit}
    />
  )
}

FormikHOC.propTypes = {
  barcodeActivation: PropTypes.bool,
  exportReceipt: PropTypes.bool,
  hasActiveReservation: PropTypes.bool,
  isRegularUser: PropTypes.bool,
  router: PropTypes.object,
  signUpLink: PropTypes.bool,
  submitText: PropTypes.string,
  t: PropTypes.func,
}

export default withRouter(FormikHOC)
