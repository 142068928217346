/* eslint-disable sort-keys */

const blazers = {
  palette: {
    black: '#000000',
    white: '#ffffff',
    grayDark: '#373840',
    gray: '#9b9b9b',
    grayLight: '#cccccc',
    grayLighter: '#ededed',
    background: '#f8f8f8',
    primary: '#bd2531',
    quaternary: '#bd2531',
    error: '#bd2531',
    highlight: '#bd2531',
    facebookBlue: '#3f69a7',
    textButton: '#ffffff',
    intercom: {
      action: '#cc0000',
      background: '#000000',
    },
  },
  font: {
    size: {
      mobile: {
        h1: '40px',
        h2: '24px',
        h3: '22px',
        h4: '20px',
        regular: '18px',
        medium: '16px',
        small: '14px',
        tiny: '12px',
      },
      desktop: {
        h1: '60px',
        h2: '44px',
        h3: '30px',
        h4: '20px',
        large: '18px',
        regular: '16px',
        medium: '16px', // Duplicated to match mobile sizes
        small: '14px',
        tiny: '12px',
      },
    },
  },
  flexboxgrid: {
    mediaQuery: 'only screen',
    outerMargin: 1,
    container: {
      sm: 46,
      md: 61,
      lg: 61,
    },
    breakpoints: {
      xs: 0,
      sm: 48,
      md: 64,
      lg: 64,
    },
  },
}

export default blazers
