import { TiLocationArrow } from 'react-icons/ti'
import styled from 'styled-components'

import Button from 'components/Atoms/Button'
import Input from 'components/Atoms/Input'
import Text from 'components/Atoms/Text'

export const Wrapper = styled.div`
  @media (max-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    padding: 20px 20px 25px;
    background-color: ${props => props.theme.palette.white};
    border-radius: 4px;
  }
`

export const TextInput = styled(Input)`
  margin: 0;
  padding: 4px 12px;
  width: 100%;
  height: 42px;
  font-size: ${props => props.theme.font.size.mobile.medium};
  font-weight: 800;
  text-transform: uppercase;
  background-color: ${props => props.theme.palette.background};
  border: solid 0.1rem ${props => props.theme.palette.gray} !important;
  border-radius: 4px;

  :focus {
    background-color: ${props => props.theme.palette.background};
    border: solid 0.1rem ${props => props.theme.palette.gray} !important;
    border-bottom: solid 2px ${props => props.theme.palette.grayDark};
  }

  ::placeholder {
    color: ${props => props.theme.palette.grayLight};
  }

  ${({ isDate }) =>
    isDate &&
    `
    padding: 5px 0;
    padding-bottom: 5px;
    height: 30px;
    cursor: pointer;
  `}

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    padding: 28px 20px;
    height: 88px;
    font-size: ${props => props.theme.font.size.desktop.h3};
    font-weight: 800;
    border: none !important;

    :focus {
      background-color: ${props => props.theme.palette.background};
      border: none;
      border-bottom: solid 2px ${props => props.theme.palette.grayDark};
    }
  }
`

export const Suggestions = styled.div`
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  padding-top: 10px;

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    position: absolute;
    top: 100px;
    padding-top: 0;
  }
`

export const SuggestionItemSecondary = styled.span`
  color: ${props => props.theme.palette.gray};

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    color: ${props => props.theme.palette.grayLight};
  }
`

export const SuggestionItem = styled.span`
  display: block;
  padding: 10px;
  font-size: ${props => props.theme.font.size.mobile.medium};
  font-weight: 600;
  color: ${props => props.theme.palette.grayDark};

  :hover {
    color: ${props => props.theme.palette.grayDark};

    ${SuggestionItemSecondary} {
      color: ${props => props.theme.palette.gray};
    }
  }

  ${({ isLoading }) =>
    !isLoading &&
    `
    cursor: pointer;
  `}

  ${({ myLocation }) =>
    myLocation &&
    `
    cursor: pointer;
    display: flex;
    align-items: center;
  `}

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    padding: 10px 16px;
    font-size: ${props => props.theme.font.size.desktop.h3};
    color: ${props => props.theme.palette.white};

    :hover {
      color: ${props => props.theme.palette.highlight};

      ${SuggestionItemSecondary} {
        color: ${props => props.theme.palette.highlight};
      }
    }
  }
`

export const LocationHint = styled(Text)`
  padding-bottom: 20px;

  &.hidden {
    visibility: hidden;
  }

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    position: absolute;
    top: -52px;
    padding-left: 20px;
    font-size: ${props => props.theme.font.size.desktop.large};
  }
`

export const LocationError = styled(Text)`
  padding: 10px 0 0;

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    padding: 15px 25px 0;
    font-size: ${props => props.theme.font.size.desktop.h4};
  }
`

export const LocationIcon = styled(TiLocationArrow)`
  font-size: ${props => props.theme.font.size.mobile.h2};
  margin-left: 5px;

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    margin-left: 20px;
    font-size: ${props => props.theme.font.size.desktop.h2};
  }
`

export const SubmitButton = styled(Button)`
  font-size: ${props => props.theme.font.size.mobile.h3};
  font-weight: 400;
  text-transform: uppercase;
  margin-top: 10px;

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    height: 70px;
    font-size: ${props => props.theme.font.size.desktop.h3};
  }
`
export const AlertBox = styled.div`
  margin-top: 20px;
  padding: 8px 12px;
  border-radius: 4px;
  background-color: ${props => props.theme.palette.primary};
`

export const Component = styled.div`
  width: 100%;

  border-radius: 4px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0;
  margin-bottom: 20px;
`
