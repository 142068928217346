import React, { useMemo, useState, useEffect } from 'react'
import { useStripe } from '@stripe/react-stripe-js'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty } from 'lodash'

import { getGuestSession } from 'helpers/guest'
import { createGuestUser } from 'helpers/guest'
import {
  setIsSubmitting,
  setNativePaymentType,
} from 'redux-web/utils/purchase/actions'
import {
  setReservationSession,
  getReservationSession,
} from 'helpers/reservation'

import Screen from './Screen'

const useOptions = paymentRequest => {
  const options = useMemo(
    () => ({
      paymentRequest,
      style: {
        paymentRequestButton: {
          theme: 'dark',
          height: '43px',
          type: 'buy',
        },
      },
    }),
    [paymentRequest]
  )

  return options
}

const usePaymentRequest = ({
  options,
  onPaymentMethod,
  paymentRequestLoadResponse,
}) => {
  const stripe = useStripe()
  const dispatch = useDispatch()
  const [paymentRequest, setPaymentRequest] = useState(null)
  const [canMakePayment, setCanMakePayment] = useState(false)

  useEffect(() => {
    if (stripe && paymentRequest === null) {
      const pr = stripe.paymentRequest(options)

      setPaymentRequest(pr)
    }
  }, [stripe, options, paymentRequest])

  useEffect(() => {
    let subscribed = true

    if (paymentRequest) {
      paymentRequest.canMakePayment().then(res => {
        paymentRequestLoadResponse(true)

        if (res && subscribed) {
          dispatch(
            setNativePaymentType(res.applePay ? 'ApplePay' : 'GooglePay')
          )
          setCanMakePayment(true)
        }
      })
    }

    return () => {
      subscribed = false
    }
  }, [paymentRequest])

  useEffect(() => {
    if (paymentRequest) {
      paymentRequest.on('token', onPaymentMethod)
    }

    return () => {
      if (paymentRequest) {
        paymentRequest.off('token', onPaymentMethod)
      }
    }
  }, [paymentRequest, onPaymentMethod])

  return canMakePayment ? paymentRequest : null
}

const PaymentsRequestButton = ({
  onPurchase,
  currency,
  countryCode,
  amount,
  guestUser,
  phoneNumber,
  isExtendingParking,
  paymentRequestLoadResponse,
  requiredFieldIsEmpty,
  onSubmitDenied,
  isValid,
  setSubmitCallback,
  onCustomPurchaseMethod,
}) => {
  const dispatch = useDispatch()
  const user = useSelector(state => state.user.me)
  const { termsAndConditionsAgreement } = useSelector(state => state.purchase)
  const isSDK = useSelector(state => state.settings.isSDK)
  const selectedNativePayment = useSelector(
    state => state.purchase.selectedNativePayment
  )

  const guestSession = getGuestSession()

  const paymentRequest = usePaymentRequest({
    options: {
      country: countryCode.toUpperCase(),
      currency: currency,
      total: {
        label: 'Total',
        amount: amount > 0 ? amount : 0,
      },
    },
    onPaymentMethod: async ({ complete, token }) => {
      try {
        dispatch(setIsSubmitting(true))
        let accessToken = getReservationSession()

        if (!guestUser && guestSession && !accessToken) {
          await createGuestUser({
            country: countryCode,
            phoneNumber: phoneNumber?.number,
            phoneVerificationToken: phoneNumber.phoneVerificationToken,
            dispatch,
            isSDK,
          })
        } else if (isEmpty(user) && !accessToken && !isExtendingParking) {
          const reservation = await setReservationSession()

          accessToken = reservation.accessToken
        }

        if (onCustomPurchaseMethod) {
          onCustomPurchaseMethod({
            paymentToken: token.id,
            ...(accessToken && {
              accessToken: accessToken,
            }),
            sendPhoneNumber: Boolean(accessToken),
          })
        } else {
          await onPurchase({
            paymentToken: token.id,
            ...(accessToken && {
              accessToken: accessToken,
            }),
            sendPhoneNumber: Boolean(accessToken),
          }).then(resp => {
            if (resp.error) {
              dispatch(setIsSubmitting(false))
              setSubmitCallback({ error: true })
            }
          })
        }
        complete('success')
      } catch (err) {
        dispatch(setIsSubmitting(false))
        complete('fail')
      }
    },
    paymentRequestLoadResponse,
  })

  const options = useOptions(paymentRequest)

  if (!options.paymentRequest || !selectedNativePayment) {
    return null
  }

  return (
    <Screen
      options={options}
      disabled={Boolean(
        !isValid && (guestUser || guestSession || isEmpty(user))
      )}
      onSubmitDenied={onSubmitDenied}
      requiredFieldIsEmpty={requiredFieldIsEmpty}
      paymentRequestLoadResponse={paymentRequestLoadResponse}
      termsAndConditionsAgreement={termsAndConditionsAgreement}
    />
  )
}

PaymentsRequestButton.propTypes = {
  currency: PropTypes.string,
  countryCode: PropTypes.string,
  amount: PropTypes.number,
  onPurchase: PropTypes.func,
  onCustomPurchaseMethod: PropTypes.func,
  setSubmitCallback: PropTypes.func,
  guestUser: PropTypes.bool,
  phoneNumber: PropTypes.any,
  onSubmitDenied: PropTypes.func,
  paymentRequestLoadResponse: PropTypes.func,
  requiredFieldIsEmpty: PropTypes.bool,
  isValid: PropTypes.bool,
  isExtendingParking: PropTypes.bool,
}

export default PaymentsRequestButton
