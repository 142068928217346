import { useDispatch } from 'react-redux'
import { withRouter } from 'next/router'
import PropTypes from 'prop-types'

import { hideModal } from 'components/Atoms/Modal/actions'
import { useTranslation } from 'i18n-web/i18next'

import Screen from './Screen'

const SendPassToEmail = props => {
  const { router } = props
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { application } = router.query

  const onCancelLink = () => {
    dispatch(hideModal())
  }

  return (
    <Screen
      onCancelLink={onCancelLink}
      application={application}
      t={t}
      {...props}
    />
  )
}

SendPassToEmail.propTypes = {
  router: PropTypes.object,
}

export default withRouter(SendPassToEmail)
