import { schema } from 'normalizr'

export const country = new schema.Entity(
  'countries',
  {},
  {
    idAttribute: 'isoCode',
  }
)

export const continent = new schema.Entity(
  'continents',
  {
    countries: [country],
  },
  {
    idAttribute: 'name',
  }
)
