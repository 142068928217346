import styled from 'styled-components'
import PropTypes from 'prop-types'
import { withRouter } from 'next/router'
import classNames from 'classnames'

import Text from 'components/Atoms/Text'
import isLightMode from 'helpers/isLightMode'

const Title = styled(Text)`
  line-height: 100%;
  z-index: 1;
  font-size: ${props => props.theme.font.size.mobile.small};
  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    font-size: ${props => props.theme.font.size.desktop.h4};
  }
`

Title.displayName = 'Thumbnail__Title'

const Subtitle = styled(Text)`
  line-height: 100%;
  margin-top: 8px;
  font-size: 9px;
  z-index: 1;
  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    font-size: ${props => props.theme.font.size.desktop.tiny};
  }
`

Subtitle.displayName = 'Thumbnail__Subtitle'

const Component = styled.div`
  &:before {
    content: '';
    background-color: ${props => props.theme.palette.primary};
    opacity: 0.8;
    width: 100%;
    height: 100%;
    position: absolute;
  }
  &:hover {
    color: ${props => props.theme.palette.grayDark};
  }
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 0 5px;
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
`

const Thumbnail = ({ title, router, subtitle, className }) => {
  const {
    query: { application },
  } = router

  return (
    <Component>
      <Title
        className={classNames(className, 'extraBold', 'center', {
          contrastText: !isLightMode(application),
        })}>
        {title}
      </Title>
      {subtitle && (
        <Subtitle
          className={classNames('extraBold', 'center', {
            contrastText: !isLightMode(application),
          })}>
          {subtitle}
        </Subtitle>
      )}
    </Component>
  )
}

Thumbnail.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  router: PropTypes.object,
  subtitle: PropTypes.string,
}

export default withRouter(Thumbnail)
