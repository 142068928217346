import 'react-dates/initialize'
import PropTypes from 'prop-types'

import DatePicker from './DatePicker'

const DateTimePicker = ({
  enableSelectFrom,
  isModalMobileVersion,
  isModalVersion,
  label,
  onChange,
  value,
}) => (
  <DatePicker
    enableSelectFrom={enableSelectFrom}
    isModalMobileVersion={isModalMobileVersion}
    isModalVersion={isModalVersion}
    label={label}
    onChange={onChange}
    value={value}
  />
)

DateTimePicker.propTypes = {
  enableSelectFrom: PropTypes.string,
  isModalMobileVersion: PropTypes.bool,
  isModalVersion: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
}

export default DateTimePicker
