import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { withRouter } from 'next/router'
import PropTypes from 'prop-types'

import { showBasicModal } from 'helpers/modal'
import { useTranslation } from 'i18n-web/i18next'
import { hideModal } from 'components/Atoms/Modal/actions'
import API from 'api'

import Screen from './Screen'

const VerifyPhoneNumber = props => {
  const { countryCode, email, phoneNumber, type } = props

  const modalProps = useSelector(state => state.modal.modalProps)
  const isSDK = useSelector(state => state.settings.isSDK)

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [formikRef, setFormikRef] = useState(null)
  const [codeSentBy, setCodeSentBy] = useState('textMessage')

  const onCancel = () => {
    dispatch(hideModal())
  }
  const onResendCode = () => {
    setCodeSentBy('textMessage')
    resendCode('textMessage')
  }
  const onCall = () => {
    setCodeSentBy('phoneCall')
    resendCode('phoneCall')
  }

  const formikInstance = ref => {
    setFormikRef(ref)
  }

  const resendCode = async method => {
    if (type === 'passwordChange') {
      let data

      const hasPhoneNumber = phoneNumber && !email
      const hasEmail = !hasPhoneNumber

      if (hasEmail) {
        data = {
          method: 'code',
          email: email,
        }
      } else if (hasPhoneNumber) {
        data = {
          method: 'code',
          phoneCountryCode: countryCode,
          phoneNumber,
        }
      }

      await API()
        .resetPassword({
          timeoutTryAgainMessage: true,
          body: JSON.stringify(data),
          ...(isSDK && { sdk: true }),
        })
        .then(resp => {
          formikRef.setSubmitting(false)

          if (resp.message) {
            formikRef.setStatus({
              message: resp.message,
              success: true,
            })
          } else if (resp.error) {
            formikRef.setStatus({ message: resp.error && resp.error.message })
            if (resp.error?.code === 408) {
              dispatch(
                showBasicModal({
                  title: resp.error.message,
                })
              )
            }
          }
        })
        .catch(err => {
          formikRef.setSubmitting(false)
          formikRef.setStatus({ message: err })
        })
    } else {
      await API()
        .sendPhoneVerification({
          timeoutTryAgainMessage: true,
          ...(isSDK && { sdk: true }),
          body: JSON.stringify({
            method: method,
            phoneCountryCode: countryCode,
            phoneNumber: phoneNumber,
          }),
        })
        .then(resp => {
          formikRef.setSubmitting(false)

          if (resp.success) {
            formikRef.setStatus({
              message: resp.success.message,
              success: true,
            })
          } else {
            formikRef.setStatus({ message: resp.error && resp.error.message })
            if (resp.error.code === 408) {
              dispatch(
                showBasicModal({
                  title: resp.error.message,
                })
              )
            }
          }
        })
        .catch(err => {
          formikRef.setSubmitting(false)
          formikRef.setStatus({ message: err })
        })
    }
  }

  return (
    <Screen
      {...props}
      onCancel={onCancel}
      onResendCode={onResendCode}
      preventClose={modalProps?.preventClose}
      formikRef={formikInstance}
      onCall={onCall}
      codeSentBy={codeSentBy}
      t={t}
    />
  )
}

VerifyPhoneNumber.propTypes = {
  countryCode: PropTypes.string,
  email: PropTypes.string,
  phoneNumber: PropTypes.string,
  type: PropTypes.string,
}

export default withRouter(VerifyPhoneNumber)
