import { useRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, Col, Row } from 'react-styled-flexboxgrid'
import { isEmpty } from 'lodash'
import { withRouter } from 'next/router'

import { hideModal } from 'components/Atoms/Modal/actions'
import Text from 'components/Atoms/Text'

import Header from './Header'
import Button from './Button'
import Image from './Image'

const Description = styled.div`
  padding-top: 16px;
`

const Component = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
`

const Scrollable = styled.div`
  flex: 1;
  overflow: scroll;
`

const InnerScrollable = styled.div`
  padding-bottom: 40px;
`

const Box = styled.div`
  margin-top: 10px;
`

const RateText = styled(Text)`
  display: inline;
  &:last-of-type {
    margin-left: 5px;
  }
`

const Divisor = styled.hr`
  margin: 13px 0;
  border-left: 0;
  border-top: 1px solid ${props => props.theme.palette.grayLight};
`

const Card = ({
  address,
  barcodeActivation,
  changePolicies,
  distance,
  image,
  latitude,
  longitude,
  name,
  notes,
  onLotSelect,
  rate,
  rateBreakdown,
  router,
  showPreview,
  t,
  ticketExportAllowed,
  time,
}) => {
  const scrollableElement = useRef()
  const innerScrollableElement = useRef()
  const dispatch = useDispatch()
  const [showButtonShadow, setShowButtonShadow] = useState(false)
  const isSDK = useSelector(state => state.settings.isSDK)

  useEffect(() => {
    if (showPreview) {
      setShowButtonShadow(
        innerScrollableElement.current.offsetHeight >
          scrollableElement.current.offsetHeight
      )
    }
  }, [showPreview])

  const {
    query: { application },
  } = router

  return (
    <Component>
      <Scrollable ref={scrollableElement}>
        <InnerScrollable ref={innerScrollableElement}>
          <Image image={image} />
          <Header
            name={name}
            distance={distance}
            latitude={latitude}
            longitude={longitude}
            hideDirections={isSDK}
          />
          <Grid>
            <Row>
              <Col xs={12}>
                <Row>
                  <Col xs={12}>
                    <Description>
                      <Text className="small semiBold">{address}</Text>
                      <Text className="small semiBold">{time}</Text>
                      <Box>
                        <RateText className="regular extraBold inline">
                          {rate}
                        </RateText>
                        <RateText className="small extraBold inline">
                          {`(${rateBreakdown})`}
                        </RateText>
                      </Box>
                      {ticketExportAllowed & barcodeActivation && (
                        <Box>
                          <Text className="small semiBold">
                            {t('event.printedPassOnly')}
                          </Text>
                        </Box>
                      )}
                      {!isEmpty(notes) && (
                        <Box>
                          <Text className="small semiBold">{notes}</Text>
                        </Box>
                      )}
                      <Divisor />
                      {changePolicies?.map((item, index) => (
                        <Text key={index} className="tiny semiBold">
                          {item}
                        </Text>
                      ))}
                    </Description>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Grid>
        </InnerScrollable>
      </Scrollable>
      <Row>
        <Col xs={12}>
          <Row>
            <Col xs={12}>
              <Button
                application={application}
                onClick={lot => {
                  dispatch(hideModal())
                  onLotSelect(lot)
                }}
                showButtonShadow={showButtonShadow}
                t={t}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Component>
  )
}

Card.propTypes = {
  address: PropTypes.array,
  barcodeActivation: PropTypes.bool,
  changePolicies: PropTypes.array,
  distance: PropTypes.string,
  image: PropTypes.string,
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  name: PropTypes.string,
  notes: PropTypes.string,
  onLotSelect: PropTypes.func,
  rate: PropTypes.string,
  rateBreakdown: PropTypes.string,
  router: PropTypes.object,
  showPreview: PropTypes.bool,
  t: PropTypes.func,
  ticketExportAllowed: PropTypes.bool,
  time: PropTypes.string,
}

export default withRouter(Card)
