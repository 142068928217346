import { useEffect } from 'react'
import { debounce, isFunction } from 'lodash'
import PropTypes from 'prop-types'

const FormikObserver = ({ values, onChange }) => {
  const debounceInterval = 100

  useEffect(() => {
    debounceOnChange()
  }, [values])

  const handleChange = () => {
    if (isFunction(onChange)) {
      onChange(values)
    }
  }

  const debounceOnChange = debounce(handleChange, debounceInterval)

  return null
}

FormikObserver.propTypes = {
  onChange: PropTypes.func,
  values: PropTypes.object,
}

export default FormikObserver
