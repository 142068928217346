import styled from 'styled-components'
import { Formik, Form, Field } from 'formik'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Button from 'components/Atoms/Button'
import Text from 'components/Atoms/Text'
import isLightMode from 'helpers/isLightMode'
import { TextField, Checkbox } from 'components/Molecules/Form'

import validationSchema from './validationSchema'

const Component = styled(Form)`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 20px 20px 0;

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    max-width: 450px;
  }
`

const Description = styled(Text)`
  font-size: ${props => props.theme.font.size.mobile.medium};

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    font-size: ${props => props.theme.font.size.desktop.regular};
  }
`

const TwoFactorTextField = styled(TextField)`
  display: flex;
  align-items: center;
  width: 100%;

  .error {
    align-self: flex-start;
  }

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    width: 50%;
  }
`
const Fields = styled.div`
  text-align: left;
  align-items: flex-start;
`

const RememberCheckBox = styled(Checkbox)`
  align-items: flex-start;
  margin-top: 20px;
  height: auto;
`

const Group = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  width: 100%;

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    flex-direction: row;
    height: 44px;
  }

  .input {
    width: 100%;

    @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
      width: 50%;
    }
  }

  .inputField {
    margin: 0;
    width: 100%;
  }

  .button {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;

    @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
      margin-left: 10px;
      width: 50%;
    }
  }
`

const ErrorMessage = styled(Text)`
  margin-top: 15px;
  color: ${props => (props.success ? '#06a223' : props.theme.palette.error)};
  font-size: ${props => props.theme.font.size.mobile.medium};

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    font-size: ${props => props.theme.font.size.desktop.regular};
  }
`

const VerifyPhoneNumberForm = ({
  application,
  error,
  formikRef,
  handleSubmit,
  initialValues,
  codeSentBy,
  t,
}) => (
  <Formik
    ref={ref => formikRef(ref)}
    initialValues={initialValues}
    onSubmit={handleSubmit}
    validationSchema={validationSchema()}
    render={props => {
      const { isSubmitting, setStatus, values, status, handleChange } = props
      const message = (status && status.message) || error

      return (
        <Component>
          <Description>
            {codeSentBy === 'phoneCall'
              ? t('twoFactorAuthentication.form.resent.call')
              : codeSentBy === 'textMessage'
              ? t('twoFactorAuthentication.form.resent.sms')
              : t('twoFactorAuthentication.form.code.label')}
          </Description>
          <Fields>
            <Group>
              <Field
                name="code"
                maxlength="4"
                className="input"
                inputClassName="inputField h2"
                component={TwoFactorTextField}
                onFocus={() => {
                  message && setStatus(null)
                }}
              />

              <Button
                className={classNames('button', {
                  highlight: isLightMode(application),
                  grayDark: isLightMode(application),
                  contrastText: !isLightMode(application),
                })}
                disabled={isSubmitting}
                type="submit">
                {t('twoFactorAuthentication.form.button').toUpperCase()}
              </Button>
            </Group>
            <RememberCheckBox
              checked={values.saveCard}
              onClick={e => {
                message && setStatus(null)
                handleChange(e)
              }}
              name="remember"
              label="Checking this option means that you will no longer be required to enter an authentication code for this device. You can manage and remove devices in your account."
            />
          </Fields>
          {message && (
            <ErrorMessage success={status?.success} className="center">
              {message}
            </ErrorMessage>
          )}
        </Component>
      )
    }}
  />
)

VerifyPhoneNumberForm.propTypes = {
  application: PropTypes.string,
  codeSentBy: PropTypes.string,
  error: PropTypes.object,
  formikRef: PropTypes.func,
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  values: PropTypes.object,
  isSubmitting: PropTypes.bool,
  setStatus: PropTypes.func,
  status: PropTypes.string,
  handleChange: PropTypes.func,
  t: PropTypes.func,
}

export default VerifyPhoneNumberForm
