import * as Yup from 'yup'
import { parsePhoneNumberFromString } from 'libphonenumber-js'

import { getPasswordDefinitions } from 'models/password'

import { i18n } from '../i18next'

import schemaBR from './br'
import schemaUS from './us'

const schemas = { br: schemaBR, us: schemaUS }

const phoneNumberBasicSchema = ({ countryCode }) =>
  Yup.string().test({
    name: 'phoneNumber',
    message: i18n.t('signIn.form.phoneNumber.error'),
    test: value =>
      value == null || inputPhoneNumberValidation(value, countryCode),
  })

export const phoneNumberSchema = ({
  countryCode,
  required = true,
  requiredMessage = true,
}) => {
  let requiredField = required

  if (requiredField && requiredMessage) {
    requiredField = i18n.t('common.form.error.required')
  }

  return phoneNumberBasicSchema({
    countryCode,
  })
    .concat(schemas[countryCode])
    .required(requiredField)
}

export const inputPhoneNumberValidation = (phoneNumber, countryCode) => {
  const country = countryCode.toUpperCase()
  const number = parsePhoneNumberFromString(phoneNumber, country)

  if ((number && number.country !== country) || !number) {
    return false
  }

  return number.isValid()
}

export const passwordSchema = ({
  passwordRules,
  facebookToken,
  appleToken,
}) => {
  let passwordSchema = Yup.string()
    .min(passwordRules.min, i18n.t('signUp.form.password.errors.minimum'))
    .required(
      !facebookToken && !appleToken && i18n.t('common.form.error.required')
    )

  passwordRules.features &&
    passwordRules.features.map(
      feature =>
        (passwordSchema = passwordSchema.matches(
          getPasswordDefinitions[feature].pattern,
          getPasswordDefinitions[feature].message
        ))
    )

  return passwordSchema
}
