import { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import Text from 'components/Atoms/Text'

const Component = styled.div`
  position: relative;
  margin-top: 4px;
`

const Content = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 26px;
  left: 20px;
  z-index: 9;
  padding: 8px 12px;
  border-radius: 4px;
  text-align: left;
  background-color: ${props => props.theme.palette.grayLight};
`

const Title = styled(Text)`
  display: flex;
  align-items: flex-start;
  padding-top: 4px;

  &:before {
    content: '?';
    flex: none;
    background-color: ${props => props.theme.palette.primary};
    color: ${props => props.theme.palette.white};
    font-size: ${props => props.theme.font.size.desktop.regular};
    margin: -2px 5px 0 0;
    border-radius: 4px;
    width: 22px;
    height: 22px;
    display: block;
    padding: 2px 0 0 7px;
    font-weight: 800;
  }

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    align-items: center;
  }
`

const Tooltip = ({ title, children, className }) => {
  const ref = useRef()
  const [open, setOpen] = useState(false)

  const onClickOut = event => {
    if (ref.current && !ref.current.contains(event.target)) {
      event.stopPropagation()
      setOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', onClickOut)
    document.addEventListener('touchstart', onClickOut)

    return () => {
      document.removeEventListener('mousedown', onClickOut)
      document.removeEventListener('touchstart', onClickOut)
    }
  }, [])

  return (
    <Component ref={ref} onClick={() => setOpen(!open)} className={className}>
      <Title className="tiny">{title}</Title>
      {open && <Content>{children}</Content>}
    </Component>
  )
}

Tooltip.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.object,
}

export default Tooltip
