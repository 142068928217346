import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Col, Row, Grid } from 'react-styled-flexboxgrid'
import { useSelector } from 'react-redux'
import dynamic from 'next/dynamic'

import { getImpersonateUserId } from 'redux-web/utils/session/actions'
import BackButton from 'components/Atoms/BackButton'
import Backdrop from 'components/Atoms/Backdrop'
import WithTemplate from 'hocs/withTemplate'
import { Header, Footer } from 'components/Organisms'
const ImpersonateUserAlert = dynamic(
  () => import('components/Atoms/ImpersonateUserAlert'),
  { ssr: false }
)

const Component = styled.div`
  display: flex;
  overflow: ${props => (props.isFixed ? 'hidden' : 'auto')};
  height: ${props => (props.isFixed ? `${props.windowHeight}px` : 'auto')};
  min-height: ${props => (props.isFixed ? `${props.windowHeight}px` : 'auto')};
  flex-direction: column;

  .row {
    margin: 0;
  }

  .backButtonGrid {
    margin-left: initial;
  }

  .main {
    margin-top: 15px;
    margin-bottom: 14px;
  }

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    min-height: 100vh;
    height: auto;

    .backButtonGrid {
      margin-left: auto;
    }

    .main {
      margin-top: 35px;
      margin-bottom: 30px;
    }
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  background-color: ${props => props.theme.palette.background};
`

const Full = ({
  application,
  authLoading,
  children,
  hasCompactFooter,
  hideFooter,
  hideHeader,
  hideLanguageSelector,
  hideMenu,
  isFixed,
  loading,
  onBackButton,
  onLogoutRedirection,
  showBackButton,
}) => {
  const [windowHeight, setWindowHeight] = useState()
  const isSDK = useSelector(state => state.settings.isSDK)
  const impersonateUserId = getImpersonateUserId()
  const disableHeaderAndFooter = useSelector(
    state => state.settings.disableHeaderAndFooter
  )

  useEffect(() => {
    if (isSDK) {
      setTimeout(
        () =>
          setWindowHeight(process.env.NEXT_PUBLIC_MOBILE_LOT_PAGE_SDK_HEIGHT),
        100
      )
    } else {
      setTimeout(() => setWindowHeight(window.innerHeight), 100)
    }
  }, [isSDK])

  return (
    <Component isFixed={isFixed} windowHeight={windowHeight}>
      {impersonateUserId && <ImpersonateUserAlert />}
      {!hideHeader && !disableHeaderAndFooter && (
        <Header
          onLogoutRedirection={onLogoutRedirection}
          loading={loading}
          hideLanguageSelector={
            hideLanguageSelector ||
            process.env.NEXT_PUBLIC_HIDE_LANGUAGE_SELECTOR
          }
          hideMenu={hideMenu}
        />
      )}
      <Content>
        {showBackButton && (
          <Grid className="backButtonGrid">
            <Row className="main" start="xs">
              <Col xs={12}>
                <BackButton
                  onBackButton={onBackButton ? () => onBackButton() : null}
                />
              </Col>
            </Row>
          </Grid>
        )}
        {children}
      </Content>
      {!hideFooter && !disableHeaderAndFooter && (
        <Footer hasCompactFooter={hasCompactFooter} />
      )}
      {(loading || authLoading) && <Backdrop application={application} />}
    </Component>
  )
}

Full.propTypes = {
  application: PropTypes.string,
  authLoading: PropTypes.bool,
  children: PropTypes.node,
  hasCompactFooter: PropTypes.bool,
  hideFooter: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  hideHeader: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  hideLanguageSelector: PropTypes.bool,
  hideMenu: PropTypes.bool,
  isFixed: PropTypes.bool,
  loading: PropTypes.bool,
  onBackButton: PropTypes.func,
  onLogoutRedirection: PropTypes.string,
  showBackButton: PropTypes.bool,
}

Full.defaultProps = {
  hideHeader: false,
  loading: false,
  hideLanguageSelector: true,
}

export default WithTemplate(Full)
