import { isEmpty } from 'lodash'

import {
  selectPhoneNumberAllowedCountries,
  selectUserAvailableCountry,
} from '../../selectors/countries'

export const FETCH_CONTINENTS_SUCCESS = 'FETCH_CONTINENTS_SUCCESS'
export const FETCH_COUNTRY_BEGIN = 'FETCH_COUNTRY_BEGIN'
export const FETCH_COUNTRY_SUCCESS = 'FETCH_COUNTRY_SUCCESS'
export const FETCH_COUNTRY_ERROR = 'FETCH_COUNTRY_ERROR'
export const SET_COUNTRY = 'SET_COUNTRY'

export const fetchCountries = sdk => async (dispatch, getState, api) => {
  await api
    .getCountries({
      ...(sdk && { sdk: true }),
    })
    .then(resp => {
      const { continents } = resp

      const availableCountryCodes = selectPhoneNumberAllowedCountries(
        getState()
      ).map(country => country.isoCode)
      const countryCode = selectUserAvailableCountry(
        availableCountryCodes,
        'us'
      )

      if (isEmpty(getState().countries.country)) {
        dispatch(setCountry(countryCode))
      }

      dispatch({
        type: FETCH_CONTINENTS_SUCCESS,
        payload: { continents },
      })
    })
    .catch(() => {})
}

export const setCountry = country => dispatch => {
  dispatch({
    type: SET_COUNTRY,
    payload: country,
  })
}

export const fetchCountry =
  (countryCode, sdk) => async (dispatch, getState, api) => {
    dispatch({
      type: FETCH_COUNTRY_BEGIN,
      payload: { countryCode },
    })

    await api
      .getCountry(countryCode, { ...(sdk && { sdk: true }) })
      .then(resp => {
        const { country } = resp

        dispatch({
          type: FETCH_COUNTRY_SUCCESS,
          payload: { countryCode, country },
        })
      })
      .catch(() => {})
  }
