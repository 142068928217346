import { gql } from '@apollo/client'

const Lots = gql`
  query Lots(
    $latitude: Float!
    $longitude: Float!
    $orderBy: LotOrderBy = DISTANCE_TO_LOCATION
    $radius: Int!
    $searchTerm: String
    $amenities: [String!]
  ) {
    lots(
      location: {
        coordinates: {
          latitude: $latitude
          longitude: $longitude
          radius: $radius
        }
      }
      amenities: $amenities
      orderBy: $orderBy
      searchTerm: $searchTerm
      monthlyParkingOnly: false
    ) {
      edges {
        node {
          id
          name
          latitude
          longitude
          about
          notes
          timezoneName
          mainPhoto {
            image {
              info {
                mode
                cloudinary {
                  cloudName
                  defaultTransformation
                  format
                  publicId
                }
                url
              }
              fallbackUrl
            }
          }
          additionalPhotos {
            image {
              info {
                mode
                cloudinary {
                  cloudName
                  publicId
                  format
                  defaultTransformation
                }
                url
              }
              fallbackUrl
            }
          }
          address: rawAddress
          amenities {
            code
            label
            description
            disables
          }
          countryCode
          country {
            continent
            currency
            isoCode
            language
            name
          }
          directionsNotes
          currentAvailability {
            rate
          }
        }
      }
    }
  }
`

export default Lots
