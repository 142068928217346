import PropTypes from 'prop-types'
import { useMemo } from 'react'
import styled from 'styled-components'
import dynamic from 'next/dynamic'

import { getLotImages } from 'helpers/image'
import { useClientResponsive } from 'helpers/hooks'

const Slider = dynamic(() => import('components/Atoms/Slider'), { ssr: false })

const Container = styled.div`
  height: 130px;
  width: 100%;

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    height: 220px;
  }
`

const LotImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 4px;
  overflow: hidden;
  object-fit: cover;
  object-position: center;
`

const LotPhotoCarousel = ({ lot, height }) => {
  const { isMobile } = useClientResponsive()

  if (!lot) {
    return null
  }

  const imageHeight = useMemo(() => (isMobile ? 130 : 220), [isMobile])

  const lotImages = useMemo(() => {
    const images = getLotImages(
      lot,
      `c_thumb,w_400,h_${height || 130},g_center`
    )

    return images?.map((image, index) => {
      if (!image) {
        return null
      }

      return <LotImage src={image} key={index} />
    })
  }, [lot, height])

  if (!lotImages || !lotImages?.length > 0) return null

  console.log(height)

  return (
    <Container {...(height && { style: { height: `${height}px` } })}>
      {lotImages.length > 1 ? (
        <Slider
          options={{
            arrows: false,
            classes: {
              pagination: 'splide__pagination pagination',
            },
            gap: '2px',
            perMove: 1,
            perPage: 1,
            width: '100vw',
            height: `${height}px` || `${imageHeight}px`,
          }}
          items={lotImages}
        />
      ) : (
        lotImages[0]
      )}
    </Container>
  )
}

LotPhotoCarousel.propTypes = {
  lot: PropTypes.object,
  height: PropTypes.number,
}

export default LotPhotoCarousel
