const Directions = () => (
  <svg xmlns="http://www.w3.org/2000/svg">
    <path
      fill="#fff"
      d="M29.765 13.935L16.205.435c-.588-.585-1.538-.585-2.125 0L.52 13.935c-.588.585-.588 1.53 0 2.115l13.56 13.5c.587.585 1.537.585 2.124 0l13.56-13.5c.588-.57.588-1.515 0-2.115zM18.148 18.75V15h-6.027v4.5H9.108v-6c0-.825.678-1.5 1.506-1.5h7.534V8.25l5.274 5.25-5.274 5.25z"
    />
  </svg>
)

export default Directions
