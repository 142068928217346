import { useMemo } from 'react'
import moment from 'moment-timezone'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { difference } from 'lodash'
import dynamic from 'next/dynamic'

import { formatCurrency } from 'i18n-web/formatters'
import { useTranslation } from 'i18n-web/i18next'
import { Card } from 'components/Molecules/Lot'
import { getPricingInformation } from 'models/rate'

const Slider = dynamic(() => import('components/Atoms/Slider'), { ssr: false })

const Preview = ({
  lots,
  event,
  activeLotIndex,
  setActiveLotIndex,
  showPreview,
  onLotSelect,
  userCredits,
}) => {
  const { t } = useTranslation()

  return (
    <Slider
      full
      options={{
        width: '100vw',
        arrows: false,
        pagination: true,
        perPage: 1,
        start: activeLotIndex,
      }}
      items={lots?.map((lot, index) => {
        let changePoliciesText = []
        const rateBreakdownItems = []

        rateBreakdownItems.push(t('common.includesServiceFee'))

        const policies = [
          lot.cancellationCutDate && {
            type: 'cancelled',
            date: moment(lot.cancellationCutDate).tz(
              lot.availability?.timezoneName
            ),
          },
          lot.transferCutDate && {
            type: 'transfered',
            date: moment(lot.exchangeCutDate).tz(
              lot.availability?.timezoneName
            ),
          },
          lot.exchangeCutDate && {
            type: 'exchanged',
            date: moment(lot.exchangeCutDate).tz(
              lot.availability?.timezoneName
            ),
          },
        ].filter(Boolean)

        const sameDate = policies.reduce((a, e) => {
          a[moment(e.date)] = ++a[moment(e.date)] || 0

          return a
        }, {})

        const changePolicies = policies.filter(e => sameDate[e.date])

        if (changePolicies.length === 2) {
          changePoliciesText.push(
            t('event.changePolicies.twoWithSameDate', {
              firstPolicy: changePolicies[0].type,
              secondPolicy: changePolicies[1].type,
              date: moment(changePolicies[0].date),
            })
          )
        } else if (changePolicies.length === 3) {
          changePoliciesText.push(
            t('event.changePolicies.allWithSameDate', {
              firstPolicy: changePolicies[0].type,
              secondPolicy: changePolicies[1].type,
              thirdPolicy: changePolicies[2].type,
              date: moment(changePolicies[0].date),
            })
          )
        }

        if (changePolicies.length !== 3) {
          const differentDate = difference(policies, changePolicies)

          differentDate.map(item =>
            changePoliciesText.push(
              t('event.changePolicies.differentDate', {
                policy: item.type,
                date: moment(item.date),
              })
            )
          )
        }

        const { appliedCreditsAmount, purchaseTotal } = useMemo(
          () =>
            getPricingInformation({
              totalPriceBeforeDeductions: lot?.rate?.value + lot?.rate?.fee,
              creditsLeft: userCredits,
            }),
          [lot.rate]
        )

        if (userCredits > 0) {
          rateBreakdownItems.push(
            t('event.rateBreakdownItems.credits', {
              credits: appliedCreditsAmount,
              currency: lot.currency,
            })
          )
        }

        return (
          <Card
            address={lot.addressFormatted}
            barcodeActivation={lot.barcodeActivation}
            changePolicies={changePoliciesText}
            className={classNames('sliderCard', {
              active: index === activeLotIndex,
            })}
            distance={lot.convertedDistance}
            image={lot.image}
            key={`lot-${index}`}
            latitude={lot.latitude}
            longitude={lot.longitude}
            name={lot.name}
            notes={lot.notes}
            onClick={() => setActiveLotIndex(index)}
            onLotSelect={() => onLotSelect(lot)}
            rate={formatCurrency(purchaseTotal, lot.currency)}
            rateBreakdown={rateBreakdownItems.join(' ')}
            showPreview={showPreview}
            t={t}
            time={`${moment(lot.availability.start)
              .tz(lot.availability?.timezoneName)
              .format('LT')} - ${moment(lot.availability.end)
              .tz(lot.availability?.timezoneName)
              .format('LT')}, ${moment(event.start)
              .tz(lot.availability?.timezoneName)
              .format('ll')}`}
          />
        )
      })}
    />
  )
}

Preview.propTypes = {
  lots: PropTypes.array,
  activeLotIndex: PropTypes.number,
  setActiveLotIndex: PropTypes.func,
  onLotSelect: PropTypes.func,
  event: PropTypes.object,
  showPreview: PropTypes.bool,
  userCredits: PropTypes.number,
}

export default Preview
