import { get } from 'lodash'

export const getConversionData = queryString => {
  if (get(queryString, 'incomingLotTextMessageId')) {
    return {
      conversion: {
        source: 'lot_text_message',
        incomingLotTextMessageId: get(queryString, 'incomingLotTextMessageId'),
      },
    }
  }
  if (get(queryString, 'qrCodeChannel') && get(queryString, 'qrCodeUrl')) {
    return {
      conversion: {
        source: 'qr_code',
        qrCodeChannel: get(queryString, 'qrCodeChannel'),
        qrCodeUrl: get(queryString, 'qrCodeUrl'),
      },
    }
  }

  return null
}

export const convertMetersToKilometers = meters => meters * 0.001

export const convertMetersToMiles = meters => meters * 0.00062137

export const isClientRender = () => typeof window !== 'undefined'

export const convertToQueryString = data =>
  Object.keys(data)
    .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(data[k])}`)
    .join('&')
