import PropTypes from 'prop-types'
import styled from 'styled-components'
import classNames from 'classnames'

import Text from 'components/Atoms/Text'
import FlagIcon from 'components/Atoms/CountrySelectSearch/FlagIcon'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0px 10px;
  cursor: pointer;
  align-items: center;
`

const Flag = styled(FlagIcon)`
  height: 42px;
  width: auto;
`

const Suggestion = styled(Text)`
  padding-left: 10px;
`

const CountrySuggestionTemplate = ({ parts, suggestion }) => {
  const countryCode = suggestion?.value

  return (
    <Container>
      <Flag countryCode={countryCode} />
      <Suggestion>
        {parts.map((part, index) => (
          <Text
            as="span"
            className={classNames({
              bold: part.highlight,
            })}
            key={index}>
            {part.text}
          </Text>
        ))}
      </Suggestion>
    </Container>
  )
}

CountrySuggestionTemplate.propTypes = {
  parts: PropTypes.array,
  suggestion: PropTypes.object,
}

export default CountrySuggestionTemplate
