import styled from 'styled-components'
import PropTypes from 'prop-types'

const List = styled.select`
  border: none;
  background: transparent;
  outline: none;
  appearance: none;
  padding-left: 14px;
  width: 100%;
  font-size: ${props => props.theme.font.size.mobile.tiny};
`

const Component = ({ items, value, onChange, label }) => (
  <List
    value={value}
    onChange={({ target }) => {
      onChange(items?.filter(item => item.value === target.value)[0])
    }}>
    <option value="">{label}</option>
    {items?.map((item, key) => (
      <option key={key} value={item.value}>
        {item.label}
      </option>
    ))}
  </List>
)

Component.propTypes = {
  items: PropTypes.array,
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
}

export default Component
