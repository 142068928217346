import styled from 'styled-components'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { useTranslation } from 'i18n-web/i18next'
import Text from 'components/Atoms/Text'
import { useClientResponsive } from 'helpers/hooks'

import PaymentCard from './PaymentCard'
import PaymentCash from './PaymentCash'

const Component = styled.div`
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  text-align: center;
  overflow: hidden;
  margin-bottom: 0;
`

const Content = styled.div`
  padding: 15px 14px;
  background: ${props => props.theme.palette.grayDark};
  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    padding: 30px;
    margin-bottom: 0;

    &.singleColumn {
      display: flex;
      flex-direction: row;
      padding: 30px;
    }

    &:not(.singleColumn) {
      padding: 17px 21px 7px 21px;
    }
  }
`

const ReceiptData = styled.div`
  width: 100%;

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    display: flex;

    &.singleColumn {
      flex-direction: column;
      justify-content: space-between;
      width: auto;
      margin-right: 85px;
    }

    &:not(.singleColumn) {
      > div {
        flex: 1;
      }
    }
  }
`

const Price = styled(Text)`
  line-height: 110%;
  margin-bottom: 18px;
  font-size: ${props => props.theme.font.size.desktop.h2};
  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    font-size: ${props => props.theme.font.size.desktop.h1};
    margin-bottom: 22px;
  }
`

const Header = styled.div`
  background-color: ${props => props.theme.palette.quaternary};
  padding: 12px 14px;

  p {
    &.medium {
      font-size: ${props => props.theme.font.size.mobile.small};
    }
  }

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    min-height: 48px;
    display: block;
    padding: 12px 21px;

    p {
      &.medium {
        font-size: ${props => props.theme.font.size.desktop.medium};
      }
    }
  }
`

const Subtitle = styled(Text)`
  font-size: ${props => props.theme.font.size.mobile.small};

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    font-size: ${props => props.theme.font.size.desktop.small};
    margin-bottom: 10px;
  }
`

const DynamicContent = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const Template = ({
  parkingType,
  total,
  children,
  card,
  nativePaymentType,
  headerText,
  className,
  isCashPayment,
  singleColumn,
}) => {
  const { t } = useTranslation()
  const { isMobile } = useClientResponsive()

  return (
    <Component className={className}>
      {headerText && (
        <Header>
          <Text className="contrastText bold medium">{headerText}</Text>
        </Header>
      )}
      <Content className={classNames({ singleColumn })}>
        {total && (
          <ReceiptData className={classNames({ singleColumn })}>
            <>
              <div>
                <Subtitle
                  className={classNames('background uppercase', {
                    light: !isMobile,
                  })}>
                  {t('receipt.details.paymentProcessed')}
                </Subtitle>
                <Price className="contrastText extraBold">{total}</Price>
                {!singleColumn && (
                  <Subtitle
                    className={classNames('background uppercase', {
                      light: !isMobile,
                      semiBold: isMobile,
                      small: isMobile,
                    })}>
                    {parkingType}
                  </Subtitle>
                )}
              </div>
              <div>
                {isCashPayment ? (
                  <PaymentCash className="desktop" t={t} />
                ) : (
                  <PaymentCard
                    className="desktop"
                    t={t}
                    card={card}
                    nativePaymentType={nativePaymentType}
                  />
                )}
              </div>
            </>
          </ReceiptData>
        )}
        <DynamicContent>{children}</DynamicContent>
        {total &&
          (isCashPayment === false ? (
            <PaymentCash className="mobile" t={t} />
          ) : (
            <PaymentCard
              className="mobile"
              t={t}
              card={card}
              nativePaymentType={nativePaymentType}
            />
          ))}
      </Content>
    </Component>
  )
}

Template.propTypes = {
  card: PropTypes.object,
  className: PropTypes.string,
  parkingType: PropTypes.string,
  total: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  children: PropTypes.node,
  nativePaymentType: PropTypes.string,
  headerText: PropTypes.string,
  isCashPayment: PropTypes.bool,
  singleColumn: PropTypes.bool,
}

const GroupComponent = styled.div`
  flex: 1 0 100%;
  margin-bottom: 14px;
  margin-top: 5px;
  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    flex: 1 0 50%;
    margin-top: 0;
    margin-bottom: 10px;
  }
`

const GroupText = styled.div`
  p {
    color: ${props => props.theme.palette.white};
    font-size: ${props => props.theme.font.size.desktop.small};
    @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
      font-size: ${props => props.theme.font.size.desktop.h4};
    }
  }
`

const GroupTitle = styled(Text)`
  font-size: ${props => props.theme.font.size.desktop.small};
  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    font-size: ${props => props.theme.font.size.desktop.h4};
  }
`

export const Group = ({ title, children }) => (
  <GroupComponent>
    {title && <GroupTitle className="bold contrastText">{title}</GroupTitle>}
    {children && <GroupText>{children}</GroupText>}
  </GroupComponent>
)

Group.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
}

export default Template
