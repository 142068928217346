import { some } from 'lodash'

import * as startOption from './start-option'
import * as endOption from './end-option'

export const getVariableRules = rate =>
  (rate.rules || []).map((rule, index) => ({
    ...rule,
    index,
  }))

export const isVariableRuleBasedOnDuration = ({ rate, ruleIndex }) => {
  const rules = getVariableRules(rate)
  const hasRulesWithStartOption = some(rules, rule => rule.start)
  const hasRulesWithEndOption = some(rules, rule => rule.end)

  if (hasRulesWithStartOption) {
    return startOption.isRuleBasedOnDuration({ rules, ruleIndex })
  }
  if (hasRulesWithEndOption) {
    return endOption.isRuleBasedOnDuration({ rules, ruleIndex })
  }
}

export const getVariableRuleAggregatedPrice = ({ rate, ruleIndex }) => {
  const rules = getVariableRules(rate)
  const hasRulesWithStartOption = some(rules, rule => rule.start)
  const hasRulesWithEndOption = some(rules, rule => rule.end)

  if (hasRulesWithStartOption) {
    return startOption.getRuleAggregatedPrice({ rules, ruleIndex })
  }
  if (hasRulesWithEndOption) {
    return endOption.getRuleAggregatedPrice({ rules, ruleIndex })
  }
}

export const getVariableRuleExpirationMoment = ({
  rate,
  ruleIndex,
  reservationStartTime,
  availabilityStart,
  availabilityEnd,
  timezoneName,
}) => {
  const rules = getVariableRules(rate)
  const hasRulesWithStartOption = some(rules, rule => rule.start)
  const hasRulesWithEndOption = some(rules, rule => rule.end)

  if (hasRulesWithStartOption) {
    return startOption.getVariableRuleExpirationMoment({
      rules,
      ruleIndex,
      reservationStartTime,
      availabilityStart,
      availabilityEnd,
      timezoneName,
    })
  }
  if (hasRulesWithEndOption) {
    return endOption.getVariableRuleExpirationMoment({
      rules,
      ruleIndex,
      reservationStartTime,
      timezoneName,
    })
  }
}

// Returns available rates we can extend to
export const getAvailableVariableRules = ({
  rate,
  expirationTime,
  reservationStartTime,
  availabilityStartTime,
  timezoneName,
}) => {
  const rules = getVariableRules(rate)
  const hasRulesWithStartOption = some(rules, rule => rule.start)
  const hasRulesWithEndOption = some(rules, rule => rule.end)

  if (hasRulesWithStartOption) {
    return startOption.getAvailableRulesForExtension({
      rules,
      expirationTime,
      reservationStartTime,
      availabilityStartTime,
      timezoneName,
    })
  }
  if (hasRulesWithEndOption) {
    return endOption.getAvailableRulesForExtension({
      rules,
      expirationTime,
      reservationStartTime,
      timezoneName,
    })
  }

  return []
}
