const CC_BRAND_ICONS = {
  'AMERICAN EXPRESS': '/static/images/cc-brands/amex_white@3x.png',
  'DINERS CLUB': '/static/images/cc-brands/dinersClub_white@3x.png',
  GENERIC: '/static/images/cc-brands/generic_white@3x.png',
  JCB: '/static/images/cc-brands/jcb_white@3x.png',
  MASTERCARD: '/static/images/cc-brands/mastercard_white@3x.png',
  VISA: '/static/images/cc-brands/visa_white@3x.png',
  DISCOVER: '/static/images/cc-brands/discover_white@3x.png',
}

export const getCardBrandIcon = name =>
  CC_BRAND_ICONS[name.toUpperCase()] || CC_BRAND_ICONS.GENERIC
