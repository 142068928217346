import styled from 'styled-components'
import { Formik, Form, Field } from 'formik'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import ApplePayButton from 'components/Atoms/ApplePayButton'
import BulletPoint from 'components/Atoms/BulletPoint'
import Button from 'components/Atoms/Button'
import FacebookButton from 'components/Atoms/FacebookButton'
import Text from 'components/Atoms/Text'
import isLightMode from 'helpers/isLightMode'
import { TextField, PhoneField } from 'components/Molecules/Form'
import { isClientRender } from 'helpers'
import { isCitifydApp } from 'helpers/application'

import validationSchema from './validationSchema'

const setPositionAboveKeyboard = () => {
  // Fixes a bug in which Android devices won't position the focused element above the keyboard under certain conditions
  // See https://citifyd.atlassian.net/browse/WP-177
  if (isClientRender() && document.activeElement) {
    document.activeElement.scrollIntoView({ block: 'start' })
  }
}

const Component = styled(Form)`
  display: flex;
  flex-direction: column;

  .accountExists {
    margin-bottom: 15px;
  }

  .input {
    margin-bottom: 0;
  }

  .password,
  .email {
    margin-top: 15px;
  }

  .socialLoginButton {
    margin-top: 20px;
  }
`
const PhoneNumberGroup = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  .input {
    width: 100%;
  }
`

const InlineButton = styled(Button)`
  width: 90px;
  height: 50px;
`

const Link = styled.a`
  margin-bottom: 15px;
  color: ${props => props.theme.palette.grayDark};
  font-size: ${props => props.theme.font.size.mobile.small};
  text-align: center;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`

const AlertMessage = styled(Text)`
  margin: 20px 0 10px;
  padding: 6px 12px;
  background-color: ${props => props.theme.palette.primary};
  border-radius: 4px;
`

const SignInForm = props => {
  const {
    application,
    className,
    country,
    emailRef,
    error,
    handlePhoneAutoSubmit,
    handleSubmit,
    initialValues,
    isCountryUs,
    isGuestAvailable,
    onClearAuthentication,
    receiptFlow,
    onFocusField,
    onForgotPasswordLink,
    passwordRef,
    formikRef,
    redirectTo,
    resetFormState,
    setPhoneNumber,
    showAppleConnected,
    showEmail,
    showFacebookConnected,
    showNextButton,
    showPassword,
    showRestrictedPhoneNumber,
    showUnrecognizedPhoneNumber,
    hasReceiptAccount,
    t,
  } = props

  const handleForgotPasswordLink = e => {
    e.preventDefault()
    onForgotPasswordLink()
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      ref={formikRef}
      render={props => {
        const { isSubmitting, setSubmitting, setStatus } = props

        return (
          <Component className={className}>
            {(showAppleConnected ||
              showFacebookConnected ||
              hasReceiptAccount) && (
              <Text className="accountExists medium">
                {t('signIn.accountExists')}
              </Text>
            )}
            <PhoneNumberGroup>
              <Field
                className="input"
                component={PhoneField}
                country={country}
                disabled={isSubmitting && !error}
                name="phoneNumber"
                onChange={value => {
                  handlePhoneAutoSubmit(value, setSubmitting, setStatus)
                  setPhoneNumber(value)
                  resetFormState(setSubmitting, setStatus)
                }}
                onFocus={() => onFocusField(setSubmitting, setStatus)}
              />
              {!isCountryUs && showNextButton && !showPassword && (
                <InlineButton
                  className="transparent noBorders"
                  disabled={isSubmitting && !error}
                  type="submit">
                  {t('signIn.form.buttons.next').toUpperCase()}
                </InlineButton>
              )}
            </PhoneNumberGroup>
            {showPassword && (
              <Field
                className="password"
                autoFocus={showPassword}
                component={TextField}
                disabled={isSubmitting && !error}
                inputRef={passwordRef}
                label={t('signIn.form.password.label')}
                name="password"
                onFocus={() => {
                  onClearAuthentication()
                  setSubmitting(false)
                  setStatus(null)
                  setPositionAboveKeyboard()
                }}
                type="password"
              />
            )}
            {showEmail && (
              <Field
                className="email"
                component={TextField}
                disabled={isSubmitting && !error}
                inputRef={emailRef}
                label={t('signIn.form.email.label')}
                name="email"
                onFocus={() => {
                  onFocusField(setSubmitting, setStatus)
                  setPositionAboveKeyboard()
                }}
                type="email"
              />
            )}
            {showPassword && (
              <>
                <Link
                  className="link center primary semiBold"
                  onClick={handleForgotPasswordLink}>
                  {t('signIn.forgotPasswordLink')}
                </Link>
                <Button
                  className={classNames('extraPadding', {
                    highlight: isLightMode(application),
                    grayDark: isLightMode(application),
                    contrastText: !isLightMode(application),
                  })}
                  disabled={isSubmitting && !error}
                  type="submit">
                  {t('signIn.form.buttons.submit').toUpperCase()}
                </Button>
              </>
            )}
            {showAppleConnected && isCitifydApp(application) && (
              <ApplePayButton
                skipLoginFlow={Boolean(receiptFlow)}
                showSupportMessage={Boolean(receiptFlow)}
                callbackRedirection={redirectTo}
                className="socialLoginButton full">
                {t('common.button.continueWithApple')}
              </ApplePayButton>
            )}
            {showFacebookConnected && isCitifydApp(application) && (
              <FacebookButton
                showSupportMessage={Boolean(receiptFlow)}
                className="socialLoginButton full">
                {t('common.button.continueWithFacebook')}
              </FacebookButton>
            )}
            {showRestrictedPhoneNumber && !isSubmitting && !showPassword && (
              <AlertMessage
                className={classNames('small', 'semiBold', {
                  contrastText: !isLightMode(application),
                })}>
                {t('signIn.restrictedPhoneNumber')}
              </AlertMessage>
            )}
            {showUnrecognizedPhoneNumber && !isSubmitting && !showPassword && (
              <>
                <AlertMessage
                  className={classNames('small', 'semiBold', {
                    contrastText: !isLightMode(application),
                  })}>
                  {t('signIn.unrecognizedPhoneNumber.title')}
                </AlertMessage>
                <BulletPoint isChecked>
                  {t('signIn.unrecognizedPhoneNumber.differentNumber')}
                </BulletPoint>
                {isGuestAvailable && isCitifydApp(application) && (
                  <BulletPoint isChecked>
                    {t('signIn.unrecognizedPhoneNumber.continueGuest')}
                  </BulletPoint>
                )}
                {isCitifydApp(application) && (
                  <BulletPoint isChecked>
                    {t('signIn.unrecognizedPhoneNumber.connectSocialMedia')}
                  </BulletPoint>
                )}
                {!receiptFlow && isCitifydApp(application) && (
                  <BulletPoint isChecked>
                    {t('signIn.unrecognizedPhoneNumber.createAccount')}
                  </BulletPoint>
                )}
              </>
            )}
          </Component>
        )
      }}
      validationSchema={() =>
        validationSchema(country, showPassword, showEmail)
      }
    />
  )
}

SignInForm.propTypes = {
  application: PropTypes.string,
  className: PropTypes.string,
  country: PropTypes.string,
  emailRef: PropTypes.object,
  error: PropTypes.string,
  formikRef: PropTypes.object,
  handlePhoneAutoSubmit: PropTypes.func,
  handleSubmit: PropTypes.func,
  hasReceiptAccount: PropTypes.bool,
  initialValues: PropTypes.object,
  isCountryUs: PropTypes.bool,
  isGuestAvailable: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  onClearAuthentication: PropTypes.func,
  onFocusField: PropTypes.func,
  onForgotPasswordLink: PropTypes.func,
  passwordRef: PropTypes.object,
  receiptFlow: PropTypes.object,
  redirectTo: PropTypes.string,
  resetFormState: PropTypes.func,
  setPhoneNumber: PropTypes.func,
  setStatus: PropTypes.bool,
  setSubmitting: PropTypes.bool,
  showAppleConnected: PropTypes.bool,
  showEmail: PropTypes.bool,
  showFacebookConnected: PropTypes.bool,
  showNextButton: PropTypes.bool,
  showPassword: PropTypes.bool,
  showUnrecognizedPhoneNumber: PropTypes.bool,
  showRestrictedPhoneNumber: PropTypes.bool,
  t: PropTypes.func,
}

export default SignInForm
