import PropTypes from 'prop-types'
import styled from 'styled-components'
import { withRouter } from 'next/router'
import classNames from 'classnames'

import Text from 'components/Atoms/Text'
import isLightMode from 'helpers/isLightMode'
import { isCitifydApp } from 'helpers/application'
import { getThemePath } from 'models/application'

const Component = styled.div`
  margin-top: 20px;
  background-color: ${props => props.theme.palette.primary};
  padding: 14px 12px;
  border-radius: 4px;
  border-top-right-radius: 25px;
  position: relative;

  &:after {
    content: '';
    display: block;
    background: ${props =>
      isCitifydApp(props.application)
        ? 'url(/static/icons/icon-no-events.svg) no-repeat top center'
        : `url(/static/icons/${getThemePath(
            props.application
          )}/icon-no-events.svg) no-repeat top center`};
    background-size: contain;
    position: absolute;
    top: 0;
    right: 0px;
    width: 51px;
    height: 51px;
  }

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    padding: 22px;
    border-top-right-radius: 50px;
    margin-top: 35px;

    &:after {
      width: 78px;
      height: 78px;
    }
  }
`

const Title = styled(Text)`
  margin-bottom: 12px;

  &.h4 {
    font-weight: 700;
    text-transform: initial;
  }

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    margin-bottom: 15px;
  }
`

const Description = styled(Text)`
  margin-bottom: 3px;
  width: 93%;
  font-size: ${props => props.theme.font.size.mobile.small};

  span {
    font-size: ${props => props.theme.font.size.mobile.small};
    font-weight: 800;
    cursor: pointer;
    text-decoration: underline;
  }

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    font-size: ${props => props.theme.font.size.desktop.regular};

    span {
      font-size: ${props => props.theme.font.size.desktop.regular};
    }
  }
`

const NoEventsAvailable = ({ cta, description, router, title }) => {
  const {
    query: { application },
  } = router

  return (
    <Component application={application}>
      <Title
        className={classNames('h4', 'bold', {
          grayDark: isLightMode(application),
          contrastText: !isLightMode(application),
        })}>
        {title}
      </Title>
      <Description
        className={classNames({
          grayDark: isLightMode(application),
          contrastText: !isLightMode(application),
        })}>
        {description}
      </Description>
      {cta && (
        <Description
          className={classNames('semiBold', {
            grayDark: isLightMode(application),
            contrastText: !isLightMode(application),
          })}>
          {cta}
        </Description>
      )}
    </Component>
  )
}

NoEventsAvailable.propTypes = {
  cta: PropTypes.node,
  description: PropTypes.string,
  router: PropTypes.object,
  title: PropTypes.string,
}

export default withRouter(NoEventsAvailable)
