import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import moment from 'moment'
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io'
import {
  DayPickerSingleDateController,
  isInclusivelyAfterDay,
} from 'react-dates'

import CustomDatePicker from 'components/Atoms/DatePicker/style'

const CustomDatePickerMobile = styled(CustomDatePicker)`
  .DayPicker,
  .CalendarMonthGrid,
  .CalendarMonth,
  .CalendarDay__default,
  .CalendarDay__blocked_out_of_range {
    background-color: ${props => props.theme.palette.background};
  }

  .CalendarMonth_caption {
    font-size: ${props => props.theme.font.size.mobile.regular};
    padding-bottom: 82px;
    margin-left: 12px;
  }

  .DayPicker_weekHeader {
    color: ${props => props.theme.palette.gray};
    margin-top: 10px;
    font-weight: 700;
  }

  .DayPicker_weekHeader ul {
    background-color: ${props => props.theme.palette.grayLight}80;
    padding: 9px 0px;
    border-radius: 4px;
  }

  .DayPicker_weekHeader_li small {
    font-size: ${props => props.theme.font.size.mobile.medium};
  }

  .CalendarDay {
    font-size: ${props => props.theme.font.size.mobile.medium};
  }

  .CalendarMonth_table {
    .CalendarDay__blocked_out_of_range {
      color: ${props => props.theme.palette.gray};
    }
  }

  .DayPickerNavigation_button {
    position: absolute;
    top: 18px;
    color: ${props => props.theme.palette.gray};
    font-size: ${props => props.theme.font.size.mobile.h2};
    &:first-of-type {
      left: 16px;
    }
    &:last-of-type {
      right: 16px;
    }
  }
  @media (max-height: 580px) {
    .CalendarMonth_caption {
      padding-bottom: 52px;
    }

    .DayPicker_weekHeader {
      margin-top: -4px;
    }
  }
`

const DatePicker = ({
  currentDate,
  customDaySize,
  setCurrentDate,
  ...rest
}) => {
  const [focused, setFocused] = useState()
  const [daySize, setDaySize] = useState(customDaySize)

  useEffect(() => {
    if (!customDaySize) {
      setDaySize(parseInt(window.innerWidth * 0.13))
    }
  }, [])

  return (
    <CustomDatePickerMobile>
      <DayPickerSingleDateController
        {...rest}
        noBorder
        numberOfMonths={1}
        hideKeyboardShortcutsPanel
        daySize={daySize}
        navPrev={<IoIosArrowBack />}
        navNext={<IoIosArrowForward />}
        isOutsideRange={day => !isInclusivelyAfterDay(day, moment())}
        date={currentDate}
        onDateChange={date => setCurrentDate(date)}
        focused={focused}
        onFocusChange={({ focused }) => setFocused(focused)}
        initialVisibleMonth={() => moment().add(1, 'M')}
      />
    </CustomDatePickerMobile>
  )
}

DatePicker.propTypes = {
  customDaySize: PropTypes.number,
  rest: PropTypes.object,
  setCurrentDate: PropTypes.func,
  currentDate: PropTypes.object,
}

export default DatePicker
