import { isFunction } from 'lodash'
import * as Cookies from 'js-cookie'

import { isSDK } from 'helpers/sdk'
import API from 'api'
import { i18n } from 'i18n-web/i18next'
import { nationalPhoneNumber } from 'helpers/phoneFormat'
import { removeReservationSession } from 'helpers/reservation'
import { setUser } from 'redux-web/utils/user/actions'
import { showBasicModal } from 'helpers/modal'
import { showModal } from 'components/Atoms/Modal/actions'
import { setSession, removeAccessToken } from 'redux-web/utils/session/actions'

export const createGuestUser = async ({
  country,
  dispatch,
  onSuccess,
  onError,
  phoneNumber,
  phoneVerificationToken,
  hideDefaultErrorModal,
  isSDK,
}) => {
  const guest = await API().createGuestUser({
    ...(isSDK && { sdk: true }),
    timeoutTryAgainMessage: true,
    body: JSON.stringify({
      phoneCountryCode: country,
      phoneNumber: nationalPhoneNumber(phoneNumber, country),
      ...(phoneVerificationToken && {
        phoneVerificationToken: phoneVerificationToken,
      }),
    }),
  })

  if (guest.user) {
    removeAccessToken()
    removeGuestSession()
    removeReservationSession()
    dispatch(setUser(guest.user))
    dispatch(setSession(guest.user))

    if (isFunction(onSuccess))
      onSuccess({ accessToken: guest.user?.accessToken })

    return { guest: guest.user }
  }

  if (guest.error) {
    if (isFunction(onError)) onError(guest.error)

    // User already exists
    if (guest.error.code === 2531) {
      dispatch(
        showModal({
          childProps: {
            prefilledPhoneNumber: phoneNumber,
          },
          modalProps: {
            className: 'noBorders',
          },
          modalType: 'signIn',
        })
      )
    } else if (guest.error.code === 408) {
      dispatch(
        showBasicModal({
          title: guest.error.message,
        })
      )
    } else if (!hideDefaultErrorModal) {
      dispatch(
        showBasicModal({
          title: i18n.t('common.title.error'),
          description: guest.error.message,
        })
      )
    }

    return { error: guest.error }
  }
}

export const getGuestSession = () => {
  if (isSDK()) {
    return localStorage?.getItem('citifyd_guest_session')
  }

  return Cookies.get('citifyd_guest_session')
}
export const removeGuestSession = () => {
  if (isSDK()) {
    localStorage.removeItem('citifyd_guest_session')
  } else {
    Cookies.remove('citifyd_guest_session')
  }
}

export const setGuestSession = () => {
  if (isSDK()) {
    localStorage.setItem('citifyd_guest_session', true)
  } else {
    Cookies.set('citifyd_guest_session', true)
  }
}
