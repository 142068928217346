const DownloadApp = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32">
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g transform="translate(-41 -481) translate(19 461) translate(22 20)">
            <circle cx="16" cy="16" r="16" fill="#373840" />
            <g stroke="#D7D7D7" strokeWidth="2">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M7.273 3.97L13.091 3.97 13.091 23.364 7.273 23.364"
                transform="translate(5.818 6.758) rotate(90 10.182 13.667)"
              />
              <g>
                <path
                  d="M10.667 0L7.916 5.333 10.667 10.667"
                  transform="translate(5.818 6.758) matrix(0 1 1 0 4.848 0) matrix(-1 0 0 1 18.583 0)"
                />
                <path
                  strokeLinecap="square"
                  d="M10.182 5.333L0.834 5.333"
                  transform="translate(5.818 6.758) matrix(0 1 1 0 4.848 0)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default DownloadApp
