import React from 'react'
import { PaymentRequestButtonElement } from '@stripe/react-stripe-js'
import PropTypes from 'prop-types'

const Screen = ({
  options,
  disabled,
  onSubmitDenied,
  paymentRequestLoadResponse,
  requiredFieldIsEmpty,
  termsAndConditionsAgreement,
}) => (
  <PaymentRequestButtonElement
    className="PaymentRequestButton"
    options={options}
    onReady={() => paymentRequestLoadResponse(true)}
    onClick={event => {
      if (requiredFieldIsEmpty || disabled || !termsAndConditionsAgreement) {
        event.preventDefault()

        if (requiredFieldIsEmpty || !termsAndConditionsAgreement) {
          onSubmitDenied()
        }
      }
    }}
  />
)

Screen.propTypes = {
  options: PropTypes.object,
  disabled: PropTypes.bool,
  onSubmitDenied: PropTypes.func,
  requiredFieldIsEmpty: PropTypes.bool,
  termsAndConditionsAgreement: PropTypes.bool,
  paymentRequestLoadResponse: PropTypes.func,
}

export default Screen
