import PropTypes from 'prop-types'
import styled from 'styled-components'
import { FaTimes } from 'react-icons/fa'
import { useSelector } from 'react-redux'

import Button from 'components/Atoms/Button'
import Text from 'components/Atoms/Text'
import { Checkbox } from 'components/Molecules/Form'
import { useTranslation } from 'i18n-web/i18next'

const Component = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px 25px 25px;
  background-color: ${props => props.theme.palette.background};
  border-radius: 12px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;

  @media (max-width: ${props => props.theme.flexboxgrid.breakpoints.md}rem) {
    height: calc(100vh - 56px);
    overflow-y: scroll;
  }

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.md}rem) {
    border-radius: 0;
  }
`

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const ClearButton = styled(Button)`
  display: flex;
  align-items: center;
  min-height: 34px;
`

const ClearIcon = styled(FaTimes)`
  margin-right: 6px;
`

const Divisor = styled.div`
  margin: 15px 0 25px;
  border-top: 1px solid ${props => props.theme.palette.gray}80;
`

const List = styled.ul`
  display: grid;
  grid-gap: 20px;
  margin-bottom: 25px;

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.md}rem) {
    grid-gap: 25px;
    grid-template-columns: repeat(2, 1fr);
  }
`

const Filter = styled.li`
  display: flex;
  align-items: center;

  label:first-of-type {
    height: 30px;

    > div {
      margin: 0 15px 0 0;
    }
  }
`

const FilterImage = styled.img`
  margin-right: 12px;
  width: 30px;
  height: 30px;
  object-fit: contain;
  object-position: center;
`

const FilterName = styled.label`
  display: flex;
  align-items: center;
  font-size: ${props => props.theme.font.size.desktop.medium};
  font-weight: bold;
  cursor: pointer;
`

const SubmitButton = styled(Button)`
  @media (max-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    position: sticky;
    bottom: 0;
  }
`

const HourlySearchFilters = ({
  count,
  getFilterCount,
  onClose,
  onFilterChange,
}) => {
  const { availableFilters, filters } = useSelector(state => state.hourlySearch)
  const { t } = useTranslation()

  return (
    <Component>
      <TitleWrapper>
        <Text as="h1" className="h4">
          {t('reservedParking.search.filter.title')}
        </Text>
        <ClearButton
          className="h4 uppercase inverted noBorders"
          onClick={() => onFilterChange()}>
          <ClearIcon />
          <span>{t('reservedParking.search.filter.clearAll')}</span>
        </ClearButton>
      </TitleWrapper>
      <Divisor />
      <List>
        {availableFilters?.map(({ code, label }) => (
          <Filter key={code}>
            <Checkbox
              id={code}
              checked={filters[code]}
              onClick={e => onFilterChange({ [code]: e.target.checked })}
              value={false}
            />
            <FilterName for={code}>
              <FilterImage
                src={`/static/icons/reserved-parking/filters/${code}.svg`}
              />
              {label} ({getFilterCount(code)})
            </FilterName>
          </Filter>
        ))}
      </List>
      <SubmitButton onClick={onClose} type="button" disabled={!count}>
        {count
          ? t('reservedParking.search.filter.showResults', { count })
          : t('reservedParking.search.filter.noResults')}
      </SubmitButton>
    </Component>
  )
}

HourlySearchFilters.propTypes = {
  count: PropTypes.number,
  getFilterCount: PropTypes.func,
  onClose: PropTypes.func,
  onFilterChange: PropTypes.func,
}

export default HourlySearchFilters
