import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Text from 'components/Atoms/Text'
import RadioTemplate from 'components/Molecules/Form/Radio/template'

const Icon = styled.img`
  height: 30px;
  margin-left: 10px;
`

const CheckboxText = styled(Text)`
  @media (max-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    font-size: ${props => props.theme.font.size.mobile.small};
  }
`

const Radio = ({
  className,
  label,
  id,
  name,
  value,
  onClick,
  checked,
  icon,
}) => (
  <RadioTemplate
    className={className}
    id={id}
    name={name}
    value={value}
    onClick={onClick}
    checked={checked}>
    <>
      <CheckboxText
        as="label"
        className={checked ? 'bold' : 'semiBold'}
        htmlFor={id}>
        {label}
      </CheckboxText>
      {icon && <Icon src={icon} />}
    </>
  </RadioTemplate>
)

Radio.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.any,
  className: PropTypes.string,
  onClick: PropTypes.func,
  checked: PropTypes.bool,
  icon: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default Radio
