import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { isEmpty } from 'lodash'

const Container = styled.div`
  min-width: 42px;
  max-height: 42px;
`

const Flag = styled.img`
  height: 42px;
  max-height: 42px;
  width: auto;
`

const FlagIcon = ({ countryCode, className }) => {
  const [imgSrc, setImgSrc] = useState('')

  useEffect(() => {
    const countryFlagSrc = isEmpty(countryCode)
      ? ''
      : `/static/images/country-flags/${countryCode?.toLowerCase()}@2x.png`

    setImgSrc(countryFlagSrc)
  }, [countryCode])

  const handleError = () => {
    const fallbackSrc = `/static/images/country-flags/${countryCode?.toLowerCase()}@3x.png`

    setImgSrc(fallbackSrc)
  }

  return (
    <Container className={className}>
      {!isEmpty(countryCode) && <Flag src={imgSrc} onError={handleError} />}
    </Container>
  )
}

FlagIcon.propTypes = {
  countryCode: PropTypes.string,
  className: PropTypes.string,
}

export default FlagIcon
