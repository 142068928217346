import styled from 'styled-components'
import PropTypes from 'prop-types'

import Text from 'components/Atoms/Text'
import { useClientResponsive } from 'helpers/hooks'

import IconCash from './Icons/Cash'

const Component = styled.div`
  &.desktop {
    display: none;
  }
  &.mobile {
    display: block;
  }
  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    &.desktop {
      display: block;
    }
    &.mobile {
      display: none;
    }
  }
`

const Subtitle = styled(Text)`
  font-size: ${props => props.theme.font.size.desktop.tiny};
  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    font-size: ${props => props.theme.font.size.desktop.small};
    margin-bottom: 10px;
  }
`

const CardBox = styled.div`
  margin-top: 2px;
  align-items: center;
  display: flex;
  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    margin-top: 10px;
  }
`

const PaymentText = styled(Text)`
  margin-left: 10px;
  &.h4 {
    font-weight: bold;
    text-transform: none;
  }
  &.mobile {
    font-size: ${props => props.theme.font.size.desktop.small};
    margin-left: 4px;
  }
`

const PaymentCash = ({ t, className }) => {
  const { isMobile } = useClientResponsive()

  return (
    <Component className={className}>
      <Subtitle className="background light uppercase">
        {t('receipt.details.chargedTo')}
      </Subtitle>
      <CardBox>
        <IconCash
          width={isMobile ? 20 : 32}
          height={isMobile ? 20 : 32}
          className={className}
        />
        <PaymentText className={'h4 contrastText ' + className}>
          {t('pos.receipt.details.cashPayment')}
        </PaymentText>
      </CardBox>
    </Component>
  )
}

PaymentCash.propTypes = {
  className: PropTypes.string,
  t: PropTypes.func,
}

export default PaymentCash
