import { withRouter } from 'next/router'

import { useTranslation } from 'i18n-web/i18next'

import Component from './Screen'

const ChangePassword = props => {
  const { t } = useTranslation()

  return <Component t={t} {...props} />
}

export default withRouter(ChangePassword)
