import { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { withFormik } from 'formik'
import { withRouter } from 'next/router'

import API from 'api'
import { getAppRoute } from 'helpers/application'
import { nationalPhoneNumber } from 'helpers/phoneFormat'
import { setCountry } from 'redux-web/utils/countries/actions'
import { showBasicModal } from 'helpers/modal'
import { showModal } from 'components/Atoms/Modal/actions'
import { useTranslation } from 'i18n-web/i18next'

import ForgotPassword from './Form'
import validationSchema from './validationSchema'

const ForgotPasswordHOC = props => {
  const { countryCode, phoneNumber, redirectTo, router } = props

  const {
    query: { application },
  } = router

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const country = countryCode || useSelector(state => state.countries.country)
  const receiptFlow = useSelector(state => state.receipt.account)
  const [initialPhoneNumber, setInitialPhoneNumber] = useState(phoneNumber)
  const isSDK = useSelector(state => state.settings.isSDK)

  const refCountry = useRef(country)

  useEffect(() => {
    refCountry.current !== country && setInitialPhoneNumber('')
  }, [country])

  useEffect(() => {
    dispatch(setCountry(countryCode))
  }, [countryCode])

  const forgotPasswordRetry = () => {
    dispatch(
      showModal({
        childProps: {
          countryCode: countryCode,
          phoneNumber: phoneNumber,
        },
        modalProps: {
          ...(Boolean(receiptFlow) && {
            onCloseModal: () =>
              dispatch(
                showModal({
                  childProps: {
                    prefilledCountryCode: countryCode,
                    prefilledPhoneNumber: nationalPhoneNumber(
                      receiptFlow?.phoneNumber || phoneNumber,
                      receiptFlow?.countryCode || countryCode
                    ),
                    preventRedirection: true,
                    redirectTo:
                      redirectTo ||
                      `${getAppRoute(application, 'as')}/my-passes`,
                  },
                  modalProps: {
                    preventClose: true,
                    className: 'noBorders',
                  },
                  modalType: 'signIn',
                })
              ),
          }),
        },
        modalType: 'forgotPassword',
      })
    )
  }

  const showForgotPasswordError = error => {
    let actionButtons

    if (receiptFlow) {
      actionButtons = {
        singleButtonOnClick: () => forgotPasswordRetry(),
        singleButtonText: t('common.button.tryAgain'),
      }
    } else {
      actionButtons = {
        rightButtonOnClick: () => forgotPasswordRetry(),
        rightButtonText: t('common.button.tryAgain'),
        singleButtonOnClick: () => {},
        singleButtonText: t('common.button.cancel'),
      }
    }

    dispatch(
      showBasicModal({
        actionButtons: actionButtons,
        description: error,
        title: t('forgotPassword.title'),
        type: 'error',
      })
    )
  }

  const Formik = withFormik({
    mapPropsToValues: () => ({
      email: '',
      phoneNumber: initialPhoneNumber || '',
    }),
    isInitialValid: initialPhoneNumber ? true : false,
    validationSchema: () => validationSchema(country),
    handleSubmit: (values, { setStatus, setSubmitting }) => {
      setSubmitting(true)
      let data

      const hasPhoneNumber = values.phoneNumber && !values.email
      const hasEmail = !hasPhoneNumber

      if (hasEmail) {
        data = {
          method: 'code',
          email: values.email,
        }
      } else if (hasPhoneNumber) {
        const phoneNumber = nationalPhoneNumber(values.phoneNumber, country)

        data = {
          method: 'code',
          phoneCountryCode: country,
          phoneNumber,
        }
      }

      API()
        .resetPassword({
          body: JSON.stringify(data),
          timeoutTryAgainMessage: true,
          ...(isSDK && { sdk: true }),
        })
        .then(resp => {
          setSubmitting(false)

          if (resp.message) {
            setStatus({ message: resp.message, success: true })

            dispatch(
              showModal({
                childProps: {
                  ...(redirectTo && { redirectTo: redirectTo }),
                  ...(hasPhoneNumber && { countryCode: data.phoneCountryCode }),
                  ...(hasPhoneNumber && { phoneNumber: data.phoneNumber }),
                  ...(hasEmail && { email: values.email }),
                  type: 'passwordChange',
                },
                fullScreen: false,
                modalProps: {
                  open: true,
                  preventClose: Boolean(receiptFlow),
                },
                modalType: 'verifyPhoneNumber',
              })
            )
          } else if (resp.error) {
            setStatus({ message: resp.error.message })

            if (resp.error?.code === 408) {
              dispatch(
                showBasicModal({
                  title: resp.error.message,
                })
              )
            } else {
              showForgotPasswordError(resp.error.message)
            }
          }
        })
        .catch(err => {
          setStatus({ message: err })
          setSubmitting(false)

          showForgotPasswordError(err)
        })
    },
    displayName: 'ForgotPassword',
  })(ForgotPassword)

  return <Formik {...props} application={application} t={t} />
}

ForgotPasswordHOC.propTypes = {
  countryCode: PropTypes.string,
  phoneNumber: PropTypes.string,
  redirectTo: PropTypes.string,
  router: PropTypes.object,
}

export default withRouter(ForgotPasswordHOC)
