import { useState, useEffect } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import { useSelector, useDispatch } from 'react-redux'
import { withRouter } from 'next/router'

import {
  joinWaitlist,
  onWaitlistSuccess,
  onWaitlistError,
} from 'helpers/events'
import { showBasicModal } from 'helpers/modal'
import { useTranslation } from 'i18n-web/i18next'
import { ListItem } from 'components/Molecules'
import { showModal } from 'components/Atoms/Modal/actions'
import { Thumbnail } from 'components/Molecules/Events'
import Text from 'components/Atoms/Text'

const Title = styled(Text)`
  font-size: ${props => props.theme.font.size.mobile.tiny};
  line-height: 140%;
  font-weight: 700;
  &.isEventName {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    font-size: ${props => props.theme.font.size.desktop.regular};
  }
  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.md}rem) {
    font-size: ${props => props.theme.font.size.desktop.regular};
  }
`

const Description = styled(Text)`
  font-size: ${props => props.theme.font.size.mobile.tiny};
  font-weight: 600;
  &:last-of-type {
    line-height: 130%;
  }
  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    font-size: ${props => props.theme.font.size.desktop.small};
    font-weight: 400;
    &:last-of-type {
      line-height: 150%;
    }
  }
`

const EventItem = ({
  className,
  id,
  image,
  isBundleEvents,
  isInWaitlist,
  isWaitlistOnly,
  mainPhoto,
  name,
  onClick,
  onMouseOver,
  postponed,
  router,
  soldOut,
  start,
  timezoneName,
  venueName,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [inWaitlist, setInWaitlist] = useState(Boolean(isInWaitlist))
  const loggedIn = useSelector(state => state.session.loggedIn)
  const user = useSelector(state => state.user.me)

  useEffect(() => {
    setInWaitlist(Boolean(isInWaitlist))
  }, [isInWaitlist])

  const renderContent = () => (
    <>
      <Title className="isEventName title">{name}</Title>
      <Description>{venueName}</Description>
      {soldOut && !isWaitlistOnly && !inWaitlist ? (
        <Title className="primary">{t('events.soldOut.description')}</Title>
      ) : postponed ? (
        <Title className="primary">{t('events.postponed.description')}</Title>
      ) : (
        !isBundleEvents && (
          <Description>
            {moment(start).tz(timezoneName).format('ll')} -{' '}
            {moment(start).tz(timezoneName).format('LT')}
          </Description>
        )
      )}
    </>
  )

  const renderThumbnail = () => {
    if (soldOut && !isWaitlistOnly) {
      return <Thumbnail title={t('events.soldOut.title').toUpperCase()} />
    }
    if (postponed) {
      return <Thumbnail title={t('events.postponed.title').toUpperCase()} />
    }
    if (inWaitlist) {
      return <Thumbnail title={t('events.isInWaitlist.title').toUpperCase()} />
    }
    if (isWaitlistOnly) {
      return (
        <Thumbnail
          title={t('events.isWaitlistOnly.title').toUpperCase()}
          subtitle={t('events.isWaitlistOnly.subtitle').toUpperCase()}
        />
      )
    }

    return null
  }

  const onCardClick = () => {
    if (isWaitlistOnly && !inWaitlist) {
      let modalContent

      if (loggedIn && user.isGuest) {
        modalContent = {
          description: t('events.joinWaitlist.guestUser.description'),
          title: t('events.joinWaitlist.guestUser.title'),
          actionButtons: {
            rightButtonOnClick: () =>
              router.push({
                pathname: '/signup',
                query: {
                  redirectTo: router.asPath,
                },
              }),
            rightButtonText: t('events.joinWaitlist.guestUser.buttons.yes'),
            singleButtonOnClick: () => {},
            singleButtonText: t('events.joinWaitlist.guestUser.buttons.no'),
          },
        }
      } else if (loggedIn && !user.isGuest) {
        modalContent = {
          description: t('events.joinWaitlist.loggedUser.description'),
          title: t('events.joinWaitlist.loggedUser.title'),
          actionButtons: {
            rightButtonOnClick: async () =>
              await joinWaitlist(id).then(resp => {
                if (!resp.error) {
                  onWaitlistSuccess({
                    dispatch,
                    t,
                    preventClose: false,
                    redirectTo: () => router.asPath,
                  })
                } else {
                  if (resp.error?.code === 408) {
                    dispatch(
                      showBasicModal({
                        title: resp.error.message,
                      })
                    )
                  } else {
                    onWaitlistError(resp.error)
                  }
                }
              }),
            rightButtonText: t('events.joinWaitlist.loggedUser.buttons.yes'),
            singleButtonOnClick: () => {},
            singleButtonText: t('events.joinWaitlist.loggedUser.buttons.no'),
          },
        }
      } else {
        modalContent = {
          description: t('events.joinWaitlist.unloggedUser.description'),
          title: t('events.joinWaitlist.unloggedUser.title'),
          actionButtons: {
            rightButtonOnClick: () =>
              dispatch(
                showModal({
                  childProps: {
                    redirectTo: router.asPath,
                  },
                  modalProps: {},
                  modalType: 'signIn',
                })
              ),
            rightButtonText: t('events.joinWaitlist.unloggedUser.buttons.yes'),
            singleButtonOnClick: () => {},
            singleButtonText: t('events.joinWaitlist.unloggedUser.buttons.no'),
          },
        }
      }

      dispatch(
        showBasicModal({
          ...modalContent,
        })
      )
    } else if (postponed) {
      dispatch(
        showBasicModal({
          title: t('events.postponed.alert.title'),
          description: t('events.postponed.alert.description'),
        })
      )
    } else if (soldOut) {
      dispatch(
        showBasicModal({
          title: t('events.soldOut.alert.title'),
          description: t('events.soldOut.alert.description'),
        })
      )
    } else {
      return onClick()
    }
  }

  return (
    <ListItem
      className={className}
      image={image || mainPhoto?.fallbackUrl}
      onClick={onCardClick}
      onMouseOver={onMouseOver}
      text={renderThumbnail}>
      {renderContent()}
    </ListItem>
  )
}

EventItem.propTypes = {
  className: PropTypes.string,
  id: PropTypes.number,
  image: PropTypes.string,
  isBundleEvents: PropTypes.bool,
  isInWaitlist: PropTypes.bool,
  isWaitlistOnly: PropTypes.bool,
  mainPhoto: PropTypes.shape({ fallbackUrl: PropTypes.string }),
  name: PropTypes.string,
  onClick: PropTypes.func,
  onMouseOver: PropTypes.func,
  postponed: PropTypes.bool,
  router: PropTypes.object,
  soldOut: PropTypes.bool,
  start: PropTypes.string,
  timezoneName: PropTypes.number,
  venueName: PropTypes.any,
}

export default withRouter(EventItem)
