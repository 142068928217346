import { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { IoIosArrowDown } from 'react-icons/io'
import { isEmpty, isFunction } from 'lodash'

import AutoSuggest from 'components/Atoms/AutoSuggest'
import Label from 'components/Atoms/Label'
import Text from 'components/Atoms/Text'

const ControlContainer = styled.div`
  position: relative;
`

const Icon = styled(IoIosArrowDown)`
  position: absolute;
  pointer-events: none;
  align-items: center;
  bottom: 11px;
  right: 12px;
  font-size: 20px;
  transform: rotate(${props => (props.isFocused ? '180deg' : '0deg')});

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    bottom: 10px;
    right: 12px;
    font-size: 23px;
  }
`

const Error = styled(Text)`
  margin-top: 4px;
`

const SelectSearch = ({ data, error, isValid, label, hideArrow, ...props }) => {
  const [isFocused, setIsFocused] = useState(false)

  const onBlur = event => {
    setIsFocused(false)
    if (isFunction(props.onBlur)) {
      props.onBlur(event)
    }
  }

  const onFocus = event => {
    setIsFocused(true)
    if (isFunction(props.onFocus)) {
      props.onFocus(event)
    }
  }

  return (
    <>
      {label && <Label>{label}</Label>}
      <ControlContainer>
        <AutoSuggest
          {...props}
          isValid={isValid}
          data={isEmpty(data) ? [] : data}
          onBlur={onBlur}
          onFocus={onFocus}
        />
        {!hideArrow && <Icon isFocused={isFocused} />}
      </ControlContainer>
      {!isValid && <Error className={'tiny error'}>{error}</Error>}
    </>
  )
}

SelectSearch.propTypes = {
  data: PropTypes.array,
  error: PropTypes.string,
  isValid: PropTypes.bool,
  label: PropTypes.string,
  hideArrow: PropTypes.bool,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
}

export default SelectSearch
