import PropTypes from 'prop-types'
import styled from 'styled-components'
import moment from 'moment-timezone'

import Text from 'components/Atoms/Text'

const Details = styled(Text)`
  font-size: ${props => props.theme.font.size.mobile.tiny};
  margin-top: 4px;
  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    margin-top: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: ${props => props.theme.font.size.mobile.medium};
    font-weight: 500;
    &:first-of-type {
      font-weight: 700;
    }
    &:last-of-type {
      margin-top: 0;
    }
  }
`

const EventDetails = ({ event }) => (
  <div>
    <Details>{event.name}</Details>
    <Details>{`${event.venueName} - ${moment(event.start)
      .tz(event.timezoneName)
      .format('ll')} - ${moment(event.start)
      .tz(event.timezoneName)
      .format('LT')}`}</Details>
  </div>
)

EventDetails.propTypes = {
  event: PropTypes.object,
}

export default EventDetails
