import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import * as Cookies from 'js-cookie'
import { withRouter } from 'next/router'

import { getAppleLoginKey } from 'models/application'
import { convertToQueryString } from 'helpers'

import ApplePayButton from './Screen'

const ApplePayButtonHOC = WrapperComponent => {
  const Wrapper = props => {
    const { router, callbackRedirection, application, skipLoginFlow } = props

    const appleKey = useMemo(() => getAppleLoginKey(application), [application])

    const data = useMemo(
      () => ({
        client_id: 'com.citifyd.web',
        redirect_uri: `${process.env.NEXT_PUBLIC_API_URL}login/apple/${appleKey}`,
        response_mode: 'form_post',
        response_type: 'code',
        scope: 'name email',
      }),
      [appleKey]
    )

    const querystring = useMemo(() => convertToQueryString(data), [data])

    const appleLink = useMemo(
      () => `${process.env.NEXT_PUBLIC_APPLE_LOGIN_API_URL}?${querystring}`,
      [querystring]
    )

    const redirectCallback = () => {
      Cookies.set(
        'citifyd_apple_login_redirect',
        callbackRedirection || router.asPath
      )

      if (skipLoginFlow) {
        Cookies.set('pay_by_signage_flow', true)
      } else {
        Cookies.remove('pay_by_signage_flow')
      }
    }

    return (
      <WrapperComponent
        appleLink={appleLink}
        redirectCallback={redirectCallback}
        {...props}
      />
    )
  }

  Wrapper.propTypes = {
    callbackRedirection: PropTypes.string,
    router: PropTypes.object,
    application: PropTypes.string,
    skipLoginFlow: PropTypes.bool,
  }

  return Wrapper
}

export default withRouter(ApplePayButtonHOC(ApplePayButton))
