import { SET_TICKET, RESET_TICKET } from './actions'

const defaultState = {}

export const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_TICKET:
      return {
        ...action.payload,
      }
    case RESET_TICKET:
      return {
        ...defaultState,
      }
    default:
      return state
  }
}
