import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { MobileButton } from 'components/Molecules/ManagePass'
import PageTitle from 'components/Atoms/PageTitle'

const Component = styled.div`
  display: flex;
  flex-direction: column;
`

const List = styled.div`
  overflow: scroll;
  height: 100%;
  padding: 0 1rem 90px;
`

const Header = styled.div`
  padding: 30px 1rem 0;
`

const ManagePass = ({
  isPassScreen,
  isSDK,
  onCancel,
  onCustomerSupport,
  onDownloadApp,
  onExchange,
  onGetDirections,
  onPurchaseAnotherPass,
  onPurchaseDetails,
  onReclaim,
  onSendPassToEmail,
  onTransfer,
  onTransferAgain,
  onUpdateVehicleButtonClick,
  onViewMyPass,
  t,
  ticket,
  user,
}) => {
  const {
    isPendingTransfer,
    transferAllowed,
    exchangeAllowed,
    cancellationAllowed,
    exportAllowed,
    lot,
  } = ticket ?? {}

  const { vehicleRequired } = lot ?? {}

  const { isGuest } = user

  return (
    <Component>
      <Header>
        <PageTitle modal noBorder shadow title={t('managePass.title')} />
      </Header>
      <List>
        {isPendingTransfer && (
          <>
            <MobileButton
              type="retransfer"
              color="blueDark"
              onClick={() => onTransferAgain()}
              title={t('managePass.buttons.transferAgain')}
            />
            <MobileButton
              type="reclaim"
              color="blue"
              onClick={() => onReclaim()}
              title={t('managePass.buttons.reclaim')}
            />
          </>
        )}
        {!isPendingTransfer && (
          <>
            {((isGuest && !isSDK) || transferAllowed) && (
              <MobileButton
                type="transfer"
                color="blueDark"
                onClick={() => onTransfer()}
                title={t('managePass.buttons.transfer')}
              />
            )}
            {((isGuest && !isSDK) || exchangeAllowed) && (
              <MobileButton
                type="exchange"
                color="blue"
                onClick={() => onExchange()}
                title={t('managePass.buttons.exchange')}
              />
            )}
            {isGuest && !isSDK && vehicleRequired && (
              <MobileButton
                type="updateVehicle"
                color="primary"
                onClick={() => onUpdateVehicleButtonClick()}
                title={t('managePass.buttons.updateVehicle')}
              />
            )}
            {((isGuest && !isSDK) || cancellationAllowed) && (
              <MobileButton
                type="cancel"
                color="primary"
                onClick={() => onCancel()}
                title={t('managePass.buttons.cancel')}
              />
            )}
          </>
        )}
        <MobileButton
          type="purchase"
          color="primary"
          onClick={() => onPurchaseAnotherPass()}
          title={t('managePass.buttons.purchaseAnother')}
        />
        {!isPendingTransfer && exportAllowed && (
          <MobileButton
            type="sendToEmail"
            color="primary"
            onClick={() => onSendPassToEmail()}
            title={t('managePass.buttons.sendToEmail')}
          />
        )}
        {!isPassScreen && !isPendingTransfer && exportAllowed && (
          <MobileButton
            type="viewPass"
            color="quaternary"
            onClick={() => onViewMyPass()}
            title={t('managePass.buttons.viewPass')}
          />
        )}
        <MobileButton
          type="purchase"
          onClick={() => onPurchaseDetails()}
          title={t('managePass.buttons.purchaseDetails')}
        />
        {!isSDK && (
          <MobileButton
            type="downloadApp"
            onClick={() => onDownloadApp()}
            title={t('managePass.buttons.downloadApp')}
          />
        )}
        {isPendingTransfer && exportAllowed && (
          <MobileButton
            type="directions"
            onClick={() => onGetDirections()}
            title={t('managePass.buttons.getDirections')}
          />
        )}
        <MobileButton
          onClick={() => onCustomerSupport()}
          type="support"
          title={t('managePass.buttons.support')}
        />
      </List>
    </Component>
  )
}

ManagePass.propTypes = {
  isPassScreen: PropTypes.bool,
  isSDK: PropTypes.bool,
  onCancel: PropTypes.func,
  onCustomerSupport: PropTypes.func,
  onDownloadApp: PropTypes.func,
  onExchange: PropTypes.func,
  onGetDirections: PropTypes.func,
  onPurchaseAnotherPass: PropTypes.func,
  onPurchaseDetails: PropTypes.func,
  onReclaim: PropTypes.func,
  onSendPassToEmail: PropTypes.func,
  onTransfer: PropTypes.func,
  onTransferAgain: PropTypes.func,
  onUpdateVehicleButtonClick: PropTypes.func,
  onViewMyPass: PropTypes.func,
  t: PropTypes.func,
  ticket: PropTypes.object,
  user: PropTypes.object,
}

export default ManagePass
