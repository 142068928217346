import { useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import { hideModal } from 'components/Atoms/Modal/actions'
import { useTranslation } from 'i18n-web/i18next'
import Button from 'components/Atoms/Button'
import Text from 'components/Atoms/Text'
import ChangeCard from 'components/Molecules/Monthly/ChangeCard'

const Component = styled.div`
  text-align: center;
  min-height: 130px;
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.palette.white};
  border-radius: 6px;
`
const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

const TitleBox = styled.div`
  flex: 1;
  display: flex;
  padding: 12px 20px;
  align-items: center;
  justify-content: center;
  border: solid 3px ${props => props.theme.palette.white};
  border-bottom: none;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  background-color: ${props => props.theme.palette.primary};
`

const Title = styled(Text)`
  font-size: ${props => props.theme.font.size.mobile.h4};
  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    font-size: ${props => props.theme.font.size.mobile.h3};
  }
`
const ActionContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${props =>
    props.multipleButtons ? 'space-between' : 'center'};
  height: 50px;
  line-height: 40px;
  margin-top: 0;

  &.divisor {
    margin-top: 15px;
    border-top: 1px solid ${props => props.theme.palette.grayLight};
  }
`

const ActionContentButton = styled(Button)`
  flex: 1;
  height: 100%;

  &:last-of-type {
    border-left: solid 1px ${props => props.theme.palette.grayDark}30 !important;
  }
`

const Screen = ({ modalCallback }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { selectedCard, subscription } = useSelector(state => state.monthly)
  const [formObject, setFormObject] = useState()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isNewCard, setIsNewCard] = useState(false)

  return (
    <Component>
      <Content>
        <TitleBox>
          <Title className="center contrastText bold">
            {t('monthly.pass.changeCardModal.title')}
          </Title>
        </TitleBox>
        <ChangeCard
          setIsNewCard={setIsNewCard}
          isNewCard={isNewCard}
          setFormObject={setFormObject}
          newCardCallback={card => {
            setIsSubmitting(false)
            modalCallback(card)
          }}
        />
      </Content>
      <ActionContent className="divisor" multipleButtons={true}>
        <ActionContentButton
          disabled={isSubmitting}
          onClick={() => dispatch(hideModal())}
          className="pointer center transparent noBorders semiBold">
          {t('monthly.pass.changeCardModal.cancelButton')}
        </ActionContentButton>
        <ActionContentButton
          disabled={isSubmitting}
          className="pointer center transparent noBorders semiBold"
          onClick={() => {
            const currentCardId = subscription?.card?.id

            if (selectedCard.id === currentCardId && !isNewCard) {
              dispatch(hideModal())

              return
            }

            if (!isNewCard) {
              modalCallback(selectedCard)
            } else {
              formObject.current?.submitForm()
              setIsSubmitting(true)
            }
          }}>
          {t('monthly.pass.changeCardModal.submitButton')}
        </ActionContentButton>
      </ActionContent>
    </Component>
  )
}

Screen.propTypes = {
  modalCallback: PropTypes.func,
}

export default Screen
