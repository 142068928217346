import { Formik, Form, Field } from 'formik'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Col, Row } from 'react-styled-flexboxgrid'
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js'

import Text from 'components/Atoms/Text'
import { StripeField, Checkbox } from 'components/Molecules/Form'

import validationSchema from './validationSchema'

const Disclaimer = styled(Text)`
  margin-top: 14px;
  margin-bottom: 14px;
  font-size: ${props => props.theme.font.size.mobile.tiny};

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    font-size: ${props => props.theme.font.size.desktop.small};
  }
`

const Component = styled(Form)`
  margin-bottom: 10px;
`

const CardForm = props => {
  const {
    handleSubmit,
    initialValues,
    formikRef,
    onFieldBlur,
    selectedNativePayment,
    showSaveCard,
    showCardFields,
    t,
  } = props

  return (
    <Formik
      ref={formikRef}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema({
        isNativePayment: Boolean(selectedNativePayment),
        showCardFields: showCardFields,
      })}
      render={props => {
        const { isValid, setFieldValue, setStatus, values, handleChange } =
          props

        return (
          <Component id="cardForm">
            {!selectedNativePayment && showCardFields && (
              <>
                <Field
                  name="cardNumber"
                  onChangeCallback={event => {
                    setFieldValue(
                      'cardNumber',
                      event.error || !event.complete ? '' : event.complete
                    )
                  }}
                  component={StripeField}
                  StripeComponent={CardNumberElement}
                  label={t('purchase.form.cardNumber.label')}
                  onBlur={() => onFieldBlur(isValid)}
                  onFocus={() => setStatus(null)}
                />
                <Row>
                  <Col xs={6}>
                    <Field
                      name="cardExpiration"
                      component={StripeField}
                      StripeComponent={CardExpiryElement}
                      label={t('purchase.form.expirationDate.label')}
                      onChangeCallback={event => {
                        setFieldValue(
                          'cardExpiration',
                          event.error || !event.complete ? '' : event.complete
                        )
                      }}
                      onBlur={() => onFieldBlur(isValid)}
                      onFocus={() => setStatus(null)}
                    />
                  </Col>
                  <Col xs={6}>
                    <Field
                      name="cardCvc"
                      component={StripeField}
                      StripeComponent={CardCvcElement}
                      label={t('purchase.form.cardCode.label')}
                      onChangeCallback={event => {
                        setFieldValue(
                          'cardCvc',
                          event.error || !event.complete ? '' : event.complete
                        )
                      }}
                      onBlur={() => onFieldBlur(isValid)}
                      onFocus={() => setStatus(null)}
                    />
                  </Col>
                </Row>
                <Disclaimer className="grayMedium">
                  {t('purchase.form.disclaimer')}
                </Disclaimer>
                {showSaveCard && (
                  <Checkbox
                    name="saveCard"
                    checked={values.saveCard}
                    onClick={e => handleChange(e)}
                    label={t('purchase.form.saveCard.label')}
                    value="save"
                  />
                )}
              </>
            )}
          </Component>
        )
      }}
    />
  )
}

CardForm.propTypes = {
  t: PropTypes.func,
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  values: PropTypes.object,
  handleChange: PropTypes.func,
  formikRef: PropTypes.any,
  setFieldValue: PropTypes.func,
  setStatus: PropTypes.func,
  selectedNativePayment: PropTypes.string,
  showSaveCard: PropTypes.bool,
  onFieldBlur: PropTypes.func,
  isValid: PropTypes.bool,
  showCardFields: PropTypes.bool,
}

export default CardForm
