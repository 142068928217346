import React, { useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import moment from 'moment'

import Text from 'components/Atoms/Text'
import Dropdown from 'components/Atoms/Dropdown'
import { i18n } from 'i18n-web/i18next'

const Flag = styled.img`
  display: flex;
  height: auto;
  width: 30px;
  opacity: 0;
  transition-delay: 1s;
  opacity: 1;
  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    width: auto;
    height: 60px;
  }
`

const Selector = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 10px;
  ${Flag} {
    margin-right: 4px;
  }
`

const List = styled.div`
  border-radius: 4px;
  padding: 10px 10px 2px 10px;
  background: ${props => props.theme.palette.grayDark};
`

const Item = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  cursor: pointer;
  height: 30px;
  &:last-of-type {
    margin-bottom: 0;
  }
  ${Flag} {
    margin-right: 12px;
  }
`

const languages = [
  {
    language: 'en',
    country: 'us',
    label: 'English / USA',
  },
  {
    language: 'es',
    country: 'us',
    label: 'Spanish / USA',
  },
  {
    language: 'fr',
    country: 'ca',
    label: 'French / Canada',
  },
  {
    language: 'en',
    country: 'ca',
    label: 'English / Canada',
  },
]

const getCurrentLanguage = () => {
  const { language } = i18n

  if (language) {
    const currentLanguage = language?.split('-')[0] || 'EN'
    const currentRegion = language?.split('-')[1] || 'us'

    moment.locale(`${currentLanguage}-${currentRegion}`)

    return {
      language: currentLanguage,
      region: currentRegion,
    }
  }

  return {
    language: null,
    region: null,
  }
}

const languageList = selectedIndex => {
  const onLanguageSelect = item => {
    i18n.changeLanguage(`${item.language}-${item.country.toUpperCase()}`)
    moment.locale(`${item.language}-${item.country}`)
  }

  return (
    <List>
      {languages.map((item, index) => (
        <Item key={index} onClick={() => onLanguageSelect(item)}>
          <Flag
            src={`/static/images/country-flags/${item.country?.toLowerCase()}@2x.png`}
          />
          <Text
            className={classNames('tiny contrastText', {
              bold: index === selectedIndex,
            })}>
            {item.label}
          </Text>
        </Item>
      ))}
    </List>
  )
}

const getSelectedLanguageIndex = (selectedLanguage, selectedRegion) =>
  languages.findIndex(
    item =>
      item.language.toUpperCase() === selectedLanguage?.toUpperCase() &&
      item.country.toUpperCase() === selectedRegion?.toUpperCase()
  )

const LanguageSelector = ({ className }) => {
  const [isActive, setActive] = useState(false)
  const { language, region } = getCurrentLanguage()
  const selectedLanguageIndex = getSelectedLanguageIndex(language, region)

  return (
    <div className={className}>
      <Dropdown
        closeOnClick
        position="RightBottom"
        content={languageList(selectedLanguageIndex)}>
        <Selector onClick={() => setActive(!isActive)}>
          {region && (
            <Flag
              src={`/static/images/country-flags/${region?.toLowerCase()}@2x.png`}
            />
          )}
          {language && (
            <Text className="contrastText medium">
              {language?.toUpperCase()}
            </Text>
          )}
        </Selector>
      </Dropdown>
    </div>
  )
}

LanguageSelector.propTypes = {
  className: PropTypes.string,
}

export default LanguageSelector
