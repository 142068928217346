import {
  SET_FLOW,
  SET_RATE,
  SET_CARD,
  SET_LICENSE_PLATE,
  SET_PHONE_NUMBER,
  SET_PARKING_CODE,
  RESET_PAY_BY_SIGNAGE,
} from './actions'

const defaultState = {
  flow: {},
  selectedRate: 0,
  licensePlate: '',
  phoneNumber: {},
  parkingCode: '',
}

export const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_FLOW:
      return {
        ...state,
        flow: action.payload,
      }
    case SET_RATE:
      return {
        ...state,
        selectedRate: action.payload,
      }
    case SET_CARD:
      return {
        ...state,
        card: action.payload,
      }
    case SET_LICENSE_PLATE:
      return {
        ...state,
        licensePlate: action.payload,
      }
    case SET_PHONE_NUMBER:
      return {
        ...state,
        phoneNumber: action.payload,
      }
    case SET_PARKING_CODE:
      return {
        ...state,
        parkingCode: action.payload,
      }
    case RESET_PAY_BY_SIGNAGE:
      return {
        ...defaultState,
      }
    default:
      return state
  }
}
