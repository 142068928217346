import { useDispatch, useSelector } from 'react-redux'

import { hideModal } from 'components/Atoms/Modal/actions'
import { setFilters } from 'redux-web/utils/reservedParking/actions'

import Screen from './Screen'

const ReservedParkingFiltersHOC = props => {
  const dispatch = useDispatch()
  const { resultsCount, lots } = useSelector(state => state.reservedParking)

  const onClose = () => dispatch(hideModal())

  const onFilterChange = filter => dispatch(setFilters(filter))

  const getFilterCount = filter => {
    let count = 0

    lots?.map(lot => {
      const amenities = lot?.node?.lot?.amenities

      amenities?.map(amenity => {
        if (amenity?.code === filter) {
          count++
        }
      })
    })

    return count
  }

  return (
    <Screen
      count={resultsCount}
      getFilterCount={getFilterCount}
      onClose={onClose}
      onFilterChange={onFilterChange}
      {...props}
    />
  )
}

export default ReservedParkingFiltersHOC
