import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Formik, Form, Field } from 'formik'
import classNames from 'classnames'
import { isFunction } from 'lodash'
import { Col, Row } from 'react-styled-flexboxgrid'
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js'

import { useTranslation } from 'i18n-web/i18next'
import Button from 'components/Atoms/Button'
import { StripeField, TextField } from 'components/Molecules/Form'
import FormikObserver from 'components/Molecules/Form/FormikObserver'
import DynamicField from 'components/Molecules/Form/DynamicField'
import CountrySelectSearchField from 'components/Molecules/Form/CountrySelectSearchField'
import Text from 'components/Atoms/Text'
import CardExpiryField from 'components/Molecules/Form/CardExpiryField'

import validationSchema from './validationSchema'

const Disclaimer = styled(Text)`
  margin-top: 24px;
  margin-bottom: 12px;
`

const Container = styled.div`
  padding: 20px 18px;

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    padding: 32px 37px 37px 37px;
  }
`

const CreditCardFormContainer = ({
  formikRef,
  initialValues,
  isEditing,
  onSubmit,
  onChange,
  options,
  dynamicFields,
}) => {
  const { t } = useTranslation()

  const handleOnChange = values => {
    if (isFunction(onChange)) {
      onChange(values)
    }
  }

  return (
    <Container>
      <Formik
        ref={formikRef}
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        render={props => {
          const { isSubmitting, values, setFieldValue } = props

          return (
            <Form>
              <Field
                name="countryCode"
                component={CountrySelectSearchField}
                items={options.countries}
                disabled={isEditing}
              />

              {isEditing && (
                <Field
                  name="cardNumber"
                  label={t('purchase.form.cardNumber.label')}
                  component={TextField}
                  disabled
                />
              )}
              {!isEditing && (
                <Field
                  name="cardNumber"
                  label={t('purchase.form.cardNumber.label')}
                  component={StripeField}
                  StripeComponent={CardNumberElement}
                  onChangeCallback={event => {
                    setFieldValue(
                      'cardNumber',
                      event.error || !event.complete ? '' : event.complete
                    )
                  }}
                />
              )}
              <Field
                name="name"
                label={t('myAccount.cards.modal.form.fields.name.label')}
                component={TextField}
                validateOnChange
              />
              <Row>
                <Col xs={6}>
                  {isEditing && (
                    <CardExpiryField
                      name="cardExpiration"
                      label={t('purchase.form.expirationDate.label')}
                    />
                  )}
                  {!isEditing && (
                    <Field
                      name="cardExpiration"
                      label={t('purchase.form.expirationDate.label')}
                      component={StripeField}
                      StripeComponent={CardExpiryElement}
                      onChangeCallback={event => {
                        setFieldValue(
                          'cardExpiration',
                          event.error || !event.complete ? '' : event.complete
                        )
                      }}
                    />
                  )}
                </Col>
                <Col xs={6}>
                  {isEditing && (
                    <Field
                      name="cardCvc"
                      label={t('purchase.form.cardCode.label')}
                      component={TextField}
                      disabled
                    />
                  )}
                  {!isEditing && (
                    <Field
                      name="cardCvc"
                      label={t('purchase.form.cardCode.label')}
                      component={StripeField}
                      StripeComponent={CardCvcElement}
                      onChangeCallback={event => {
                        setFieldValue(
                          'cardCvc',
                          event.error || !event.complete ? '' : event.complete
                        )
                      }}
                    />
                  )}
                </Col>
              </Row>

              {dynamicFields?.map((field, index) => (
                <DynamicField key={index} {...field} />
              ))}

              <Disclaimer className={'tiny'}>
                {t('purchase.form.disclaimer')}
              </Disclaimer>
              <Button
                className={classNames('full uppercase')}
                disabled={isSubmitting}
                type="submit">
                {t('myAccount.vehicles.modal.form.buttons.submit')}
              </Button>
              <FormikObserver values={values} onChange={handleOnChange} />
            </Form>
          )
        }}
      />
    </Container>
  )
}

CreditCardFormContainer.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  formikRef: PropTypes.object,
  setStatus: PropTypes.func,
  status: PropTypes.string,
  isSubmitting: PropTypes.bool,
  isValid: PropTypes.bool,
  error: PropTypes.object,
  onChange: PropTypes.func,
  isLoading: PropTypes.bool,
  values: PropTypes.object,
  options: PropTypes.object,
  dynamicFields: PropTypes.array,
  isEditing: PropTypes.bool,
  setFieldValue: PropTypes.func,
}

export default CreditCardFormContainer
