import styled from 'styled-components'

import { Trans } from 'i18n-web/i18next'
import Text from 'components/Atoms/Text'

const Subtitle = styled(Text)`
  margin-bottom: 12px;
  margin-left: 48px;
  strong {
    margin-right: 5px;
    font-weight: 800;
  }
  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    margin-left: 0;
    margin-bottom: 44px;
  }
`

const EventDetails = () => (
  <Subtitle className="h4 secondary">
    <Trans i18nKey={'event.subtitle'} count={2} />
  </Subtitle>
)

export default EventDetails
