import * as Yup from 'yup'

import { i18n } from 'i18n-web/i18next'

const validationSchema = isPasswordChange =>
  Yup.object().shape({
    code: Yup.string()
      .min(
        isPasswordChange ? 6 : 4,
        i18n.t('verifyPhoneNumber.form.code.error')
      )
      .max(
        isPasswordChange ? 6 : 4,
        i18n.t('verifyPhoneNumber.form.code.error')
      )
      .required(),
  })

export default validationSchema
