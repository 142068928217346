import { SET_LOADING_REQUEST } from './actions'

const defaultState = {
  loading: false,
}

export const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_LOADING_REQUEST:
      return {
        loading: action.payload,
      }
    default:
      return state
  }
}
