const isLightMode = application => {
  switch (application) {
    case 'portland-trailblazers':
    case 'blazers':
      return false
    default:
      return true
  }
}

export default isLightMode
