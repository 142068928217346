import { combineReducers } from 'redux'

import { reducer as modal } from 'components/Atoms/Modal/reducers'

import { reducer as settings } from './utils/settings/reducers'
import { reducer as countries } from './utils/countries/reducers'
import { reducer as user } from './utils/user/reducers'
import { reducer as session } from './utils/session/reducers'
import { reducer as authentication } from './utils/authentication/reducers'
import { reducer as myPasses } from './utils/my-passes/reducers'
import { reducer as monthly } from './utils/monthly/reducers'
import { reducer as payBySignage } from './utils/payBySignage/reducers'
import { reducer as purchase } from './utils/purchase/reducers'
import { reducer as receipt } from './utils/receipt/reducers'
import { reducer as ticket } from './utils/ticket/reducers'
import { reducer as reservation } from './utils/reservation/reducers'
import { reducer as request } from './utils/request/reducers'
import { reducer as reservedParking } from './utils/reservedParking/reducers'
import { reducer as hourlySearch } from './utils/hourlySearch/reducers'

const reducers = combineReducers({
  modal,
  settings,
  countries,
  user,
  session,
  authentication,
  myPasses,
  monthly,
  payBySignage,
  purchase,
  receipt,
  ticket,
  reservation,
  request,
  reservedParking,
  hourlySearch,
})

const combinedReducers = (state, action) => {
  try {
    return reducers(state, action)
  } catch (error) {
    console.error(
      `Reducer threw error. action=${JSON.stringify(action)} error=${error}`
    )

    return state
  }
}

export default combinedReducers
