import PropTypes from 'prop-types'
import styled from 'styled-components'
import { isEmpty } from 'lodash'

const AmenitiesItem = styled.div`
  display: flex;
  align-items: center;
  font-size: ${props => props.theme.font.size.mobile.small};
`

const AmenitiesItemImage = styled.img`
  margin-right: 14px;
  width: 24px;
  height: 24px;
  object-fit: contain;
  object-position: center;
`

const Amenity = ({ code, label }) => {
  if (isEmpty(code)) {
    return null
  }

  return (
    <AmenitiesItem key={code}>
      <AmenitiesItemImage src={`/static/icons/lot-amenities/${code}.svg`} />
      {label}
    </AmenitiesItem>
  )
}

Amenity.propTypes = {
  code: PropTypes.string,
  label: PropTypes.string,
}

export default Amenity
