import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'

import { fetchMe } from 'redux-web/utils/user/actions'
import { showBasicModal } from 'helpers/modal'
import Intercom from 'helpers/intercom'
import {
  setSelectedCard,
  setNewCardOption,
  setNativePayment,
} from 'redux-web/utils/purchase/actions'
import { useTranslation } from 'i18n-web/i18next'
import { sendPageSize } from 'helpers/sdk'
import API from 'api'

import Screen from './Screen'

const CardsList = WrapperComponent => {
  const Wrapper = props => {
    const {
      guestUser,
      guestSession,
      isPaymentRequestLoad,
      isLoadingPage,
      order,
    } = props
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const user = useSelector(state => state.user.me)

    const {
      isNewCard,
      selectedCard,
      selectedNativePayment,
      nativePaymentType,
      isSubmitting,
    } = useSelector(state => state.purchase)
    const parentDomain = useSelector(state => state.settings.parentDomain)
    const isSDK = useSelector(state => state.settings.isSDK)
    const phoneNumberSupportSDK = useSelector(
      state => state.settings.phoneNumberSupport
    )
    const emailSupportSDK = useSelector(state => state.settings.emailSupport)

    const isGuest = Boolean(guestUser || guestSession)

    const { cards, defaultCardId } = user

    useEffect(() => {
      if (nativePaymentType) {
        dispatch(setNativePayment(nativePaymentType))
      }

      if (isGuest && !nativePaymentType) {
        dispatch(setNewCardOption(true))
      }
    }, [nativePaymentType])

    useEffect(() => {
      if (isPaymentRequestLoad && !isSubmitting && !isLoadingPage) {
        if (!isEmpty(user)) {
          if (nativePaymentType && order?.raw?.total > 0 && !isNewCard) {
            dispatch(setNativePayment(nativePaymentType))
          } else if (user.cards) {
            if (cards.length === 0) {
              dispatch(setNewCardOption(true))
            } else if (
              defaultCardId &&
              !user?.isGuest &&
              isEmpty(selectedCard)
            ) {
              dispatch(
                setSelectedCard(
                  cards.filter(item => item.id === defaultCardId)[0]
                )
              )
            } else if (isEmpty(selectedCard) && !user?.isGuest) {
              dispatch(setSelectedCard(cards[0]))
            }
          } else {
            dispatch(setNewCardOption(true))
          }
        } else {
          dispatch(setNewCardOption(true))
        }
      }
    }, [user, isPaymentRequestLoad, isSubmitting, isLoadingPage])

    useEffect(() => {
      if (!isSubmitting && !isLoadingPage) {
        // send a new page size to SDK
        isSDK && sendPageSize({ parentDomain, timeout: 600 })
      }
    }, [isNewCard, isSubmitting, isLoadingPage])

    useEffect(() => {
      if (!isSubmitting && !isLoadingPage) {
        // send a new page size to SDK
        !isEmpty(user) &&
          isPaymentRequestLoad &&
          isSDK &&
          sendPageSize({ parentDomain })
      }
    }, [user, isPaymentRequestLoad, isSubmitting, isLoadingPage])

    useEffect(() => {
      if (isPaymentRequestLoad) {
        isSDK && sendPageSize({ parentDomain, timeout: 600 })
      }
    }, [isPaymentRequestLoad])

    const onNativePaymentClick = type => {
      dispatch(setNativePayment(type))
    }

    const onCardClick = item => {
      dispatch(setSelectedCard(item))
    }

    const onNewCardClick = () => {
      dispatch(setNewCardOption(true))
    }

    const onDeleteCardClick = id => {
      dispatch(
        showBasicModal({
          actionButtons: {
            rightButtonOnClick: () => deleteCard(id),
            rightButtonText: t('common.button.yes'),
          },
          textButton: t('common.button.no'),
          description: t('cards.deleteCard.description'),
          title: t('cards.deleteCard.title'),
        })
      )
    }

    const onDeleteCardError = resp => {
      if (resp.error.code === 408) {
        dispatch(
          showBasicModal({
            title: resp.error.message,
          })
        )
      } else if (resp.error.code === 2509) {
        dispatch(
          showBasicModal({
            actionButtons: {
              rightButtonOnClick: () => {
                if (!isSDK) {
                  Intercom().sendMessage()
                } else {
                  if (phoneNumberSupportSDK) {
                    window.open(`tel:${phoneNumberSupportSDK}`, '_self')
                  } else if (emailSupportSDK) {
                    window.open(`mailto:${phoneNumberSupportSDK}`, '_self')
                  }
                }
              },
              rightButtonText: t('cards.unableToDelete.button'),
            },
            textButton: t('common.button.cancel'),
            description: t('cards.unableToDelete.description'),
            title: t('cards.unableToDelete.title'),
          })
        )
      } else {
        dispatch(showBasicModal({ title: resp.error.message }))
      }
    }

    const deleteCard = id =>
      API()
        .deleteCard(id, { timeoutTryAgainMessage: true })
        .then(resp => {
          if (!resp.error) {
            dispatch(fetchMe(isSDK))
          } else {
            onDeleteCardError(resp)
          }
        })
        .catch(err => dispatch(showBasicModal({ title: err.message })))

    return (
      <WrapperComponent
        {...{
          ...props,
          selectedCard,
          onCardClick,
          onNewCardClick,
          onNativePaymentClick,
          nativePaymentType,
          selectedNativePayment,
          onDeleteCardClick,
          isGuest,
          isNewCard,
          user,
          t,
        }}
      />
    )
  }

  Wrapper.propTypes = {
    guestUser: PropTypes.bool,
    guestSession: PropTypes.string,
    isPaymentRequestLoad: PropTypes.bool,
    order: PropTypes.object,
    isLoadingPage: PropTypes.bool,
  }

  return Wrapper
}

export default CardsList(Screen)
