import React from 'react'
import styled from 'styled-components'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Col, Row } from 'react-styled-flexboxgrid'

import ShadowDivisor from 'components/Atoms/ShadowDivisor'
import Text from 'components/Atoms/Text'

const Component = styled(Row)`
  position: relative;
  padding-bottom: 0;
  margin-top: -62px;
  margin-bottom: 0;
  margin-left: 0;
  align-items: flex-end;

  &.showBackButton {
    margin-left: 48px;
  }
  &.gridTemplate {
    margin-top: -52px;
    margin-bottom: 24px;
    &.showBackButton {
      margin-top: -62px;
    }
  }
  &.modal {
    margin-top: -15px;
    margin-bottom: 24px;
    padding-bottom: 10px;
  }
  &.highlight {
    margin-top: -20px;
    margin-left: 0;
  }
  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    position: relative;
    align-items: center;
    margin: 0 0 30px;
    padding-bottom: 0;
    border-bottom: solid 4px ${props => props.theme.palette.black};

    &.gridTemplate {
      margin-top: 0;
      &.showBackButton {
        margin-top: 0;
      }
    }
    &.showBackButton {
      margin-left: 0;
    }

    &.showBackButton {
      margin-left: 0;
    }

    &.modal {
      margin: 20px 35px 0;
      padding: 0;
    }
  }
  &.noBorders {
    border: none;
  }
  ${Col}:first-of-type {
    padding-left: 0;
  }

  ${Col}:last-of-type {
    padding-right: 0;
  }

  @media (max-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    &.flat {
      position: relative;
      align-items: center;
      margin: 0 0 0;
      padding-bottom: 0;
      border-bottom: solid 4px ${props => props.theme.palette.black};

      &.gridTemplate {
        margin-top: 0;
        &.showBackButton {
          margin-top: 0;
        }
      }
      &.showBackButton {
        margin-left: 0;
      }

      &.showBackButton {
        margin-left: 0;
      }

      &.modal {
        margin: 20px 35px 0;
        padding: 0;
      }
    }
  }
`

const Title = styled(Text)`
  padding-bottom: 4px;
  width: 100%;
  font-size: ${props => props.theme.font.size.mobile.h2};
  font-weight: 800;
  text-transform: uppercase;
  color: ${props => props.theme.palette.black};
  line-height: 122%;
  margin-top: 17px;
  margin-bottom: 10px;
  &.modal {
    margin-top: -2px;
    margin-bottom: 0px;
  }

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    margin: 0;
    padding-bottom: 0;
    font-size: ${props => props.theme.font.size.desktop.h3};
  }
`

const PageTitle = props => {
  const {
    className,
    title,
    rightContent,
    highlight,
    noBorder,
    modal,
    shadow,
    gridTemplate,
    showBackButton,
    rightContentColumns,
    rightContentDisabledOnMobile,
    flat,
  } = props

  return (
    <Component
      className={classNames(className, {
        noBorders: noBorder,
        modal: modal,
        highlight: highlight,
        gridTemplate: gridTemplate,
        showBackButton: showBackButton,
        flat: flat,
      })}
      center="xs">
      <Col xs={12} sm={rightContent ? 12 - rightContentColumns : 12}>
        <Title
          as="h1"
          className={classNames({
            modal: modal,
          })}
          {...props}>
          {title}
          {shadow && (
            <ShadowDivisor
              noMargin={!showBackButton}
              extraMargin={modal || (gridTemplate && showBackButton)}
            />
          )}
        </Title>
      </Col>
      {rightContent && (
        <Col
          xs={rightContentDisabledOnMobile ? false : 12}
          sm={rightContentColumns}>
          {rightContent}
        </Col>
      )}
    </Component>
  )
}

PageTitle.defaultProps = {
  showBackButton: true,
  rightContentDisabledOnMobile: false,
  rightContentColumns: 6,
}

PageTitle.propTypes = {
  title: PropTypes.string,
  rightContent: PropTypes.node,
  noBorder: PropTypes.bool,
  highlight: PropTypes.bool,
  gridTemplate: PropTypes.bool,
  modal: PropTypes.bool,
  showBackButton: PropTypes.bool,
  shadow: PropTypes.bool,
  className: PropTypes.string,
  rightContentDisabledOnMobile: PropTypes.bool,
  rightContentColumns: PropTypes.number,
  flat: PropTypes.bool,
}

export default PageTitle
