import styled from 'styled-components'
import { Formik, Form, Field } from 'formik'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Button from 'components/Atoms/Button'
import Text from 'components/Atoms/Text'
import isLightMode from 'helpers/isLightMode'
import { TextField } from 'components/Molecules/Form'
import { formatPhoneNumber } from 'helpers/phoneFormat'

import validationSchema from './validationSchema'

const Component = styled(Form)`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 20px 20px 0;

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    max-width: 450px;
  }
`

const Description = styled(Text)`
  font-size: ${props => props.theme.font.size.mobile.medium};

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    font-size: ${props => props.theme.font.size.desktop.regular};
  }
`

const VerifyTextField = styled(TextField)`
  display: flex;
  align-items: center;
  width: 100%;

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    width: 50%;
  }
`

const Group = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  width: 100%;

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    flex-direction: row;
    height: 44px;
  }

  .input {
    width: 100%;

    @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
      width: 50%;
    }
  }

  .inputField {
    margin: 0;
    width: 100%;
  }

  .button {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;

    @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
      margin-left: 10px;
      width: 50%;
    }
  }
`

const ErrorMessage = styled(Text)`
  margin-top: 15px;
  color: ${props => (props.success ? '#06a223' : props.theme.palette.error)};
  font-size: ${props => props.theme.font.size.mobile.medium};

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    font-size: ${props => props.theme.font.size.desktop.regular};
  }
`

const VerifyPhoneNumberForm = ({
  application,
  email,
  error,
  formikRef,
  handleSubmit,
  initialValues,
  isPasswordChange,
  isVerifyPhoneNumber,
  phoneNumber,
  codeSentBy,
  countryCode,
  t,
}) => (
  <Formik
    ref={ref => formikRef(ref)}
    initialValues={initialValues}
    onSubmit={handleSubmit}
    validationSchema={validationSchema(isPasswordChange)}
    render={props => {
      const { isSubmitting, setStatus, status, isValid } = props
      const message = (status && status.message) || error

      return (
        <Component>
          <Description>
            {!isVerifyPhoneNumber &&
              !isPasswordChange &&
              (codeSentBy === 'phoneCall'
                ? t('verifyCode.callInstructions', {
                    phoneNumber: formatPhoneNumber(phoneNumber, countryCode),
                  })
                : t('verifyCode.codeInstructions', {
                    phoneNumber: formatPhoneNumber(phoneNumber, countryCode),
                  }))}

            {isVerifyPhoneNumber &&
              t('verifyPhoneNumber.form.code.label', {
                phoneNumber: formatPhoneNumber(phoneNumber, countryCode),
              })}
            {isPasswordChange && email && t('verifyCode.form.emailCode.label')}
            {isPasswordChange &&
              phoneNumber &&
              t('verifyCode.form.phoneNumberCode.label')}
          </Description>
          <Group>
            <Field
              name="code"
              maxlength="4"
              className="input"
              inputClassName="inputField h2 center"
              component={VerifyTextField}
              onFocus={() => {
                message && setStatus(null)
              }}
            />
            <Button
              className={classNames('button', {
                highlight: isLightMode(application),
                grayDark: isLightMode(application),
                contrastText: !isLightMode(application),
              })}
              disabled={isSubmitting || !isValid}
              type="submit">
              {t('verifyPhoneNumber.form.button').toUpperCase()}
            </Button>
          </Group>
          {message && (
            <ErrorMessage success={status.success} className="center">
              {message}
            </ErrorMessage>
          )}
        </Component>
      )
    }}
  />
)

VerifyPhoneNumberForm.propTypes = {
  application: PropTypes.string,
  codeSentBy: PropTypes.string,
  countryCode: PropTypes.string,
  email: PropTypes.string,
  error: PropTypes.object,
  formikRef: PropTypes.func,
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  isPasswordChange: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  isValid: PropTypes.bool,
  isVerifyPhoneNumber: PropTypes.bool,
  phoneNumber: PropTypes.string,
  setStatus: PropTypes.func,
  status: PropTypes.string,
  t: PropTypes.func,
}

export default VerifyPhoneNumberForm
