import { Router } from 'i18n-web/i18next'
import { trackPageView } from 'helpers/analytics'
import { sendPageSize } from 'helpers/sdk'

import Intercom from './intercom'

export const routerEventsListening = (handler, parentDomain) => {
  Router.events.on('routeChangeStart', () => {
    handler.setState(() => ({ isLoading: true }))
  })

  Router.events.on('routeChangeComplete', url => {
    !parentDomain && Intercom().update()
    trackPageView(url)
    setTimeout(function () {
      window.scrollTo(0, 1)
      parentDomain &&
        sendPageSize({
          parentDomain,
          previousHeight: document.body.scrollHeight,
        })
    }, 100)
    handler.setState(() => ({ isLoading: false }))
  })

  Router.events.on('routeChangeError', () => {
    handler.setState(() => ({ isLoading: false }))
  })
}
