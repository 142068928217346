import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import classNames from 'classnames'
import { omit, isEmpty, get } from 'lodash'

import { i18n } from 'i18n-web/i18next'
import Text from 'components/Atoms/Text'
import Label from 'components/Atoms/Label'
import Input from 'components/Atoms/Input'
import FieldHint from 'components/Molecules/Form/FieldHint'

const Component = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;

  .label {
    margin-bottom: 6px;
  }

  p.error {
    margin-top: 4px;
    animation: fadein 0.2s;
  }

  @keyframes fadein {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
`

const Error = styled(Text)`
  margin-left: ${props => (props.extraMargin ? '64px' : 0)};

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    margin-left: ${props => (props.extraMargin ? '84px' : 0)};
  }
`

const TextField = ({
  InputComponent = Input,
  className,
  field,
  form,
  inputClassName,
  inputRef,
  label,
  validateOnFocus,
  hint,
  ...props
}) => {
  const { errors, setFieldValue, touched } = form

  const fieldError = get(errors, field.name)
  const fieldTouched = get(touched, field.name)

  const isInvalid = useMemo(
    () =>
      validateOnFocus
        ? Boolean(fieldError)
        : Boolean(fieldTouched && fieldError && fieldError.length),
    [fieldTouched, fieldError, validateOnFocus]
  )

  return (
    <Component className={className}>
      <Label>{label}</Label>
      <InputComponent
        {...field}
        {...omit(props, 'label')}
        className={classNames(inputClassName, {
          error: isInvalid,
        })}
        onFieldChange={setFieldValue}
        ref={inputRef}
      />
      {isInvalid && !isEmpty(fieldError) && (
        <Error
          extraMargin={field.name === 'phoneNumber'}
          className="tiny error">
          {i18n.t(fieldError)}
        </Error>
      )}
      <FieldHint hint={hint} />
    </Component>
  )
}

TextField.propTypes = {
  InputComponent: PropTypes.any,
  className: PropTypes.string,
  field: PropTypes.object,
  form: PropTypes.object,
  inputClassName: PropTypes.string,
  inputRef: PropTypes.object,
  label: PropTypes.string,
  validateOnFocus: PropTypes.bool,
  hint: PropTypes.string,
}

export default TextField
