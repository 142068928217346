import { withRouter } from 'next/router'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import { getReservationSession } from 'helpers/reservation'
import { useTranslation } from 'i18n-web/i18next'
import { showModal } from 'components/Atoms/Modal/actions'
import { showBasicModal } from 'helpers/modal'
import API from 'api'

import Screen from './Screen'

const FormikHOC = props => {
  const { hasActiveReservation = true, router, signUpLink } = props

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const isSDK = useSelector(state => state.settings.isSDK)

  const initialValues = {
    email: '',
  }
  const handleSubmit = (values, { setSubmitting, setStatus }) => {
    setSubmitting(true)
    const reservationSession = getReservationSession()

    const sentToEmailRequest = () =>
      hasActiveReservation
        ? API().sendActiveReservationReceipt({
            timeoutTryAgainMessage: true,
            ...(isSDK && { sdk: true }),
            ...(reservationSession && { accessToken: reservationSession }),
            body: JSON.stringify({ reservation: { email: values.email } }),
          })
        : API().sendReservationReceipt(router?.query?.id, {
            timeoutTryAgainMessage: true,
            ...(isSDK && { sdk: true }),
            body: JSON.stringify({ reservation: { email: values.email } }),
          })

    sentToEmailRequest()
      .then(resp => {
        if (!resp.error) {
          setSubmitting(false)
          let modalContent

          if (reservationSession) {
            modalContent = {
              actionButtons: {
                singleButtonOnClick: () => {},
                singleButtonText: t('common.button.close'),
              },
              title: t('onDemand.receipt.emailSent.reservation.title'),
              description: t(
                'onDemand.receipt.emailSent.reservation.description'
              ),
            }
          } else {
            modalContent = {
              actionButtons: {
                rightButtonOnClick: () => signUpLink(values.email),
                rightButtonText: t(
                  'onDemand.receipt.emailSent.guests.buttons.createAccount'
                ),
                singleButtonOnClick: () => {},
                singleButtonText: t('common.button.close'),
              },
              title: t('onDemand.receipt.emailSent.guests.title'),
              description: t('onDemand.receipt.emailSent.guests.description'),
            }
          }

          dispatch(showBasicModal({ ...modalContent }))
        } else if (resp.error.code === 401) {
          setSubmitting(false)
          dispatch(
            showModal({
              childProps: {
                preventRedirection: true,
                redirectTo: router.asPath,
              },
              modalProps: {
                preventClose: true,
                className: 'noBorders',
              },
              modalType: 'signIn',
            })
          )
        } else {
          setSubmitting(false)
          if (resp.error.message) {
            dispatch(showBasicModal({ title: resp.error.message }))
          }
        }
      })
      .catch(err => {
        setStatus({ message: err })
      })
  }

  return (
    <Screen
      {...props}
      t={t}
      initialValues={initialValues}
      handleSubmit={handleSubmit}
    />
  )
}

FormikHOC.propTypes = {
  barcodeActivation: PropTypes.bool,
  exportReceipt: PropTypes.bool,
  hasActiveReservation: PropTypes.bool,
  isRegularUser: PropTypes.bool,
  router: PropTypes.object,
  signUpLink: PropTypes.bool,
  submitText: PropTypes.string,
  t: PropTypes.func,
}

export default withRouter(FormikHOC)
