import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Col, Row } from 'react-styled-flexboxgrid'

import Button from 'components/Atoms/Button'
import Text from 'components/Atoms/Text'
import { withTranslation } from 'i18n-web/i18next'

const Component = styled(Row)`
  text-align: center;
`

const Description = styled(Text)`
  margin-top: 20px;
`

const CloseButton = styled(Button)`
  margin-top: 20px;
`

const DeviceNotCompatible = ({ t }) => (
  <Component>
    <Col xs={12}>
      <Text as="h1" className="center h2 light">
        {t('deviceNotCompatible.title')}
      </Text>
      <Description className="center">
        {t('deviceNotCompatible.description', {
          appName: 'App Name',
          venueName: 'Venue Name',
        })}
      </Description>
      <CloseButton className="primary extraPadding">
        <Text className="center contrastText">
          {t('deviceNotCompatible.button').toUpperCase()}
        </Text>
      </CloseButton>
    </Col>
  </Component>
)

DeviceNotCompatible.propTypes = {
  t: PropTypes.func,
}

export default withTranslation()(DeviceNotCompatible)
