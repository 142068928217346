import { kebabCase, isEmpty } from 'lodash'

import getImageUrl from 'helpers/image'
import {
  formatAddress,
  formatCurrency,
  formatRoundedPrice,
} from 'i18n-web/formatters'
import { getDistanceByGeoLib } from 'models/lots'
import { selectCurrencyForCountry } from 'redux-web/selectors/countries'

export const sortByDistance = lots =>
  lots?.slice().sort((a, b) => a?.node?.lot?.distance - b?.node?.lot?.distance)

export const sortByPrice = lots =>
  lots
    ?.slice()
    .sort(
      (a, b) =>
        a?.node?.calculatedPrice?.total - b?.node?.calculatedPrice?.total
    )

export const getMonthlyPlanDetailsUrl = ({ lot }) => {
  const city = lot?.address?.city || lot?.rawAddress?.city
  const state = lot?.address?.state || lot?.rawAddress?.state
  const addressLine = lot?.address?.line1 || lot?.rawAddress?.line1

  const citySegment = kebabCase(`${city}-${state}`)
  const lotAddressSegment = kebabCase(`${lot?.id}-${addressLine}`)

  return `/monthly/${citySegment}/${lotAddressSegment}`
}

export const getMonthlyPurchaseUrl = ({ lot, planId }) => {
  const purchaseUrl = getMonthlyPlanDetailsUrl({ lot })

  return `${purchaseUrl}/purchase?plan=${planId}`
}

export const getMonthlyUrl = address => {
  const locationSegment = kebabCase(address)

  return `/monthly/${locationSegment}`
}

export const getFormattedPlanPrice = ({
  value,
  total,
  fee,
  language,
  currency,
}) => {
  if (!value || !fee || !total || !language || !currency) {
    return null
  }

  return {
    value: formatCurrency(value, currency),
    fee: formatCurrency(fee, currency),
    total: formatCurrency(value + fee, currency),
  }
}

export const getFormattedLot = ({
  node,
  countries,
  lat,
  lng,
  addressFormat = 'full_inline',
}) => {
  if (isEmpty(node) || isEmpty(countries)) {
    return {}
  }

  const {
    about,
    additionalPhotos,
    address,
    amenities,
    country,
    countryCode,
    directionsNotes,
    id,
    latitude,
    longitude,
    mainPhoto,
    name,
    notes,
    plans,
    soldOut,
  } = node ?? {}

  const currency = selectCurrencyForCountry(countries, countryCode)

  const getPriceWithFee = plan => {
    const fees =
      plan?.fee?.type === 'PERCENTAGE'
        ? (plan?.fee?.value * plan?.price) / 100
        : plan?.fee?.value

    return plan?.price + fees
  }

  const getFormattedPlans = plans?.map(plan => ({
    ...plan,
    fee: {
      ...plan.fee,
      formattedFee:
        plan.fee.type === 'PERCENTAGE'
          ? `${plan.fee.value}%`
          : formatCurrency(plan.fee.value, currency),
    },
    formattedPrice: formatCurrency(plan?.price, currency),
    formattedPriceWithFees: formatCurrency(getPriceWithFee(plan), currency),
    formattedRoundedPrice: formatRoundedPrice({
      price: plan?.price,
      currency,
      language: country?.language,
    }),
  }))

  return {
    about,
    additionalPhotos,
    address,
    addressFormatted: formatAddress(address, countryCode, addressFormat),
    amenities,
    countryCode,
    currency,
    directionsNotes,
    id,
    image: getImageUrl(mainPhoto?.image?.info, 'c_thumb,w_460,h_270,g_center'),
    language: country?.language,
    latitude,
    longitude,
    mainPhoto,
    name,
    notes,
    plans: getFormattedPlans,
    soldOut,
    thumbnail: getImageUrl(
      mainPhoto?.image?.info,
      'c_thumb,w_120,h_120,g_center'
    ),
    ...(lat && lng
      ? {
          distance: getDistanceByGeoLib(latitude, longitude, lat, lng),
        }
      : {}),
  }
}

export const getFormattedSubscription = subscription => ({
  ...subscription,
  financial: {
    ...subscription?.financial,
    formattedAmountCharged: formatCurrency(
      subscription?.financial?.amountCharged,
      subscription?.currency
    ),
    formattedFee: formatCurrency(
      subscription?.financial?.fee,
      subscription?.currency
    ),
    formattedValue: formatCurrency(
      subscription?.financial?.value,
      subscription?.currency
    ),
  },
  plan: {
    ...subscription?.plan,
    formattedPrice: formatCurrency(
      subscription?.plan?.price,
      subscription?.currency
    ),
  },
})
