import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { isEmpty, isFunction } from 'lodash'

import { useTranslation } from 'i18n-web/i18next'
import Input from 'components/Atoms/Input'
import BaseTextField from 'components/Atoms/BaseTextField'

const BasicTextField = ({
  className,
  inputClassName,
  inputRef,
  label,
  validateOnFocus,
  name,
  value,
  onBlur,
  onChange,
  onFocus,
  isRequired,
}) => {
  const { t } = useTranslation()
  const [isValid, setIsValid] = useState(true)
  const [errorMessage, setErrorMessage] = useState('')

  const validate = (fieldValue = value) => {
    let isValid = true

    if (isEmpty(fieldValue) && isRequired) {
      isValid = false
      setErrorMessage(null)
    }
    setIsValid(isValid)
  }

  const handleOnBlur = e => {
    if (isFunction(onBlur)) onBlur(e)
  }

  const handleOnChange = e => {
    if (isFunction(onChange)) onChange(e.target.value)
    validate(e.target.value)
  }

  const handleOnFocus = e => {
    if (isFunction(onFocus)) onFocus(e)
    if (validateOnFocus) validate()
  }

  return (
    <BaseTextField
      className={className}
      label={label}
      isInvalid={!isValid}
      error={errorMessage || t('common.form.error.required')}>
      <Input
        name={name}
        value={value}
        onBlur={handleOnBlur}
        onChange={handleOnChange}
        onFocus={handleOnFocus}
        className={classNames(inputClassName, {
          error: !isValid,
        })}
        ref={inputRef}
      />
    </BaseTextField>
  )
}

BasicTextField.propTypes = {
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  inputRef: PropTypes.object,
  label: PropTypes.string,
  validateOnFocus: PropTypes.bool,
  name: PropTypes.string,
  value: PropTypes.string,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onChange: PropTypes.func,
  isRequired: PropTypes.bool,
}

export default BasicTextField
