export const trackPageView = url => {
  if (process.env.NEXT_PUBLIC_GOOGLE_TRACKING_ID) {
    try {
      window.gtag('config', process.env.NEXT_PUBLIC_GOOGLE_TRACKING_ID, {
        page_location: url,
      })
    } catch (error) {
      // silences the error in dev mode
      // and/or if gtag fails to load
    }
  }
}

const setGoogleTags = () => ({
  __html: `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_TRACKING_ID}');
    `,
})

export const GoogleAnalyticsScript = () =>
  process.env.NEXT_PUBLIC_GOOGLE_TRACKING_ID ? (
    <>
      <script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_TRACKING_ID}`}
      />
      <script dangerouslySetInnerHTML={setGoogleTags()} />
    </>
  ) : null
