import { SET_MY_PASSES } from '../my-passes/actions'

const defaultState = {
  list: [],
  loaded: false,
}

export const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_MY_PASSES:
      return {
        list: action.payload,
        loaded: true,
      }

    default:
      return state
  }
}
