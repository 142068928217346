import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'next/router'

import Component from './Screen'

const WhiteMode = props => {
  const { children } = props

  return <Component {...props}>{children}</Component>
}

WhiteMode.propTypes = {
  children: PropTypes.node,
}

export default withRouter(WhiteMode)
