import {
  ATTEMPT_LOGIN_BEGIN,
  ATTEMPT_LOGIN_SUCCESS,
  AUTHENTICATION_ERROR,
  AUTHENTICATION_RESET,
} from './actions'

const defaultState = {
  error: null,
  isLoading: false,
}

export const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case ATTEMPT_LOGIN_BEGIN:
      return {
        error: null,
        isLoading: true,
      }
    case ATTEMPT_LOGIN_SUCCESS:
      return {
        error: null,
        isLoading: false,
      }
    case AUTHENTICATION_ERROR:
      return {
        error: action.payload,
        isLoading: false,
      }
    case AUTHENTICATION_RESET:
      return {
        error: null,
        isLoading: false,
      }
    default:
      return state
  }
}
