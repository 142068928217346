import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'styled-components'
import { get } from 'lodash'

import { getTheme } from 'models/application'

const WithApplicationHOC = WrapperComponent => {
  const Wrapper = props => {
    const { router } = props
    const application = get(router, 'query.application', 'citifyd')
    const [theme, setTheme] = useState(getTheme(application))

    useEffect(() => {
      setTheme(getTheme(application))
    }, [router.query])

    if (!theme) {
      return null
    }

    return (
      <ThemeProvider theme={theme}>
        <WrapperComponent {...props} application={application} />
      </ThemeProvider>
    )
  }

  Wrapper.getInitialProps = async context => {
    let pageProps = {}

    if (WrapperComponent.getInitialProps) {
      pageProps = await WrapperComponent.getInitialProps(context)
    }

    const application = get(context, 'router.query.application', 'citifyd')
    const theme = getTheme(application)

    if (!theme) {
      const redirectTo = '/404'

      if (context.ctx.res) {
        context.ctx.res.writeHead(302, { Location: redirectTo })
        context.ctx.res.end()
      } else {
        window.location.replace(redirectTo)
      }
    }

    return { ...pageProps }
  }

  Wrapper.propTypes = {
    router: PropTypes.object,
  }

  return Wrapper
}

export default WithApplicationHOC
