import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import classNames from 'classnames'
import { FaCheck } from 'react-icons/fa'

import Text from 'components/Atoms/Text'

const Component = styled.label`
  display: flex;
  height: 45px;
  align-items: center;
  position: relative;
`

const CheckBoxElement = styled.div`
  margin: 5px 13px 5px 0px;
  flex: none;
  cursor: pointer;
  width: 30px;
  height: 30px;
  position: relative;

  &::before {
    content: '';
    border-radius: 4px;
    border: 1px solid ${props => props.theme.palette.grayLight};
    background: ${props => props.theme.palette.white};
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    box-sizing: border-box;
    pointer-events: none;
    z-index: 0;
  }

  &.error {
    &::before {
      border: 1px solid ${props => props.theme.palette.error};
    }
  }

  &.focus {
    &::before {
      border: 1px solid ${props => props.theme.palette.grayDark};
    }
  }
`

const CheckBoxIcon = styled(FaCheck)`
  color: ${props => props.theme.palette.grayDark};
  width: 0;
  height: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
  z-index: 1;
`

const Input = styled.input`
  opacity: 0;
  z-index: 2;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &:checked {
    & ~ ${CheckBoxIcon} {
      width: 20px;
      height: 20px;
      transition: width 0.1s ease-out, height 0.1s ease-out;
    }
  }
`

const Label = styled(Text)`
  font-size: ${props => props.theme.font.size.mobile.tiny};

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    font-size: ${props => props.theme.font.size.desktop.small};
  }
`

const Checkbox = ({
  checked,
  className,
  error,
  field,
  id,
  label,
  name,
  onClick,
  value,
}) => {
  const [isFocused, setIsFocused] = useState(false)
  const fieldName = field?.name
  const isChecked = checked || field?.value

  return (
    <>
      <Component className={className}>
        <CheckBoxElement
          className={classNames({ focus: isFocused, error: error })}>
          <Input
            id={id}
            checked={isChecked}
            name={name || fieldName}
            onChange={onClick}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            type="checkbox"
            value={value}
          />
          <CheckBoxIcon />
        </CheckBoxElement>
        <Label>{label}</Label>
      </Component>
      {error && <Text className="tiny error">{error}</Text>}
    </>
  )
}

Checkbox.propTypes = {
  checked: PropTypes.bool,
  className: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  field: PropTypes.object,
  id: PropTypes.string,
  label: PropTypes.any,
  name: PropTypes.string,
  onClick: PropTypes.func,
  value: PropTypes.any,
}

export default Checkbox
