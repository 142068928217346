import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Formik, Form, Field } from 'formik'
import classNames from 'classnames'

import Button from 'components/Atoms/Button'
import { TextField } from 'components/Molecules/Form'
import isLightMode from 'helpers/isLightMode'

import validationSchema from './validationSchema'

const FormContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 20px 0;

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    flex-direction: row;
  }
`

const FieldContainer = styled.div`
  width: 100%;
  flex: 0 0 100%;

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    width: auto;
    flex: 1;
  }
`

const ButtonContainer = styled.div`
  width: 100%;
  flex: 0 0 100%;

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    width: auto;
    flex: 0 1 auto;
  }
`

const Email = styled(TextField)`
  margin-bottom: 10px;

  input {
    margin-top: 0;
  }
`

const SendToEmail = ({
  error,
  initialValues,
  handleSubmit,
  t,
  application,
}) => (
  <Formik
    initialValues={initialValues}
    onSubmit={handleSubmit}
    validationSchema={validationSchema}
    render={props => {
      const { isSubmitting, setStatus, isValid, status } = props
      const message = (status && status.message) || error

      return (
        <Form>
          <FormContent>
            <FieldContainer>
              <Field
                name="email"
                type="email"
                inputClassName="full"
                placeholder={t('modals.sendGuestEmail.email.placeholder')}
                component={Email}
                onFocus={() => {
                  message && setStatus(null)
                }}
              />
            </FieldContainer>
            <ButtonContainer>
              <Button
                className={classNames('full uppercase', {
                  contrastText: !isLightMode(application),
                })}
                disabled={isSubmitting || !isValid}
                type="submit">
                {t('modals.sendGuestEmail.email.button')}
              </Button>
            </ButtonContainer>
          </FormContent>
        </Form>
      )
    }}
  />
)

SendToEmail.propTypes = {
  isSubmitting: PropTypes.bool,
  isValid: PropTypes.bool,
  t: PropTypes.func,
  initialValues: PropTypes.object,
  handleSubmit: PropTypes.func,
  setStatus: PropTypes.func,
  status: PropTypes.string,
  error: PropTypes.object,
  application: PropTypes.string,
}

export default SendToEmail
