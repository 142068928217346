import {
  SET_USER,
  SET_ORIGINAL_USER,
  CLEAR_USER,
  CLEAR_ORIGINAL_USER,
  FETCH_ME_BEGIN,
  FETCH_ME_ERROR,
  UPDATE_USER_BEGIN,
  UPDATE_USER_ERROR,
} from '../user/actions'

const defaultState = {
  isLoading: false,
  me: {},
  originalUser: {},
  error: null,
}

export const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        me: action.payload,
        isLoading: false,
      }
    case SET_ORIGINAL_USER:
      return {
        ...state,
        originalUser: action.payload,
        isLoading: false,
      }
    case CLEAR_USER:
      return {
        me: {},
        isLoading: false,
        error: null,
      }
    case CLEAR_ORIGINAL_USER:
      return {
        originalMe: {},
        isLoading: false,
        error: null,
      }
    case FETCH_ME_BEGIN:
    case UPDATE_USER_BEGIN: {
      return {
        ...state,
        error: null,
        isLoading: true,
      }
    }
    case FETCH_ME_ERROR: {
      return {
        me: {},
        error: null,
        isLoading: false,
      }
    }
    case UPDATE_USER_ERROR: {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      }
    }
    default:
      return state
  }
}
