import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Image from 'components/Atoms/Image'
import Text from 'components/Atoms/Text'

const Component = styled.div`
  top: 50%;
  left: 50%;
  width: 50px;
  height: 72px;
  transform: translate(-50%, -50%);
  position: absolute;
  text-align: center;
  cursor: pointer;
  transition: 0.2s;

  img {
    margin-top: -25%;
    height: 72px;
    transition: 0.2s;
  }

  p {
    transition: 0.15s;
  }

  &.active {
    z-index: 99;

    img {
      height: 72px;
    }

    p {
      top: -4px;
      left: 4px;
      font-size: 18px;
    }
  }

  &.target {
    width: 36px;
    height: 36px;

    img {
      margin-top: 0;
      height: 36px;
    }
  }

  &.spot {
    width: 36px;
    height: 50px;

    :not(&.active) {
      filter: grayscale(100%);
    }

    img {
      margin-top: 0;
      height: 50px;
    }
  }
`

const MarkerText = styled(Text)`
  position: absolute;
  top: 6px;
  left: 3px;
  right: 0;
  text-align: center;
`

const Marker = ({ className, src, text }) => (
  <Component className={className}>
    {text && (
      <MarkerText className="tiny extraBold background">{text}</MarkerText>
    )}
    <Image alt="marker" src={src} />
  </Component>
)

Marker.propTypes = {
  className: PropTypes.string,
  src: PropTypes.string.isRequired,
  text: PropTypes.string,
}

export default Marker
