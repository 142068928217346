import PropTypes from 'prop-types'
import classNames from 'classnames'
import styled from 'styled-components'
import { Formik, Form, Field } from 'formik'

import Button from 'components/Atoms/Button'
import isLightMode from 'helpers/isLightMode'
import { PhoneField, TextField } from 'components/Molecules/Form'
import { useTranslation } from 'i18n-web/i18next'

import validationSchema from './validationSchema'

const SubmitButton = styled(Button)`
  margin-top: 18px;
`

const EditAccountForm = ({
  application,
  country,
  formikRef,
  handleSubmit,
  initialValues,
}) => {
  const { t } = useTranslation()

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      ref={ref => formikRef(ref)}
      render={props => {
        const { isSubmitting, isValid, values } = props

        return (
          <Form>
            <Field
              disabled={isSubmitting}
              component={TextField}
              label={t('myAccount.settings.editAccount.modal.form.name.label')}
              name="name"
              value={values.name}
            />
            <Field
              disabled={isSubmitting}
              component={TextField}
              label={t('myAccount.settings.editAccount.modal.form.email.label')}
              name="email"
              value={values.email}
            />
            <Field
              component={PhoneField}
              country={country}
              disabled={isSubmitting}
              label={t(
                'myAccount.settings.editAccount.modal.form.phoneNumber.label'
              )}
              name="phoneNumber"
              value={values.phoneNumber}
            />
            <SubmitButton
              className={classNames('extraPadding', 'full', {
                highlight: isLightMode(application),
                contrastText: !isLightMode(application),
              })}
              disabled={isSubmitting || !isValid}
              type="submit">
              {t(
                'myAccount.settings.editAccount.modal.form.submit'
              ).toUpperCase()}
            </SubmitButton>
          </Form>
        )
      }}
      validationSchema={validationSchema}
    />
  )
}

EditAccountForm.propTypes = {
  application: PropTypes.string,
  country: PropTypes.string,
  formikRef: PropTypes.func,
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  isSubmitting: PropTypes.bool,
  isValid: PropTypes.bool,
  values: PropTypes.object,
}

export default EditAccountForm
