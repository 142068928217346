import PropTypes from 'prop-types'
import styled from 'styled-components'
import classNames from 'classnames'
import { IoIosWarning } from 'react-icons/io'

import Text from 'components/Atoms/Text'
import { ListItem } from 'components/Molecules'
import { useTranslation } from 'i18n-web/i18next'
import { Thumbnail } from 'components/Molecules/Events'

const StyledListItem = styled(ListItem)`
  width: calc(100vw - 6rem);

  hr {
    margin: 8px 0;
    border: none;
    border-top: solid 1px ${props => props.theme.palette.grayDark}30;
  }

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    width: auto;
  }
`

const TextWithEllipsis = styled(Text)`
  font-size: ${props => props.theme.font.size.mobile.tiny};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    font-size: ${props => props.theme.font.size.mobile.small};

    &.isExpanded {
      font-size: ${props => props.theme.font.size.mobile.tiny};
      text-overflow: clip;
      white-space: normal;
      overflow: visible;

      &.boldWhenExpanded {
        font-weight: bold;
      }

      &.title {
        margin-bottom: 6px;
        line-height: 1.1;
      }
    }

    &:first-of-type {
      font-size: ${props => props.theme.font.size.mobile.medium};
    }
  }
`

const OnlineSaleIsNoLongerAvailable = styled(Text)`
  font-size: ${props => props.theme.font.size.mobile.tiny};
  color: ${props => props.theme.palette.quaternary};

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.md}rem) {
    font-size: ${props => props.theme.font.size.mobile.small};
  }
`

const WarningIcon = styled(IoIosWarning)`
  color: ${props => props.theme.palette.quaternary};
  font-size: ${props => props.theme.font.size.mobile.medium};
  vertical-align: middle;
`

const WarningText = styled(Text)`
  color: ${props => props.theme.palette.quaternary};
  font-size: ${props => props.theme.font.size.mobile.tiny};
`
const LotItem = ({
  barcodeActivation,
  changePolicies,
  className,
  saleCutoff,
  description,
  id,
  image,
  isExpanded,
  onClick,
  onMouseOver,
  ticketExportAllowed,
  time,
  title,
  isDynamic,
}) => {
  const { t } = useTranslation()

  const renderThumbnail = () => {
    if (saleCutoff) {
      return (
        <Thumbnail
          className={isExpanded ? 'medium' : 'tiny'}
          title={t('event.onSitePurchase').toUpperCase()}
        />
      )
    }

    return null
  }

  return (
    <StyledListItem
      className={className}
      id={id}
      image={image}
      isExpanded={isExpanded}
      onClick={onClick}
      onMouseOver={onMouseOver}
      text={renderThumbnail}
      small>
      <TextWithEllipsis
        className={classNames({
          title: true,
          bold: true,
          isExpanded: isExpanded,
        })}>
        {title}
      </TextWithEllipsis>
      <TextWithEllipsis
        className={classNames({
          boldWhenExpanded: true,
          isExpanded: isExpanded,
        })}>
        {description}
      </TextWithEllipsis>
      <TextWithEllipsis className={classNames({ isExpanded: isExpanded })}>
        {time}
      </TextWithEllipsis>
      {isDynamic && !saleCutoff && (
        <WarningText>
          <WarningIcon />
          {t('event.pricesAreSubjectToChange')}
        </WarningText>
      )}

      {saleCutoff && (
        <OnlineSaleIsNoLongerAvailable>
          {t('event.onlineSaleIsNoLongerAvailable')}
        </OnlineSaleIsNoLongerAvailable>
      )}

      {isExpanded && (
        <>
          {((ticketExportAllowed && barcodeActivation) || changePolicies) && (
            <hr />
          )}
          {ticketExportAllowed && barcodeActivation && (
            <TextWithEllipsis
              className={classNames({
                boldWhenExpanded: true,
                isExpanded: isExpanded,
              })}>
              {t('event.printedPassOnly')}
            </TextWithEllipsis>
          )}
          {changePolicies && (
            <TextWithEllipsis
              className={classNames({ isExpanded: isExpanded })}>
              {changePolicies}
            </TextWithEllipsis>
          )}
        </>
      )}
    </StyledListItem>
  )
}

LotItem.propTypes = {
  barcodeActivation: PropTypes.bool,
  changePolicies: PropTypes.array,
  className: PropTypes.string,
  description: PropTypes.any,
  id: PropTypes.string,
  image: PropTypes.string,
  isDynamic: PropTypes.bool,
  isExpanded: PropTypes.bool,
  onClick: PropTypes.func,
  onMouseOver: PropTypes.func,
  ticketExportAllowed: PropTypes.bool,
  time: PropTypes.string,
  saleCutoff: PropTypes.bool,
  title: PropTypes.string,
}

export default LotItem
