import { useDispatch } from 'react-redux'
import {
  useStripe,
  useElements,
  CardNumberElement,
} from '@stripe/react-stripe-js'

import { useTranslation } from 'i18n-web/i18next'
import { place } from 'api/google'
import { showBasicModal } from 'helpers/modal'

const useCreateStripeToken = () => {
  const stripe = useStripe()
  const elements = useElements()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const submit = async (zipCode, country, name) => {
    try {
      if (stripe) {
        const userPlace = await place(`${zipCode}, ${country}`)
        const cardNumber = elements.getElement(CardNumberElement)

        const card = {
          address_country: country.toUpperCase(),
          address_zip: zipCode,
          address_city: userPlace && userPlace.city,
          address_state: userPlace && userPlace.state,
          name,
        }

        const response = await stripe.createToken(cardNumber, card)

        if (response.error) {
          if (response.error.type !== 'validation_error') {
            dispatch(showBasicModal({ title: response.error.message }))
          }
        }

        return response
      }
    } catch (error) {
      if (error.message) {
        dispatch(showBasicModal({ title: error.message }))
      } else {
        dispatch(showBasicModal({ title: t('common.alert.api.description') }))
      }

      throw error
    }
  }

  return { stripe, elements, submit }
}

export default useCreateStripeToken
