import CurrencyFormatter from '@citifyd/currency-formatter'
import AddressFormatter from '@citifyd/address-formatter'

import i18next from './i18next'

export const formatCurrency = (value, currency) => {
  // TO-DO make CurrencyFormatter accept language-region
  // const [language, region] = i18next.i18n.language.split('-')
  const { language } = i18next.i18n

  if (!Number.isNaN(value) && currency) {
    return CurrencyFormatter.format(value, { currency, language })
  }

  return
}

export const formatAddress = (address, countryCode, format) =>
  AddressFormatter.format(address, countryCode, format)

export const formatRoundedPrice = ({ price, currency, language }) => {
  if (CurrencyFormatter.isZeroDecimal({ currency })) {
    if (price >= 1000) {
      const roundedPrice = Math.ceil(price / 100)
      const formatted = CurrencyFormatter.format(roundedPrice, {
        currency,
        language,
      })

      return `${formatted}k` // add k to represent thousands
    }

    return CurrencyFormatter.format(price, { currency, language })
  }
  const roundedPrice = Math.ceil(price / 100) * 100
  const formatted = CurrencyFormatter.format(roundedPrice, {
    currency,
    language: 'en-US',
  })

  return formatted.replace(/\.00$/, '')
}
