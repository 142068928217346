import moment from 'moment'

import { getFormattedDate, getFormattedTime } from 'models/reservedParking'

import {
  CLEAR_FILTERS,
  RESET_RESERVED_PARKING,
  SET_ACTIVE_LOT,
  SET_ACTIVE_PIN,
  SET_AVAILABLE_FILTERS,
  SET_END_DATE,
  SET_END_TIME,
  SET_FILTERS,
  SET_LOCATION,
  SET_LOTS,
  SET_PASS,
  SET_RADIUS,
  SET_RESULTS_COUNT,
  SET_SHOW_SEARCH,
  SET_START_DATE,
  SET_START_TIME,
  SET_TIMEZONE,
} from './actions'

function round(date, duration, method) {
  return moment(Math[method](+date / +duration) * +duration).format()
}

const now = moment()
const roundedStartDate = round(now, moment.duration(30, 'minutes'), 'ceil')

const defaultStartDate = getFormattedDate(roundedStartDate)

const defaultStartTime = getFormattedTime(roundedStartDate)

const defaultEndDate = getFormattedDate(
  moment(roundedStartDate).add(2, 'hours').format()
)
const defaultEndTime = getFormattedTime(
  moment(roundedStartDate).add(2, 'hours').format()
)

const defaultState = {
  activeLot: false,
  activePin: 0,
  availableFilters: [],
  endDate: defaultEndDate,
  endTime: defaultEndTime,
  error: null,
  filters: {},
  isLoading: false,
  location: {},
  lots: [],
  pass: {},
  radius: 20,
  resultsCount: 0,
  showSearch: false,
  startDate: defaultStartDate,
  startTime: defaultStartTime,
  timezone: null,
}

export const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_LOCATION:
      return {
        ...state,
        location: {
          ...action.payload,
        },
      }

    case SET_TIMEZONE:
      return {
        ...state,
        timezone: action.payload,
      }

    case SET_START_DATE:
      return {
        ...state,
        startDate: action.payload,
      }

    case SET_END_DATE:
      return {
        ...state,
        endDate: action.payload,
      }

    case SET_START_TIME:
      return {
        ...state,
        startTime: action.payload,
      }

    case SET_END_TIME:
      return {
        ...state,
        endTime: action.payload,
      }

    case SET_RADIUS:
      return {
        ...state,
        radius: action.payload,
      }

    case SET_AVAILABLE_FILTERS:
      return {
        ...state,
        availableFilters: action.payload,
      }

    case SET_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload,
        },
      }

    case SET_LOTS:
      return {
        ...state,
        lots: action.payload,
      }

    case CLEAR_FILTERS:
      return {
        ...state,
        filters: {},
      }

    case SET_RESULTS_COUNT:
      return {
        ...state,
        resultsCount: action.payload,
      }

    case SET_ACTIVE_LOT:
      return {
        ...state,
        activeLot: action.payload,
      }

    case SET_ACTIVE_PIN:
      return {
        ...state,
        activePin: action.payload,
      }

    case RESET_RESERVED_PARKING:
      return {
        ...defaultState,
      }

    case SET_PASS:
      return {
        ...state,
        pass: action.payload,
      }

    case SET_SHOW_SEARCH:
      return {
        ...state,
        showSearch: action.payload,
      }

    default:
      return state
  }
}
