const Transfer = () => (
  <svg xmlns="http://www.w3.org/2000/svg">
    <g fill="#fff" fillRule="evenodd">
      <g>
        <g>
          <g>
            <g>
              <path
                d="M13.714 9.143v2.286l-11.429-.001v18.286h18.286V18.286h2.286V32H0V9.143h13.714zM32 0v17.143h-2.286V4.358L10.759 23.314l-1.616-1.617L28.554 2.285H14.857V0H32z"
                transform="translate(-40 -154) translate(18 133) translate(20 19) translate(2 2)"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default Transfer
