import { useMemo } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import classNames from 'classnames'

import AmenityList from 'components/Atoms/AmenityList'
import LotPhotoCarousel from 'components/Molecules/LotPhotoCarousel'
import Text from 'components/Atoms/Text'
import { getDistanceByGeoLib } from 'models/lots'
import { useTranslation } from 'i18n-web/i18next'
import { useUserCurrentLocation } from 'helpers/hooks'

const TwoColumnsRow = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  margin-top: 10px;
  align-items: center;
`
const LotDistance = styled.div`
  border-radius: 50px;
  border: 1px solid ${props => props.theme.palette.quaternary};
  color: ${props => props.theme.palette.quaternary};
  font-weight: bold;
  padding: 0 11px;
  font-size: ${props => props.theme.font.size.mobile.tiny};
`

const OrderValue = styled(Text)`
  margin-top: 15px;
`

const LotNotice = styled(Text)`
  margin-top: 15px;
`

const LotAddress = styled(Text)`
  &.customWidth {
    max-width: 60%;
  }
`

const AmenitiesTitle = styled(Text)`
  margin-bottom: 15px;
`

const Divisor = styled.div`
  margin: 20px 0;
  border-bottom: 1px solid ${props => props.theme.palette.grayLight};
`

const ContentText = styled(Text)`
  margin-top: 10px;
  white-space: pre-line;
`

const LotDetailsScreen = ({
  hideHowToPark,
  lot,
  orderTotal,
  shouldUseLocation,
}) => {
  const { t } = useTranslation()

  const {
    about,
    amenities,
    directionsNotes,
    distance,
    notes,
    reservedParkingValidationMethod,
  } = lot ?? {}

  const isQRCodeEntry = reservedParkingValidationMethod === 'QRCODE_ON_ENTRY'

  const [latitude, longitude] = shouldUseLocation
    ? useUserCurrentLocation()
    : [null, null]

  const calculatedDistance = useMemo(() => {
    if (!lot || !latitude || !longitude) {
      return null
    }

    return getDistanceByGeoLib(lot.latitude, lot.longitude, latitude, longitude)
  }, [lot, latitude, longitude])

  return (
    <div>
      <LotPhotoCarousel lot={lot} height={220} />

      <TwoColumnsRow>
        <LotAddress className={classNames('small', { customWidth: distance })}>
          {lot?.addressFormatted}
        </LotAddress>

        {distance && (
          <LotDistance>
            {t('lotDetails.distance', {
              distance: distance || calculatedDistance,
            })}
          </LotDistance>
        )}
      </TwoColumnsRow>

      {orderTotal && (
        <>
          <OrderValue className="h3">{orderTotal}</OrderValue>

          <LotNotice className="tiny gray">{t('lotDetails.notice')}</LotNotice>
        </>
      )}

      {about && (
        <>
          <Divisor />
          <Text className="medium bold gray">{t('lotDetails.about')}</Text>
          <ContentText className="small">{about}</ContentText>
        </>
      )}

      {!isEmpty(amenities) && (
        <>
          <Divisor />
          <AmenitiesTitle className="medium bold gray">
            {t('lotDetails.amenities')}
          </AmenitiesTitle>
          <AmenityList amenities={amenities} />
        </>
      )}

      {directionsNotes && (
        <>
          <Divisor />
          <Text className="medium bold gray">
            {t('lotDetails.gettingThere')}
          </Text>
          <ContentText className="small">{directionsNotes}</ContentText>
        </>
      )}

      {!hideHowToPark && (
        <>
          <Divisor />
          <Text className="medium bold gray">
            {t('lotDetails.howToPark.title')}
          </Text>
          <ContentText className="small">
            {isQRCodeEntry
              ? t('lotDetails.howToPark.qrCodeEntry')
              : t('lotDetails.howToPark.noValidation')}
          </ContentText>
        </>
      )}

      {notes && (
        <>
          <Divisor />
          <Text className="medium bold gray">
            {t('lotDetails.thingsYouShouldKnow')}
          </Text>
          <ContentText className="small">{notes}</ContentText>
        </>
      )}
    </div>
  )
}

LotDetailsScreen.propTypes = {
  hideHowToPark: PropTypes.bool,
  lot: PropTypes.object,
  orderTotal: PropTypes.number,
  shouldUseLocation: PropTypes.bool,
}

LotDetailsScreen.defaultProps = {
  shouldUseLocation: true,
  showCancellationNotice: true,
}

export default LotDetailsScreen
