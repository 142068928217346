import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { isFunction } from 'lodash'

import Input from 'components/Atoms/Input'
import FlagIcon from 'components/Atoms/CountrySelectSearch/FlagIcon'

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  align-items: center;
`

const Flag = styled(FlagIcon)`
  position: absolute;
  left: 10px;
  top: 5px;
`

const InputComponent = styled(Input)`
  padding-left: 62px;
`

const CountryInputTemplate = React.forwardRef((props, ref) => {
  const { selectedItem } = props
  const countryCode = selectedItem?.value

  const [showFlag, setShowFlag] = useState(true)

  useEffect(() => {
    setShowFlag(true)
  }, [selectedItem])

  const handleOnKeyUp = (...args) => {
    const [event] = args

    // Clear form value when user type anything on search input
    // because even if the user types the country correctly, we still need
    // the country's iso code, and this code is set only when the user selects
    // an item from the list.
    // In other words, the user cannot add a new value.
    if (event.keyCode !== 13) {
      // setFieldValue(name, '')
      setShowFlag(false)
    }

    if (isFunction(props?.onKeyUp)) {
      props.onKeyUp(...args)
    }
  }

  const handleOnBlur = (...args) => {
    setShowFlag(true)

    if (isFunction(props?.onBlur)) {
      props.onBlur(...args)
    }
  }

  return (
    <Container>
      {showFlag && <Flag countryCode={countryCode} />}
      <InputComponent
        {...props}
        onKeyUp={handleOnKeyUp}
        onBlur={handleOnBlur}
        ref={ref}
      />
    </Container>
  )
})

CountryInputTemplate.displayName = 'CountryInputTemplate'

CountryInputTemplate.propTypes = {
  selectedItem: PropTypes.object,
  onBlur: PropTypes.func,
  onKeyUp: PropTypes.func,
}

export default CountryInputTemplate
