import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { withRouter } from 'next/router'

import API from 'api'
import { getAppRoute } from 'helpers/application'
import { hideModal } from 'components/Atoms/Modal/actions'
import { setMyPasses } from 'redux-web/utils/my-passes/actions'
import { showBasicModal } from 'helpers/modal'
import { useTranslation } from 'i18n-web/i18next'

import Screen from './Screen'

const CancelYourPass = props => {
  const { ticket, preview, router } = props

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [error, setError] = useState(null)
  const [disabledLinks, setDisabledLinks] = useState(false)
  const isSDK = useSelector(state => state.settings.isSDK)

  const {
    query: { application },
  } = router

  const onCancel = () => {
    dispatch(hideModal())
  }

  const onCancelPassButton = () => {
    setDisabledLinks(true)
    API()
      .cancelTicket(ticket.id, {
        ...(isSDK && { sdk: true }),
        timeoutTryAgainMessage: true,
      })
      .then(resp => {
        if (!resp.error) {
          dispatch(hideModal())
          setError(null)
          setDisabledLinks(false)
          router.replace(
            `${getAppRoute(application, 'as')}/my-passes`,
            `${getAppRoute(application, 'url')}/my-passes`
          )

          setTimeout(
            () =>
              dispatch(
                showBasicModal({
                  description: t('cancelYourPassSuccess.description'),
                  title: t('cancelYourPassSuccess.title'),
                })
              ),
            500
          )
          API()
            .getMyEvents(
              { ...(isSDK && { sdk: true }) },
              'showPurchasedOnly=true&includeTransferred=true'
            )
            .then(resp => {
              dispatch(setMyPasses(resp.events))
            })
            .catch(() => {})
        } else {
          setError(resp.error)
          setDisabledLinks(false)

          if (resp.error.message) {
            dispatch(
              showBasicModal({
                title: resp.error.message,
              })
            )
          }
        }
      })
      .catch(() => {
        setDisabledLinks(false)
      })
  }

  return (
    <Screen
      application={application}
      disabledLinks={disabledLinks}
      error={error}
      onCancel={onCancel}
      onCancelPassButton={onCancelPassButton}
      refund={preview.refund}
      t={t}
      {...props}
    />
  )
}

CancelYourPass.propTypes = {
  preview: PropTypes.object,
  ticket: PropTypes.object,
  router: PropTypes.object,
  application: PropTypes.string,
}

export default withRouter(CancelYourPass)
