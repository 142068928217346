import moment from 'moment'
import { kebabCase } from 'lodash'

import getImageUrl from 'helpers/image'

export const getEventsSortedByDate = events => {
  if (!events) {
    return null
  }

  return Object.values(events).sort((a, b) => {
    const aStart = moment(a.start)
    const bStart = moment(b.start)

    if (aStart.isBefore(bStart)) {
      return -1
    }
    if (aStart.isSame(bStart)) {
      return a.id - b.id
    }

    return 1
  })
}

export const getFormattedEvent = item => ({
  ...item,
  image: getImageUrl(item.image || item.images),
  isInWaitlist: item.soldOut && item.waitlistEnabled && item.inWaitlist,
  isWaitlistOnly: item.soldOut && item.waitlistEnabled && item.waitlistOpen,
})

export const getEvents = events => {
  if (!events) return null

  return events.map(event => getFormattedEvent(event))
}

export const getBundles = bundles => {
  if (!bundles) return null

  return bundles.map(bundle => ({
    ...bundle,
  }))
}

export const getEventsQueryString = object =>
  Object.keys(object)
    .map(key => key + '=' + object[key])
    .join('&')

export const getEventUrl = event => kebabCase(`${event.id}-${event.name}`)
