import * as Yup from 'yup'

import { phoneNumberSchema } from 'i18n-web/validators'
import { i18n } from 'i18n-web/i18next'

const validationSchema = countryCode =>
  Yup.object().shape({
    email: Yup.string().email(i18n.t('forgotPassword.form.email.error')),
    phoneNumber: phoneNumberSchema({ countryCode, required: false }),
  })

export default validationSchema
