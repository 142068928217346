import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Elements } from '@stripe/react-stripe-js'

import { CardForm } from 'components/Molecules/Purchase'
import { Radio } from 'components/Molecules/Form'
import { useTranslation } from 'i18n-web/i18next'

const Component = styled.div`
  padding: 15px 30px 0;
`

const NewCardForm = styled.div`
  margin-top: 10px;
`

const ChangeCard = ({
  selectedCard,
  setSelectedCard,
  isNewCard,
  setIsNewCard,
  cards,
  setFormObject,
  stripeKey,
  newCardCallback,
}) => {
  const { t } = useTranslation()

  return (
    <Component>
      {cards?.map(card => (
        <Radio
          key={card.id}
          id={card.id}
          name="paymentCard"
          checked={card.id === selectedCard?.id && !isNewCard}
          onClick={() => {
            setSelectedCard(card)
            setIsNewCard(false)
          }}
          label={`${card.brand} *${card.last4}`}
          value={card.id}
        />
      ))}
      <Radio
        checked={isNewCard}
        id="paymentCard"
        label={t('monthly.pass.changeCardModal.newCardOptionLabel')}
        name="vehicle"
        onClick={() => {
          setIsNewCard(true)
        }}
      />
      <NewCardForm>
        <Elements stripe={stripeKey}>
          <CardForm
            showCardFields={isNewCard}
            setStripeElements={() => {}}
            formObject={formObject => setFormObject(formObject)}
            saveCard={true}
            submitCallback={callback => newCardCallback(callback?.card)}
          />
        </Elements>
      </NewCardForm>
    </Component>
  )
}

ChangeCard.propTypes = {
  selectedCard: PropTypes.object,
  setSelectedCard: PropTypes.func,
  setFormObject: PropTypes.func,
  newCardCallback: PropTypes.func,
  isNewCard: PropTypes.bool,
  setIsNewCard: PropTypes.func,
  stripeKey: PropTypes.string,
  cards: PropTypes.arrayOf(PropTypes.object),
}

export default ChangeCard
