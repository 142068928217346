import React, { Fragment, useMemo } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styled from 'styled-components'
import { getOrdinal } from '@citifyd/ordinal'
import { isEmpty } from 'lodash'

import AmenityList from 'components/Atoms/AmenityList'
import LotPhotoCarousel from 'components/Molecules/LotPhotoCarousel'
import Text from 'components/Atoms/Text'
import { formatAddress } from 'i18n-web/formatters'
import { getDistanceByGeoLib } from 'models/lots'
import { useTranslation } from 'i18n-web/i18next'
import { useUserCurrentLocation, useClientResponsive } from 'helpers/hooks'

const Content = styled.div`
  padding: 0;

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    padding: 0 30px;

    &.isModal {
      padding: 0;
    }
  }
`

const TwoColumnsRow = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  margin-top: 20px;
  align-items: center;
`
const LotDistance = styled.div`
  border-radius: 50px;
  border: 1px solid ${props => props.theme.palette.quaternary};
  color: ${props => props.theme.palette.quaternary};
  font-weight: bold;
  padding: 0 11px;
  font-size: ${props => props.theme.font.size.mobile.tiny};
`

const LotAddress = styled(Text)`
  &.customWidth {
    max-width: 60%;
  }
`

const AmenitiesTitle = styled(Text)`
  margin-bottom: 15px;
`

const Divisor = styled.div`
  margin: 20px 0;
  border-bottom: 1px solid ${props => props.theme.palette.grayLight};
`

const ContentText = styled(Text)`
  margin-top: 10px;
  white-space: pre-line;
`

const AvailabilityContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  align-items: center;
`

const AvailabilityImage = styled.img`
  margin-right: 14px;
  width: 24px;
  height: 24px;
  object-fit: contain;
  object-position: center;
`

const PlanContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
`
const PlanValueContainer = styled.div`
  display: flex;
  flex-direction: row;
`

const MonthText = styled(Text)`
  align-self: flex-end;
  margin-bottom: 1px;
  margin-left: 2px;
`

const PlanFiller = styled.div`
  width: 100%;
  height: 1px;
  flex: 2;
  align-self: center;
  border-top: 1px solid ${props => props.theme.palette.grayLighter};
  margin-left: 10px;
  margin-right: 10px;
`

const PlanDisclaimer = styled.div`
  margin-top: 20px;
`

const LotDetailsScreen = ({ hidePlans, isModal, lot, shouldUseLocation }) => {
  const { isMobile } = useClientResponsive()
  const { t } = useTranslation()

  const {
    about,
    address,
    amenities,
    countryCode,
    directionsNotes,
    distance,
    language,
    notes,
    plans,
  } = lot ?? {}

  const [latitude, longitude] = shouldUseLocation
    ? useUserCurrentLocation()
    : [null, null]

  const calculatedDistance = useMemo(() => {
    if (!lot || !latitude || !longitude) {
      return null
    }

    return getDistanceByGeoLib(lot.latitude, lot.longitude, latitude, longitude)
  }, [lot, latitude, longitude])

  const isPlan247 = (daysOfTheWeek, startTime, endTime) =>
    !!(
      daysOfTheWeek?.length === 7 &&
      ((startTime === '00:00:00' && endTime === '24:00:00') ||
        startTime === endTime)
    )

  const isLotOpen247 = plans?.some(({ daysOfTheWeek, endTime, startTime }) =>
    isPlan247(daysOfTheWeek, startTime, endTime)
  )

  const isPlansSoldOut = plans?.every(({ soldOut }) => !!soldOut)

  const hasPlans = !hidePlans && !isPlansSoldOut

  return (
    <div>
      <LotPhotoCarousel lot={lot} height={!isMobile ? 250 : 220} />

      <Content className={classNames({ isModal })}>
        <TwoColumnsRow>
          <LotAddress
            className={classNames('small', { customWidth: distance })}>
            {formatAddress(address, countryCode, 'full_inline')}
          </LotAddress>
          {distance && (
            <LotDistance>
              {t('lotDetails.distance', {
                distance: distance || calculatedDistance,
              })}
            </LotDistance>
          )}
        </TwoColumnsRow>

        {isLotOpen247 && (
          <AvailabilityContainer>
            <AvailabilityImage src={'/static/icons/24h.svg'} />
            <Text className="semiBold">
              {t('monthly.results.lot.lotOpen247')}
            </Text>
          </AvailabilityContainer>
        )}
        {hasPlans && (
          <>
            <Divisor />
            <Text className="medium bold gray">
              {t('monthly.results.lot.availablePlans')}
            </Text>
            {plans?.map(
              (
                {
                  billingCycle,
                  cancellationNoticePeriod,
                  daysOfTheWeek,
                  endTime,
                  fee,
                  formattedPrice,
                  name,
                  period,
                  soldOut,
                  startTime,
                },
                index
              ) => {
                if (soldOut) return

                return (
                  <Fragment key={index}>
                    <PlanContainer>
                      <Text className="bold medium">{name}</Text>
                      <PlanFiller />
                      <PlanValueContainer>
                        <Text className="bold medium">{formattedPrice}</Text>
                        <MonthText className="gray tiny">
                          {t('monthly.results.lot.period', { period })}
                        </MonthText>
                      </PlanValueContainer>
                    </PlanContainer>
                    {isPlan247(daysOfTheWeek, startTime, endTime) && (
                      <Text className="small">
                        {t('monthly.results.lot.plan247')}
                      </Text>
                    )}
                    <PlanDisclaimer>
                      {fee && (
                        <Text className="tiny">
                          {t('monthly.results.lot.serviceFee', {
                            serviceFee: fee?.formattedFee,
                          })}
                        </Text>
                      )}
                      <Text className="tiny">
                        {t('monthly.results.lot.billingCycle', {
                          day: getOrdinal(billingCycle, language),
                        })}
                      </Text>
                      <Text className="tiny">
                        {t('monthly.results.lot.cancellationNotice', {
                          cancellationDays: cancellationNoticePeriod,
                        })}
                      </Text>
                    </PlanDisclaimer>
                  </Fragment>
                )
              }
            )}
          </>
        )}

        {about && (
          <>
            <Divisor />
            <Text className="medium bold gray">{t('lotDetails.about')}</Text>
            <ContentText className="small">{about}</ContentText>
          </>
        )}

        {!isEmpty(amenities) && (
          <>
            <Divisor />
            <AmenitiesTitle className="medium bold gray">
              {t('lotDetails.amenities')}
            </AmenitiesTitle>
            <AmenityList amenities={amenities} />
          </>
        )}

        {directionsNotes && (
          <>
            <Divisor />
            <Text className="medium bold gray">
              {t('lotDetails.gettingThere')}
            </Text>
            <ContentText className="small">{directionsNotes}</ContentText>
          </>
        )}

        {notes && (
          <>
            <Divisor />
            <Text className="medium bold gray">
              {t('lotDetails.thingsYouShouldKnow')}
            </Text>
            <ContentText className="small">{notes}</ContentText>
          </>
        )}
      </Content>
    </div>
  )
}

LotDetailsScreen.propTypes = {
  hidePlans: PropTypes.bool,
  isModal: PropTypes.bool,
  lot: PropTypes.object,
  shouldUseLocation: PropTypes.bool,
}

LotDetailsScreen.defaultProps = {
  hidePlans: false,
  isModal: false,
  shouldUseLocation: true,
}

export default LotDetailsScreen
