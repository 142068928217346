import PropTypes from 'prop-types'
import styled from 'styled-components'

import Map from 'components/Atoms/Map'

const Component = styled.div`
  height: 100%;
  flex: 1;
  width: 100vw;
  margin-bottom: 0;
  display: flex;
  &:before {
    content: '';
    display: block;
    box-shadow: inset 1px 8px 7px 0px rgba(0, 0, 0, 0.15);
    width: 100%;
    z-index: 9;
    height: 18px;
    position: absolute;
  }
  .mapImage {
    text-align: center;
    width: auto;
    height: 100%;
  }

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    &:before {
      display: none;
    }
    height: 452px;
    width: 100%;
    margin-left: 0;
    margin-top: 19px;
    .mapImage {
      width: 100%;
      height: auto;
    }
  }
`

const MapComponent = ({
  activeMarker,
  fitBounds,
  mapPositions,
  onMarkerClick,
  options,
}) => (
  <Component>
    <Map
      {...mapPositions}
      fitBounds={fitBounds}
      activeMarker={activeMarker}
      onChildClick={(key, childProps) => onMarkerClick(key, childProps)}
      options={options}
    />
  </Component>
)

MapComponent.propTypes = {
  activeMarker: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  fitBounds: PropTypes.bool,
  mapPositions: PropTypes.object,
  onMarkerClick: PropTypes.func,
  options: PropTypes.object,
}

export default MapComponent
