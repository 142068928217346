const CurrencyFormatter = require('@citifyd/currency-formatter')
const NextI18Next = require('next-i18next').default
const i18nextXHRBackend = require('i18next-xhr-backend')
const getOrdinal = require('@citifyd/ordinal')
const _ = require('lodash')
const moment = require('moment')

require('moment/min/locales')

module.exports = new NextI18Next({
  backend: {
    ajax: loadLocales,
    loadPath: '{{lng}}',
    parse: data => data,
  },
  debug: false,
  interpolation: {
    escapeValue: false,
    format: (value, format, lng) => {
      if (moment.isMoment(value) && _.startsWith(format, 'dateFormat:')) {
        return value
          .locale(lng || 'en')
          .format(format.replace('dateFormat:', '').trim())
      }
      if (format === 'ordinal' || _.startsWith(format, 'ordinal:')) {
        let options = {}

        // options passed?
        if (_.startsWith(format, 'ordinal:')) {
          const optionsQueryString = format.replace('ordinal:', '').trim()
          const optionPairs = optionsQueryString.split('&').map(item => {
            const parts = item.split('=')

            return [parts[0], parts.slice(1).join('=')]
          })

          options = _.zipObject(optionPairs)
        }

        return getOrdinal(value, lng, options)
      }

      return value
    },
  },
  defaultLanguage: 'en-US',
  localePath: 'src/i18n-web/locales',
  otherLanguages: ['en-CA', 'fr-CA', 'es-US', 'ja-JP', 'pt-BR'],
  postProcess: ['processCurrency'],
  fallbackLng: 'en-US',
  use: [
    i18nextXHRBackend,
    {
      type: 'postProcessor',
      name: 'processCurrency',
      process: (value, key, options) =>
        value.replace(/\{\$([^$]+)\$\}/g, (_, content) => {
          let [variable, currencyOption] = content.split(',').map(x => x.trim())

          if (!currencyOption) {
            currencyOption = 'currency'
          }

          const amount = options[variable]

          return CurrencyFormatter.format(amount, {
            currency: options[currencyOption],
            language: options.lng,
          })
        }),
    },
  ],
  useCookie: false,
  useLocalStorage: false,
})

function loadLocales(url, options, callback) {
  try {
    let locale = require(`./locales/${url}/common.json`)

    callback(locale, { status: '200' })
  } catch (e) {
    callback(null, { status: '404' })
  }
}
