import styled from 'styled-components'
import PropTypes from 'prop-types'

import Button from 'components/Atoms/Button'
import Text from 'components/Atoms/Text'
import { VerifyPhoneNumberForm } from 'components/Molecules'

const Component = styled.div`
  text-align: center;
  min-height: 130px;
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.palette.white};
  border-radius: 6px;
`

const TitleBox = styled.div`
  flex: 1;
  display: flex;
  padding: 12px 20px;
  align-items: center;
  justify-content: center;
  border: solid 3px ${props => props.theme.palette.white};
  border-bottom: none;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  background-color: ${props => props.theme.palette.primary};

  &.error {
    background-color: ${props => props.theme.palette.error};
  }

  &.facebook {
    background-color: ${props => props.theme.palette.facebookBlue};
  }
`

const Title = styled(Text)`
  font-size: ${props => props.theme.font.size.mobile.h4};
  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    font-size: ${props => props.theme.font.size.mobile.h3};
  }
`

const ActionContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${props =>
    props.multipleButtons ? 'space-between' : 'center'};
  height: 50px;
  line-height: 40px;
  margin-top: 25px;
  border-top: 1px solid ${props => props.theme.palette.grayDark}30;
`

const ActionContentButton = styled(Button)`
  flex: 1;
  height: 100%;

  &:not(:first-child) {
    border-left: solid 1px ${props => props.theme.palette.grayDark}30;
  }
`

const VerifyPhoneNumber = ({
  countryCode,
  description,
  email,
  formikRef,
  modalCallback,
  preventClose,
  onCall,
  onCancel,
  onResendCode,
  phoneNumber,
  redirectTo,
  t,
  type,
  codeSentBy,
}) => {
  const isVerifyPhoneNumber = !type
  const isGuestLogin = type === 'guestLogin'
  const isPasswordChange = type === 'passwordChange'

  return (
    <Component>
      <TitleBox>
        <Title className="center contrastText bold">
          {isVerifyPhoneNumber && t('verifyPhoneNumber.title')}
          {(isPasswordChange || isGuestLogin) && t('verifyCode.title')}
        </Title>
      </TitleBox>
      <VerifyPhoneNumberForm
        codeSentBy={codeSentBy}
        countryCode={countryCode}
        description={description}
        email={email}
        type={type}
        formikRef={formikRef}
        preventClose={preventClose}
        redirectTo={redirectTo}
        isGuestLogin={isGuestLogin}
        isPasswordChange={isPasswordChange}
        isVerifyPhoneNumber={isVerifyPhoneNumber}
        modalCallback={modalCallback}
        phoneNumber={phoneNumber}
      />
      <ActionContent>
        {!preventClose && (
          <ActionContentButton
            className="transparent noBorders semiBold"
            onClick={() => onCancel()}>
            {t('verifyPhoneNumber.cancel')}
          </ActionContentButton>
        )}
        <ActionContentButton
          className="transparent noBorders semiBold"
          onClick={() => onResendCode()}>
          {t('verifyPhoneNumber.resendCode')}
        </ActionContentButton>
        {(isVerifyPhoneNumber || isGuestLogin) && (
          <ActionContentButton
            className="transparent noBorders semiBold"
            onClick={() => onCall()}>
            {t('verifyPhoneNumber.callMe')}
          </ActionContentButton>
        )}
      </ActionContent>
    </Component>
  )
}

VerifyPhoneNumber.propTypes = {
  countryCode: PropTypes.string,
  description: PropTypes.string,
  email: PropTypes.string,
  formikRef: PropTypes.func,
  modalCallback: PropTypes.func,
  onCall: PropTypes.func,
  onCancel: PropTypes.func,
  onResendCode: PropTypes.func,
  phoneNumber: PropTypes.string,
  t: PropTypes.func,
  type: PropTypes.string,
  redirectTo: PropTypes.string,
  preventClose: PropTypes.bool,
  codeSentBy: PropTypes.string,
}

export default VerifyPhoneNumber
