import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import styled from 'styled-components'
import classNames from 'classnames'

const Component = styled.div`
  position: relative;
  margin-top: 10px;
  padding-top: 10px;
  border-top: solid 1px rgba(55, 56, 64, 0.4);
  user-select: none;
  align-items: center;
  display: flex;

  &.small {
    margin-top: 0;
    padding-top: 0;
    height: 30px;
    border-top: none;
    border-bottom: solid 2px ${props => props.theme.palette.grayDark};
  }

  &.mobile {
    margin-top: 0;
  }
`

const Time = styled.span`
  display: block;
  font-size: ${props => props.theme.font.size.desktop.h3};
  font-weight: 800;
  line-height: 1;
  color: ${props => props.theme.palette.grayDark};

  &.small {
    display: inline-block;
    font-size: ${props => props.theme.font.size.desktop.regular};
    font-weight: 600;
  }

  &.mobile {
    display: inline-block;
    font-size: ${props => props.theme.font.size.mobile.medium};
    font-weight: 800;
  }
`

const Select = styled.div`
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  height: 300px;
  padding: 15px 0;
  background-color: ${props => props.theme.palette.background};
  border-radius: 4px;
  box-shadow: 0 0 20px 0 rgba(55, 56, 64, 0.3);
  z-index: 100;
  overflow-y: scroll;
  display: none;

  &.show {
    display: block;
  }

  &.small {
    top: 30px;
  }

  &.mobile {
    top: 38px;
  }
`

const Option = styled.span`
  display: block;
  font-size: ${props => props.theme.font.size.desktop.h4};
  font-weight: 800;
  text-align: center;
  color: ${props => props.theme.palette.grayDark};
  cursor: pointer;

  & + & {
    margin-top: 5px;
  }

  &.selected {
    color: ${props => props.theme.palette.primary};
  }

  &.disabled {
    cursor: default;
    color: ${props => props.theme.palette.grayLight};
  }
`

const TimePicker = ({
  disablePastHours,
  className,
  onChange,
  value,
  date,
  enableFromHour,
}) => {
  let optionsRefs
  const ref = useRef()
  const [isOpen, setIsOpen] = useState(false)

  const getFormattedOptionId = value =>
    value.replace(':', '-').replace(' ', '-')

  const onClickOut = e => {
    if (!ref?.current.contains(e?.target)) {
      e.stopPropagation()
      setIsOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', onClickOut)
    document.addEventListener('touchstart', onClickOut)

    return () => {
      document.removeEventListener('mousedown', onClickOut)
      document.removeEventListener('touchstart', onClickOut)
    }
  }, [])

  useEffect(() => {
    if (isOpen) {
      // GET THE SELECTED ITEM INTO THE LIST AND SCROLL THE LIST TO TOP OF THIS ELEMENT
      const currentOptionId = getFormattedOptionId(getFormattedTime(value))

      const selectedOption = optionsRefs[currentOptionId]?.current

      if (selectedOption) {
        selectedOption.parentNode.scrollTop = selectedOption.offsetTop
      }
    }
  }, [isOpen])

  const handleOnToggleClick = () => setIsOpen(prevState => !prevState)

  const handleOnChange = (hour, minute) => {
    const time = moment(value).set({ hours: hour, minutes: minute }).format()

    handleOnToggleClick()
    onChange(time)
  }

  const getFormattedTime = time => moment(time).format('hh:mm a')

  const renderTimePicker = () => {
    let startTime = 0
    const interval = 30
    const ap = ['am', 'pm']
    const values = []

    for (let i = 0; startTime < 24 * 60; i++) {
      const hour = Math.floor(startTime / 60)
      const minutes = startTime % 60
      const formattedHour = ('0' + (hour % 12)).slice(-2)
      const formattedMinutes = ('0' + minutes).slice(-2)
      const value = `${
        formattedHour === '00' ? 12 : formattedHour
      }:${formattedMinutes} ${ap[Math.floor(hour / 12)]}`
      const id = getFormattedOptionId(value)

      startTime = startTime + interval
      values.push({ hour, minutes, value, id })
    }

    optionsRefs = values.reduce((acc, value) => {
      acc[value.id] = React.createRef()

      return acc
    }, {})

    const shouldEnable = time => {
      if (enableFromHour) {
        return moment(enableFromHour, 'HH:mm a').isBefore(
          moment(time, 'HH:mm a'),
          'minutes'
        )
      }

      if (disablePastHours) {
        if (moment().isSameOrAfter(date, 'YYYY-MM-DD', 'day')) {
          return moment().isBefore(moment(time, 'HH:mm a'), 'minutes')
        }
      }

      return true
    }

    return (
      <Select className={classNames(className, { show: isOpen })}>
        {values.map(({ hour, minutes, value: optionValue, id }, index) => (
          <Option
            className={classNames({
              selected: optionValue === getFormattedTime(value),
              disabled: !shouldEnable(optionValue),
            })}
            ref={optionsRefs[id]}
            key={index}
            onClick={e => {
              if (shouldEnable(optionValue)) {
                e.stopPropagation()
                handleOnChange(hour, minutes)
              }
            }}>
            {optionValue}
          </Option>
        ))}
      </Select>
    )
  }

  return (
    <Component className={className} ref={ref} onClick={handleOnToggleClick}>
      <Time className={className}>{getFormattedTime(value)}</Time>
      {renderTimePicker()}
    </Component>
  )
}

TimePicker.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  disablePastHours: PropTypes.bool,
  date: PropTypes.string,
  enableFromHour: PropTypes.string,
}

export default TimePicker
