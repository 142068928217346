import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Component = styled.button`
  cursor: pointer;
  outline: none;
  padding: 12px 12px;
  border-radius: 4px;
  text-align: center;
  min-height: 44px;
  font-size: ${props => props.theme.font.size.mobile.medium};
  font-weight: 600;
  border: 1px solid ${props => props.theme.palette.primary};
  background-color: ${props => props.theme.palette.primary};
  color: ${props => props.theme.palette.grayDark};
  transition: 0.2s;

  &.gray {
    color: ${props => props.theme.palette.gray};
  }

  &.grayDark {
    color: ${props => props.theme.palette.grayDark};
  }

  &.contrastText {
    color: ${props => props.theme.palette.white};
  }

  &.quaternary {
    color: ${props => props.theme.palette.quaternary};
  }

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    font-size: ${props => props.theme.font.size.desktop.medium};

    &:hover {
      opacity: 0.8;
    }

    &.hover {
      &:hover {
        opacity: 0.8;
      }
    }

    :focus:not(.absolute),
    .active {
      position: relative;
      top: 1px;
      left: 1px;
      outline: none;
    }
  }

  &:disabled {
    opacity: 0.5;
  }

  &.facebook {
    font-weight: 600;
    color: #ffffff;
    background-color: #4267b2;
    border: 1px solid #4267b2;
    box-shadow: 0 2px 4px 0 rgba(155, 155, 155, 0.3);
  }

  &.applePay {
    background-color: ${props => props.theme.palette.white};
    border: 2px solid ${props => props.theme.palette.grayDark};
    box-shadow: 0 2px 4px 0 rgba(155, 155, 155, 0.3);

    & > a {
      font-size: ${props => props.theme.font.size.mobile.medium};
      font-weight: 600;
      color: #000;
    }
  }

  &.secondary {
    background-color: ${props => props.theme.palette.grayDark};
    border: 1px solid ${props => props.theme.palette.grayDark};
    color: ${props => props.theme.palette.white};
  }

  &.highlight {
    background-color: ${props => props.theme.palette.highlight};
    border: 1px solid ${props => props.theme.palette.highlight};
  }
  &.rounded {
    border-radius: 25px;
  }

  &.grayLight {
    background-color: ${props => props.theme.palette.grayLight};
    border: 1px solid ${props => props.theme.palette.grayLight};

    &:hover:not(:disabled) {
      background-color: ${props => props.theme.palette.primary};
      border: 1px solid ${props => props.theme.palette.primary};
      color: ${props => props.theme.palette.white};
      opacity: 1;
    }
  }

  &.transparentWithBorders {
    box-shadow: 0px 0px 0px 1.5px ${props => props.theme.palette.primary} inset;
    background-color: transparent;
    p {
      &:hover {
        opacity: 1;
      }
    }
    &:disabled {
      opacity: 0.5;
    }
  }

  &.surface {
    background-color: ${props => props.theme.palette.white};
    border: 1px solid ${props => props.theme.palette.white};

    p {
      &:hover {
        opacity: 1;
      }
    }
  }

  &.transparent {
    background-color: transparent;
  }

  &.uppercase {
    text-transform: uppercase;
  }

  &.noBorders {
    padding: 0;
    border: none !important;
    border-radius: 0;

    p {
      transition: 0.2s;
    }

    :hover {
      opacity: 1;
      &:disabled {
        opacity: 0.3;
      }
    }

    :focus:not(.absolute),
    .active {
      top: 0px;
      left: 0px;
    }
  }

  &.extraPadding {
    padding-left: 40px;
    padding-right: 40px;
  }

  &.full {
    width: 100%;
  }

  &.tiny {
    font-size: ${props => props.theme.font.size.mobile.tiny};
  }

  &.medium {
    font-size: ${props => props.theme.font.size.mobile.medium};
  }

  &.small {
    font-size: ${props => props.theme.font.size.mobile.small};
  }

  &.h3 {
    font-size: ${props => props.theme.font.size.mobile.h3};

    @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
      font-size: ${props => props.theme.font.size.desktop.h3};
    }
  }

  &.h4 {
    font-size: ${props => props.theme.font.size.mobile.h4};
  }

  &.extraBold {
    font-weight: 800;
  }

  &.bold {
    font-weight: 700;
  }

  &.semiBold {
    font-weight: 600;
  }

  &.normal {
    font-weight: 400;
  }

  &.light {
    font-weight: 300;
  }

  p {
    line-height: 100%;
    &:hover {
      opacity: 0.7;
    }
  }

  &.shadow {
    box-shadow: 8px 8px 10px -5px rgba(55, 56, 64, 0.3);
  }

  &:disabled {
    cursor: default;
  }

  &.inverted {
    background-color: ${props => props.theme.palette.background};
    border: 2px solid ${props => props.theme.palette.grayLight};

    :hover {
      border-color: ${props => props.theme.palette.primary};
    }
  }

  &.primaryBorder {
    border: 3px solid ${props => props.theme.palette.primary};
  }
`

const Button = React.forwardRef(({ children, ...props }, ref) => (
  <Component as={props?.href ? 'a' : 'button'} ref={ref} {...props}>
    {children}
  </Component>
))

Button.displayName = 'Button'

Button.propTypes = {
  children: PropTypes.node,
  href: PropTypes.string,
}

export default Button
