import PropTypes from 'prop-types'
import { isFunction } from 'lodash'
import moment from 'moment-timezone'
import { Field } from 'formik'

import { useTranslation } from 'i18n-web/i18next'
import Input from 'components/Atoms/Input'
import TextField from 'components/Molecules/Form/TextField'

const CardExpiryField = ({ onKeyUp, ...props }) => {
  const { t } = useTranslation()

  const validate = value => {
    var creditCardDate = moment(value, 'MM/YYYY')
    var today = moment()

    const valid =
      creditCardDate.isValid() && today < creditCardDate.add(1, 'months')

    if (!valid) {
      t('common.form.error.invalidExpiryDate')
    }
  }

  function formatString(event) {
    const allowedKeyCodes = [8]

    if (allowedKeyCodes.indexOf(event.keyCode) !== -1) {
      return
    }

    event.target.value = event.target.value
      .replace(
        /^([1-9]\/|[2-9])$/g,
        '0$1/' // 3 > 03/
      )
      .replace(
        /^(0[1-9]|1[0-2])$/g,
        '$1/' // 11 > 11/
      )
      .replace(
        /^([0-1])([3-9])$/g,
        '0$1/$2' // 13 > 01/3
      )
      .replace(
        /^(0?[1-9]|1[0-2])([0-9]{2})$/g,
        '$1/$2' // 141 > 01/41
      )
      .replace(
        /^([0]+)\/|[0]+$/g,
        '0' // 0/ > 0 and 00 > 0
      )
      .replace(
        /[^\d/]|^[/]*$/g,
        '' // To allow only digits and `/`
      )
      .replace(
        /\/\//g,
        '/' // Prevent entering more than 1 `/`
      )

    if (isFunction(onKeyUp)) {
      onKeyUp(event)
    }
  }

  return (
    <Field
      {...props}
      component={TextField}
      InputComponent={Input}
      placeholder={'MM/YYYY'}
      maxLength={7}
      onKeyUp={formatString}
      validate={validate}
    />
  )
}

CardExpiryField.propTypes = {
  onKeyUp: PropTypes.func,
}

export default CardExpiryField
