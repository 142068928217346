const getImageUrl = (image, transformation) => {
  if (image?.mode === 'url') {
    return image?.url
  }
  if (image?.mode === 'cloudinary') {
    return `https://res.cloudinary.com/${
      image?.cloudinary?.cloudName
    }/image/upload/${transformation || 'c_thumb,w_240,h_240,g_center'}/${
      image?.cloudinary?.publicId
    }.jpg`
  }
  if (image?.image1) {
    return image?.image1
  }

  return image
}

export default getImageUrl

export const getLotImages = (lot, transformation) => {
  if (!lot) {
    return []
  }

  const images = []

  const mainPhoto = lot?.mainPhoto?.image?.info
  const mainPhotoFallback = lot?.mainPhoto?.image?.fallbackUrl

  if (mainPhoto?.url || mainPhoto?.mode === 'cloudinary') {
    images.push(getImageUrl(mainPhoto, transformation))
  } else if (mainPhotoFallback) {
    images.push(mainPhotoFallback)
  }

  lot?.additionalPhotos?.forEach(photo =>
    images.push(getImageUrl(photo?.image?.info, transformation))
  )

  return images
}
